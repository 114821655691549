import * as React from 'react';
import './SessionManagementPopup.scss';
import { useEffect } from 'react';
import { Box, Popup, popupController } from '../../lib/@bit/redsky.framework.rs.996';
import Label from '../../lib/@bit/redsky.framework.rs.label/dist/Label';
import Paper from '../../components/paper/Paper';
import Icon from '../../lib/@bit/redsky.framework.rs.icon';
import { PopupProps } from '../../lib/@bit/redsky.framework.rs.996/dist/popup/Popup';
import Button from '../../lib/@bit/redsky.framework.rs.button';
import router from '../../utils/router';
import LabelButton from '../../components/labelButton/LabelButton';
import serviceFactory from '../../services/serviceFactory';
import UserService from '../../services/user/user.service';
import { rsToastify } from '../../lib/@bit/redsky.framework.rs.toastify';

export interface SessionManagementPopupProps extends PopupProps {
	successCallback?: () => void;
	handleInteraction?: any;
}

const SessionManagementPopup: React.FC<SessionManagementPopupProps> = (props) => {
	const fullProps = popupController.convertProps(props);
	const userService = serviceFactory.get<UserService>('UserService');

	useEffect(() => {
		window.history.pushState(
			null,
			'SessionManagementPopup Close',
			window.location.pathname + window.location.search
		);
		window.addEventListener('popstate', (e: PopStateEvent) => {
			e.preventDefault();
			popupController.close(SessionManagementPopup);
		});
		const id = router.subscribeToBeforeRouterNavigate(async () => {
			router.getCurrentPath();
			return true;
		});
		return () => {
			router.unsubscribeFromBeforeRouterNavigate(id);
			window.removeEventListener('popstate', (e: PopStateEvent) => {
				e.preventDefault();
				popupController.close(SessionManagementPopup);
			});
		};
	}, []);

	return (
		<Popup {...props} preventCloseByBackgroundClick>
			<Paper className={'rsSessionManagementPopup'} position={'relative'}>
				<Icon
					iconImg={'icon-close'}
					onClick={() => {
						userService.logout();
						localStorage.clear();
						// rsToastify.success('Session expired');
						// window.location.assign('/');
						popupController.closeById(fullProps.popupId);
					}}
					size={16}
					className={'closeBtn'}
					cursorPointer
				/>
				<Box className={'popupTitle'}>
					<Label variant={'h5'} className={'primaryTextColor'}>
						Notification
					</Label>
				</Box>
				<Box className={'popupBody'}>
					<div className={'girlContanar'}>
						<div className={'girlContanarMain'}>
							<p className={'girlContanarDis'}>
								Your session will expire after 1 min.
								<br />
								click on continue to keep your session active
							</p>

							<Box className="buttoncontainer" display="flex">
								<LabelButton
									look={'containedPrimary'}
									className={'closeButton'}
									onClick={() => {
										userService.logout();
										localStorage.clear();
										// rsToastify.success('Session expired');
										// window.location.assign('/');
										popupController.close(SessionManagementPopup);
									}}
									label={'close'}
									variant={'buttonSm'}
								/>

								<LabelButton
									look={'containedPrimary'}
									className={'ContinueButton'}
									onClick={() => {
										props.handleInteraction();
									}}
									label={'Continue'}
									variant={'buttonSm'}
								/>
							</Box>

							{/* <LabelButton
								className={'NoThankYouText'}
								look={'none'}
								variant={'h6'}
								label={'No, Thank You'}
								onClick={() => {
									popupController.close(CallToActionPopup);
									// popupController.open<ForgotPasswordPopupProps>(ForgotPasswordPopup);
								}}
							/> */}
							{/* <Button
								look={'none'}
								className={'NoThankYouText'}
								onClick={() => {
									popupController.close(SessionManagementPopup);
								}}
							>
								<Label variant={'buttonSm'}>No, Thank You</Label>
							</Button> */}
						</div>
						{/* <div className={'PopupGirlblock'}>
							<img className={'PopupGirl'} src={require('../../images/PopupGirl.png')} />
						</div> */}
					</div>
				</Box>
			</Paper>
		</Popup>
	);
};

export default SessionManagementPopup;
