// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rsSelect [class*='control']{cursor:pointer}.rsSelect [class*='control'].error{border-color:#f44336}.rsSelect [class*='option']{cursor:pointer}.rsSelect [class*='placeholder'].error{color:#f44336}.rsSelect .rsInputErrorMessage{text-align:left;color:#f44336}
`, "",{"version":3,"sources":["webpack://./src/lib/@bit/redsky.framework.rs.select/dist/Select.scss"],"names":[],"mappings":"AAAA,6BAME,cAAe,CANjB,mCAQG,oBAAqB,CARxB,4BAmBE,cAAe,CAnBjB,uCAwBG,aAAc,CAxBjB,+BAwCE,eAAgB,CAEhB,aAAc","sourcesContent":[".rsSelect {\n\t[class*='container'] {\n\t\t//Use to target container css\n\t}\n\t[class*='control'] {\n\t\t//Use to target Control css\n\t\tcursor: pointer;\n\t\t&.error {\n\t\t\tborder-color: #f44336;\n\t\t}\n\t}\n\t[class*='ValueContainer'] {\n\t\t//Use to target ValueContainer css\n\t}\n\t[class*='menu'] {\n\t\t//Use to target Menu css\n\t}\n\t[class*='option'] {\n\t\t//Use to target Menu css\n\t\tcursor: pointer;\n\t}\n\t[class*='placeholder'] {\n\t\t//Use to target Placeholder css\n\t\t&.error {\n\t\t\tcolor: #f44336;\n\t\t}\n\t}\n\t[class*='input'] {\n\t\t//Use to target Input css\n\t}\n\t[class*='IndicatorsContainer'] {\n\t\t//Use to target the container wrapping the separator and chevron\n\t}\n\t[class*='indicatorSeparator'] {\n\t\t//Use to target the separator between the chevron and Select\n\t}\n\t[class*='indicatorContainer'] {\n\t\t//Use to target the chevron css\n\t}\n\t.rsInputErrorMessage {\n\t\ttext-align: left;\n\n\t\tcolor: #f44336;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
