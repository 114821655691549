import * as React from 'react';
import './ImageLabel.scss';
import Label from '../../lib/@bit/redsky.framework.rs.label';
import { Box } from '../../lib/@bit/redsky.framework.rs.996';
import Img from '../../lib/@bit/redsky.framework.rs.img';
import serviceFactory from '../../services/serviceFactory';
import UserService from '../../services/user/user.service';
import { useRecoilState } from 'recoil';
import globalState from '../../state/globalState';
import { getPageFinder, undefinedHandler } from '../../utils/undefinedHandler';

interface ImageLabelProps {
	labelName: string | React.ReactNode;
	imgSrc:
		| 'alcohol.png'
		| 'beach-chair.png'
		| 'bellboy.png'
		| 'fitness.png'
		| 'golf.png'
		| 'racket.png'
		| 'shower.png'
		| 'sleep.png'
		| 'square-foot.png'
		| string;
	imgWidth: string | number;
	imgHeight: string | number;
	iconPosition: 'top' | 'right' | 'bottom' | 'left';
	labelVariant?:
		| 'h1'
		| 'h2'
		| 'h3'
		| 'h4'
		| 'h5'
		| 'h6'
		| 'sectionHeader'
		| 'title'
		| 'subtitle1'
		| 'subtitle2'
		| 'body1'
		| 'body2'
		| 'caption'
		| 'button'
		| 'overline'
		| 'srOnly'
		| 'inherit'
		| 'error'
		| string;
	className?: string;
	color?: string;
	destinationId?: number;
	onClick?: () => void;
}

const ImageLabel: React.FC<ImageLabelProps> = (props) => {
	const userService = serviceFactory.get<UserService>('UserService');
	const [user, setUser] = useRecoilState<Api.User.Res.Detail | undefined>(globalState.user);
	const [destinationSelection, setDestinationSelection] = useRecoilState<any>(globalState.destinationSelection);

	const redHeart = '../../images/accountTravelPreferences/redHeart.svg';
	const whiteHeart = '../../images/accountTravelPreferences/whiteHeart.svg';

	function buildImageSrc() {
		let imageSrc = props.imgSrc;
		let defaultImages = [
			'alcohol.png',
			'beach-chair.png',
			'bellboy.png',
			'fitness.png',
			'golf.png',
			'racket.png',
			'shower.png',
			'sleep.png',
			'square-foot.png'
		];
		if (!imageSrc) imageSrc = '';

		let newSrc = defaultImages.find((item) => item === imageSrc);
		if (newSrc) return require(`../../images/colorIcons/${newSrc}`);
		else return imageSrc;
	}

	return (
		<Box className={`rsImageLabel ${props.className || ''} ${props.iconPosition}`} onClick={props.onClick}>
			<Img src={buildImageSrc()} alt={'Image Label'} width={props.imgWidth} height={props.imgHeight} />
			{typeof props.labelName === 'string' ? (
				<Label variant={props.labelVariant} className={'label'} color={props.color} lineClamp={1}>
					{props.labelName}
				</Label>
			) : (
				props.labelName
			)}

			{user && props.destinationId && (
				<img
					className="heartIcon"
					onClick={async () => {
						if (!destinationSelection.includes(props.destinationId)) {
							setDestinationSelection((DestinationSelectionList: any) => [
								...DestinationSelectionList,
								props.destinationId
							]);

							try {
								await userService.saveMyFavoriteDestinations(
									JSON.stringify([...destinationSelection, props.destinationId]),
									user?.id
								);
							} catch (error: any) {
								const message = `Getting error :${error.message} on ${getPageFinder(
									window.location.pathname
								)} page.`;
								undefinedHandler(message);
							}
						} else {
							setDestinationSelection(
								destinationSelection.filter((item: any) => item !== props.destinationId)
							);

							try {
								await userService.saveMyFavoriteDestinations(
									JSON.stringify(
										destinationSelection.filter((item: any) => item !== props.destinationId)
									),
									user?.id
								);
							} catch (error: any) {
								const message = `Getting error :${error.message} on ${getPageFinder(
									window.location.pathname
								)} page.`;
								undefinedHandler(message);
							}
						}
					}}
					src={
						destinationSelection && destinationSelection.includes(props.destinationId)
							? redHeart
							: whiteHeart
					}
					alt="Add Card"
				/>
			)}
		</Box>
	);
};

export default ImageLabel;
