import React from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import FlipToConfirmation from '../../components/flipDotTo/FlipToConfirmation';
import './CheckoutFlowPage.scss';
import { Page, popupController } from '../../lib/@bit/redsky.framework.rs.996';
import ConfirmationImageSummary from '../../components/confirmationImageSummary/ConfirmationImageSummary';
import ThankYouCard from '../../components/thankYouCard/ThankYouCard';
import PersonalInformation from '../../components/personalInformation/PersonalInformation';
import PaymentMethod from '../../components/paymentMethod/PaymentMethod';
import RsPagedResponseData = RedSky.RsPagedResponseData;
import Policies from '../../components/policies/Policies';
import CheckoutReservationSummary from '../../components/checkoutReservationSummary/CheckoutReservationSummary';
import CheckoutBreadcrumbs from '../../components/checkoutBreadcrumbs/CheckoutBreadcrumbs';
import PrintableQrCode from '../../components/printableQrCode/PrintableQrCode';
import BookingSummaryCard from '../../components/bookingSummaryCard/BookingSummaryCard';
import Button from '../../lib/@bit/redsky.framework.rs.button';
import router from '../../utils/router';
import CheckOutPaymentCard from '../../components/checkoutPaymentCard/CheckOutPaymentCard';
import { useRecoilState, useRecoilValue } from 'recoil';
import globalState, { setRecoilExternalValue } from '../../state/globalState';
import CheckOutInfoCard from '../../components/checkoutInfoCard/CheckOutInfoCard';
import { DateUtils, NumberUtils, ObjectUtils, StringUtils, WebUtils } from '../../utils/utils';
import { rsToastify } from '../../lib/@bit/redsky.framework.rs.toastify';
import serviceFactory from '../../services/serviceFactory';
import SpinningLoaderPopup from '../../popups/spinningLoaderPopup/SpinningLoaderPopup';
import PaymentService from '../../services/payment/payment.service';
import ReservationsService from '../../services/reservations/reservations.service';
import debounce from 'lodash.debounce';
import SigninPopup from '../../popups/signin/SigninPopup';
import UserService from '../../services/user/user.service';
import Label from '../../lib/@bit/redsky.framework.rs.label/dist/Label';
import UpdatePaymentMethodPopup from '../../popups/updatePaymentMethodPopup/UpdatePaymentMethodPopup';
import UpdateAddressPopup, { UpdateAddressPopupProps } from '../../popups/updateAddressPopup/UpdateAddressPopup';
import useIsAtBreakpoint from '../../customHooks/useIsAtBreakpoint';
import QuestionnairePopup, { QuestionnairePopupProps } from '../../popups/questionnairePopup/QuestionnairePopup';
import Loader from '../../components/loader/Loader';
import TagManager from 'react-gtm-module';
import DestinationService from '../../services/destination/destination.service';
import metaCapi from '../../customHooks/useMetaCapi';
import ConvertToCashPopup, { ConvertToCashPopupProps } from '../../popups/convertToCashPopup/ConvertToCashPopup';
import NotEnoughPointsPopup, {
	NotEnoughPointsPopupProps
} from '../../popups/notEnoughPointsPopup/NotEnoughPointsPopup';
import PageViewBookingScript from './PageViewBookingScript';
import { get } from 'lodash';
import RulerAnalytics from '../../components/rulerAnalytics/RulerAnalytics';
import { getPageFinder, undefinedHandler } from '../../utils/undefinedHandler';
import AccommodationService from '../../services/accommodation/accommodation.service';
import ConfirmBoxPopup, { ConfirmBoxPopupProps } from '../../popups/confirmBoxPopup/ConfirmBoxPopup';
import SystemService from '../../services/system/system.service';
import moment from 'moment';

enum CheckoutStages {
	INFO = 0,
	PAYMENT = 1,
	BOOK = 2,
	CONFIRMATION = 3,
	AFTER_PRINT = 4
}

interface CheckoutFlowPageProps {}

var isCvvLength = 0;
const CheckoutFlowPage: React.FC<CheckoutFlowPageProps> = () => {
	let navigate = useNavigate();
	const { pathname } = useLocation();
	const [searchParams] = useSearchParams();
	const monitorUrl = pathname + searchParams;
	const isMobile = useIsAtBreakpoint();

	const paymentService = serviceFactory.get<PaymentService>('PaymentService');
	const reservationService = serviceFactory.get<ReservationsService>('ReservationsService');
	const userService = serviceFactory.get<UserService>('UserService');
	const destinationService = serviceFactory.get<DestinationService>('DestinationService');
	const accommodationService = serviceFactory.get<AccommodationService>('AccommodationService');
	const questionnaireService = serviceFactory.get<SystemService>('SystemService');
	const reservationFilters = useRecoilValue<Misc.ReservationFilters>(globalState.reservationFilters);
	const [isSavePaymentMethodGlobal, setIsSavePaymentMethodGlobal] = useRecoilState<boolean>(
		globalState.isSavePaymentMethodGlobal
	);
	const params = router.getPageUrlParams<{
		stage: number;
		data: any;
		reservationId: number;
		anonymousUserID: number;
	}>([
		{ key: 's', default: 0, type: 'integer', alias: 'stage' },
		{ key: 'data', default: 0, type: 'string', alias: 'data' },
		{ key: 'ri', default: '', type: 'string', alias: 'reservationId' },
		{ key: 'aui', default: '', type: 'string', alias: 'anonymousUserID' }
	]);
	const queryString = window.location.search;

	const urlParams = new URLSearchParams(queryString);
	const resortId: any = urlParams.get('data');
	const anonymousUserID: any = urlParams.get('anonymousUserID');

	const paramsData = ObjectUtils.smartParse(params.data as unknown as string) as Misc.BookingParams;
	const destinationId: number = paramsData.destinationId;
	const stayParams: Misc.StayParams = get(paramsData, 'stays.0', []);
	const [verifiedAccommodation, setVerifiedAccommodation] = useRecoilState<
		Api.Reservation.Res.Verification | undefined
	>(globalState.verifiedAccommodation);
	const user = useRecoilValue<Api.User.Res.Detail | undefined>(globalState.user);
	const [anonymousUserData, setAnonymousUserData] = useRecoilState<any>(globalState.anonymousUserData);
	const [checkoutUser, setCheckoutUser] = useRecoilState<Misc.Checkout | undefined>(globalState.checkoutUser);
	const [showBillingAddress, setShowBillingAddress] = useState<boolean>(false);
	const [checkoutButtonRef, setCheckoutButtonRef] = useState<React.RefObject<any> | null>(null);
	const [checkoutPaymentButtonRef, setCheckoutPaymentButtonRef] = useState<React.RefObject<any> | null>(null);
	const [userPrimaryAddress, setUserPrimaryAddress] = useState<Api.User.Address>();
	const [completeTransaction, setCompleteTransaction] = useState<boolean>(false);
	const [transactionData, setTransactionData] = useState<Api.Reservation.Res.Itinerary.Get>();
	const [userPrimaryPaymentMethod, setUserPrimaryPaymentMethod] = useState<Api.User.PaymentMethod>(
		JSON.parse(localStorage.getItem('primaryPaymentMethod')!)
	);
	const [userTempPoints, setUserTempPoints] = useState<number>(0);
	const [itineraryId, setItineraryId] = useState<any>(null);
	const [tempAnonymousUserId, setTempAnonymousUserId] = useState<any>(null);
	const mountedRef = useRef<any>(null);
	const mountedRefvalue = useRef(null);
	const [signUp, setSignUp] = useState<boolean>(checkoutUser?.shouldCreateUser || false);

	const [destinationDetails, setDestinationDetails] = useState<any>();
	const [destinationName, setDestinationName] = useState<string>('');
	const [destinationLogo, setDestinationLogo] = useState<string>('');
	const [isFormFilledOut, setIsFormFilledOut] = useState<boolean>(false);
	const [isInvalidSpreedlyIframe, setIsInvalidSpreedlyIframe] = useState<boolean>(true);
	const [isFormFilledOutPayment, setIsFormFilledOutPayment] = useState<boolean>(false);
	const [showExpectedPoint, setShowExpectedPoint] = useState<boolean>(true);
	const [redemptionHandler, setRedemptionHandler] = useState<boolean>(false);
	const [bookedStays, setBookedStays] = useState<
		{
			images: Api.Media[];
			title: string;
			price: number;
			dateBooked: Date;
			paymentMethod: any;
			confirmationNumber: string;
			currencyCode: string;
		}[]
	>(getStoredBookedStays);
	const checkoutFormHasError = useRecoilValue<Misc.CheckoutFormError>(globalState.checkoutFormHasError);
	const printRef = useRef(null);
	const [checkoutPolicy, setCheckoutPolicy] = useState(null);
	const [second, setSecond] = useState(600);
	let myInterval: any;

	mountedRefvalue.current = tempAnonymousUserId;

	function buildCheckoutUser(data: any): Misc.Checkout {
		return {
			...checkoutUser,
			personal: {
				firstName: data.firstName,
				lastName: data.lastName,
				address1: data.address.split(',')[0],
				address2: data.address.split(',')[1],
				zip: data.zipCode,
				city: data.city,
				state: data.state,
				country: data.country,
				email: data.email,
				phone: data.phone
			},
			billing: {
				firstName: data.firstName,
				lastName: data.lastName,
				address1: data.address.split(',')[0],
				address2: data.address.split(',')[1],
				zip: data.zipCode,
				city: data.city,
				state: data.state,
				country: data.country,
				email: data.email,
				phone: data.phone
			},
			shouldCreateUser: signUp
		};
	}

	useEffect(() => {
		if (anonymousUserID) {
			getAnonymousUserInfo(anonymousUserID);
		}
	}, [anonymousUserID]);

	const getAnonymousUserInfo = async (anonymousUserID: any) => {
		const res = await questionnaireService.getAnonymousUserInfo(anonymousUserID);
		const newCheckoutUser = buildCheckoutUser(res.data);
		setCheckoutUser(newCheckoutUser);
		await userService.setCheckoutUserInLocalStorage(newCheckoutUser);
	};

	useEffect(() => {
		setTimeout(() => {
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			});
		}, 100);
	}, [monitorUrl]);

	useEffect(() => {
		return () => {
			if (checkoutUser) {
				setCheckoutUser({
					...checkoutUser,
					isAffirmCheckout: false
				});
			}
		};
	}, []);

	useEffect(() => {
		myInterval = setInterval(async () => {
			if (second == 0) {
				popupController.open<ConfirmBoxPopupProps>(ConfirmBoxPopup, {});
				clearInterval(myInterval);
			} else {
				setSecond(second - 1);
			}
		}, 1000);
		return () => {
			clearInterval(myInterval);
		};
	}, []);

	async function getAccommodationPolicy() {
		const searchQueryObj: Misc.ReservationFilters = { ...reservationFilters, destinationId: destinationId };
		let key: keyof Misc.ReservationFilters;
		for (key in searchQueryObj) {
			if (searchQueryObj[key] === undefined) delete searchQueryObj[key];
		}

		let accommodationPolicy: any = await accommodationService.getBookingPolicy(
			destinationId,
			searchQueryObj,
			destinationId,
			verifiedAccommodation?.accommodationId
		);

		accommodationPolicy.find((accommodation: any) => {
			let policy = accommodation.contentLists.PolicyList.CancelPolicy[0].Description;
			setCheckoutPolicy(policy);
		});
	}

	useEffect(() => {
		if (verifiedAccommodation?.accommodationName && destinationId && reservationFilters) {
			getAccommodationPolicy().catch(console.error);
		}
	}, [destinationId, verifiedAccommodation, reservationFilters]);

	useEffect(() => {
		async function getDestination() {
			try {
				const res = await destinationService.getDestinationById({ id: destinationId });

				setDestinationName(res.name);
				setDestinationDetails(res);
				setDestinationLogo(res.logoUrl);
				if (reservationFilters.redeemPoints && res.loyaltyStatus !== 'ACTIVE') {
					rsToastify.warning('This destination is not accepting points at the moment.', 'No Points Allowed');
					popupController.open<ConvertToCashPopupProps>(ConvertToCashPopup, {
						accommodationId: stayParams.accommodationId,
						destinationDetails: destinationDetails,
						destinationId: destinationId,
						packageLength: 0,
						destinationName: res.name
					});
				}
			} catch (error: any) {
				const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
				undefinedHandler(message);
			}
		}
		getDestination().catch(console.error);
	}, [destinationId]);

	useEffect(() => {
		let id = router.subscribeToBeforeRouterNavigate(async (newPath) => {
			let disableNavGate = newPath === '/booking/checkout' && params.stage === CheckoutStages.CONFIRMATION;
			if (disableNavGate) rsToastify.info('You cannot go back after a successful checkout', 'Sorry!');

			return disableNavGate;
		});

		async function sendAnonymousUserInfo(data?: any) {
			try {
				if (params.stage == 1 && !user && CheckoutStages.PAYMENT) {
					const tempdata = {
						firstName: checkoutUser?.personal.firstName,
						lastName: checkoutUser?.personal.lastName,
						address: `${checkoutUser?.personal?.address1},${checkoutUser?.personal?.address2}`,
						zipCode: checkoutUser?.personal.zip,
						city: checkoutUser?.personal.city,
						state: checkoutUser?.personal.state,
						country: checkoutUser?.personal.country,
						email: checkoutUser?.personal.email,
						phone: Number(checkoutUser?.personal.phone)
					};

					let res: any;

					if (data) {
						res = await questionnaireService.upDateAnonymousUserInfo(tempdata, data);
					} else {
						res = await questionnaireService.sendAnonymousUserInfo(tempdata);
					}
					setTempAnonymousUserId(res.data.id);
					localStorage.setItem('TempAnonymousUser', JSON.stringify(res.data));

					// tempAnonymousUserId = res.data.id;
				}
			} catch (error: any) {
				const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
				undefinedHandler(message);
			}
		}

		const TempAnonymousUser: any = JSON.parse(localStorage.getItem('TempAnonymousUser') ?? 'null');

		if (!TempAnonymousUser) {
			if (anonymousUserID) {
				sendAnonymousUserInfo(anonymousUserID).catch(console.error);
			} else {
				sendAnonymousUserInfo().catch(console.error);
			}
		} else {
			if (
				TempAnonymousUser &&
				TempAnonymousUser.email &&
				TempAnonymousUser.email !== checkoutUser?.personal.email
			) {
				if (anonymousUserID) {
					sendAnonymousUserInfo(anonymousUserID).catch(console.error);
				} else {
					sendAnonymousUserInfo().catch(console.error);
				}
			}
		}

		return () => {
			router.unsubscribeFromBeforeRouterNavigate(id);
		};
	}, [params.stage]);

	useEffect(() => {
		if (params.stage !== CheckoutStages.CONFIRMATION && params.stage !== CheckoutStages.AFTER_PRINT) {
			const tempPoints = (user?.availablePoints || 0) - (verifiedAccommodation?.prices.grandTotalPoints || 0);
			setUserTempPoints(tempPoints);
		}
	}, [user]);

	useEffect(() => {
		try {
			if (!reservationFilters.redeemPoints || !verifiedAccommodation) return;
			if (userTempPoints < 0) {
				popupController.open<NotEnoughPointsPopupProps>(NotEnoughPointsPopup, {
					verifiedAccommodation,
					userTempPoints,
					addedPackages: verifiedAccommodation.upsellPackages,
					destinationId: destinationId,
					removePackage: (id: number, points: number) => {
						setVerifiedAccommodation((prev) => {
							if (!prev) return;
							return { ...prev, upsellPackages: prev.upsellPackages.filter((item) => item.id !== id) };
						});
						setUserTempPoints((prev) => prev + points);
					}
				});
			}
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}, [userTempPoints, reservationFilters.redeemPoints]);

	useEffect(() => {
		if (!user) return;
		const primaryUserAddress: any = user.address.find((address) => address.isDefault === 1);
		const primaryPaymentMethod = user.paymentMethods.find((paymentMethod) => paymentMethod.isPrimary === 1);
		setCheckoutUser({
			personal: {
				firstName: checkoutUser?.personal?.firstName || user?.firstName || '',
				lastName: checkoutUser?.personal?.lastName || user?.lastName || '',
				address1: primaryUserAddress?.address1 || checkoutUser?.personal?.address1 || '',
				address2: primaryUserAddress?.address2 || checkoutUser?.personal?.address2 || '',
				zip: (primaryUserAddress?.zip || checkoutUser?.personal.zip || '').toString(),
				city: primaryUserAddress?.city || checkoutUser?.personal.city || '',
				state: primaryUserAddress?.state || checkoutUser?.personal.state || '',
				country: primaryUserAddress?.country || checkoutUser?.personal?.country || 'US',
				email: checkoutUser?.personal?.email || user?.primaryEmail || '',
				phone: user?.phone || checkoutUser?.personal?.phone || ''
			},
			billing: {
				firstName:
					checkoutUser?.billing?.firstName || checkoutUser?.personal?.firstName || user?.firstName || '',
				lastName: checkoutUser?.billing?.lastName || checkoutUser?.personal?.lastName || user?.lastName || '',
				address1: checkoutUser?.billing?.address1 || checkoutUser?.personal?.address1 || '',
				address2: checkoutUser?.billing?.address2 || checkoutUser?.personal?.address2 || '',
				city: checkoutUser?.billing?.city || checkoutUser?.personal?.city || '',
				state: checkoutUser?.billing?.state || checkoutUser?.personal?.state || '',
				zip: checkoutUser?.billing?.zip || checkoutUser?.personal?.zip || '',
				country: checkoutUser?.billing?.country || checkoutUser?.personal?.country || 'US',
				email: checkoutUser?.personal?.email || user?.primaryEmail || '',
				phone: user?.phone || checkoutUser?.personal?.phone || ''
			},
			usePoints: !!reservationFilters?.redeemPoints,
			shouldCreateUser: checkoutUser?.shouldCreateUser || false,
			pmData: checkoutUser?.pmData,
			isAffirmCheckout: checkoutUser?.isAffirmCheckout,
			isExistingCard: checkoutUser?.isAffirmCheckout ? false : user.paymentMethods.length > 0,
			existingCardId: checkoutUser?.isAffirmCheckout
				? undefined
				: user.paymentMethods.find((card) => card.isPrimary)?.id
		});
		setUserPrimaryAddress(primaryUserAddress);
		if (primaryPaymentMethod) {
			setUserPrimaryPaymentMethod(primaryPaymentMethod);
		}
	}, [user]);

	useEffect(() => {
		async function init() {
			const gatewayDetails: Api.Payment.Res.PublicData = await paymentService.getGateway();
			window.Spreedly.init(gatewayDetails.publicData.token, {
				numberEl: 'spreedly-number',
				cvvEl: 'spreedly-cvv'
			});
		}
		init().catch(console.error);
	}, []);

	useEffect(() => {
		let cancelRequest = false;
		async function verifyAvailability() {
			// if (verifiedAccommodation) return;
			try {
				let verifyData: Api.Reservation.Req.Verification = {
					accommodationId: stayParams.accommodationId,
					destinationId: destinationId,
					adultCount: stayParams.adults,
					childCount: stayParams.children,
					arrivalDate: stayParams.arrivalDate,
					departureDate: stayParams.departureDate,
					numberOfAccommodations: 1,
					upsellPackages: stayParams.packages.map((packageId) => {
						return { id: packageId };
					})
				};
				if (stayParams.rateCode) verifyData.rateCode = stayParams.rateCode;

				setShowExpectedPoint(true);
				let response = await reservationService.verifyAvailability(verifyData);
				if (cancelRequest) return;
				// response.arrivalDate = DateUtils.displayUserDate(response.arrivalDate, 'MM-DD-YYYY');
				// response.departureDate = DateUtils.displayUserDate(response.departureDate, 'MM-DD-YYYY');
				setVerifiedAccommodation(response);
				setUserTempPoints((user ? user.availablePoints : 0) - response.prices.grandTotalPoints);
			} catch (e: any) {
				const results: Api.Destination.Res.PublicSlim[] = await destinationService.getPublicSlim();
				const destinationIdtem: any = urlParams.get('data');
				const destinationId = results.find(
					(result) => parseInt(result?.id.toString()) === JSON.parse(destinationIdtem).destinationId
				)?.id;

				const destinationName = results
					.find((result) => parseInt(result?.id.toString()) === JSON.parse(destinationIdtem).destinationId)
					?.name.split(' ')
					.join('-')
					.toLocaleLowerCase();

				navigate(`/destination/${destinationName}`, {
					state: {
						...reservationFilters,
						destinationId: destinationId
					}
				});
				// await navigate(`/destination/details${WebUtils.createUrlParams(reservationFilters)}`);
				rsToastify.error(
					'Your selected accommodation is no longer available for these dates. Removed unavailable accommodation(s).',
					'No Longer Available'
				);
				const message = `Getting error :${e.message} on ${getPageFinder(window.location.pathname)} page.`;
				undefinedHandler(message);
			}
		}
		verifyAvailability().catch(console.error);
		return () => {
			cancelRequest = true;
		};
	}, []);

	useEffect(() => {
		sessionStorage.setItem('bookedStays', JSON.stringify(bookedStays));
		return () => {
			sessionStorage.removeItem('bookedStays');
		};
	}, [bookedStays]);

	let debounceCvvCardError = debounce(async (element: 'Number' | 'Cvv') => {
		let htmlBlock: HTMLElement | null = document.querySelector(`#${element}`);
		setIsInvalidSpreedlyIframe(true);
		if (!!htmlBlock) htmlBlock.style.color = 'red';
	}, 100);
	let debounceCvvCardSuccess = debounce(async (element: 'Number' | 'Cvv') => {
		let htmlBlock: HTMLElement | null = document.querySelector(`#${element}`);
		setIsInvalidSpreedlyIframe(false);
		if (!!htmlBlock) htmlBlock.style.color = '#001933';
	}, 100);

	useEffect(() => {
		let readySubscriptionId = paymentService.subscribeToSpreedlyReady(() => {
			window.Spreedly.setStyle(
				'number',
				'width:100%;font-size: 16px;height: 38px;padding: 0 10px;box-sizing: border-box;border-radius: 5px;border: ' +
					'1px solid #dedede; color: #001933; background-color: #ffffff; transition: border-color 300ms; '
			);
			window.Spreedly.setStyle(
				'cvv',
				'width:100%;font-size: 16px;height: 38px;padding: 0 10px;box-sizing: border-box;border-radius: 5px;' +
					'border: 1px solid #dedede; color: #001933; background-color: #ffffff; transition: border-color 300ms; '
			);
			window.Spreedly.setFieldType('number', 'text');
			window.Spreedly.setNumberFormat('prettyFormat');
		});

		let fieldEventSubscriptionId = paymentService.subscribeToSpreedlyFieldEvent(
			(
				name: 'number' | 'cvv',
				type: 'focus' | 'blur' | 'mouseover' | 'mouseout' | 'input' | 'enter' | 'escape' | 'tab' | 'shiftTab',
				activeEl: 'number' | 'cvv',
				inputProperties: {
					cardType?: string;
					validNumber?: boolean;
					validCvv?: boolean;
					numberLength?: number;
					cvvLength?: number;
				}
			) => {
				if (name === 'number') {
					if (type === 'focus') {
						window.Spreedly.setStyle('number', 'border: 1px solid #004b98;');
					}
					if (type === 'blur') {
						window.Spreedly.setStyle('number', 'border: 1px solid #dedede;');
					}
					if (type === 'mouseover') {
						window.Spreedly.setStyle('number', 'border: 1px solid #004b98;');
					}
					if (type === 'mouseout') {
						window.Spreedly.setStyle('number', 'border: 1px solid #dedede;');
					}

					if (type === 'input' && !inputProperties.validNumber) {
						debounceCvvCardError('Number');
						setRecoilExternalValue<Misc.CheckoutFormError>(globalState.checkoutFormHasError, {
							cvv: !inputProperties.validCvv,
							card: !inputProperties.validNumber
						});
					} else if (type === 'input' && inputProperties.validNumber) {
						debounceCvvCardSuccess('Number');
						setRecoilExternalValue<Misc.CheckoutFormError>(globalState.checkoutFormHasError, {
							cvv: !inputProperties.validCvv,
							card: !inputProperties.validNumber
						});
					}
				}
				if (name === 'cvv') {
					if (inputProperties.cvvLength) isCvvLength = inputProperties.cvvLength;
					if (type === 'focus') {
						window.Spreedly.setStyle('cvv', 'border: 1px solid #004b98;');
					}
					if (type === 'blur') {
						window.Spreedly.setStyle('cvv', 'border: 1px solid #dedede;');
					}
					if (type === 'mouseover') {
						window.Spreedly.setStyle('cvv', 'border: 1px solid #004b98;');
					}
					if (type === 'mouseout') {
						window.Spreedly.setStyle('cvv', 'border: 1px solid #dedede;');
					}
					if (type === 'input' && isCvvLength !== 3 && isCvvLength !== 4) {
						debounceCvvCardError('Cvv');
						setRecoilExternalValue<Misc.CheckoutFormError>(globalState.checkoutFormHasError, {
							cvv: true,
							card: !inputProperties.validNumber
						});
					} else if (type === 'input' && (isCvvLength === 3 || isCvvLength === 4)) {
						debounceCvvCardSuccess('Cvv');
						setRecoilExternalValue<Misc.CheckoutFormError>(globalState.checkoutFormHasError, {
							cvv: false,
							card: !inputProperties.validNumber
						});
					}
				}
			}
		);

		// Error response codes
		// https://docs.spreedly.com/reference/api/v1/#response-codes
		let errorSubscriptionId = paymentService.subscribeToSpreedlyError((errorMsg) => {
			setIsFormFilledOutPayment(false);
			let errorMessage = 'Please verify the information you have entered.';
			if (ObjectUtils.isArrayWithData(errorMsg)) {
				errorMessage = errorMsg[0].message;
			}
			rsToastify.error(errorMessage, 'Payment method invalid');
		});
		let paymentMethodSubscriptionId = paymentService.subscribeToSpreedlyPaymentMethod(
			async (token: string, pmData: Api.Payment.PmData) => {
				if (!checkoutUser || isInvalidSpreedlyIframe) return;

				if (!pmData.verification_value) {
					debounceCvvCardError('Cvv');
					rsToastify.error('Please verify the information you have entered.', 'Payment method invalid');
					setIsFormFilledOutPayment(false);
					return;
				}
				const newCheckoutUser = { ...checkoutUser, pmData: pmData };
				// setPmData(pmData);
				setCheckoutUser(newCheckoutUser);
				userService.setCheckoutUserInLocalStorage(newCheckoutUser);
				if (params.stage !== CheckoutStages.BOOK) {
					await handleForwardButtonClick();
				}
				popupController.close(UpdatePaymentMethodPopup);
				rsToastify.success('Payment method added successfully', 'Success');
			}
		);

		return () => {
			paymentService.unsubscribeToSpreedlyFieldEvent(fieldEventSubscriptionId);
			paymentService.unsubscribeToSpreedlyError(errorSubscriptionId);
			paymentService.unsubscribeToSpreedlyPaymentMethod(paymentMethodSubscriptionId);
			paymentService.unsubscribeToSpreedlyReady(readySubscriptionId);
		};
	}, [checkoutUser]);

	useEffect(() => {
		const data = {
			arrivalDate: stayParams.arrivalDate,
			departureDate: stayParams.departureDate,
			adultCount: stayParams.adults,
			childCount: stayParams.children,
			name: verifiedAccommodation?.destinationName,
			price: JSON.stringify({
				totalBaseRate: verifiedAccommodation?.prices?.accommodationTotalInCents,
				taxAndFeeTotalInCents: verifiedAccommodation?.prices?.taxAndFeeTotalInCents,
				itineraryId: itineraryId?.tempitineraryId,
				totalIncludingTaxesAndFees: verifiedAccommodation?.prices.grandTotalCents
			}),

			reservationId: itineraryId?.reservationId
		};

		setAnonymousUserData(data);
	}, [verifiedAccommodation, itineraryId, mountedRefvalue, mountedRef]);

	mountedRef.current = anonymousUserData;

	useEffect(() => {
		return () => {
			onVisibilityChange();
		};
	}, []);

	useEffect(() => {
		window.addEventListener('beforeunload', alertUser);
		// window.addEventListener('unload', handleTabClosing);
		return () => {
			window.removeEventListener('beforeunload', alertUser);
			// window.removeEventListener('unload', handleTabClosing);
		};
	});

	const handleTabClosing = () => {
		onVisibilityChange();
	};

	const alertUser = (event: any) => {
		event.preventDefault();
		// event.returnValue = '';
		onVisibilityChange();
	};

	async function onVisibilityChange() {
		if (mountedRefvalue.current && !user && !anonymousUserID) {
			const temp = {
				...mountedRef.current,
				anonymousUserId: mountedRefvalue.current,
				anonymousUserURL: mountedRef.current.reservationId ? '' : window.location.href,
				abandonmentPlace: JSON.stringify({
					abandonmentDateStamp: moment().toDate().toISOString(),
					abandonmentPlace:
						params.stage === 0
							? {
									page: 'booking/checkout',
									section: 'Info'
							  }
							: params.stage === 1
							? {
									page: 'booking/checkout',
									section: 'Payment'
							  }
							: params.stage === 2
							? {
									page: 'booking/checkout',
									section: 'Review & Book'
							  }
							: params.stage === 3 && CheckoutStages.CONFIRMATION
							? {
									page: 'booking/checkout',
									section: 'Confirmation'
							  }
							: {
									page: 'booking/checkout',
									section: 'Confirmation'
							  }
				})
			};
			questionnaireService.sendAnonymousReservation(temp);
		}
	}

	function getStoredBookedStays() {
		let stays = sessionStorage.getItem('bookedStays');
		if (!!stays) return JSON.parse(stays);
		else return [];
	}

	function isMissingSubmissionData() {
		if (!checkoutUser) return;
		return (
			!stayParams ||
			!(!!checkoutUser.pmData || checkoutUser.isExistingCard || reservationFilters.redeemPoints) ||
			!verifiedAccommodation
		);
	}

	function isMissingSubmissionDatastpe() {
		if (!checkoutUser) return;
		return !isSavePaymentMethodGlobal;
	}

	function shouldTokenizePaymentInformation() {
		return !reservationFilters.redeemPoints && checkoutUser?.pmData;
	}

	const googleCheckoutProcess3 = () => {
		try {
			TagManager.dataLayer({
				// Clear the previous ecommerce object.
				dataLayer: {
					event: 'checkout-step-3',
					ecommerce: null
				}
			});

			TagManager.dataLayer({
				dataLayer: {
					event: 'checkout-step-3',
					ecommerce: {
						checkout: {
							actionField: {
								step: 3,
								option: 'Review and Book'
							},
							products: [{}]
						}
					}
				}
			});
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	};

	const googleCheckoutProcess4 = () => {
		try {
			TagManager.dataLayer({
				// Clear the previous ecommerce object.
				dataLayer: {
					event: 'checkout-step-4',
					ecommerce: null
				}
			});

			TagManager.dataLayer({
				dataLayer: {
					event: 'checkout-step-4',
					ecommerce: {
						checkout: {
							actionField: {
								step: 4,
								option: 'Confirmation'
							},
							products: [{}]
						}
					}
				}
			});
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	};

	async function completeBooking() {
		if (isMissingSubmissionData()) {
			rsToastify.error('Missing proper data or existing card is invalid', 'Error!');
			throw new Error('Missing proper data or existing card is invalid');
		}
		if (!verifiedAccommodation || params.stage !== CheckoutStages.BOOK || !checkoutUser) return;
		popupController.open(SpinningLoaderPopup);
		const stays: Api.Reservation.Req.Itinerary.Stay[] = [
			{
				accommodationId: verifiedAccommodation.accommodationId,
				numberOfAccommodations: 1,
				arrivalDate: verifiedAccommodation.arrivalDate,
				departureDate: verifiedAccommodation.departureDate,
				adultCount: verifiedAccommodation.adultCount,
				childCount: verifiedAccommodation.childCount,
				rateCode: verifiedAccommodation.rateCode,
				upsellPackages: verifiedAccommodation.upsellPackages,
				guest: {
					firstName: checkoutUser?.personal?.firstName,
					lastName: checkoutUser?.personal?.lastName,
					phone: checkoutUser?.personal?.phone.toString(),
					email: checkoutUser?.personal?.email,
					Comments: checkoutUser.isAffirmCheckout ? '***Affirm Payment***' : ''
				},
				additionalDetails: ''
			}
		];
		let data: Api.Reservation.Req.Itinerary.Create;
		if (shouldTokenizePaymentInformation() && !!checkoutUser.pmData && !checkoutUser.isExistingCard) {
			data = {
				destinationId: destinationId,
				stays,
				isAffirmCheckout: checkoutUser?.isAffirmCheckout ? 1 : 0,
				payment: {
					pmData: {
						...checkoutUser.pmData,
						first_six_digits: Number(checkoutUser.pmData.first_six_digits),
						last_four_digits: Number(checkoutUser.pmData.last_four_digits)
					},
					isPrimary: checkoutUser?.isAffirmCheckout ? 0 : 1,
					cardToken: checkoutUser.pmData.token,
					offsiteLoyaltyEnrollment: 0
				}
			};
		} else {
			data = {
				destinationId: destinationId,
				stays
			};
		}
		if (checkoutUser.isExistingCard && !checkoutUser.usePoints) {
			data.paymentMethodId = checkoutUser.existingCardId;
		} else {
			delete data.paymentMethodId;
		}
		try {
			if (user) data.userId = user.id;
			if (!user) data.signUp = checkoutUser.shouldCreateUser ? 1 : 0;
			if (userPrimaryAddress) {
				data.existingAddressId = userPrimaryAddress.id;
			} else {
				data.newAddress = {
					type: showBillingAddress ? (checkoutUser.billing ? 'BILLING' : 'BOTH') : 'BOTH',
					address1: showBillingAddress
						? checkoutUser.billing
							? checkoutUser.billing?.address1
							: checkoutUser?.personal?.address1
						: checkoutUser?.personal?.address1,
					address2: showBillingAddress
						? checkoutUser.billing
							? checkoutUser.billing?.address2
							: checkoutUser?.personal?.address2
						: checkoutUser?.personal?.address2,
					city: showBillingAddress
						? checkoutUser.billing
							? checkoutUser.billing?.city
							: checkoutUser?.personal?.city
						: checkoutUser?.personal?.city,
					state: showBillingAddress
						? checkoutUser.billing
							? checkoutUser.billing?.state
							: checkoutUser?.personal?.state
						: checkoutUser?.personal?.state,
					zip: showBillingAddress
						? checkoutUser.billing
							? checkoutUser.billing?.zip
							: checkoutUser?.personal?.zip
						: checkoutUser?.personal?.zip,
					country: showBillingAddress
						? checkoutUser.billing
							? checkoutUser.billing?.country
							: checkoutUser?.personal?.country
						: checkoutUser?.personal?.country,
					isDefault: 1
				};
			}

			const res = await reservationService.createItinerary(data);
			setItineraryId({ tempitineraryId: res.itineraryId, reservationId: res.parentReservationId });

			setShowExpectedPoint(false);

			googleCheckoutProcess4();
			googleTagEcommerce(res);
			metaCapi.purchase(res);
			setTransactionData(res);
			setCompleteTransaction(true);
			setBookedStays(
				res.stays.map((stay) => {
					return {
						images: stay.accommodation.media,
						title: stay.accommodation.name,
						price: checkoutUser?.usePoints
							? stay.priceDetail.grandTotalPoints
							: stay.priceDetail?.grandTotalCents,
						currencyCode: stay.priceDetail.currencyCode,
						paymentMethod: res.paymentMethod,
						dateBooked: new Date(),
						confirmationNumber: stay.externalConfirmationId
					};
				})
			);

			const destinationId = JSON.parse(params.data);

			const searchQueryObj: Misc.ReservationFilters = { ...reservationFilters };
			let key: keyof Misc.ReservationFilters;
			for (key in searchQueryObj) {
				if (searchQueryObj[key] === undefined) delete searchQueryObj[key];
			}
			const results: RsPagedResponseData<Api.Accommodation.Res.MinMaxAvailability> =
				await accommodationService.availability(destinationId.destinationId, searchQueryObj);

			const accommodationStay = results?.data.find(
				(stay: any) => stay.id === verifiedAccommodation?.accommodationId
			);

			const prices = accommodationStay?.prices.find(
				(stay: any) => stay.rate.code === verifiedAccommodation?.rateCode
			);

			const booking = {
				item_name: verifiedAccommodation?.accommodationName,
				item_id: get(accommodationStay, 'externalSystemId', verifiedAccommodation?.accommodationId),
				price: NumberUtils.centsToDollars(prices?.accommodationTotalInCents),
				item_brand: verifiedAccommodation?.destinationName,
				item_category: get(accommodationStay, 'contentLists.RoomCategoryList.0.Name', null),
				item_category2: get(accommodationStay, 'contentLists.RoomCategoryList.0.Name', null),
				quantity: 1,
				transaction_id: res?.stays[0]?.externalConfirmationId,
				item_list_name: 'Rooms',
				arrival_date: searchQueryObj.startDate,
				depart_date: searchQueryObj.endDate,
				total_cost: NumberUtils.centsToDollars(prices.grandTotalCents),
				currency: prices.rate.currencyCode
			};

			TagManager.dataLayer({
				dataLayer: {
					event: 'booking',
					transactionId: res.stays[0].externalConfirmationId,
					transactionTotal: res.stays.map((stay) => {
						return checkoutUser?.usePoints
							? stay.priceDetail.grandTotalPoints
							: stay.priceDetail?.grandTotalCents;
					}),
					ecommerce: {
						checkout: {
							products: [booking]
						}
					}
				}
			});
			popupController.close(SpinningLoaderPopup);
			popupController.open<QuestionnairePopupProps>(QuestionnairePopup, {
				confirmationNumber: res.itineraryId,
				guestName: res.stays[0].guest.firstName,
				firstName: res.stays[0].guest.firstName,
				lastName: res.stays[0].guest.lastName,
				email: res.stays[0].guest.email,
				arrivalDate: res.stays[0].arrivalDate,
				departureDate: res.stays[0].departureDate,
				adultCount: res.stays[0].adultCount,
				childCount: res.stays[0].childCount,
				destinationName: res.destination.name,
				price: {
					baseRate: res.stays[0].priceDetail.accommodationTotalInCents,
					total: res.stays[0].priceDetail.grandTotalCents
				},
				userId: res.userId
			});
			await handleForwardButtonClick();
		} catch (e: any) {
			rsToastify.error(
				WebUtils.getRsErrorMessage(e, 'An error occurred, unable to book your reservation.'),
				'Server Error!',
				{ autoClose: false, position: 'top-center' }
			);
			popupController.close(SpinningLoaderPopup);
			const message = `Getting error :${e.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	// Google Tag code for Ecommerce Analytics
	const googleTagEcommerce = (res: any) => {
		try {
			TagManager.dataLayer({
				// Clear the previous ecommerce object.
				dataLayer: {
					event: 'transaction',
					ecommerce: null
				}
			});

			TagManager.dataLayer({
				dataLayer: {
					event: 'transaction',
					ecommerce: {
						purchase: {
							actionField: {
								id: res.stays[0].externalConfirmationId,
								affiliation: res.destination.name,
								revenue: NumberUtils.centsToDollars(res.stays[0].priceDetail?.grandTotalCents),
								tax: NumberUtils.centsToDollars(res.stays[0].priceDetail.taxTotalsInCents[0]?.amount)
							},
							products: [
								{
									id: res.stays[0].externalConfirmationId,
									name: res.destination.name,
									price: NumberUtils.centsToDollars(
										res.stays[0].priceDetail.accommodationTotalInCents
									),
									// category: res.stays.map((stay: any) => stay.accommodation.name).toString(),
									quantity: 1
								}
							]
						}
					}
				}
			});
		} catch (e: any) {
			console.error(e);
			console.error('Error in googleTagEcommerce function.');
			const message = `Getting error :${e.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	};

	// End of Google Tag code for Ecommerce Analytics

	//function to add dot to indicate cent
	const addDotToShowCent = (amount: Number) => {
		const amountConvertedToString = amount.toString();

		const last2Str = amountConvertedToString.slice(-2); // remove the last two digit
		const amountWithoutLast2Str = amountConvertedToString.slice(0, -2); //delete last 2 from number

		//join now
		const newAmount = Number(amountWithoutLast2Str + '.' + last2Str);
		return Number(newAmount.toFixed(2));
	};

	async function handleBackButtonClick() {
		await navigate(-1);
	}

	async function handleForwardButtonClick() {
		const isRedeemPoints = reservationFilters.redeemPoints ? '&redeemPoints=true' : '';
		function advance() {
			if (!anonymousUserID) {
				return navigate(`/booking/checkout?s=${params.stage + 1}&data=${params.data}${isRedeemPoints}`);
			} else {
				const updatedData = `${params.data}&anonymousUserID=${anonymousUserID}`;
				const updatedUrl = `/booking/checkout?s=${params.stage + 1}&data=${updatedData}${isRedeemPoints}`;
				navigate(updatedUrl);
			}
		}
		if (params.stage === CheckoutStages.INFO && checkoutButtonRef) {
			await checkoutButtonRef?.current?.click();
			return advance();
		}
		if (params.stage === CheckoutStages.PAYMENT && !isFormFilledOutPayment && checkoutPaymentButtonRef)
			return checkoutPaymentButtonRef?.current?.click();
		return advance();
	}

	function renderInfoView() {
		return (
			<CheckOutInfoCard
				onContinue={handleForwardButtonClick}
				isFormFilledOutCallback={(value) => {
					setIsFormFilledOut(value);
				}}
				onCheckoutInfoButtonClick={(buttonRef) => {
					setCheckoutButtonRef(buttonRef);
				}}
			/>
		);
	}

	function renderViewsByStage() {
		const views = [renderInfoView, () => null, renderRemainingViews];
		if (params.stage > CheckoutStages.PAYMENT) {
			return views[2]();
		}

		return views[params.stage]();
	}

	const redeemMyPointHandler = (mode: string) => {
		if (mode === 'REDEEM') {
			setRedemptionHandler(true);
		} else if (mode === 'UNREDEEM') {
			setRedemptionHandler(false);
		} else {
			setRedemptionHandler(false);
		}
	};
	function handleButtonText() {
		const buttons = ['Sign in for a faster checkout', 'Review & Book', 'Book now', 'Print confirmation'];
		if (params.stage > CheckoutStages.BOOK) {
			return buttons[3];
		}
		if (user && params.stage <= CheckoutStages.PAYMENT) {
			return buttons[1];
		}

		return buttons[params.stage];
	}

	function handleButtonColor(brandType: any) {
		let companyid;
		if (brandType) {
			companyid = JSON.parse(brandType).themeId;
		} else {
			companyid = null;
		}
		let bgColor = '';
		switch (companyid) {
			case 3:
				bgColor = 'encorereunion';
				break;
			case 78:
				bgColor = 'cottagesbyrentyl';
				break;
			case 19:
				bgColor = 'cottagesbyrentyl';
				break;
			case 9:
				bgColor = 'bearsdenorlando';
				break;
			case 10:
				bgColor = 'resortsbyspectrum';
				break;
			default:
				bgColor = 'yellow';
				break;
		}

		return bgColor;
	}

	function renderRemainingViews() {
		let userCheckout: any = checkoutUser;
		if (checkoutUser?.isExistingCard) {
			let selectedPaymentMethord = user?.paymentMethods.find((card) => card.id == checkoutUser.existingCardId);

			userCheckout = {
				pmData: {
					first_name: selectedPaymentMethord?.nameOnCard || '',
					last_name: ' ',
					cardType: selectedPaymentMethord?.type || '',
					card_type: selectedPaymentMethord?.type || '',
					last_four_digits:
						selectedPaymentMethord?.cardNumber.slice(
							selectedPaymentMethord.cardNumber.length - 4,
							selectedPaymentMethord.cardNumber.length
						) || ''
				}
			};
		}

		return (
			<>
				{params.stage > CheckoutStages.BOOK && (
					<>
						{bookedStays[0]?.images?.length >= 3 && (
							<ConfirmationImageSummary
								images={bookedStays[0]?.images.slice(0, 3).map((image) => image?.urls?.large)}
							/>
						)}
						<ThankYouCard confirmationNumber={bookedStays[0]?.confirmationNumber} />
					</>
				)}
				{checkoutUser && (
					<React.Fragment>
						<PersonalInformation
							personalInfo={checkoutUser.personal}
							isBillingInfo={showBillingAddress}
							billingInfo={checkoutUser.billing || checkoutUser.personal}
							onChangeCheckbox={(value) => {
								setShowBillingAddress(value);
								if (!value) {
									setCheckoutUser((prev) => {
										if (!prev) return;
										let newData = { ...prev };
										newData.billing = newData.personal;
										return newData;
									});
								}
							}}
							onEditPersonalClickCallback={
								params.stage === CheckoutStages.BOOK
									? async () => {
											if (!checkoutUser) return;
											popupController.open<UpdateAddressPopupProps>(UpdateAddressPopup, {
												// addressFormData: checkoutUser.billing || checkoutUser.personal,
												info: checkoutUser.personal,
												isPersonalInfo: true,
												onUpdate: (value) => {
													setCheckoutUser((prev) => {
														if (!prev) return;
														let newData = { ...prev };
														// if (isPersonal) {
														newData.personal = value;
														return newData;
														// }
														// newData.billing = value;
														// return newData;
													});
													rsToastify.success('Updated');
													popupController.close(UpdateAddressPopup);
												}
											});
									  }
									: undefined
							}
							onEditBillingClickCallback={
								params.stage === CheckoutStages.BOOK
									? async () => {
											if (!checkoutUser) return;
											popupController.open<UpdateAddressPopupProps>(UpdateAddressPopup, {
												// addressFormData: checkoutUser.billing || checkoutUser.personal,
												// personalInfo: checkoutUser.personal,
												info: checkoutUser.billing || checkoutUser.personal,
												isPersonalInfo: false,
												onUpdate: (value) => {
													setCheckoutUser((prev) => {
														if (!prev) return;
														let newData = { ...prev };
														// if (isPersonal) {
														// 	newData.personal = value;
														// 	return newData;
														// }
														newData.billing = value;
														return newData;
													});
													rsToastify.success('Updated');
													popupController.close(UpdateAddressPopup);
												}
											});
									  }
									: undefined
							}
						/>
						<PaymentMethod
							userCheckout={
								bookedStays.length == 0
									? userCheckout
									: {
											pmData: {
												...bookedStays[0].paymentMethod,
												card_type: bookedStays[0].paymentMethod?.type,
												first_name: bookedStays[0].paymentMethod?.nameOnCard,
												last_name: ' ',
												last_four_digits: bookedStays[0].paymentMethod?.last4
											}
									  }
							}
							userPrimaryPaymentMethod={userPrimaryPaymentMethod}
							onEditClickCallback={
								params.stage === CheckoutStages.BOOK && !checkoutUser.isExistingCard
									? async () => popupController.open(UpdatePaymentMethodPopup)
									: undefined
							}
						/>
					</React.Fragment>
				)}

				{verifiedAccommodation && (
					<Policies
						checkInTime={StringUtils.convertTwentyFourHourTime(
							verifiedAccommodation.policies.find((policy) => policy.type === 'CheckIn')?.value || '1600'
						)}
						checkOutTime={StringUtils.convertTwentyFourHourTime(
							verifiedAccommodation.policies.find((policy) => policy.type === 'CheckOut')?.value || '1000'
						)}
						bookingDescription={verifiedAccommodation.accommodationName}
						guaranteePolicy={
							verifiedAccommodation.policies.find((policy) => policy.type === 'Guarantee')?.value ||
							'10% of the total price is required at the time of booking to guarantee the reservation.'
						}
						cancellationPolicy={checkoutPolicy || ''}
					/>
				)}
				{params.stage > CheckoutStages.BOOK && (
					<CheckoutReservationSummary
						orders={bookedStays.map((stay) => {
							return {
								image:
									stay.images.find((image) => image.isPrimary)?.urls.large ||
									stay.images[0].urls.thumb,
								dateBooked: DateUtils.formatDate(new Date(stay.dateBooked), 'MM-DD-YY'),
								price: stay.price,
								title: stay.title,
								currencyCode: stay.currencyCode,
								resortName: destinationName,
								resortLogo: destinationLogo,
								usePoints: !!reservationFilters.redeemPoints
							};
						})}
					/>
				)}
			</>
		);
	}

	const googleCheckoutProcess2 = async () => {
		const destinationId = JSON.parse(params.data);

		const searchQueryObj: Misc.ReservationFilters = { ...reservationFilters };
		let key: keyof Misc.ReservationFilters;
		for (key in searchQueryObj) {
			if (searchQueryObj[key] === undefined) delete searchQueryObj[key];
		}
		const results: RsPagedResponseData<Api.Accommodation.Res.MinMaxAvailability> =
			await accommodationService.availability(destinationId.destinationId, searchQueryObj);

		const accommodationStay = results?.data.find((stay: any) => stay.id === verifiedAccommodation?.accommodationId);

		const prices = accommodationStay?.prices.find(
			(stay: any) => stay.rate.code === verifiedAccommodation?.rateCode
		);

		const checkout_step_2 = {
			item_name: verifiedAccommodation?.accommodationName,
			item_id: get(accommodationStay, 'externalSystemId', verifiedAccommodation?.accommodationId),
			price: NumberUtils.centsToDollars(prices?.accommodationTotalInCents),
			item_brand: verifiedAccommodation?.destinationName,
			item_category: get(accommodationStay, 'contentLists.RoomCategoryList.0.Name', null),
			item_category2: get(accommodationStay, 'contentLists.RoomCategoryList.0.Name', null),
			quantity: 1, // we'll consider 1 room as a item
			item_list_name: 'Rooms',
			arrival_date: searchQueryObj.startDate,
			depart_date: searchQueryObj.endDate,
			total_cost: NumberUtils.centsToDollars(prices.grandTotalCents),
			payment_type: 'Card Payment',
			currency: prices.rate.currencyCode
		};

		try {
			TagManager.dataLayer({
				// Clear the previous ecommerce object.
				dataLayer: {
					event: 'checkout-step-2',
					ecommerce: null
				}
			});

			TagManager.dataLayer({
				dataLayer: {
					event: 'checkout-step-2',
					ecommerce: {
						checkout: {
							actionField: {
								step: 2,
								option: 'Payment'
							},
							products: [checkout_step_2]
						}
					}
				}
			});
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	};

	async function handleButtonClick() {
		switch (params.stage) {
			case 0:
				if (!user) return popupController.open(SigninPopup);
				await handleForwardButtonClick();
				return;
			case 1:
				googleCheckoutProcess2();
				await handleForwardButtonClick();
				return;
			case 2:
				googleCheckoutProcess3();
				await completeBooking();
				return;
			case 3:
				setTimeout(() => {
					window.print();
				}, 700);
				return;
			default:
				await handleForwardButtonClick();
				return;
		}
	}

	function isButtonDisabled() {
		// console.log('params.stage==?', checkoutUser);
		// console.log('params.stage==?', params.stage);
		switch (params.stage) {
			case 0:
				if (!user) {
					//if no user display the sign in for faster checkout button so do not disable
					return false;
				} else {
					return !isFormFilledOut;
				}
			case 1:
				if (!checkoutUser) return;
				return isMissingSubmissionDatastpe();
			case 2:
				if (!checkoutUser) return;
				return isMissingSubmissionData() && !checkoutUser.paymentInfo;

			default:
				return false;
		}
	}

	return (
		<Page className={'rsCheckoutFlowPage'}>
			{params.stage < CheckoutStages.AFTER_PRINT && (
				<CheckoutBreadcrumbs activeStage={params.stage} onBackButtonClick={handleBackButtonClick} />
			)}
			<div className={'printableContentWrapper'} ref={printRef}>
				<div className={'leftColumn'}>
					{params.stage === CheckoutStages.PAYMENT && (
						<>
							{checkoutUser && (
								<PersonalInformation
									personalInfo={checkoutUser.personal}
									isBillingInfo={showBillingAddress}
									billingInfo={checkoutUser.billing || checkoutUser.personal}
									onChangeCheckbox={(value) => {
										setShowBillingAddress(value);
										if (!value) {
											setCheckoutUser((prev) => {
												if (!prev) return;
												let newData = { ...prev };
												newData.billing = newData.personal;
												return newData;
											});
										}
									}}
									onEditPersonalClickCallback={async () => {
										if (!checkoutUser) return;
										popupController.open<UpdateAddressPopupProps>(UpdateAddressPopup, {
											// addressFormData: checkoutUser.billing || checkoutUser.personal,
											info: checkoutUser.personal,
											isPersonalInfo: true,
											onUpdate: (value) => {
												setCheckoutUser((prev) => {
													if (!prev) return;
													let newData = { ...prev };
													// if (isPersonal) {
													newData.personal = value;
													return newData;
													// }
													// newData.billing = value;
													// return newData;
												});
												rsToastify.success('Updated');
												popupController.close(UpdateAddressPopup);
											}
										});
									}}
									onEditBillingClickCallback={async () => {
										if (!checkoutUser) return;
										popupController.open<UpdateAddressPopupProps>(UpdateAddressPopup, {
											// addressFormData: checkoutUser.billing || checkoutUser.personal,
											// personalInfo: checkoutUser.personal,
											info: checkoutUser.billing || checkoutUser.personal,
											isPersonalInfo: false,
											onUpdate: (value) => {
												setCheckoutUser((prev) => {
													if (!prev) return;
													let newData = { ...prev };
													// if (isPersonal) {
													// 	newData.personal = value;
													// 	return newData;
													// }
													newData.billing = value;
													return newData;
												});
												rsToastify.success('Updated');
												popupController.close(UpdateAddressPopup);
											}
										});
									}}
								/>
							)}
							<CheckOutPaymentCard
								onRedemption={redeemMyPointHandler}
								onContinue={handleForwardButtonClick}
								bookingData={verifiedAccommodation}
								userPrimaryPaymentMethod={userPrimaryPaymentMethod}
								onCheckoutPaymentButtonClick={(buttonRef) => {
									setCheckoutPaymentButtonRef(buttonRef);
								}}
								isFormFilledOutCallback={(value) => {
									setIsFormFilledOutPayment(value);
								}}
							/>
						</>
					)}
					{renderViewsByStage()}
				</div>
				<div className={'bookingSummaryColumn'}>
					<Button
						className={`printConfirmButton ${handleButtonColor(resortId)}`}
						look={'containedPrimary'}
						onClick={handleButtonClick}
						disabled={isButtonDisabled()}
					>
						<Label variant={'buttonMdLg'}>{handleButtonText()}</Label>
					</Button>
					{/* {handleButtonText(resortId)} */}
					<PrintableQrCode qrCodeValue={'https://spireloyalty.com/'} />
					{!!verifiedAccommodation ? (
						<BookingSummaryCard
							onRedeem={redemptionHandler}
							bookingData={verifiedAccommodation}
							canHide={false}
							usePoints={!!reservationFilters.redeemPoints}
							isOpen={false}
							showExpectedPoint={showExpectedPoint}
						/>
					) : (
						<Loader />
					)}
				</div>
			</div>
			<div className={'bookNowButtonWrapper'}>
				{params.stage === 2 && isMobile && (
					<Button
						className={'printConfirmButton yellow'}
						look={'containedPrimary'}
						onClick={handleButtonClick}
					>
						<Label variant={'buttonMdLg'}>Book now</Label>
					</Button>
				)}
			</div>
			{params.stage === 2 && <PageViewBookingScript />}
			{completeTransaction && <FlipToConfirmation transactions={transactionData} />}
			{completeTransaction && <RulerAnalytics orderDetails={transactionData} />}
		</Page>
	);
};

export default CheckoutFlowPage;
