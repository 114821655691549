import React, { useEffect, useState } from 'react';
// import '../../pages/reservationAvailabilityPage/ReservationAvailabilityPage.scss';
import { Box, Page, popupController } from '../../lib/@bit/redsky.framework.rs.996';
import Label from '../../lib/@bit/redsky.framework.rs.label';
import serviceFactory from '../../services/serviceFactory';
import { useSearchParams } from 'react-router-dom';
import useIsAtBreakpoint from '../../customHooks/useIsAtBreakpoint';
import globalState from '../../state/globalState';
import { useRecoilValue, useRecoilState } from 'recoil';
import { ObjectUtils } from '../../utils/utils';
import DestinationSearchResultCard from '../../components/destinationSearchResultCard/DestinationSearchResultCard';
import DestinationService from '../../services/destination/destination.service';
import { rsToastify } from '../../lib/@bit/redsky.framework.rs.toastify';
import MobileLightBox, { MobileLightBoxProps } from '../../popups/mobileLightBox/MobileLightBox';
import LightBoxCarouselPopup, {
	TabbedCarouselPopupProps
} from '../../popups/lightBoxCarouselPopup/LightBoxCarouselPopup';
import Loader from '../../components/loader/Loader';
import CountryService from '../../services/country/country.service';
import RegionService from '../../services/region/region.service';
import CallToActionPopup, { CallToActionPopupProps } from '../../popups/callToActionPopup/CallToActionPopup';
import UserService from '../../services/user/user.service';
import { getPageFinder, undefinedHandler } from '../../utils/undefinedHandler';
import './similarChoiceRecommendation.scss';

export interface SimilarChoiceRecommendationProps {
	regionId?: number;
	destinationId?: number;
}

const SimilarChoiceRecommendation: React.FC<SimilarChoiceRecommendationProps> = (props) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const isMobile = useIsAtBreakpoint();
	const reservationFilters = useRecoilValue<Misc.ReservationFilters>(globalState.reservationFilters);
	const destinationService = serviceFactory.get<DestinationService>('DestinationService');
	const perPage = 5;
	const [isFetchingResults, setIsFetchingResults] = useRecoilState<boolean>(globalState.isFetchingResults);
	const [page, setPage] = useState<number>(1);
	const [availabilityTotal, setAvailabilityTotal] = useState<number>(0);
	const [destinations, setDestinations] = useState<Api.Destination.Res.AvailabilitySummary[]>([]);
	const [pointPerDollar, setPointPerDollar] = useState<number>(0);
	// const regionName = useRecoilValue<any>(globalState.regionNameList);
	const [regionName, setRegionName] = useRecoilState<any | []>(globalState.regionNameList);
	const [regionNameId, setRegionNameId] = useRecoilState<any | []>(globalState.regionNameListId);
	const [regionList, setRegionList] = useState<any>([]);
	const [callToActionPopup, setCallToActionPopup] = useRecoilState<number>(globalState.callToActionPopup);

	const [countryList, setCountryList] = useRecoilState<Misc.CountryList>(globalState.countryList);
	const countryService = serviceFactory.get<CountryService>('CountryService');
	const regionService = serviceFactory.get<RegionService>('RegionService');

	const [user, setUser] = useRecoilState<Api.User.Res.Detail | undefined>(globalState.user);
	const userService = serviceFactory.get<UserService>('UserService');
	const [destinationSelection, setDestinationSelection] = useRecoilState<any>(globalState.destinationSelection);

	const redHeart = '../../images/accountTravelPreferences/redHeart.svg';
	const whiteHeart = '../../images/accountTravelPreferences/whiteHeart.svg';

	useEffect(() => {
		const search = '';
		// setSearchParams(search, { replace: true });
		getPlatformVariables();
	}, []);

	//when page load
	useEffect(() => {
		setDestinations([]);
		setPage(1);
		let cancelFetch = false;
		(async function getAvailableReservations() {
			try {
				setIsFetchingResults(true);
				const res = await getAvailable(1);
				// showCallToUrgencyPopUp(res.data);
				if (cancelFetch) return;
				setDestinations(res.data);
				setAvailabilityTotal(res.total || 0);
			} catch (e: any) {
				rsToastify.error('Failed to get availability, please try again.', 'Server Error');
				const message = `Getting error :${e.message} on ${getPageFinder(window.location.pathname)} page.`;
				undefinedHandler(message);
			}
			setIsFetchingResults(false);
		})();
		return () => {
			cancelFetch = true;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reservationFilters]);

	useEffect(() => {
		(async () => {
			try {
				let regions: Api.Region.Res.Get[] = await regionService.getAllRegions();
				const tempRegionList: any = [];
				const tempRegionListId: any = [];
				reservationFilters.regionIds?.map((item1: any) => {
					const regionObj = regions.find((item) => item.id == item1);
					if (regionObj) {
						tempRegionList.push(regionObj.name);
						tempRegionListId.push(regionObj.id);
					}
				});
				setRegionName(tempRegionList);
				setRegionNameId(tempRegionListId);
				setRegionList(tempRegionList);
			} catch (e: any) {
				rsToastify.error('There was an issue getting regions');
				const message = `Getting error :${e.message} on ${getPageFinder(window.location.pathname)} page.`;
				undefinedHandler(message);
			}
		})();
	}, [reservationFilters]);

	useEffect(() => {
		if (!isFetchingResults && destinations.length === 0 && callToActionPopup === 0) {
			setCallToActionPopup(callToActionPopup + 1);
			popupController.open<CallToActionPopupProps>(CallToActionPopup, {});
		} else {
			popupController.close(CallToActionPopup);
		}
	}, [isFetchingResults, destinations]);

	const showCallToUrgencyPopUp = (data: Api.Destination.Res.AvailabilitySummary[]) => {
		try {
			let count = 0;
			for (let i = 0; i < data.length; i++) {
				if (data[i].minAccommodationPriceInCents === null && data[i].allowOversell === false) {
					count++;
				}
			}

			if (count === data.length) {
				popupController.open<CallToActionPopupProps>(CallToActionPopup, {});
			}
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	};

	function getAvailable(searchPage: number) {
		let searchQueryObj: Misc.ReservationFilters;
		if (props.regionId) {
			searchQueryObj = { ...reservationFilters, regionIds: [props.regionId] };
		} else {
			searchQueryObj = { ...reservationFilters };
		}

		let key: keyof Misc.ReservationFilters;
		for (key in searchQueryObj) {
			if (searchQueryObj[key] === undefined) delete searchQueryObj[key];
		}
		searchQueryObj.pagination = { page: searchPage, perPage };
		return destinationService.searchAvailableReservations(searchQueryObj);
	}

	async function getPlatformVariables() {
		const pointPerDollar = (await destinationService.getPlatformVariables()).rewardPointsPerDollar; //global reward point per dollar
		setPointPerDollar(pointPerDollar);
	}

	const preferencesData = async () => {
		try {
			const userFavoriteDestinations = await userService.userFavoriteDestinations(user?.id);

			const FavoriteDestinations = userFavoriteDestinations.data.myFavoriteDestinations;

			if (FavoriteDestinations === undefined || FavoriteDestinations === null || FavoriteDestinations === '') {
				setDestinationSelection([]);
			} else {
				if (user) setDestinationSelection(JSON.parse(FavoriteDestinations));
			}
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	};

	useEffect(() => {
		// if (!user) navigate('/page-not-found');
		if (user) {
			preferencesData();
		}
	}, [user]);

	function renderDestinationSearchResultCards(type = 'full') {
		try {
			if (!ObjectUtils.isArrayWithData(destinations)) return;

			let filteredDestinations = destinations.filter(
				(item) => item.id !== props.destinationId && item.minAccommodationPriceInCents
			);
			return filteredDestinations.slice(0, 3).map((destination) => {
				let urls: string[] = getImageUrls(destination);

				const heart = destinationSelection.includes(destination?.id) ? redHeart : whiteHeart;

				return (
					<DestinationSearchResultCard
						countryList={countryList.list ? countryList.list : []}
						key={destination.id}
						pointPerDollar={pointPerDollar}
						destinationObj={destination}
						isAccommodations={destination?.accommodations.length}
						FavoriteDestinationsIcon={heart}
						picturePaths={urls.filter(Boolean)}
						onGalleryClick={() => {
							if (isMobile) {
								popupController.open<MobileLightBoxProps>(MobileLightBox, {
									imageData: destination.media
								});
							} else {
								popupController.open<TabbedCarouselPopupProps>(LightBoxCarouselPopup, {
									imageData: destination.media.sort((a: any, b: any) => a.mediaIndex - b.mediaIndex),
									defaultImageIndex: 0
								});
							}
						}}
						className={type}
					/>
				);
			});
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	function getImageUrls(destination: Api.Destination.Res.AvailabilitySummary): string[] {
		try {
			if (destination.media) {
				let images = destination.media.sort((a: any, b: any) => a.mediaIndex - b.mediaIndex);
				images.sort((a, b) => {
					return b.isPrimary - a.isPrimary;
				});
				return images.map((urlObj) => {
					return urlObj.urls.large?.toString() || urlObj.urls.thumb;
				});
			}
			return [];
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
			return [];
		}
	}

	return (
		<>
			<div className={'mt-3'}>
				{!isFetchingResults && destinations.length > 1 && (
					<>
						<Box>
							<Label className="rsLabel h2 text-center">You may also be interested in:</Label>
							<div className="interested-destinations">{renderDestinationSearchResultCards('short')}</div>
						</Box>
					</>
				)}
			</div>
		</>
	);
};

export default SimilarChoiceRecommendation;
