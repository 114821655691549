var __assign =
	(this && this.__assign) ||
	function () {
		__assign =
			Object.assign ||
			function (t) {
				for (var s, i = 1, n = arguments.length; i < n; i++) {
					s = arguments[i];
					for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
				}
				return t;
			};
		return __assign.apply(this, arguments);
	};
var __awaiter =
	(this && this.__awaiter) ||
	function (thisArg, _arguments, P, generator) {
		function adopt(value) {
			return value instanceof P
				? value
				: new P(function (resolve) {
						resolve(value);
				  });
		}
		return new (P || (P = Promise))(function (resolve, reject) {
			function fulfilled(value) {
				try {
					step(generator.next(value));
				} catch (e) {
					reject(e);
				}
			}
			function rejected(value) {
				try {
					step(generator['throw'](value));
				} catch (e) {
					reject(e);
				}
			}
			function step(result) {
				result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
			}
			step((generator = generator.apply(thisArg, _arguments || [])).next());
		});
	};
var __generator =
	(this && this.__generator) ||
	function (thisArg, body) {
		var _ = {
				label: 0,
				sent: function () {
					if (t[0] & 1) throw t[1];
					return t[1];
				},
				trys: [],
				ops: []
			},
			f,
			y,
			t,
			g;
		return (
			(g = { next: verb(0), throw: verb(1), return: verb(2) }),
			typeof Symbol === 'function' &&
				(g[Symbol.iterator] = function () {
					return this;
				}),
			g
		);
		function verb(n) {
			return function (v) {
				return step([n, v]);
			};
		}
		function step(op) {
			if (f) throw new TypeError('Generator is already executing.');
			while (_)
				try {
					if (
						((f = 1),
						y &&
							(t =
								op[0] & 2
									? y['return']
									: op[0]
									? y['throw'] || ((t = y['return']) && t.call(y), 0)
									: y.next) &&
							!(t = t.call(y, op[1])).done)
					)
						return t;
					if (((y = 0), t)) op = [op[0] & 2, t.value];
					switch (op[0]) {
						case 0:
						case 1:
							t = op;
							break;
						case 4:
							_.label++;
							return { value: op[1], done: false };
						case 5:
							_.label++;
							y = op[1];
							op = [0];
							continue;
						case 7:
							op = _.ops.pop();
							_.trys.pop();
							continue;
						default:
							if (
								!((t = _.trys), (t = t.length > 0 && t[t.length - 1])) &&
								(op[0] === 6 || op[0] === 2)
							) {
								_ = 0;
								continue;
							}
							if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
								_.label = op[1];
								break;
							}
							if (op[0] === 6 && _.label < t[1]) {
								_.label = t[1];
								t = op;
								break;
							}
							if (t && _.label < t[2]) {
								_.label = t[2];
								_.ops.push(op);
								break;
							}
							if (t[2]) _.ops.pop();
							_.trys.pop();
							continue;
					}
					op = body.call(thisArg, _);
				} catch (e) {
					op = [6, e];
					y = 0;
				} finally {
					f = t = 0;
				}
			if (op[0] & 5) throw op[1];
			return { value: op[0] ? op[1] : void 0, done: true };
		}
	};
Object.defineProperty(exports, '__esModule', { value: true });
var BrowserNavigation = (function () {
	function BrowserNavigation() {
		this.lastHistoryTime = 0;
		this.historyData = [];
		this.backCallbacks = [];
		this.forwardCallbacks = [];
		this.checkNavigationCallbacks = [];
		this.onPopState = this.onPopState.bind(this);
		window.onpopstate = this.onPopState;
		window.browserNavigation = this;
	}
	BrowserNavigation.prototype.getLastHistoryTime = function () {
		return this.lastHistoryTime;
	};
	BrowserNavigation.prototype.replaceState = function (state) {
		var swappedState;
		if (this.historyData.length > 0) {
			this.historyData = this.historyData.map(function (item) {
				if (item.key !== window.history.state.key) return item;
				swappedState = __assign(__assign({}, item), { state: state });
				return swappedState;
			});
		} else {
			swappedState = {
				key: Date.now(),
				isBacked: false,
				state: state
			};
			this.historyData.push(swappedState);
		}
		if (swappedState) window.history.replaceState(this.createBrowserState(swappedState.key), '', state.path);
		this.printAllViewHistory();
	};
	BrowserNavigation.prototype.pushHistory = function (state) {
		this.deleteAllBackedStates();
		var history = {
			key: Date.now(),
			state: state,
			isBacked: false
		};
		this.historyData.push(history);
		this.lastHistoryTime = history.key;
		window.history.pushState(this.createBrowserState(history.key), '', state.path);
		this.printAllViewHistory();
	};
	BrowserNavigation.prototype.back = function () {
		if (this.historyData.length < 2) return false;
		window.history.back();
		return true;
	};
	BrowserNavigation.prototype.forward = function () {
		window.history.forward();
	};
	BrowserNavigation.prototype.onBackPressed = function (callback) {
		this.backCallbacks.push(callback);
	};
	BrowserNavigation.prototype.onForwardPressed = function (callback) {
		this.forwardCallbacks.push(callback);
	};
	BrowserNavigation.prototype.onCheckNavigation = function (callback) {
		this.checkNavigationCallbacks.push(callback);
	};
	BrowserNavigation.prototype.onPopState = function (e) {
		return __awaiter(this, void 0, void 0, function () {
			var key, viewHistoryState, prevHistoryState;
			return __generator(this, function (_a) {
				switch (_a.label) {
					case 0:
						if (!e.state) {
							console.error("Received a browser action but we don't have any state to navigate to.");
							return [2];
						}
						if (window.hasOwnProperty('showBrowseLogs')) console.log('POP STATE (TEST): ', e.state);
						key = e.state.key;
						viewHistoryState = this.getMatchingViewHistoryState(key);
						if (!viewHistoryState) {
							console.error("We don't have a matching history state for browser event!!!");
							return [2];
						}
						return [4, this.checkNavigation(viewHistoryState)];
					case 1:
						if (!_a.sent()) {
							if (window.hasOwnProperty('showBrowseLogs')) console.log('rejected navigation');
							prevHistoryState = this.getMatchingViewHistoryState(this.lastHistoryTime);
							if (!prevHistoryState) {
								console.error("We don't have a matching history state for previous history!!!");
								return [2];
							}
							window.history.pushState(
								this.createBrowserState(this.lastHistoryTime),
								'',
								prevHistoryState.state.path
							);
							return [2];
						}
						if (this.lastHistoryTime < key) {
							this.clearHistoryStateBacked(key);
							this.lastHistoryTime = key;
							this.printAllViewHistory();
							this.fireForwardEvent(viewHistoryState);
						} else {
							this.setHistoryStateBacked(this.lastHistoryTime);
							this.lastHistoryTime = key;
							this.printAllViewHistory();
							this.fireBackEvent(viewHistoryState);
						}
						return [2];
				}
			});
		});
	};
	BrowserNavigation.prototype.getMatchingViewHistoryState = function (key) {
		return this.historyData.find(function (item) {
			return item.key === key;
		});
	};
	BrowserNavigation.prototype.setHistoryStateBacked = function (key) {
		var history = this.getMatchingViewHistoryState(key);
		if (!history) {
			console.error('Could not find history matching key: ', key);
			return;
		}
		history.isBacked = true;
	};
	BrowserNavigation.prototype.clearHistoryStateBacked = function (key) {
		var history = this.getMatchingViewHistoryState(key);
		if (!history) {
			console.error('Could not find history matching key: ', key);
			return;
		}
		history.isBacked = false;
	};
	BrowserNavigation.prototype.fireBackEvent = function (newHistory) {
		if (window.hasOwnProperty('showBrowseLogs')) console.log('Browser back clicked');
		for (var i in this.backCallbacks) {
			this.backCallbacks[i](newHistory.state);
		}
	};
	BrowserNavigation.prototype.fireForwardEvent = function (newHistory) {
		if (window.hasOwnProperty('showBrowseLogs')) console.log('Browser forward clicked');
		for (var i in this.forwardCallbacks) {
			this.forwardCallbacks[i](newHistory.state);
		}
	};
	BrowserNavigation.prototype.checkNavigation = function (newHistory) {
		return __awaiter(this, void 0, void 0, function () {
			var _a, _b, _i, i;
			return __generator(this, function (_c) {
				switch (_c.label) {
					case 0:
						_a = [];
						for (_b in this.checkNavigationCallbacks) _a.push(_b);
						_i = 0;
						_c.label = 1;
					case 1:
						if (!(_i < _a.length)) return [3, 4];
						i = _a[_i];
						return [4, this.checkNavigationCallbacks[i](newHistory.state)];
					case 2:
						if (!_c.sent()) return [2, false];
						_c.label = 3;
					case 3:
						_i++;
						return [3, 1];
					case 4:
						return [2, true];
				}
			});
		});
	};
	BrowserNavigation.prototype.createBrowserState = function (key) {
		return { key: key };
	};
	BrowserNavigation.prototype.deleteAllBackedStates = function () {
		this.historyData = this.historyData.filter(function (data) {
			return !data.isBacked;
		});
	};
	BrowserNavigation.prototype.printAllViewHistory = function () {
		if (!window.hasOwnProperty('showBrowseLogs')) return;
		var historyLog = {};
		var count = 1;
		for (var _i = 0, _a = this.historyData; _i < _a.length; _i++) {
			var history_1 = _a[_i];
			var prefix = '';
			if (history_1.key === this.lastHistoryTime) prefix = '♣';
			if (history_1.isBacked) prefix += 'BACK';
			historyLog['history: ' + count++] = {
				key: history_1.key,
				path: prefix + history_1.state.path,
				viewName: history_1.state.viewName,
				options: history_1.state.routeOptions ? JSON.stringify(history_1.state.routeOptions) : ''
			};
		}
		console.table(historyLog);
	};
	return BrowserNavigation;
})();
exports.default = BrowserNavigation;
