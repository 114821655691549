var __extends =
	(this && this.__extends) ||
	(function () {
		var extendStatics = function (d, b) {
			extendStatics =
				Object.setPrototypeOf ||
				({ __proto__: [] } instanceof Array &&
					function (d, b) {
						d.__proto__ = b;
					}) ||
				function (d, b) {
					for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
				};
			return extendStatics(d, b);
		};
		return function (d, b) {
			extendStatics(d, b);
			function __() {
				this.constructor = d;
			}
			d.prototype = b === null ? Object.create(b) : ((__.prototype = b.prototype), new __());
		};
	})();
var __assign =
	(this && this.__assign) ||
	function () {
		__assign =
			Object.assign ||
			function (t) {
				for (var s, i = 1, n = arguments.length; i < n; i++) {
					s = arguments[i];
					for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
				}
				return t;
			};
		return __assign.apply(this, arguments);
	};
var __createBinding =
	(this && this.__createBinding) ||
	(Object.create
		? function (o, m, k, k2) {
				if (k2 === undefined) k2 = k;
				Object.defineProperty(o, k2, {
					enumerable: true,
					get: function () {
						return m[k];
					}
				});
		  }
		: function (o, m, k, k2) {
				if (k2 === undefined) k2 = k;
				o[k2] = m[k];
		  });
var __setModuleDefault =
	(this && this.__setModuleDefault) ||
	(Object.create
		? function (o, v) {
				Object.defineProperty(o, 'default', { enumerable: true, value: v });
		  }
		: function (o, v) {
				o['default'] = v;
		  });
var __importStar =
	(this && this.__importStar) ||
	function (mod) {
		if (mod && mod.__esModule) return mod;
		var result = {};
		if (mod != null)
			for (var k in mod)
				if (k !== 'default' && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
		__setModuleDefault(result, mod);
		return result;
	};
var __spreadArrays =
	(this && this.__spreadArrays) ||
	function () {
		for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
		for (var r = Array(s), k = 0, i = 0; i < il; i++)
			for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++) r[k] = a[j];
		return r;
	};
Object.defineProperty(exports, '__esModule', { value: true });
exports.PopupController = void 0;
var react_1 = __importStar(require('react'));
var PopupController = (function (_super) {
	__extends(PopupController, _super);
	function PopupController(props) {
		var _this = _super.call(this, props) || this;
		_this.open = function (component, props) {
			var _a, _b, _c, _d, _e;
			if (props === void 0) {
				props = {};
			}
			var fullProps = props;
			fullProps.opened = true;
			fullProps.popupId = Date.now();
			fullProps.animateType =
				(_a = fullProps.animateType) !== null && _a !== void 0 ? _a : popupDefaults.animateType;
			fullProps.animateDurationMs =
				(_b = fullProps.animateDurationMs) !== null && _b !== void 0 ? _b : popupDefaults.animateDurationMs;
			fullProps.animateDirection =
				(_c = fullProps.animateDirection) !== null && _c !== void 0 ? _c : popupDefaults.animateDirection;
			fullProps.backgroundColor =
				(_d = fullProps.backgroundColor) !== null && _d !== void 0 ? _d : popupDefaults.backgroundColor;
			fullProps.enableBodyScrollLock =
				(_e = fullProps.enableBodyScrollLock) !== null && _e !== void 0
					? _e
					: popupDefaults.enableBodyScrollLock;
			fullProps.onDestroyed = _this.removePopupFromRenderById;
			_this.setState(function (_a) {
				var popups = _a.popups;
				return { popups: __spreadArrays(popups, [{ component: component, props: props }]) };
			});
			return fullProps.popupId;
		};
		_this.hide = function (component) {
			var popups = _this.state.popups;
			for (var i in popups) {
				if (_this._matchComponent(popups[i].component, component)) {
					popups[i].props.opened = false;
				}
			}
			_this.setState({ popups: popups });
		};
		_this.closeAll = function () {
			var popups = _this.state.popups;
			for (var _i = 0, popups_1 = popups; _i < popups_1.length; _i++) {
				var popup = popups_1[_i];
				_this.destroyPopupById(popup.props.popupId);
			}
		};
		_this.closeById = function (popupId) {
			_this.destroyPopupById(popupId);
		};
		_this.closeLast = function () {
			var popups = _this.state.popups;
			if (!popups.length) return;
			_this.destroyPopupById(popups[popups.length - 1].props.popupId);
		};
		_this.close = function (component) {
			var popups = _this.state.popups;
			for (var _i = 0, popups_2 = popups; _i < popups_2.length; _i++) {
				var popup = popups_2[_i];
				if (_this._matchComponent(popup.component, component)) {
					_this.destroyPopupById(popup.props.popupId);
				}
			}
		};
		_this.numberOfPopupsOpened = function () {
			var count = 0;
			for (var _i = 0, _a = _this.state.popups; _i < _a.length; _i++) {
				var popup = _a[_i];
				if (popup.props.opened) count++;
			}
			return count;
		};
		_this.removePopupFromRenderById = function (popupId) {
			_this.setState(function (_a) {
				var popups = _a.popups;
				return {
					popups: popups.filter(function (popup) {
						return popup.props.popupId !== popupId;
					})
				};
			});
		};
		_this._matchComponent = function (component1, component2) {
			if (!component1) return false;
			if (!component2) return false;
			if (component1 === component2) return true;
			if (component1 === component2.constructor) return true;
			return false;
		};
		_this.destroyPopupById = function (popupId) {
			_this.setState(function (_a) {
				var popups = _a.popups;
				return {
					popups: popups.map(function (popup) {
						if (popup.props.popupId === popupId) {
							return {
								component: popup.component,
								props: __assign(__assign({}, popup.props), { destroy: true })
							};
						}
						return popup;
					})
				};
			});
		};
		_this.state = {
			popups: []
		};
		return _this;
	}
	PopupController.prototype.render = function () {
		var _this = this;
		var comps = [];
		var _loop_1 = function (i) {
			var Comp = this_1.state.popups[i].component;
			var props = this_1.state.popups[i].props;
			comps.push(
				react_1.default.createElement(
					Comp,
					__assign(
						{
							key: props.popupId,
							onHide: function () {
								_this.close(Comp);
							}
						},
						props
					)
				)
			);
		};
		var this_1 = this;
		for (var i in this.state.popups) {
			_loop_1(i);
		}
		if (comps.length > 0) return comps;
		return null;
	};
	return PopupController;
})(react_1.Component);
exports.PopupController = PopupController;
var popupDefaults = {
	animateType: 'slideInOut',
	animateDurationMs: 500,
	animateDirection: 'bottom',
	backgroundColor: 'rgba(0, 0, 0, 0.18)',
	enableBodyScrollLock: true
};
var popupControllerRef = {};
var popupController = {
	open: function (Comp, props) {
		return popupControllerRef.open(Comp, props);
	},
	hide: function (Comp) {
		popupControllerRef.hide(Comp);
	},
	close: function (Comp) {
		popupControllerRef.close(Comp);
	},
	closeById: function (popupId) {
		popupControllerRef.closeById(popupId);
	},
	closeLast: function () {
		popupControllerRef.closeLast();
	},
	closeAll: function () {
		popupControllerRef.closeAll();
	},
	setDefaults: function (defaults) {
		popupDefaults = __assign(__assign({}, popupDefaults), defaults);
	},
	convertProps: function (props) {
		return props;
	},
	numberOfPopupsOpened: function () {
		return popupControllerRef.numberOfPopupsOpened();
	},
	instance: react_1.default.createElement(PopupController, {
		ref: function (ref) {
			return (popupControllerRef = ref);
		}
	})
};
exports.default = popupController;
