import React from 'react';
import './PersonalInformation.scss';
import Label from '../../lib/@bit/redsky.framework.rs.label';
import Icon from '../../lib/@bit/redsky.framework.rs.icon';
import UserCheckoutInfo = Misc.UserCheckoutInfo;
import LabelCheckboxFilterBar from '../labelCheckbox/LabelCheckboxFilterBar';

interface PersonalInformationProps {
	personalInfo: UserCheckoutInfo;
	billingInfo: UserCheckoutInfo;
	isBillingInfo: boolean;
	onEditPersonalClickCallback?: () => void;
	onEditBillingClickCallback?: () => void;
	onChangeCheckbox: (value: boolean) => void;
}

const PersonalInformation: React.FC<PersonalInformationProps> = (props) => {
	const [differentBillingAddress, setDifferentBillingAddress] = React.useState<boolean>(props?.isBillingInfo);

	return (
		<React.Fragment>
			{!!props.onEditBillingClickCallback && (
				<div className={'checkboxWrapper'}>
					<LabelCheckboxFilterBar
						value={''}
						isDisabled={!props.onEditPersonalClickCallback}
						isChecked={differentBillingAddress}
						text={'Use Different Billing Address'}
						onSelect={() => {
							props.onChangeCheckbox(true);
							setDifferentBillingAddress(true);
						}}
						onDeselect={() => {
							props.onChangeCheckbox(false);
							setDifferentBillingAddress(false);
						}}
					/>
				</div>
			)}
			<div className={'rsPersonalInformationWrapper'}>
				<div className={'rsPersonalInformation'}>
					<div className={'personalInformationWrapper'}>
						<Label variant={'buttonMdLg'} className={'sectionTitle'} mb={40}>
							Personal Info
						</Label>
						<Label variant={'body2'} className={'name'}>
							{props?.personalInfo?.firstName} {props?.personalInfo?.lastName}
						</Label>
						<Label variant={'body2'} className={'street'}>
							{props?.personalInfo?.address1}
						</Label>
						<Label variant={'body2'} className={'street'}>
							{props?.personalInfo?.address2}
						</Label>
						<Label variant={'body2'} className={'city'}>
							{props?.personalInfo?.city}, {props?.personalInfo.state} {props?.personalInfo.zip}{' '}
							{props?.personalInfo.country}
						</Label>
					</div>
					{!!props.onEditPersonalClickCallback && (
						<Icon
							className={'editIcon'}
							iconImg={'icon-edit'}
							size={32}
							color={'black'}
							onClick={props.onEditPersonalClickCallback}
						/>
					)}
				</div>
				<div className={'rsPersonalInformation'}>
					<div className={'billingAddressWrapper'}>
						<Label
							variant={'buttonMdLg'}
							color={differentBillingAddress ? '#000' : '#7070706e'}
							className={'sectionTitle'}
							mb={40}
						>
							Billing Address
						</Label>
						<Label
							variant={'body2'}
							color={differentBillingAddress ? '#000' : '#7070706e'}
							className={'name'}
						>
							{props?.billingInfo?.firstName} {props?.billingInfo?.lastName}
						</Label>
						<Label
							variant={'body2'}
							color={differentBillingAddress ? '#000' : '#7070706e'}
							className={'street'}
						>
							{props?.billingInfo?.address1}
						</Label>
						<Label
							variant={'body2'}
							color={differentBillingAddress ? '#000' : '#7070706e'}
							className={'street'}
						>
							{props?.billingInfo?.address2}
						</Label>
						<Label
							variant={'body2'}
							color={differentBillingAddress ? '#000' : '#7070706e'}
							className={'city'}
						>
							{props?.billingInfo?.city}, {props?.billingInfo?.state} {props?.billingInfo?.zip}{' '}
							{props?.billingInfo?.country}
						</Label>
					</div>
					{!!props.onEditBillingClickCallback && (
						<Icon
							className={'editIcon'}
							iconImg={'icon-edit'}
							size={32}
							color={differentBillingAddress ? 'black' : '#7070706e'}
							onClick={differentBillingAddress ? props.onEditBillingClickCallback : () => null}
						/>
					)}
				</div>
			</div>
		</React.Fragment>
	);
};

export default PersonalInformation;
