import * as React from 'react';
import './SignupBanner.scss';
import { Box, popupController } from '../../lib/@bit/redsky.framework.rs.996';
import Label from '../../lib/@bit/redsky.framework.rs.label/dist/Label';
import Button from '../../lib/@bit/redsky.framework.rs.button';
import useIsAtBreakpoint from '../../customHooks/useIsAtBreakpoint';
import SignupPopup, { SignupPopupProps } from '../../popups/signup/SignupPopup';

const SignupBanner: React.FC = () => {
	const isMobile = useIsAtBreakpoint();

	return (
		<Box className={'rsSignupBanner'}>
			<Label variant={`${isMobile ? 'h2' : 'h1'}`} className={'textTag'}>
				Where convenience meets luxury
			</Label>
			<Button
				onClick={() => {
					popupController.open<SignupPopupProps>(SignupPopup);
				}}
				children={<Label variant={'buttonMdLg'}>Discover More About Rentyl Resorts</Label>}
				look={'containedPrimary'}
				className={'yellow discoverSignupButton'}
			/>
		</Box>
	);
};

export default SignupBanner;
