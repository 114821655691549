// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rsToastMessage .rsLabel{color:white}
`, "",{"version":3,"sources":["webpack://./src/lib/@bit/redsky.framework.rs.toastify/dist/ToastMessage.scss"],"names":[],"mappings":"AAAA,yBAEE,WAAY","sourcesContent":[".rsToastMessage {\n\t.rsLabel {\n\t\tcolor: white;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
