import * as React from 'react';
import { Box } from '../../lib/@bit/redsky.framework.rs.996';
import AccommodationSearchCardMobile from './accommodationSearchCardMobile/AccommodationSearchCardMobile';
import AccommodationSearchCardResponsive from './accommodationSearchCardResponsive/AccommodationSearchCardResponsive';
import useIsAtBreakpoint from '../../customHooks/useIsAtBreakpoint';

interface AccommodationSearchCardProps {
	pointPerDollar?: number;
	accommodation: Api.Destination.Res.Accommodation;
	destinationDetails: Api.Destination.Res.Details | undefined;
	destinationId: number;
	promotedRate?: any;
	nonPromotedRate?: any;
	openAccordion?: boolean;
	showInfoIcon?: boolean;
	packageLength: number | any;
	onClickInfoIcon?: (accommodationId: number) => void;
	loyaltyStatus: Model.LoyaltyStatus;
	upfrontCashRequired: boolean;
	resortPoint: number;
	resortPointsPrice: number;
	isCustomResort?: boolean;
	phone?: number;
	isOpen?: () => void;
	accessCode?: string;
}

const AccommodationSearchCard: React.FC<AccommodationSearchCardProps> = (props) => {
	const isMobile = useIsAtBreakpoint();

	return (
		<Box className={'rsAccommodationSearchCard'}>
			{isMobile ? (
				<AccommodationSearchCardMobile
					isOpen={props.isOpen}
					accessCode={props.accessCode}
					pointPerDollar={props.pointPerDollar}
					accommodation={props.accommodation}
					destinationDetails={props.destinationDetails}
					resortPoint={props.resortPoint}
					promotedRate={props.promotedRate}
					nonPromotedRate={props.nonPromotedRate}
					resortPointsPrice={props.resortPointsPrice}
					destinationId={props.destinationId}
					openAccordion={props.openAccordion}
					showInfoIcon={props.showInfoIcon}
					packageLength={props.packageLength}
					onClickInfoIcon={props.onClickInfoIcon}
					loyaltyStatus={props.loyaltyStatus}
					upfrontCashRequired={props.upfrontCashRequired}
					isCustomResort={props.isCustomResort}
					phone={props.phone}
				/>
			) : (
				<AccommodationSearchCardResponsive
					isOpen={props.isOpen}
					accessCode={props.accessCode}
					pointPerDollar={props.pointPerDollar}
					accommodation={props.accommodation}
					promotedRate={props.promotedRate}
					nonPromotedRate={props.nonPromotedRate}
					destinationDetails={props.destinationDetails}
					destinationId={props.destinationId}
					resortPoint={props.resortPoint}
					resortPointsPrice={props.resortPointsPrice}
					openAccordion={props.openAccordion}
					showInfoIcon={props.showInfoIcon}
					packageLength={props.packageLength}
					onClickInfoIcon={props.onClickInfoIcon}
					loyaltyStatus={props.loyaltyStatus}
					upfrontCashRequired={props.upfrontCashRequired}
					isCustomResort={props.isCustomResort}
					phone={props.phone}
				/>
			)}
		</Box>
	);
};

export default AccommodationSearchCard;
