var __createBinding =
	(this && this.__createBinding) ||
	(Object.create
		? function (o, m, k, k2) {
				if (k2 === undefined) k2 = k;
				Object.defineProperty(o, k2, {
					enumerable: true,
					get: function () {
						return m[k];
					}
				});
		  }
		: function (o, m, k, k2) {
				if (k2 === undefined) k2 = k;
				o[k2] = m[k];
		  });
var __setModuleDefault =
	(this && this.__setModuleDefault) ||
	(Object.create
		? function (o, v) {
				Object.defineProperty(o, 'default', { enumerable: true, value: v });
		  }
		: function (o, v) {
				o['default'] = v;
		  });
var __importStar =
	(this && this.__importStar) ||
	function (mod) {
		if (mod && mod.__esModule) return mod;
		var result = {};
		if (mod != null)
			for (var k in mod)
				if (k !== 'default' && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
		__setModuleDefault(result, mod);
		return result;
	};
Object.defineProperty(exports, '__esModule', { value: true });
var React = __importStar(require('react'));
var react_1 = require('react');
var Icon = function (props) {
	var _a = react_1.useState(props.iconImg),
		iconImgSrc = _a[0],
		setIconImgSrc = _a[1];
	react_1.useEffect(
		function () {
			setIconImgSrc(props.iconImg);
		},
		[props.iconImg]
	);
	var iconStyles = {
		color: props.color,
		fontSize: props.size ? props.size + 'px' : '16px'
	};
	if (props.cursorPointer) iconStyles['cursor'] = 'pointer';
	var iconClassName = 'rsIcon ' + iconImgSrc + ' ' + (props.className || '');
	return React.createElement('span', { className: iconClassName, style: iconStyles, onClick: props.onClick });
};
exports.default = Icon;
