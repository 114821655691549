import React, { useEffect, useState } from 'react';
import { Link, Page, popupController } from '../../lib/@bit/redsky.framework.rs.996';
import Label from '../../lib/@bit/redsky.framework.rs.label/dist/Label';
import Footer from '../../components/footer/Footer';
import { FooterLinks } from '../../components/footer/FooterLinks';
import './VerifyEmailPage.scss';
import Loader from '../../components/loader/Loader';
import { useParams, useNavigate } from 'react-router-dom';
import UserService from '../../services/user/user.service';
import serviceFactory from '../../services/serviceFactory';
import { rsToastify } from '../../lib/@bit/redsky.framework.rs.toastify';
import Button from '../../lib/@bit/redsky.framework.rs.button';
import OnboardingFlowOne from '../../components/onboardingFlow/OnboardingFlowOne';
import { getPageFinder, undefinedHandler } from '../../utils/undefinedHandler';
import OnboardingFlowOtp, { OnboardingFlowOtpProps } from '../../components/onboardingFlow/OnboardingFlowOtp';

const VerifyEmailPage: React.FC = () => {
	const { userId, token } = useParams();
	const userService = serviceFactory.get<UserService>('UserService');

	const [loading, setLoading] = useState(true);
	const [count, setCount] = useState(0);
	const navigate = useNavigate();

	const sendAgain = async () => {
		if (userId && token) {
			try {
				setCount(count + 1);
				setLoading(true);
				let response = await userService.resendVerificationMailById(userId);
				rsToastify.success('Verification Mail send successfully', 'Success!');
				setLoading(false);
			} catch (e: any) {
				setLoading(false);
				console.log('error --', e);
				rsToastify.error('UserId and token does not match!', 'Error!');
				const message = `Getting error :${e.message} on ${getPageFinder(window.location.pathname)} page.`;
				undefinedHandler(message);
			}
		}
	};

	const verifyUser = async () => {
		if (userId && token) {
			try {
				let response = await userService.verifyUser(userId.toString(), token);
				rsToastify.success('Signup successfully', 'Success!');
				navigate('/', { replace: true });
				if (response) {
					popupController.open<OnboardingFlowOtpProps>(OnboardingFlowOtp, {
						userResponse: response
					});
				} else {
					popupController.open<OnboardingFlowOtpProps>(OnboardingFlowOtp, {
						userResponse: response
					});
				}
			} catch (error: any) {
				console.log('error ----', error);
				setLoading(false);
				rsToastify.error('Email authentication token has expired', 'Error!');
				const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
				undefinedHandler(message);
			}
		}
	};

	useEffect(() => {
		verifyUser();
	}, [userId]);

	return (
		<Page className={'verifyEmailPage'}>
			{loading ? (
				<Loader />
			) : (
				<>
					{count <= 2 ? (
						<Button className={'updateButton'} look={'containedPrimary'} onClick={sendAgain}>
							<Label variant={'buttonMdLg'}>Resend </Label>
						</Button>
					) : (
						<Label>
							{
								'You have cross your resend verification mail limit. please wait some time and try again later!'
							}
						</Label>
					)}
				</>
			)}
		</Page>
	);
};

export default VerifyEmailPage;
