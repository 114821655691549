import * as React from 'react';
import './ReservationInfoCard.scss';
import Label from '../../lib/@bit/redsky.framework.rs.label';
import { DateUtils } from '../../utils/utils';
import { Box } from '../../lib/@bit/redsky.framework.rs.996';
import { getPageFinder, undefinedHandler } from '../../utils/undefinedHandler';
import { get } from 'lodash';

interface ReservationInfoCardProps {
	reservationDates: { startDate: string | Date; endDate: string | Date };
	propertyType: string;
	itineraryId: string;
	maxOccupancy: number;
	misc?: { title: string; data: string | number }[];
	cancelPermitted: 0 | 1;
}

const ReservationInfoCard: React.FC<ReservationInfoCardProps> = (props) => {
	function renderReservationDates() {
		try {
			return `${DateUtils.displayUserDate(props.reservationDates.startDate)} - ${DateUtils.displayUserDate(
				props.reservationDates.endDate
			)}`;
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	return (
		<Box className={'rsReservationInfoCard'}>
			<Box paddingRight={50}>
				<Label variant={'body1'} mb={8}>
					Reservation date
				</Label>
				<Label variant={'h6'} className={'contentFont'}>
					{renderReservationDates()}
				</Label>
			</Box>
			<Box paddingRight={50}>
				<Label variant={'body1'} mb={8}>
					Itinerary number
				</Label>
				<Label variant={'h6'} className={'contentFont'}>
					{get(props, 'itineraryId')}
				</Label>
			</Box>
			<Box paddingRight={50}>
				<Label variant={'body1'} mb={8}>
					Property type
				</Label>
				<Label variant={'h6'} className={'contentFont'}>
					{get(props, 'propertyType')}
				</Label>
			</Box>
			<Box>
				<Label variant={'body1'} mb={8}>
					Max occupancy
				</Label>
				<Label variant={'h6'} className={'contentFont'}>
					{get(props, 'maxOccupancy')}
				</Label>
			</Box>
		</Box>
	);
};

export default ReservationInfoCard;
