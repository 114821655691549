import { useEffect, useRef, useState } from 'react';
import { rsToastify } from '../lib/@bit/redsky.framework.rs.toastify';
import serviceFactory from '../services/serviceFactory';
import DestinationService from '../services/destination/destination.service';
import AccommodationService from '../services/accommodation/accommodation.service';

export interface FilterOptions {
	propertyTypes: Model.PropertyType[];
	amenities: Misc.OptionType[];
	experiences: Misc.OptionType[];
}
export default function useFilterOptions(destinationId?: number) {
	const [filterOptions, setFilterOptions] = useState<FilterOptions>({
		propertyTypes: [],
		amenities: [],
		experiences: []
	});
	const destinationService = serviceFactory.get<DestinationService>('DestinationService');
	const accommodationService = serviceFactory.get<AccommodationService>('AccommodationService');

	useEffect(() => {
		async function getFilterBarOptions() {
			try {
				let propertyTypes: Api.Destination.Res.PropertyType[];
				if (!destinationId) {
					propertyTypes = await destinationService.getAllPropertyTypes();
				} else {
					propertyTypes = await destinationService.getPropertyTypesForDestination(destinationId);
				}
				const amenities: Api.Amenity.Res.Get[] = await accommodationService.getAllAmenities();
				const experiences: Api.Experience.Res.Get[] = await destinationService.getExperienceTypes();
				setFilterOptions({
					propertyTypes,
					amenities: amenities.map((amenity) => {
						return { value: amenity.id, label: amenity.title };
					}),
					experiences: experiences.map((experience) => {
						return { value: experience.id, label: experience.title };
					})
				});
			} catch (e) {
				rsToastify.error(
					'An unexpected error occurred on the server, unable to get all the options.',
					'Server Error!'
				);
			}
		}

		getFilterBarOptions().catch(console.error);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return filterOptions;
}
