import * as React from 'react';
import './TopSearchBarMobile.scss';
import Label from '../../../lib/@bit/redsky.framework.rs.label/dist/Label';
import { Box, popupController } from '../../../lib/@bit/redsky.framework.rs.996';
import Icon from '../../../lib/@bit/redsky.framework.rs.icon';
import DateRangeSelector from '../../dateRangeSelector/DateRangeSelector';
import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { formatFilterDateForServer, ObjectUtils, WebUtils } from '../../../utils/utils';
import { RsFormControl, RsFormGroup } from '../../../lib/@bit/redsky.framework.rs.form';
import { rsToastify } from '../../../lib/@bit/redsky.framework.rs.toastify';
import serviceFactory from '../../../services/serviceFactory';
import RegionService from '../../../services/region/region.service';
import Paper from '../../paper/Paper';
import { useRecoilState } from 'recoil';
import globalState from '../../../state/globalState';
import LabelButton from '../../labelButton/LabelButton';
import themes from '../../../themes/themes.scss';
import { getPageFinder, undefinedHandler } from '../../../utils/undefinedHandler';
import UserService from '../../../services/user/user.service';
import { useNavigate } from 'react-router-dom';
import useOnClickOutsideRef from '../../../customHooks/useOnClickOutsideRef';
import DestinationDetailsMobilePopup, {
	DestinationDetailsMobilePopupProps
} from '../../../popups/destinationDetailsMobilePopup/DestinationDetailsMobilePopup';

interface TopSearchBarMobileProps {
	onFilterClick?: () => void;
}

interface UserSearchHistory {
	id: number;
	sessionId: number;
	sessionSearchHistory: any;
	userId: number;
}

const TopSearchBarMobile: React.FC<TopSearchBarMobileProps> = (props) => {
	const navigate = useNavigate();
	const boxRef = useRef<HTMLDivElement>(null);
	const regionService = serviceFactory.get<RegionService>('RegionService');
	const userService = serviceFactory.get<UserService>('UserService');

	const [reservationFilters, setReservationFilters] = useRecoilState<Misc.ReservationFilters>(
		globalState.reservationFilters
	);
	const [regionName, setRegionName] = useRecoilState<string>(globalState.regionName);
	const [regionList, setRegionList] = useState<Api.Region.Res.Get[]>([]);
	// const [selectedCategory, setSelectedCategory] = useState<number[]>([]);
	const [searchRegionParameter, setSearchRegionParameter] = useState<string>('');
	const [selectedCategory, setSelectedCategory] = useRecoilState<any | []>(globalState.regionNameListId);
	const [regionNameList, setRegionNameList] = useRecoilState<any | []>(globalState.regionNameList);
	const [startDateControl, setStartDateControl] = useState<moment.Moment | null>(
		moment(reservationFilters.startDate)
	);
	const [endDateControl, setEndDateControl] = useState<moment.Moment | null>(moment(reservationFilters.endDate));
	const [focusedInput, setFocusedInput] = useState<'startDate' | 'endDate' | null>(null);
	const [topSearchBarForm, setTopSearchBarForm] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl('regionIds', reservationFilters.regionIds || [], []),
			new RsFormControl('startDate', (reservationFilters.startDate as string) || '', []),
			new RsFormControl('endDate', (reservationFilters.endDate as string) || '', [])
		])
	);
	const [user, setUser] = useRecoilState<Api.User.Res.Detail | undefined>(globalState.user);
	const [userSearchHistory, setUserSearchHistory] = useState<UserSearchHistory[]>([]);
	const [isFetchingResults, setIsFetchingResults] = useRecoilState<boolean>(globalState.isFetchingResults);
	const [searchHistoryShow, setSearchHistoryShow] = useRecoilState<number>(globalState.searchHistoryShow);
	const [unknownUserSearchHistoryData, setUnknownUserSearchHistoryData] = useState<any>();
	const [unknownUserSearchHistoryId, setUnknownUserSearchHistoryId] = useState<string>('');
	const backUpArrow = '../../../images/backUpArrow.svg';

	useEffect(() => {
		(async () => {
			try {
				let regions: Api.Region.Res.Get[] = await regionService.getAllRegions();
				setRegionList(regions);
				const newRegionName = regionList.find((item) => reservationFilters.regionIds?.includes(item.id));
				setRegionName(newRegionName ? newRegionName.name : regionName);
				let topSearchBarFormCopy = topSearchBarForm.clone();
				let regionIdControl = topSearchBarFormCopy.get('regionIds');
				let startDateControl = topSearchBarFormCopy.get('startDate');
				let endDateControl = topSearchBarFormCopy.get('endDate');
				regionIdControl.value = reservationFilters.regionIds || [];
				startDateControl.value = (reservationFilters.startDate as string) || '';
				endDateControl.value = (reservationFilters.endDate as string) || '';
				topSearchBarFormCopy.update(regionIdControl);
				topSearchBarFormCopy.update(startDateControl);
				topSearchBarFormCopy.update(endDateControl);
				setTopSearchBarForm(topSearchBarFormCopy);
				setStartDateControl(moment(reservationFilters.startDate));
				setEndDateControl(moment(reservationFilters.endDate));
			} catch (e: any) {
				rsToastify.error('There was an issue getting regions');
				const message = `Getting error :${e.message} on ${getPageFinder(window.location.pathname)} page.`;
				undefinedHandler(message);
			}
		})();
	}, [reservationFilters]);

	useEffect(() => {
		if (!user) {
			const data: any = localStorage.getItem('userSearchHistory');
			if (data) {
				setUnknownUserSearchHistoryId(data);
				getUnknownUserSearchHistory(data);
			}
		}
		function handleClickOutside(event: any) {
			if (boxRef && boxRef.current && !boxRef.current.contains(event.target)) {
				let test = boxRef.current;
				test.style.display = 'none';
			}
		}
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	useEffect(() => {
		user && getUserSearchHistory();
	}, [user]);

	useEffect(() => {
		let newControl = topSearchBarForm.get('regionIds');
		newControl.value = [...selectedCategory];
		setTopSearchBarForm(topSearchBarForm.clone().update(newControl));
	}, [selectedCategory]);

	async function onApplyClick() {
		if (!user) {
			const sessionId = moment(new Date()).valueOf().toString();
			if (!unknownUserSearchHistoryId) {
				const data = {
					sessionId: sessionId,

					startDate: topSearchBarForm.get('startDate').value as string,
					endDate: topSearchBarForm.get('endDate').value as string,
					regionIds: topSearchBarForm.get('regionIds').value as number[]
				};

				const sessiondata = await userService.saveSearchHistory(data);

				localStorage.setItem('userSearchHistory', sessionId);

				setUnknownUserSearchHistoryData(sessiondata);
			}
			//else {
			//	const updateData = {
			//		sessionId: unknownUserSearchHistoryId,
			//		startDate: topSearchBarForm.get('startDate').value as string,
			//		endDate: topSearchBarForm.get('endDate').value as string,
			//		regionIds: topSearchBarForm.get('regionIds').value as number[]
			//	};

			//	const updateSessiondata = await userService.updateSaveSearchActivity(updateData);
			//	setUnknownUserSearchHistoryData(updateSessiondata);
			//}
		}
		if (user) {
			const sessionId = moment(new Date()).valueOf().toString();
			const data = {
				sessionId: sessionId,
				...reservationFilters,
				startDate: topSearchBarForm.get('startDate').value as string,
				endDate: topSearchBarForm.get('endDate').value as string,
				regionIds: topSearchBarForm.get('regionIds').value as number[]
			};

			const compareArrays = (arr1: number[], arr2: number[]) => {
				return arr1.some((element, index) => element != arr2[index]);
			};

			const previousSearch = userSearchHistory[0].sessionSearchHistory;

			if (
				data.startDate != previousSearch.startDate ||
				data.endDate != previousSearch.endDate ||
				data.adultCount != previousSearch.adultCount ||
				data.childCount != previousSearch.childCount ||
				compareArrays(data.regionIds, previousSearch.regionIds)
			) {
				(async () => {
					await userService.saveSearchHistory(data);
					getUserSearchHistory();
				})();
			}
		}

		setReservationFilters((prev) => {
			const newFilter: Misc.ReservationFilters = {
				...prev,
				startDate: topSearchBarForm.get('startDate').value as string,
				endDate: topSearchBarForm.get('endDate').value as string,
				regionIds: topSearchBarForm.get('regionIds').value as number[]
			};
			setSearchRegionParameter('');

			// WebUtils.updateUrlParams(newFilter);
			return newFilter;
		});
	}

	async function onApplyClickResumeYourJourney() {
		try {
			setSelectedCategory([...unknownUserSearchHistoryData.regionIds]);
			onDatesChange(moment(unknownUserSearchHistoryData.startDate), moment(unknownUserSearchHistoryData.endDate));
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	async function onApplyClickUserResumeYourJourney(object: any, index: number) {
		setSearchHistoryShow(searchHistoryShow + 1);
		const sessionId = moment(new Date()).valueOf().toString();
		const data = {
			sessionId: sessionId,
			...object
		};
		index != 0 &&
			(async () => {
				await userService.saveSearchHistory(data);
				getUserSearchHistory();
			})();
		setReservationFilters((prev) => {
			const newFilter: Misc.ReservationFilters = {
				...prev,
				...object
			};
			setSearchRegionParameter('');

			return newFilter;
		});

		if (object.destinationName && object.destinationId) {
			popupController.open<DestinationDetailsMobilePopupProps>(DestinationDetailsMobilePopup, {
				destinationId: object.destinationId as number,
				startDate: object.startDate as string,
				endDate: object.endDate as string
			});
		}
		setTimeout(() => {
			boxRef.current!.style.display = 'none';
		}, 50);
	}

	async function getUserSearchHistory() {
		try {
			const searchHistory = await userService.getUserSearchHistory();
			setUserSearchHistory(searchHistory);
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	async function getUnknownUserSearchHistory(data: any) {
		try {
			const sessiondata = await userService.getUnknownUserSearchHistory(JSON.stringify(data));
			setUnknownUserSearchHistoryData(sessiondata);
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	function updateDatesForSearchBarForm(key: 'startDate' | 'endDate', value: any) {
		let newControl = topSearchBarForm.get(key);
		newControl.value = value;
		setTopSearchBarForm(topSearchBarForm.clone().update(newControl));
	}

	function onDatesChange(startDate: moment.Moment | null, endDate: moment.Moment | null): void {
		setStartDateControl(startDate);
		setEndDateControl(endDate);
		if (endDate === null) {
			return rsToastify.error('Make sure you select a check out date!', 'No Check Out Date Selected');
		}
		updateDatesForSearchBarForm('startDate', formatFilterDateForServer(startDate, 'start'));
		updateDatesForSearchBarForm('endDate', formatFilterDateForServer(endDate, 'end'));
		onApplyClick();
		setSearchHistoryShow(searchHistoryShow + 1);
	}

	function renderRegionLabel() {
		try {
			let regionId = topSearchBarForm.get('regionIds').value as number[];
			if (!ObjectUtils.isArrayWithData(regionId)) return 'Location';
			const tempRegionList: any = [];
			regionId.map((item1: number, index: number) => {
				const regionObj = regionList.find((item: any) => item.id == item1);
				if (regionObj) {
					tempRegionList.push(regionObj.name);
				}
			});
			let regionlist = tempRegionList ? tempRegionList.join() : '';
			return 'Location';
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	const onChangeCategory = (event: any, item: any) => {
		try {
			let val: string = event.target?.value;
			// props.onChangeSortBy(event.target.value);
			if (event.target?.checked && !regionNameList.includes(item.name)) {
				setSelectedCategory([...selectedCategory, item.id]);
				setRegionNameList([...regionNameList, item.name]);
			} else if (!event.target?.checked && regionNameList.includes(item.name)) {
				setSelectedCategory(selectedCategory.filter((o: any) => o != item.id));
				setRegionNameList(regionNameList.filter((o: any) => o != item.name));
			}
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	};

	function regionListBuilder(searchValue: string) {
		try {
			if (!ObjectUtils.isArrayWithData(regionList)) return;

			let filteredRegion = regionList.filter((regionObj: any) => {
				return regionObj.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1;
			});

			if (filteredRegion.length === 0) {
				return 'No Region Found';
			}

			return filteredRegion.map((item, index) => {
				let isSelected = topSearchBarForm.get('regionIds').value === item.id;

				return (
					<label htmlFor={`regionCheckBoxMobile${index}`}>
						<Box
							key={item.id}
							className={`regionItem ${isSelected ? 'selected' : ''}`}
							onClick={() => {
								// let newControl = topSearchBarForm.get('regionIds');
								// newControl.value = [item.id];
								// setTopSearchBarForm(topSearchBarForm.clone().update(newControl));
								// onApplyClick();
								// setTimeout(() => {
								// 	boxRef.current!.style.display = 'none';
								// }, 50);
							}}
							display={'flex'}
							alignItems={'center'}
						>
							<div className={`rsLabelCheckboxFilterBar  ''}`}>
								<label className={'checkboxContainer'}>
									<input
										id={`regionCheckBoxMobile${index}`}
										value={item.name}
										type={'checkbox'}
										className={'checkboxInput'}
										checked={regionNameList.includes(item.name)}
										onChange={(e) => onChangeCategory(e, item)}
									/>
								</label>
								<Label variant={'h6'} lineClamp={2} className="titleLabel">
									{item.name}
								</Label>
							</div>
						</Box>
					</label>
				);
			});
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	function savedSearches() {
		try {
			const tempRegionList: any = [];
			unknownUserSearchHistoryData?.regionIds?.map((item1: number, index: number) => {
				const regionObj = regionList?.find((item: any) => item.id == item1);
				if (regionObj) {
					tempRegionList.push(regionObj.name);
				}
			});

			let regionlist = tempRegionList ? tempRegionList.join() : '';

			return (
				<Box
					className="savedSearches"
					onClick={() => {
						onApplyClickResumeYourJourney();
						setSearchHistoryShow(searchHistoryShow + 1);
					}}
				>
					<Label className="savedRegionlist">
						{regionlist.length <= 30 ? `${regionlist}` : `${regionlist.slice(0, 30)}...`}
					</Label>
					<Label className="savedDate">
						{moment(unknownUserSearchHistoryData?.startDate).format('MM/DD/YYYY')} -{' '}
						{moment(unknownUserSearchHistoryData?.endDate).format('MM/DD/YYYY')}
					</Label>
					<Box className="backUpArrowBox" mt={5}>
						<Label className="savedDate">Resume your journey</Label>
						<img src={backUpArrow} className="backUpArrow" alt="backUpArrow" />
					</Box>
				</Box>
			);
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	return (
		<div className={'rsTopSearchBarMobile'}>
			<Label
				className={'locationTitle'}
				variant={'h5'}
				textOverflow={'ellipsis'}
				whiteSpace={'nowrap'}
				overflow={'hidden'}
				width={'9ch'}
				onClick={() => {
					boxRef.current!.style.display = 'block';
				}}
			>
				<input
					className="searchInputField"
					onChange={(e) => setSearchRegionParameter(e.target.value)}
					value={searchRegionParameter}
					placeholder={renderRegionLabel() || ''}
				/>
			</Label>
			<Box display={'flex'} alignItems={'center'}>
				<DateRangeSelector
					isMobile
					showSeparator={false}
					startDate={startDateControl}
					endDate={endDateControl}
					onDatesChange={onDatesChange}
					monthsToShow={1}
					focusedInput={focusedInput}
					onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
					startDateLabel={'Check in'}
					endDateLabel={'Check out'}
					labelVariant={'h6'}
				/>
				<hr />
				<Icon iconImg={'icon-slider'} size={24} onClick={props.onFilterClick} cursorPointer />

				<Box boxRef={boxRef} className={'popoutBox'}>
					<Paper
						boxShadow
						borderRadius={'20px'}
						padding={'13px 23px'}
						backgroundColor={themes.white}
						className={'regionList'}
					>
						{!!user && !isFetchingResults && !!userSearchHistory && userSearchHistory.length > 0 && (
							<>
								<div className={'resumeJourneyMobile'}>
									<Label variant="h6" marginBottom={15}>
										Resume Your Journey
									</Label>
									{userSearchHistory.map((item, index) => {
										const obj = item.sessionSearchHistory;
										let regionlist = '';
										if (!!obj.regionIds && obj.regionIds.length > 0) {
											let array = obj.regionIds.map((o: number) => {
												let obj2 = regionList.find((item: any) => item.id == o);
												return obj2 ? obj2.name : '';
											});
											regionlist = array.join();
										}

										return (
											<React.Fragment key={index}>
												<Box
													className="historyItem"
													onClick={() => onApplyClickUserResumeYourJourney(obj, index)}
												>
													{!!obj.destinationName && !!obj.destinationId && (
														<Label variant={'body1'}>
															{obj.destinationName.length <= 30
																? `${obj.destinationName}`
																: `${obj.destinationName.slice(0, 30)}...`}{' '}
															&emsp;
														</Label>
													)}
													{!!regionlist && regionlist.length > 0 && (
														<Label variant={'body1'}>
															{regionlist.length <= 30
																? `${regionlist}`
																: `${regionlist.slice(0, 30)}...`}{' '}
															&emsp;
														</Label>
													)}
													<Label
														variant={'body1'}
														lineClamp={2}
														className="historySearchDate"
													>
														{moment(item.sessionSearchHistory.startDate).format(
															'MM/DD/YYYY'
														)}
														{' - '}
														{moment(item.sessionSearchHistory.endDate).format('MM/DD/YYYY')}
													</Label>
												</Box>
												<hr style={{ color: '#f1f1f1', marginTop: 12, marginBottom: 12 }} />
											</React.Fragment>
										);
									})}
								</div>
								<br />
							</>
						)}
						{!user &&
						unknownUserSearchHistoryId &&
						unknownUserSearchHistoryData &&
						!isFetchingResults &&
						searchHistoryShow === 0 ? (
							<>
								{savedSearches()} <hr />
							</>
						) : null}

						<Label variant={'h6'} className={'primaryTextColor'}>
							Location
						</Label>
						<Label variant={'body1'} mb={10}>
							Where are you going?
						</Label>
						{regionListBuilder(searchRegionParameter)}
						<hr />
						<Box className="cardFooter">
							<LabelButton
								variant="buttonSm"
								label="Clear"
								look="none"
								onClick={() => {
									setSearchRegionParameter('');
									// setRegionName('');
									setSelectedCategory([]);
									setRegionNameList([]);
									setReservationFilters((prev) => {
										const newFilter: Misc.ReservationFilters = {
											...prev,
											regionIds: []
										};

										// WebUtils.updateUrlParams(newFilter);
										return newFilter;
									});
									setTimeout(() => {
										boxRef.current!.style.display = 'none';
									}, 50);
								}}
							/>
							<LabelButton
								className={'applyButton'}
								look={'containedPrimary'}
								variant={'buttonSm'}
								label={'Apply'}
								onClick={() => {
									onApplyClick();
									setSearchHistoryShow(searchHistoryShow + 1);
									setTimeout(() => {
										boxRef.current!.style.display = 'none';
									}, 50);
								}}
							/>
						</Box>
					</Paper>
				</Box>
			</Box>
		</div>
	);
};

export default TopSearchBarMobile;
