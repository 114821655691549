import React from 'react';
import './ItineraryCardResponsive.scss';
import { Box, popupController } from '../../../lib/@bit/redsky.framework.rs.996';
import Label from '../../../lib/@bit/redsky.framework.rs.label';
import ReservationInfoCard from '../../reservationInfoCard/ReservationInfoCard';
import LabelLink from '../../labelLink/LabelLink';
import Icon from '../../../lib/@bit/redsky.framework.rs.icon';
import IconLabel from '../../iconLabel/IconLabel';
import CarouselV2 from '../../carouselV2/CarouselV2';
import { useNavigate } from 'react-router-dom';
import LightBoxCarouselPopup, {
	TabbedCarouselPopupProps
} from '../../../popups/lightBoxCarouselPopup/LightBoxCarouselPopup';
import Media = Api.Media;
import { ObjectUtils, StringUtils } from '../../../utils/utils';
import { get } from 'lodash';
import { getPageFinder, undefinedHandler } from '../../../utils/undefinedHandler';

interface ItineraryCardResponsiveProps {
	title: string;
	address: string;
	reservationDates: { startDate: string | Date; endDate: string | Date };
	destinationExperiences: {
		id: number;
		title: string;
		icon: string;
	}[];
	propertyType: string;
	itineraryId: string;
	maxOccupancy: number;
	amenities: string[];
	totalPoints: number;
	linkPath: string;
	cancelPermitted: 0 | 1;
	itineraryTotal: number;
	paidWithPoints: boolean;
	media: Media[];
	currencyCode: string;
}

const ItineraryCardResponsive: React.FC<ItineraryCardResponsiveProps> = (props) => {
	let navigate = useNavigate();
	function getImagePaths(imageGallery: Media[]) {
		try {
			if (!ObjectUtils.isArrayWithData(imageGallery) || !imageGallery[0].id) return [];
			let images = imageGallery;
			images.sort((a, b) => {
				return b.isPrimary - a.isPrimary;
			});
			return images.map((urlObj) => {
				return urlObj.urls.large.toString();
			});
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
			return [];
		}
	}

	function renderFeatures() {
		try {
			return props.destinationExperiences.map((experience) => {
				return (
					<IconLabel
						key={experience.id}
						labelName={experience.title}
						iconImg={experience.icon}
						iconPosition={'top'}
						iconSize={45}
						labelVariant={'body1'}
					/>
				);
			});
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}
	return (
		<Box className={'rsItineraryCardResponsive'}>
			<Box className={'columnOne'}>
				<CarouselV2
					path={props.linkPath}
					resortPoint={0}
					imgPaths={getImagePaths(props.media)}
					onGalleryClick={() => {
						popupController.open<TabbedCarouselPopupProps>(LightBoxCarouselPopup, {
							imageData: props.media.sort((a: any, b: any) => a.mediaIndex - b.mediaIndex)
						});
					}}
					imageWidth={327}
					hideCompareButton
				/>
			</Box>
			<Box
				className={'columnTwo'}
				onClick={() => {
					navigate(`${props.linkPath}`);
				}}
			>
				<Box className={'rowOne'}>
					<Label variant={'h5'} marginRight={24}>
						{get(props, 'title')}
					</Label>
					<Label variant={'h6'}>
						<Icon iconImg="icon-pin" color={'#D2555F'} size={17} className="locationIcon" />
						{get(props, 'address')}
					</Label>
				</Box>
				<Box className={'rowTwo'}>
					<ReservationInfoCard
						reservationDates={props.reservationDates}
						propertyType={props.propertyType}
						itineraryId={props.itineraryId}
						maxOccupancy={props.maxOccupancy}
						cancelPermitted={props.cancelPermitted}
					/>
				</Box>
				<Box className={'rowThree'}>
					<Label marginBottom={15} variant={'body1'}>
						Amenities
					</Label>
					<Box className={'experienceWrapper'}>{renderFeatures()}</Box>
				</Box>
			</Box>
			<Box
				className={'columnThree'}
				onClick={() => {
					navigate(`${props.linkPath}`);
				}}
			>
				<div>
					<Label variant={'body1B'} className={'primaryTextColor'} marginBottom={8}>
						{!props.paidWithPoints ? 'Trip Total' : 'Points Paid'}
					</Label>
					<Label variant={'h1'} marginBottom={8} className="priceDisplayLabel">
						{!props.paidWithPoints
							? StringUtils.formatCurrency(props.currencyCode) +
							  `${StringUtils.formatMoney(props.itineraryTotal)}`
							: StringUtils.addCommasToNumber(props.totalPoints)}
					</Label>
					<LabelLink path={props.linkPath} label={'view details'} variant={'Body1B'} />
				</div>
			</Box>
		</Box>
	);
};

export default ItineraryCardResponsive;
