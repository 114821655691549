import React, { useEffect, useRef, useState } from 'react';
import './AccountPointsPage.scss';
import { Page } from '../../lib/@bit/redsky.framework.rs.996';
import serviceFactory from '../../services/serviceFactory';
import UserPointService from '../../services/userPoint/userPoint.service';
import Box from '../../lib/@bit/redsky.framework.rs.996/dist/box/Box';
import Label from '../../lib/@bit/redsky.framework.rs.label';
import { useNavigate } from 'react-router-dom';
import LoadingPage from '../loadingPage/LoadingPage';
import Paper from '../../components/paper/Paper';
import globalState from '../../state/globalState';
import { useRecoilValue } from 'recoil';
import useIsAtBreakpoint from '../../customHooks/useIsAtBreakpoint';
import { DateUtils, StringUtils, WebUtils } from '../../utils/utils';
import { rsToastify } from '../../lib/@bit/redsky.framework.rs.toastify';
import SubNavMenu from '../../components/subNavMenu/SubNavMenu';
import UserBasicInfoPaper from '../../components/userBasicInfoPaper/UserBasicInfoPaper';
import UserService from '../../services/user/user.service';
import Button from '../../lib/@bit/redsky.framework.rs.button';
import Icon from '../../lib/@bit/redsky.framework.rs.icon';
import themes from '../../themes/themes.scss';
import PointsOrCentsBox from '../../components/pointsOrCentsBox/PointsOrCentsBox';
import { getPageFinder, undefinedHandler } from '../../utils/undefinedHandler';
import { get } from 'lodash';

const AccountPointsPage: React.FC = () => {
	let navigate = useNavigate();
	const isMobile = useIsAtBreakpoint();
	const scrollToTopButtonRef = useRef<HTMLButtonElement>(null);
	const userService = serviceFactory.get<UserService>('UserService');
	const user = useRecoilValue<Api.User.Res.Detail | undefined>(globalState.user);
	const userPointService = serviceFactory.get<UserPointService>('UserPointService');
	const [pointHistory, setPointHistory] = useState<Api.UserPoint.Res.Verbose[]>();

	useEffect(() => {
		if (!user) navigate('/page-not-found');
		async function getUserPoints() {
			try {
				if (user) {
					let res = await userPointService.getPointTransactionsByUserId();
					setPointHistory(res);
				}
			} catch (e: any) {
				rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unable to get paints for user.'), 'Server Error');
				const message = `Getting error :${e.message} on ${getPageFinder(window.location.pathname)} page.`;
				undefinedHandler(message);
			}
		}
		getUserPoints().catch(console.error);
	}, [user]);

	useEffect(() => {
		window.addEventListener('scroll', getTopOffset);

		return () => {
			window.removeEventListener('scroll', getTopOffset);
		};
	}, []);

	function getPointAmount(point: Api.UserPoint.Res.Verbose) {
		try {
			if (point.status === 'PENDING' || point.status === 'RECEIVED' || point.status === 'REFUNDED') {
				return StringUtils.addCommasToNumber(point.pointAmount);
			} else if (
				point.status === 'REVOKED' ||
				point.status === 'EXPIRED' ||
				point.status === 'CANCELED' ||
				point.status === 'REDEEMED'
			) {
				return `-${StringUtils.addCommasToNumber(point.pointAmount)}`;
			} else {
				return '';
			}
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}
	function getTopOffset() {
		if (!scrollToTopButtonRef.current) return;
		if (window.pageYOffset > window.screen.availHeight) {
			scrollToTopButtonRef.current.classList.add('show');
		} else {
			scrollToTopButtonRef.current.classList.remove('show');
		}
	}

	function renderPoints(type: string) {
		if (!pointHistory) return;
		if (!isMobile) {
			return (
				<table className={'pointsTable'}>
					<thead>
						<tr className={'pointsTableHeader'}>
							<td>
								<Label className={'transactionType'} variant={'h6'}>
									Status
								</Label>
							</td>
							<td>
								<Label className={'pointAmount'} variant={'h6'}>
									Point Type
								</Label>
							</td>
							<td>
								<Label className={'pointAmount'} variant={'h6'}>
									Point Amount
								</Label>
							</td>
							<td>
								<Label className={'pointAmount'} variant={'h6'}>
									Reason
								</Label>
							</td>
							<td>
								<Label className={'pointAmount'} variant={'h6'}>
									Notes
								</Label>
							</td>
							<td>
								<Label className={'pointAmount'} variant={'h6'}>
									Created On
								</Label>
							</td>
							<td>
								<Label className={'pointAmount'} variant={'h6'}>
									Modified On
								</Label>
							</td>
							<td>
								<Label className={'pointAmount'} variant={'h6'}>
									Availabile On
								</Label>
							</td>
							<td>
								<Label className={'pointAmount'} variant={'h6'}>
									Expire On
								</Label>
							</td>
						</tr>
					</thead>
					<tbody>
						{pointHistory.map((point, index) => {
							if (type === 'pending' && point.status !== 'PENDING') return false;
							if (type === 'completed' && point.status === 'PENDING') return false;
							return (
								<tr key={index} className={'pointsTableBody'}>
									<td>
										<Box className={'pendingPointsDetailsContainer'}>
											<Label
												className={
													point.pointType.toLowerCase() === 'admin'
														? 'pinkChip pointTypeLabel'
														: 'blueChip pointTypeLabel'
												}
												variant={'buttonSm'}
											>
												{StringUtils.capitalizeFirst(point.status.toLowerCase())}
											</Label>
											{/* <Label variant={'body1'}>{StringUtils.capitalizeFirst(point.title.toLowerCase())}</Label> */}
										</Box>
									</td>
									<td>
										<Label className={'points'}>{get(point, 'pointType')}</Label>
									</td>
									<td>
										<Label className={'points'} variant={'body1'}>
											{getPointAmount(point)}
										</Label>
									</td>
									<td>
										<Label className={'points'} variant={'body1'}>
											{get(point, 'reason')}
										</Label>
									</td>
									<td>
										<Label className={'points'} variant={'body1'}>
											{get(point, 'notes')}
										</Label>
									</td>
									<td>
										<Label className={'date'} variant={'body1'}>
											{DateUtils.formatDate(new Date(get(point, 'createdOn')), 'MM-DD-YY')}
										</Label>
									</td>
									<td>
										<Label className={'date'} variant={'body1'}>
											{DateUtils.formatDate(new Date(get(point, 'modifiedOn')), 'MM-DD-YY')}
										</Label>
									</td>
									<td>
										<Label className={'date'} variant={'body1'}>
											{DateUtils.formatDate(new Date(get(point, 'availableOn')), 'MM-DD-YY')}
										</Label>
									</td>
									<td>
										<Label className={'date'} variant={'body1'}>
											{!get(point, 'expireOn') ||
											get(point, 'expireOn') === '9999-12-31T00:00:00.000Z' ? (
												<>No Expiration</>
											) : (
												DateUtils.formatDate(new Date(get(point, 'expireOn')), 'MM-DD-YY')
											)}
										</Label>
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			);
		}
		return (
			<div>
				{pointHistory.map((point, index) => {
					if (type === 'pending' && point.status !== 'PENDING') return false;
					if (type === 'completed' && point.status === 'PENDING') return false;
					return (
						<Box key={index} className={'pointItemContainer pendingPointItemContainer'}>
							<Label
								className={
									point.pointType.toLowerCase() === 'admin'
										? 'pinkChip pointTypeLabel'
										: 'blueChip pointTypeLabel'
								}
								variant={'buttonSm'}
							>
								{StringUtils.capitalizeFirst(get(point, 'pointType').toLowerCase())}
							</Label>

							<div className={'pendingPointMobile'}>
								<Label variant={'h6'}>Status</Label>
								<Label className={'pendingPointRight'} variant={'body1'}>
									{get(point, 'status')}
								</Label>
							</div>
							<div className={'pendingPointMobile'}>
								<Label variant={'h6'}>Point Amount</Label>
								<Label className={'pendingPointRight'} variant={'body1'}>
									{get(point, 'pointAmount')}
								</Label>
							</div>
							<div className={'pendingPointMobile'}>
								<Label variant={'h6'}>Reason</Label>
								<Label className={'pendingPointRight'} variant={'body1'}>
									{get(point, 'reason')}
								</Label>
							</div>
							{point.notes && (
								<div className={'pendingPointMobile'}>
									<Label variant={'h6'}>Notes</Label>
									<Label className={'pendingPointRight'} variant={'body1'}>
										{get(point, 'notes')}
									</Label>
								</div>
							)}
							<div className={'pendingPointMobile'}>
								<Label variant={'h6'}>Created On</Label>
								<Label className={'pendingPointRight'} variant={'body1'}>
									{DateUtils.formatDate(new Date(get(point, 'createdOn')), 'MM-DD-YY')}
								</Label>
							</div>

							<div className={'pendingPointMobile'}>
								<Label variant={'h6'}>Modified On</Label>
								<Label className={'pendingPointRight'} variant={'body1'}>
									{DateUtils.formatDate(new Date(get(point, 'modifiedOn')), 'MM-DD-YY')}
								</Label>
							</div>
							<div className={'pendingPointMobile'}>
								<Label variant={'h6'}>Availabile On</Label>
								<Label className={'pendingPointRight'} variant={'body1'}>
									{DateUtils.formatDate(new Date(get(point, 'availableOn')), 'MM-DD-YY')}
								</Label>
							</div>
							<div className={'pendingPointMobile'}>
								<Label variant={'h6'}>Expire On</Label>
								<Label className={'pendingPointRight'} variant={'body1'}>
									{DateUtils.formatDate(new Date(get(point, 'expireOn')), 'MM-DD-YY')}
								</Label>
							</div>
						</Box>
					);
				})}
			</div>
		);
	}

	return !user ? (
		<LoadingPage />
	) : (
		<Page className={'rsAccountPointsPage'}>
			<div className={'rs-page-content-wrapper'}>
				<SubNavMenu title={'Account'} />
				<UserBasicInfoPaper
					userData={user}
					onLogOut={() => {
						userService.logout();
						navigate('/');
					}}
				/>

				<Paper
					className={'pointHistoryContainer'}
					backgroundColor={themes.white}
					padding={isMobile ? '20px' : '40px'}
					boxShadow
				>
					<div className={'pendingPointsContainer'}>
						<Label className={'pendingPointsLabel'} variant={'buttonMdLg'} mb={24}>
							Pending Points
						</Label>
						<div className={'pending pointTableContainer'}>{renderPoints('pending')}</div>
					</div>
				</Paper>
				<Paper
					className={'pointHistoryContainer'}
					backgroundColor={themes.white}
					padding={isMobile ? '10px' : '40px'}
					boxShadow
				>
					<div className={'completedTransactionsContainer'}>
						<Label variant={'buttonMdLg'} mb={24}>
							Completed Transactions
						</Label>
						<div className={'completed pointTableContainer'}>{renderPoints('completed')}</div>
					</div>
				</Paper>
				{isMobile && (
					<Button
						buttonRef={scrollToTopButtonRef}
						className={`scrollToTopButton`}
						look={'none'}
						onClick={() => {
							window.scrollTo({ top: 0, behavior: 'smooth' });
						}}
					>
						<Icon iconImg={'icon-chevron-up'} size={24} />{' '}
					</Button>
				)}
			</div>
		</Page>
	);
};

export default AccountPointsPage;
