import * as React from 'react';
import './DestinationSearchResultCardMobile.scss';
import CarouselV2 from '../../carouselV2/CarouselV2';
import { Box, popupController } from '../../../lib/@bit/redsky.framework.rs.996';
import Label from '../../../lib/@bit/redsky.framework.rs.label';
import Icon from '../../../lib/@bit/redsky.framework.rs.icon';
import UserService from '../../../services/user/user.service';
import { useRecoilState, useRecoilValue } from 'recoil';
import globalState, { setRecoilExternalValue } from '../../../state/globalState';
import { NumberUtils, ObjectUtils, StringUtils } from '../../../utils/utils';
import DestinationDetailsMobilePopup, {
	DestinationDetailsMobilePopupProps
} from '../../../popups/destinationDetailsMobilePopup/DestinationDetailsMobilePopup';
import LabelButton from '../../labelButton/LabelButton';
import AccommodationsPopup, { AccommodationsPopupProps } from '../../../popups/accommodationsPopup/AccommodationsPopup';
import metaCapi from '../../../customHooks/useMetaCapi';
import TagManager from 'react-gtm-module';
import themes from '../../../themes/themes.scss';
import { Rating } from 'react-simple-star-rating';
import ReviewPopup, { ReviewPopupProps } from '../../../popups/reviewPopup/ReviewPopup';
import serviceFactory from '../../../services/serviceFactory';
import parse from 'html-react-parser';
import useMerchantReward from '../../../customHooks/useMerchantReward';
import { get } from 'lodash';
import { getPageFinder, undefinedHandler } from '../../../utils/undefinedHandler';
import Button from '../../../lib/@bit/redsky.framework.rs.button';
import moment from 'moment';
import AccommodationService from '../../../services/accommodation/accommodation.service';
interface DestinationSearchResultCardMobileProps {
	className?: string;
	pointPerDollar: number;
	destinationObj: Api.Destination.Res.AvailabilitySummary;
	picturePaths: string[];
	onAddCompareClick?: () => void;
	onGalleryClick: () => void;
	onRemoveCompareClick?: () => void;
	locationInfo: string;
	FavoriteDestinationsIcon?: string;
	isAccommodations: number;
}

const DestinationSearchResultCardMobile: React.FC<DestinationSearchResultCardMobileProps> = (props) => {
	const user = useRecoilValue<Api.User.Res.Detail | undefined>(globalState.user);
	const userTierValue = useRecoilValue<number>(globalState.userTierValue);
	const userService = serviceFactory.get<UserService>('UserService');
	const [reservationFilters, setReservationFilters] = useRecoilState<Misc.ReservationFilters>(
		globalState.reservationFilters
	);
	const accommodationService = serviceFactory.get<AccommodationService>('AccommodationService');

	const googleProductViewMobileHandler = (productData: any) => {
		try {
			TagManager.dataLayer({
				dataLayer: {
					event: 'product_view',
					ecommerce: null
				}
			});

			TagManager.dataLayer({
				dataLayer: {
					event: 'product_view',
					ecommerce: {
						detail: {
							products: [
								{
									id: productData.externalId,
									name: productData.name
								}
							]
						}
					}
				}
			});
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	};

	const googleProductViewHandlerViewItemList = async (productData?: any) => {
		const searchQueryObj: Misc.ReservationFilters = {
			...reservationFilters,
			destinationId: props.destinationObj.id
		};
		let key: keyof Misc.ReservationFilters;
		for (key in searchQueryObj) {
			if (searchQueryObj[key] === undefined) delete searchQueryObj[key];
		}
		searchQueryObj.pagination = { page: 1, perPage: 1 };

		let result: Api.Accommodation.Res.MinMaxAvailability = await accommodationService.availability(
			props.destinationObj.id,
			searchQueryObj
		);

		const view_item_list = {
			item_name: get(result, 'data.0.name', null),
			item_id: get(result, 'data.0.externalSystemId', result?.data.id),
			price: NumberUtils.centsToDollars(get(result, 'data.0.prices.baseRates', null)),
			item_brand: props.destinationObj.name,
			item_category: get(result, 'data.0.contentLists.RoomCategoryList.0.Name', null),
			item_category2: get(result, 'data.0.contentLists.RoomCategoryList.0.Name', null),
			item_list_name: 'Rooms',
			arrival_date: searchQueryObj.startDate,
			depart_date: searchQueryObj.endDate
		};

		try {
			TagManager.dataLayer({
				dataLayer: {
					event: 'view_item_list',
					ecommerce: null
				}
			});

			TagManager.dataLayer({
				dataLayer: {
					event: 'view_item_list',
					ecommerce: {
						detail: {
							products: [view_item_list]
						}
					}
				}
			});
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	};

	function setDestinationIdAndRegionIdGlobal() {
		setRecoilExternalValue<Misc.ReservationFilters>(globalState.reservationFilters, {
			...reservationFilters,
			destinationId: props.destinationObj.id,
			flipToregionIds: props.destinationObj.regions
		});
	}

	const UserResortSelectedCampaign = () => {
		try {
			if (props.destinationObj.campaignDefinition !== null && props.destinationObj.campaignDefinition !== '') {
				if (user?.id !== undefined) {
					userService.logUserResortSelectedCampaign(user.id, props.destinationObj);
				} else {
					userService.logUserResortSelectedCampaign('UNKNOWN', props.destinationObj);
				}
			}
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	};

	function openDestinationDetails() {
		try {
			UserResortSelectedCampaign();
			setDestinationIdAndRegionIdGlobal();
			setReservationFilters((prev) => {
				const newFilter = { ...prev, destinationId: props.destinationObj.id };
				// WebUtils.updateUrlParams(newFilter);

				return newFilter;
			});

			if (user) {
				const sessionId = moment(new Date()).valueOf().toString();
				const data = {
					sessionId: sessionId,
					...reservationFilters,
					destinationId: props.destinationObj.id,
					destinationName: props.destinationObj.name
				};
				(async () => await userService.saveSearchHistory(data))();
			}

			googleProductViewMobileHandler(props.destinationObj);
			googleProductViewHandlerViewItemList(props.destinationObj);
			metaCapi.checkAvailability(props.destinationObj);
			popupController.open<DestinationDetailsMobilePopupProps>(DestinationDetailsMobilePopup, {
				destinationId: props.destinationObj.id,
				resortPoint: props.destinationObj.pointsEarnable,
				resortPointsPrice: props.destinationObj.minAccommodationPriceInPoints,
				startDate: reservationFilters.startDate as string,
				endDate: reservationFilters.endDate as string
			});
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	function renderPricePerNight() {
		if (reservationFilters.redeemPoints && props.destinationObj.loyaltyStatus === 'ACTIVE') {
			return !!props.destinationObj.minAccommodationPriceInPoints ? (
				<Box display={'flex'} mb={8} className={'pricePerNightOuterBox'} onClick={openDestinationDetails}>
					<Label variant={'body1B'} className={'secondaryTextColor'}>
						{StringUtils.addCommasToNumber(props.destinationObj.minAccommodationPriceInPoints)}
						pts
					</Label>
					<Label variant={'body1'}> / night</Label>
				</Box>
			) : null;
		} else {
			return !!props.destinationObj.minAccommodationPriceInCents ? (
				<Box display={'flex'} mb={8} className={'pricePerNightOuterBox'} onClick={openDestinationDetails}>
					<Box>
						{props.className === 'short' && <Label variant={'body1'}>from</Label>}
						<Label variant={'body1B'}>
							{StringUtils.formatCurrency(props.destinationObj.currencyCode)}
							{StringUtils.formatMoney(props.destinationObj.minAccommodationPriceInCents)}
						</Label>
						{props.className !== 'short' && <Label variant={'body1'}> / night +taxes and fees</Label>}
					</Box>
					{props.className === 'short' && (
						<Button
							children={<Label variant={'buttonMdLg'}>Book Now</Label>}
							look={'containedPrimary'}
							className={'yellow discoverSignupButton'}
						/>
					)}
				</Box>
			) : null;
		}
	}

	function renderButtons() {
		try {
			return props.destinationObj.propertyTypes.map((button) => {
				return (
					<LabelButton
						key={button.id}
						look={'containedPrimary'}
						variant={'buttonSm'}
						label={button.name}
						className={'accommodationButton'}
						onClick={(event) => {
							try {
								UserResortSelectedCampaign();
								// setDestinationIdAndRegionIdGlobal();
								googleProductViewMobileHandler(props.destinationObj);
								metaCapi.checkAvailability(props.destinationObj);
								popupController.open<AccommodationsPopupProps>(AccommodationsPopup, {
									pointPerDollar: props.pointPerDollar,
									propertyTypeName: button.name,
									destinationObj: props.destinationObj,
									destinationId: props.destinationObj.id,
									packageCount: 0,
									destinationName: props.destinationObj.name,
									propertyTypeId: button.id,
									loyaltyStatus: props.destinationObj.loyaltyStatus,
									upfrontCashRequired: !!props.destinationObj.upfrontCashRequired,
									isCustomResort: props.destinationObj.isCustomResort,
									phone: props.destinationObj.phone
								});
								event.stopPropagation();
							} catch (error: any) {
								const message = `Getting error :${error.message} on ${getPageFinder(
									window.location.pathname
								)} page.`;
								undefinedHandler(message);
							}
						}}
					/>
				);
			});
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	function reviewPopup() {
		return popupController.open<ReviewPopupProps>(ReviewPopup, {
			destinationId: props.destinationObj.id,
			googleTotalReview: props.destinationObj.googleTotalReview,
			googleAllOverReview: props.destinationObj.googleAllOverReview
		});
	}

	function renderCampaignMessage() {
		try {
			if (
				props.destinationObj?.campaigns === undefined ||
				props.destinationObj?.campaigns === null ||
				props.destinationObj.campaigns.length === 0
			) {
				return '';
			}
			let campaignsData = props.destinationObj?.campaigns.sort(function (a, b) {
				return new Date(b.createdOn).valueOf() - new Date(a.createdOn).valueOf();
			});
			let index = campaignsData.length - 1;
			return <>{parse(get(campaignsData, index + '.campaignMessage', ''))}</>;
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	function pointEarningCalculation(): string {
		try {
			let calc = 0;
			let pointEarnText;

			if (
				props.destinationObj?.campaigns === undefined ||
				props.destinationObj.campaigns === null ||
				props.destinationObj.campaigns.length === 0
			) {
				calc =
					Number(
						StringUtils.formatMoney(props.destinationObj?.minAccommodationPriceInCents).replace(/,/g, '')
					) *
						props?.pointPerDollar *
						1 +
					0;
				pointEarnText = 'You could earn from ' + StringUtils.addCommasToNumber(calc) + ' points for this stay';
				return pointEarnText;
			}

			var campaignsData = props.destinationObj.campaigns?.sort(function (a, b) {
				return new Date(b.createdOn).valueOf() - new Date(a.createdOn).valueOf();
			});

			if (campaignsData[0]?.pointMultiplier === 0) {
				return '';
			}

			const currentDate = new Date();
			const startDate = new Date(campaignsData[0]?.startOn);
			const endDate = new Date(campaignsData[0]?.endOn);

			const daysOfTheWeek = campaignsData[0]?.activeOnDays;

			if ((daysOfTheWeek === undefined || daysOfTheWeek === '') && campaignsData[0]?.alwaysActive !== 1) {
				calc =
					Number(
						StringUtils.formatMoney(props.destinationObj?.minAccommodationPriceInCents).replace(/,/g, '')
					) *
						props?.pointPerDollar *
						1 +
					0;
				pointEarnText = 'You could earn from ' + StringUtils.addCommasToNumber(calc) + ' points for this stay';
				return pointEarnText;
			}

			const arrDaysOfTheWeek = daysOfTheWeek.split(',');
			let getTodayDates = new Date().getDay();
			let count = 0;

			if (getTodayDates === 0) {
				getTodayDates = 7;
			}

			arrDaysOfTheWeek.forEach((item) => {
				if (getTodayDates === Number(item)) {
					count++;
				}
			});

			if (count !== 1) {
				calc =
					Number(
						StringUtils.formatMoney(props.destinationObj?.minAccommodationPriceInCents).replace(/,/g, '')
					) *
						props?.pointPerDollar *
						1 +
					0;

				pointEarnText = 'You could earn from ' + StringUtils.addCommasToNumber(calc) + ' points for this stay';
				return pointEarnText;
			}

			if (campaignsData.length === 0) {
				calc =
					Number(
						StringUtils.formatMoney(props.destinationObj?.minAccommodationPriceInCents).replace(/,/g, '')
					) *
						props?.pointPerDollar *
						1 +
					0;
			} else {
				if (campaignsData[0]?.alwaysActive === 1) {
					calc =
						Number(
							StringUtils.formatMoney(props.destinationObj?.minAccommodationPriceInCents).replace(
								/,/g,
								''
							)
						) *
							props?.pointPerDollar *
							campaignsData[0]?.pointMultiplier +
						campaignsData[0]?.bulkPoint;
				} else {
					// Check if the current date is between the start and end dates
					if (currentDate >= startDate && currentDate <= endDate) {
						calc =
							Number(
								StringUtils.formatMoney(props.destinationObj?.minAccommodationPriceInCents).replace(
									/,/g,
									''
								)
							) *
								props?.pointPerDollar *
								campaignsData[0]?.pointMultiplier +
							campaignsData[0]?.bulkPoint;
					} else {
						calc =
							Number(
								StringUtils.formatMoney(props.destinationObj?.minAccommodationPriceInCents).replace(
									/,/g,
									''
								)
							) *
								props?.pointPerDollar *
								1 +
							0;
					}
				}
			}

			pointEarnText = 'You could earn from ' + StringUtils.addCommasToNumber(calc) + ' points for this stay';
			return pointEarnText;
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
			return '';
		}
	}

	return (
		<Box className={`rsDestinationSearchResultCardMobile ${props.className || ''}`}>
			<CarouselV2
				path={openDestinationDetails}
				isAccommodations={props.isAccommodations}
				resortPoint={props.destinationObj.pointsEarnable}
				imgPaths={props.picturePaths}
				FavoriteDestinationsIcon={props.FavoriteDestinationsIcon}
				onAddCompareClick={() => {
					if (props.onAddCompareClick) props.onAddCompareClick();
				}}
				onGalleryClick={props.onGalleryClick}
				onRemoveCompareClick={() => {
					if (props.onRemoveCompareClick) props.onRemoveCompareClick();
				}}
				destinationObj={props.destinationObj}
				destinationId={props.destinationObj.id}
				imageWidth={327}
			/>
			<Box className={'mobileCardInfo'}>
				<Box display={'flex'} justifyContent={'space-between'} margin={'16px 0 10px'}>
					<Label variant={'h6'}>{get(props, 'destinationObj.name')}</Label>
					<Icon iconImg={'icon-info-outline'} onClick={openDestinationDetails} size={20} />
				</Box>
				{!!!props.destinationObj.minAccommodationPriceInCents && (
					<Box display={'flex'} justifyContent={'space-between'} margin={'10px 0 10px'}>
						<Label variant={'caption'} color={themes.errorColor}>
							{'Unavailable for the dates selected.'}
						</Label>
					</Box>
				)}
				<Box className="destination-description">
					<Box display={'flex'} marginBottom={'10px'} alignItems={'center'}>
						<Label variant={'caption'} paddingRight={'74px'}>
							{props.destinationObj.minBedroom === props.destinationObj.maxBedroom
								? props.destinationObj.minBedroom
								: `${props.destinationObj.minBedroom} - ${props.destinationObj.maxBedroom}`}{' '}
							Bedrooms
						</Label>
					</Box>
					<Box display={'flex'} marginBottom={'10px'} alignItems={'center'}>
						<Label variant={'caption'} className={'addressLabel'}>
							<Icon iconImg="icon-pin" size={10} color="#FF6469" className="locationIcon" />
							{get(props, 'locationInfo')}
						</Label>
					</Box>
				</Box>
				{/* TEMPORARY CODE IS COMMENT FOR GOOGLE REVIEWS  */}
				{/* {!!props.destinationObj.googleAllOverReview &&
					!!props.destinationObj.googleTotalReview &&
					props.className !== 'short' && (
						<Box display={'flex'} marginBottom={'10px'} alignItems={'center'} onClick={() => reviewPopup()}>
							<Label variant={'caption'}>{get(props, 'destinationObj.googleAllOverReview')}</Label>
							<Rating
								initialValue={props.destinationObj.googleAllOverReview}
								readonly={true}
								allowFraction={true}
								SVGstyle={{ width: 15, height: 15 }}
								style={{ margin: '0 5px 0 3px' }}
							/>
							<Label color={themes.primaryTextColor} variant={'caption'} className="googleReviewFormate">
								{get(props, 'destinationObj.googleTotalReview') + ' Google reviews'}
							</Label>
						</Box>
					)} */}
				{renderPricePerNight()}
				{!reservationFilters.redeemPoints &&
					props.destinationObj.loyaltyStatus === 'ACTIVE' &&
					props.className !== 'short' && (
						<Label className={'secondaryTextColor'} variant={'captionBI'}>
							{/* {useMerchantReward.pointEarningCalculation(
								{
									campaigns: props.destinationObj.campaigns,
									minAccommodationPriceInCents: props.destinationObj.baseRates
								},
								props.pointPerDollar
							)} */}
							{useMerchantReward.rewardPointsCalculation(
								{
									campaigns: props.destinationObj.campaigns,
									minAccommodationPriceInCents: props.destinationObj.baseRates
								},
								props.pointPerDollar,
								userTierValue,
								user
							)}
						</Label>
					)}
				{!reservationFilters.redeemPoints &&
					props.destinationObj.loyaltyStatus === 'ACTIVE' &&
					props.className !== 'short' && <div className={'campaignMessage'}>{renderCampaignMessage()}</div>}
			</Box>
			{props.className !== 'short' && (
				<div>
					<Label variant={'subtitle1'} mb={4} mt={8}>
						{ObjectUtils.isArrayWithData(props.destinationObj.propertyTypes) && 'Accommodation Types'}
					</Label>
					<Box className={'buttonContainer'}>{renderButtons()}</Box>
				</div>
			)}
		</Box>
	);
};

export default DestinationSearchResultCardMobile;
