import React, { useLayoutEffect } from 'react';
import './SubNavMenu.scss';
import { useEffect, useState } from 'react';
import useWindowScrollChange from '../../customHooks/useWindowScrollChange';
import Button from '../../lib/@bit/redsky.framework.rs.button';
import Icon from '../../lib/@bit/redsky.framework.rs.icon';
import { useLocation, useNavigate } from 'react-router-dom';
import Label from '../../lib/@bit/redsky.framework.rs.label/dist/Label';
import useIsAtBreakpoint from '../../customHooks/useIsAtBreakpoint';
import { useRecoilState, useRecoilValue } from 'recoil';
import globalState from '../../state/globalState';
import LabelButton from '../labelButton/LabelButton';
import { Box } from '../../lib/@bit/redsky.framework.rs.996';
import { StringUtils, WebUtils } from '../../utils/utils';
import classNames from 'classnames';
import FilterBarLimited from '../filterBar/FilterBarLimited';
import router from '../../utils/router';
import DestinationService from '../../services/destination/destination.service';
import serviceFactory from '../../services/serviceFactory';
import { rsToastify } from '../../lib/@bit/redsky.framework.rs.toastify';
import { getPageFinder, undefinedHandler } from '../../utils/undefinedHandler';

type PageRefs = {
	overviewRef: React.RefObject<HTMLElement>;
	experiencesRef: React.RefObject<HTMLElement>;
	availableStaysRef: React.RefObject<HTMLElement>;
};

interface SubNavMenuProps {
	title?: string;
	minStay?: number;
	pageRefs?: PageRefs;
	activeViewSection?: 'gallery' | 'overview' | 'experiences' | 'availableStays';
	navigateTo?: 'RESERVATIONS' | 'HOME';
	destinationDetails?: Api.Destination.Res.Details;
}

const SubNavMenu: React.FC<SubNavMenuProps> = (props) => {
	let navigate = useNavigate();
	const scrollDirection = useWindowScrollChange();
	const isMobile = useIsAtBreakpoint();
	const [selected, setSelected] = useState<'gallery' | 'overview' | 'experiences' | 'availableStays'>('gallery');
	const subNavBarHeight = parseInt(StringUtils.removeAllExceptNumbers(getAppBarHeight()));
	const destinationService = serviceFactory.get<DestinationService>('DestinationService');
	const [loyaltyStatus, setLoyaltyStatus] = useState<Model.LoyaltyStatus>('PENDING');
	const [destinationDetails, setDestinationDetails] = useState<Api.Destination.Res.Details>();
	const [isOtherFilterPopupOpen, setIsOtherFilterPopupOpen] = useState<boolean>(false);

	const [reservationFilters, setReservationFilters] = useRecoilState<Misc.ReservationFilters>(
		globalState.reservationFilters
	);

	const tempdata: any = useLocation();
	const params = router.getPageUrlParams<{
		destinationId: number;
		startDate?: string | any;
		endDate?: string | any;
		guests: number;
	}>([
		//Note: don't change the key 'di', because we have a data contract with a 3rd party if the key 'di' is changed it is going to break their architecture.
		{ key: 'di', default: 0, type: 'integer', alias: 'destinationId' },
		{
			key: 'startDate',
			default: reservationFilters.startDate.toString() || '',
			type: 'string',
			alias: 'startDate'
		},
		{ key: 'endDate', default: reservationFilters.endDate.toString() || '', type: 'string', alias: 'endDate' },
		{ key: 'guests', default: reservationFilters.adultCount || 1, type: 'integer', alias: 'guests' }
	]);
	useEffect(() => {
		if (props.activeViewSection) setSelected(props.activeViewSection);
	}, [props.activeViewSection]);

	useLayoutEffect(() => {
		async function getDestinationDetails(id: number, data?: any) {
			// if (isMobile) return;

			if (
				!!props.destinationDetails &&
				!props.destinationDetails?.id &&
				!props.destinationDetails?.isCustomResort
			) {
				try {
					const dest = await destinationService.getDestinationDetails({
						destinationId: id,
						startDate: data && data.startDate ? data.startDate : reservationFilters.startDate,
						endDate: data && data.endDate ? data.endDate : reservationFilters.endDate
					});

					setDestinationDetails(dest);

					/* Flip.to Integration. Don't delete the code */
					// useFlipTo.setFlipInit(dest);

					// setRecoilExternalValue<Misc.ReservationFilters>(globalState.reservationFilters, {
					// 	...reservationFilters,
					// 	destinationId: dest.id,
					// 	flipToregionIds: dest.regions
					// });

					// const request: Api.UpsellPackage.Req.Availability = {
					// 	destinationId: params.destinationId,
					// 	startDate: params.startDate,
					// 	endDate: params.endDate
					// };
					// const responsePackages = await packageService.getAvailable(request);
					// setResortPackagesCount(responsePackages.total);
					// setCenter({
					// 	lat: dest.latitude as number,
					// 	lng: dest.longitude as number
					// });
					// setLoyaltyStatus(dest.loyaltyStatus);
				} catch (e: any) {
					// rsToastify.error(
					// 	WebUtils.getRsErrorMessage(e, 'Cannot get details for this destination.'),
					// 	'Server Error '
					// 	// {
					// 	// 	onClose: () => {
					// 	// 		navigate(-1);
					// 	// 	}
					// 	// }
					// );
					const message = `Getting error :${e.message} on ${getPageFinder(window.location.pathname)} page.`;
					undefinedHandler(message);
				}
			} else {
				setDestinationDetails(props.destinationDetails);
			}
		}

		// if (tempdata.destinationId) {
		// 	getDestinationDetails(tempdata.destinationId).catch(console.error);
		// }
		async function getDestinationDetailsUrl(name: string) {
			try {
				const results: Api.Destination.Res.PublicSlim[] = await destinationService.getPublicSlim();
				const conditioncheck: any = [];

				const data: any = results.map((el) => {
					if (el?.name?.toLocaleLowerCase() == name) {
						getDestinationDetails(el.id).catch(console.error);
						conditioncheck.push(el);
					}
				});

				if (conditioncheck && conditioncheck?.length === 0) {
					const e = '';
					rsToastify.error(
						WebUtils.getRsErrorMessage(e, 'Cannot get details for this destination.'),
						'Server Error ',
						{
							onClose: () => {
								navigate('/');
							}
						}
					);
				}
			} catch (error: any) {
				const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
				undefinedHandler(message);
			}
		}

		try {
			if (params && params?.destinationId) {
				getDestinationDetails(params.destinationId, params).catch(console.error);
			} else {
				if (tempdata.pathname.includes('/destination/')) {
					if (tempdata && !tempdata.state) {
						getDestinationDetailsUrl(tempdata.pathname.split('/')[2].split('-').join(' '));
					} else {
						getDestinationDetails(tempdata.state.destinationId).catch(console.error);
					}
				}
			}
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}, [tempdata]);

	function getAppBarHeight() {
		let appBar = document.querySelector('.rsAppBar');
		if (!appBar) return '0px';
		if (appBar.scrollHeight > 99) {
			return '99px';
		} else return appBar.scrollHeight + 'px';
	}
	function navigator() {
		return navigate(-1);
		// if (!props.navigateTo) {
		// 	return navigate(-1);
		// }
		// const paths = { HOME: '/', RESERVATIONS: '/reservations' };
		// navigate(paths[props.navigateTo]);
	}

	return (
		<div
			className={classNames('rsSubNavMenu', { moveUp: scrollDirection === 'DOWN' })}
			style={{ top: getAppBarHeight() }}
		>
			<Button className={'backButton'} look={'none'} onClick={navigator}>
				<Icon iconImg={'icon-chevron-left'} size={isMobile ? 13 : 20} />
			</Button>

			{!props.pageRefs ? (
				<Label ml={isMobile ? 20 : 33} variant={'buttonMdLg'}>
					{props.title}
				</Label>
			) : (
				<Box className={'destinationPageRefsWrapper'}>
					<FilterBarLimited
						destinationId={destinationDetails?.id}
						loyaltyStatus={destinationDetails?.loyaltyStatus || 'visible'}
						externalId={destinationDetails?.externalId || ''}
						chainId={destinationDetails?.chainId || 0}
						minStay={props.minStay}
						isMobile={isMobile}
						setIsOtherFilterPopupOpen={setIsOtherFilterPopupOpen}
						isCustomResort={destinationDetails?.isCustomResort}
					/>
					{/* <LabelButton
						className={classNames('refButton', { selected: selected === 'gallery' })}
						look={'none'}
						variant={'h5'}
						label={'Gallery'}
						onClick={() => {
							window.scrollTo({ top: 0, behavior: 'smooth' });
							setSelected('gallery');
						}}
					/>
					<LabelButton
						className={classNames('refButton', { selected: selected === 'overview' })}
						look={'none'}
						variant={'h5'}
						label={'Overview'}
						onClick={() => {
							if (!props.pageRefs) return;
							let ref = props.pageRefs.overviewRef.current;
							if (!ref) return;
							window.scrollTo({ top: ref.offsetTop - subNavBarHeight, behavior: 'smooth' });
							setSelected('overview');
						}}
					/>
					<LabelButton
						className={classNames('refButton', { selected: selected === 'experiences' })}
						look={'none'}
						variant={'h5'}
						label={'Experiences'}
						onClick={() => {
							if (!props.pageRefs) return;
							let ref = props.pageRefs.experiencesRef.current;
							if (!ref) return;
							window.scrollTo({ top: ref.offsetTop - subNavBarHeight, behavior: 'smooth' });
							setSelected('experiences');
						}}
					/>
					<LabelButton
						className={classNames('refButton', { selected: selected === 'availableStays' })}
						look={'none'}
						variant={'h5'}
						label={'Available Stays'}
						onClick={() => {
							if (!props.pageRefs) return;
							let ref = props.pageRefs.availableStaysRef.current;
							if (!ref) return;
							window.scrollTo({ top: ref.offsetTop - subNavBarHeight, behavior: 'smooth' });
							setSelected('availableStays');
						}}
					/>
					<LabelButton
						look={'containedPrimary'}
						variant={'buttonMdLg'}
						label={'Reserve Stay'}
						onClick={() => {
							if (!props.pageRefs) return;
							let ref = props.pageRefs.availableStaysRef.current;
							if (!ref) return;
							window.scrollTo({ top: ref.offsetTop - subNavBarHeight, behavior: 'smooth' });
						}}
					/> */}
				</Box>
			)}
		</div>
	);
};

export default SubNavMenu;
