Object.defineProperty(exports, '__esModule', { value: true });
var DomUtils = (function () {
	function DomUtils() {
		this.validElement = function (element) {
			if (!element) return false;
			if (!element.classList) return false;
			return true;
		};
		this.parent = function (element) {
			if (!element) return null;
			return element.parentElement;
		};
	}
	DomUtils.prototype.addClass = function (element, className) {
		if (!this.validElement(element)) return false;
		element === null || element === void 0 ? void 0 : element.classList.add(className);
	};
	DomUtils.prototype.removeClass = function (element, className) {
		if (!this.validElement(element)) return false;
		element === null || element === void 0 ? void 0 : element.classList.remove(className);
	};
	DomUtils.prototype.removeClasses = function (element, classes) {
		if (!this.validElement(element)) return false;
		for (var i in classes) {
			element === null || element === void 0 ? void 0 : element.classList.remove(classes[i]);
		}
	};
	DomUtils.prototype.hasClass = function (element, className) {
		if (!this.validElement(element)) return false;
		return element === null || element === void 0 ? void 0 : element.classList.contains(className);
	};
	DomUtils.prototype.childrenWithClass = function (element, className) {
		if (!element || !this.validElement(element)) return [];
		var children = [];
		for (var i in element.children) {
			if (this.hasClass(element === null || element === void 0 ? void 0 : element.children[i], className))
				children.push(element === null || element === void 0 ? void 0 : element.children[i]);
		}
		return children;
	};
	DomUtils.prototype.firstChildWithClass = function (element, className) {
		if (!element) return null;
		for (var i in element.children) {
			if (this.hasClass(element.children[i], className)) return element.children[i];
		}
		return null;
	};
	DomUtils.prototype.lastChildWithClass = function (element, className) {
		if (!this.validElement(element)) return null;
		var last = null;
		for (var i in element === null || element === void 0 ? void 0 : element.children) {
			if (this.hasClass(element === null || element === void 0 ? void 0 : element.children[i], className))
				last = element === null || element === void 0 ? void 0 : element.children[i];
		}
		return last;
	};
	return DomUtils;
})();
var domUtils = new DomUtils();
exports.default = domUtils;
window.domUtils = domUtils;
