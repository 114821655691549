Object.defineProperty(exports, '__esModule', { value: true });
exports.spacingKeys = exports.getSpacingProperties = void 0;
var properties = {
	m: 'margin',
	p: 'padding'
};
var directions = {
	t: 'Top',
	r: 'Right',
	b: 'Bottom',
	l: 'Left',
	x: ['Left', 'Right'],
	y: ['Top', 'Bottom']
};
var aliases = {
	marginX: 'mx',
	marginY: 'my',
	paddingX: 'px',
	paddingY: 'py'
};
var getSpacingProperties = function (prop) {
	if (prop.length > 2) {
		if (aliases[prop]) {
			prop = aliases[prop];
		} else {
			return [prop];
		}
	}
	var _a = prop.split(''),
		a = _a[0],
		b = _a[1];
	var property = properties[a];
	var direction = directions[b] || '';
	return Array.isArray(direction)
		? direction.map(function (dir) {
				return property + dir;
		  })
		: [property + direction];
};
exports.getSpacingProperties = getSpacingProperties;
exports.spacingKeys = [
	'm',
	'mt',
	'mr',
	'mb',
	'ml',
	'mx',
	'my',
	'p',
	'pt',
	'pr',
	'pb',
	'pl',
	'px',
	'py',
	'margin',
	'marginTop',
	'marginRight',
	'marginBottom',
	'marginLeft',
	'marginX',
	'marginY',
	'padding',
	'paddingTop',
	'paddingRight',
	'paddingBottom',
	'paddingLeft',
	'paddingX',
	'paddingY'
];
