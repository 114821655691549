import * as React from 'react';
import './UpdatePaymentMethodPopup.scss';
import { Box, Popup, popupController, PopupProps } from '../../lib/@bit/redsky.framework.rs.996';
import Paper from '../../components/paper/Paper';
import Icon from '../../lib/@bit/redsky.framework.rs.icon';
import Label from '../../lib/@bit/redsky.framework.rs.label';
import CardInfoCard from '../../components/cardInfoCard/CardInfoCard';
import { useEffect, useState } from 'react';
import { RsFormControl, RsFormGroup, RsValidator, RsValidatorEnum } from '../../lib/@bit/redsky.framework.rs.form';
import LabelButton from '../../components/labelButton/LabelButton';
import serviceFactory from '../../services/serviceFactory';
import PaymentService from '../../services/payment/payment.service';
import { rsToastify } from '../../lib/@bit/redsky.framework.rs.toastify';
import router from '../../utils/router';

export interface UpdatePaymentMethodPopupProps extends PopupProps {
	onUpdate: (data: any) => void;
}

enum FormKeys {
	NAME_ON_CARD = 'nameOnCard',
	EXPIRATION = 'expiration'
}

const UpdatePaymentMethodPopup: React.FC<UpdatePaymentMethodPopupProps> = (props) => {
	const paymentService = serviceFactory.get<PaymentService>('PaymentService');
	const fullProps = popupController.convertProps(props);
	const firstLastNamePattern = new RegExp(/[a-zA-Z]+\s[a-zA-Z]+/);
	const [validCvv, setValidCvv] = useState<boolean>(false);
	const [validNumber, setValidNumber] = useState<boolean>(false);
	const [formGroup, setFormGroup] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl(FormKeys.NAME_ON_CARD, '', [
				new RsValidator(RsValidatorEnum.REQ, 'Required'),
				new RsValidator(
					RsValidatorEnum.CUSTOM,
					'Card Name Requires First and Last Name',
					(control: RsFormControl) => {
						return firstLastNamePattern.test(control.value.toString());
					}
				)
			]),
			new RsFormControl(FormKeys.EXPIRATION, '', [
				new RsValidator(RsValidatorEnum.REQ, 'Required'),
				new RsValidator(RsValidatorEnum.MIN, 'Expiration too short', 7),
				new RsValidator(RsValidatorEnum.MAX, 'Expiration too long', 7),
				new RsValidator(RsValidatorEnum.CUSTOM, 'Invalid Expiration Date', (control) => {
					let month = parseInt(control.value.toString().slice(0, 2));
					let year = parseInt(control.value.toString().slice(3, 7));
					let currentYear = new Date().getFullYear();
					let currentMonth = new Date().getMonth() + 1;
					if (month > 12) return false;
					if (year === currentYear) return month >= currentMonth;
					else return year > currentYear;
				})
			])
		])
	);

	useEffect(() => {
		const id = router.subscribeToBeforeRouterNavigate(async () => {
			popupController.closeById(fullProps.popupId);
			router.getCurrentPath();
			return true;
		});
		return () => {
			router.unsubscribeFromBeforeRouterNavigate(id);
		};
	}, []);

	// useEffect(() => {
	// 	let fieldEventId = paymentService.subscribeToSpreedlyFieldEvent(
	// 		(
	// 			name: 'number' | 'cvv',
	// 			type: 'focus' | 'blur' | 'mouseover' | 'mouseout' | 'input' | 'enter' | 'escape' | 'tab' | 'shiftTab',
	// 			activeEl: 'number' | 'cvv',
	// 			inputProperties: {
	// 				cardType?: string;
	// 				validNumber?: boolean;
	// 				validCvv?: boolean;
	// 				numberLength?: number;
	// 				cvvLength?: number;
	// 			}
	// 		) => {
	// 			if (name === 'number') {
	// 				if (type === 'input' && !inputProperties.validNumber) {
	// 					setValidNumber(false);
	// 				} else if (type === 'input' && inputProperties.validNumber) {
	// 					setValidNumber(true);
	// 				}
	// 			}
	// 			if (name === 'cvv') {
	// 				if (type === 'input' && !inputProperties.validCvv) {
	// 					setValidCvv(false);
	// 				} else if (type === 'input' && inputProperties.validCvv) {
	// 					setValidCvv(true);
	// 				}
	// 			}
	// 		}
	// 	);

	// 	return () => {
	// 		paymentService.unsubscribeToSpreedlyFieldEvent(fieldEventId);
	// 	};
	// }, []);

	async function handleUpdate() {
		if (!(await formGroup.isValid()) || !validCvv || !validNumber) {
			setFormGroup(formGroup.clone());
			rsToastify.error('Fix Invalid Fields', 'Error');
			return;
		}

		let data = formGroup.toModel<{
			nameOnCard: string;
			expiration: string;
		}>();
		let paymentObj = {
			full_name: data.nameOnCard,
			month: Number(data.expiration.split('/')[0]),
			year: Number(data.expiration.split('/')[1])
		};
		// window.Spreedly.tokenizeCreditCard(paymentObj);
	}

	return (
		<Popup {...props} preventCloseByBackgroundClick>
			<Paper className={'rsUpdatePaymentMethodPopup'} borderRadius={'20px'}>
				<Icon
					iconImg={'icon-close'}
					onClick={() => {
						popupController.close(UpdatePaymentMethodPopup);
					}}
					cursorPointer
				/>
				<Label variant={'h5'} className={'primaryTextColor'} m={'32px 24px 24px'}>
					Edit Payment
				</Label>
				<Box className={'cardBodyContainer'}>
					<CardInfoCard
						form={formGroup}
						onUpdate={(control) => {
							setFormGroup(formGroup.clone().update(control));
						}}
						onFormError={(isError) => null}
					/>
					<LabelButton
						label={'UPDATE'}
						look={'containedSecondary'}
						variant={'ButtonMdLg'}
						onClick={handleUpdate}
					/>
				</Box>
			</Paper>
		</Popup>
	);
};

export default UpdatePaymentMethodPopup;
