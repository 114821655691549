import React from 'react';
import './AccountPaymentMethodsPage.scss';
import useIsAtBreakpoint from '../../customHooks/useIsAtBreakpoint';
import { useEffect, useRef, useState } from 'react';
import serviceFactory from '../../services/serviceFactory';
import PaymentService from '../../services/payment/payment.service';
import { useRecoilValue } from 'recoil';
import globalState from '../../state/globalState';
import { RsFormControl, RsFormGroup, RsValidator, RsValidatorEnum } from '../../lib/@bit/redsky.framework.rs.form';
import { Link, Page, popupController } from '../../lib/@bit/redsky.framework.rs.996';
import { rsToastify } from '../../lib/@bit/redsky.framework.rs.toastify';
import SpinningLoaderPopup from '../../popups/spinningLoaderPopup/SpinningLoaderPopup';
import { ObjectUtils, StringUtils } from '../../utils/utils';
import OtherPaymentCard from '../../components/otherPaymentsCard/OtherPaymentCard';
import LoadingPage from '../loadingPage/LoadingPage';
import SubNavMenu from '../../components/subNavMenu/SubNavMenu';
import Paper from '../../components/paper/Paper';
import Label from '../../lib/@bit/redsky.framework.rs.label';
import Box from '../../lib/@bit/redsky.framework.rs.996/dist/box/Box';
import LabelInput from '../../components/labelInput/LabelInput';
import Icon from '../../lib/@bit/redsky.framework.rs.icon';
import LabelCheckbox from '../../components/labelCheckbox/LabelCheckbox';
import LabelButton from '../../components/labelButton/LabelButton';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash.debounce';
import { getPageFinder, undefinedHandler } from '../../utils/undefinedHandler';
import { get } from 'lodash';

var isCvvLength = 0;
const AccountPaymentMethodsPage: React.FC = () => {
	let navigate = useNavigate();
	const isMobile = useIsAtBreakpoint();
	const numberRef = useRef<HTMLElement>(null);
	const cvvRef = useRef<HTMLElement>(null);
	const [editPaymentMethod, setEditPaymentMethod] = useState<any>(null);
	const paymentService = serviceFactory.get<PaymentService>('PaymentService');
	const user = useRecoilValue<Api.User.Res.Detail | undefined>(globalState.user);
	const [isValidCard, setIsValidCard] = useState<boolean>(false);
	const [isValidCvv, setIsValidCvv] = useState<boolean>(false);
	const [isValidForm, setIsValidForm] = useState<boolean>(false);
	const [isFormComplete, setIsFormComplete] = useState<boolean>(false);
	const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
	const [expDate, setExpDate] = useState<string>('');
	const [isPrimary, setIsPrimary] = useState<0 | 1>(0);
	const [existingCardList, setExistingCardList] = useState<Api.User.PaymentMethod[]>([]);
	const [creditCardObj, setCreditCardObj] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl('full_name', '', [new RsValidator(RsValidatorEnum.REQ, 'Full name is required')]),
			new RsFormControl('expDate', '', [
				new RsValidator(RsValidatorEnum.REQ, 'Expiration required'),
				new RsValidator(RsValidatorEnum.MIN, 'Expiration too short', 7),
				new RsValidator(RsValidatorEnum.MAX, 'Expiration too long', 7),
				new RsValidator(RsValidatorEnum.CUSTOM, 'Invalid Expiration Date', (control) => {
					let month = parseInt(control.value.toString().slice(0, 2));
					let year = parseInt(control.value.toString().slice(3, 7));
					let currentYear = new Date().getFullYear();
					let currentMonth = new Date().getMonth() + 1;
					if (month > 12) return false;
					if (year === currentYear) return month >= currentMonth;
					else return year > currentYear;
				})
			])
		])
	);

	useEffect(() => {
		setIsFormComplete(isValidCvv && isValidCard);
	}, [isValidForm, isValidCard, isValidCvv]);

	useEffect(() => {
		window.scrollTo(0, 0);
		async function init() {
			const gatewayDetails: Api.Payment.Res.PublicData = await paymentService.getGateway();
			window.Spreedly.init(gatewayDetails.publicData.token, {
				numberEl: 'spreedly-number',
				cvvEl: 'spreedly-cvv'
			});
		}
		init().catch(console.error);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	let debounceCvvCardError = debounce(async (element: 'Number' | 'Cvv') => {
		let htmlBlock: HTMLElement | null = document.querySelector(`#${element}`);
		if (!!htmlBlock) htmlBlock.style.color = 'red';
	}, 100);
	let debounceCvvCardSuccess = debounce(async (element: 'Number' | 'Cvv') => {
		let htmlBlock: HTMLElement | null = document.querySelector(`#${element}`);
		if (!!htmlBlock) htmlBlock.style.color = '#001933';
	}, 100);

	useEffect(() => {
		setIsPrimary(ObjectUtils.isArrayWithData(existingCardList) ? 0 : 1);
	}, [existingCardList]);

	useEffect(() => {
		let readyId = paymentService.subscribeToSpreedlyReady(() => {
			if (isMobile) {
				window.Spreedly.setStyle(
					'number',
					'width:100%;font-size: 16px;height: 40px;padding: 0 10px;box-sizing: border-box;border-radius: 0;border: 1px solid #dedede; color: #001933; background-color: #ffffff; transition: border-color 300ms;border-radius: 5px;'
				);
				window.Spreedly.setStyle(
					'cvv',
					'width:100%;font-size: 16px;height: 40px;padding: 0 10px;box-sizing: border-box;border-radius: 0;border: 1px solid #dedede; color: #001933; background-color: #ffffff; text-align: center; transition: border-color 300ms;border-radius: 5px; '
				);
			} else {
				window.Spreedly.setStyle(
					'number',
					'width:100%;font-size: 16px;height: 40px;padding: 0 10px;box-sizing: border-box;border-radius: 0;border: 1px solid #dedede; color: #001933; background-color: #ffffff; transition: border-color 300ms;border-radius: 5px; '
				);
				window.Spreedly.setStyle(
					'cvv',
					'width:100%;font-size: 16px;height: 40px;padding: 0 10px;box-sizing: border-box;border-radius: 0;border: 1px solid #dedede; color: #001933; background-color: #ffffff; text-align: center; transition: border-color 300ms;border-radius: 5px; '
				);
			}
			window.Spreedly.setFieldType('number', 'text');
			window.Spreedly.setNumberFormat('prettyFormat');
		});

		let fieldEventId = paymentService.subscribeToSpreedlyFieldEvent(
			(
				name: 'number' | 'cvv',
				type: 'focus' | 'blur' | 'mouseover' | 'mouseout' | 'input' | 'enter' | 'escape' | 'tab' | 'shiftTab',
				activeEl: 'number' | 'cvv',
				inputProperties: {
					cardType?: string;
					validNumber?: boolean;
					validCvv?: boolean;
					numberLength?: number;
					cvvLength?: number;
				}
			) => {
				if (name === 'number') {
					if (type === 'focus') {
						window.Spreedly.setStyle('number', 'border: 1px solid #004b98;');
					}
					if (type === 'blur') {
						window.Spreedly.setStyle('number', 'border: 1px solid #dedede;');
					}
					if (type === 'mouseover') {
						window.Spreedly.setStyle('number', 'border: 1px solid #004b98;');
					}
					if (type === 'mouseout') {
						window.Spreedly.setStyle('number', 'border: 1px solid #dedede;');
					}

					if (type === 'input' && !inputProperties.validNumber) {
						debounceCvvCardError('Number');
						setIsValidCard(false);
					} else if (type === 'input' && inputProperties.validNumber) {
						debounceCvvCardSuccess('Number');
						setIsValidCard(true);
					}
				}
				if (name === 'cvv') {
					if (inputProperties.cvvLength) isCvvLength = inputProperties.cvvLength;
					if (type === 'focus') {
						window.Spreedly.setStyle('cvv', 'border: 1px solid #004b98;');
					}
					if (type === 'blur') {
						window.Spreedly.setStyle('cvv', 'border: 1px solid #dedede;');
					}
					if (type === 'mouseover') {
						window.Spreedly.setStyle('cvv', 'border: 1px solid #004b98;');
					}
					if (type === 'mouseout') {
						window.Spreedly.setStyle('cvv', 'border: 1px solid #dedede;');
					}
					if (type === 'input' && isCvvLength !== 3 && isCvvLength !== 4) {
						debounceCvvCardError('Cvv');
						setIsValidCvv(false);
					} else if (type === 'input' && (isCvvLength === 3 || isCvvLength === 4)) {
						debounceCvvCardSuccess('Cvv');
						setIsValidCvv(true);
					}
				}
			}
		);

		// Error response codes
		// https://docs.spreedly.com/reference/api/v1/#response-codes
		let errorId = paymentService.subscribeToSpreedlyError((errorMsg) => {
			let errorMessages = errorMsg.map((item) => {
				return item.message;
			});
			popupController.closeAll();
			return rsToastify.error(errorMessages.join(' '), 'Error!');
		});

		let paymentMethodId = paymentService.subscribeToSpreedlyPaymentMethod(
			async (token: string, pmData: Api.Payment.PmData) => {
				if (!pmData?.verification_value) {
					debounceCvvCardError('Cvv');
					return;
				}
				let data: Api.Payment.Req.Create = {
					cardToken: token,
					pmData: pmData,
					isPrimary: isPrimary,
					offsiteLoyaltyEnrollment: isAuthorized ? 1 : 0
				};

				try {
					const result = await paymentService.addPaymentMethod(data);
					if (result) rsToastify.success('Card successfully added.', 'Card Added!');
					setIsAuthorized(false);
					window.Spreedly.reload();
					creditCardObj.resetToInitialValue();
					let newExistingCardList = [
						...existingCardList,
						{
							id: result.id,
							userAddressId: result.userAddressId,
							nameOnCard: result.nameOnCard,
							type: result.type,
							last4: result.last4,
							expirationMonth: result.expirationMonth,
							expirationYear: result.expirationYear,
							cardNumber: result.cardNumber,
							isPrimary: result.isPrimary,
							createdOn: result.createdOn,
							systemProvider: result.systemProvider
						}
					];
					if (result.isPrimary) {
						newExistingCardList = newExistingCardList.map((item) => {
							return { ...item, isPrimary: item.id === result.id ? 1 : 0 };
						});
					}
					newExistingCardList.sort((card1, card2) => card2.isPrimary - card1.isPrimary);
					setExistingCardList(newExistingCardList);
					popupController.close(SpinningLoaderPopup);
				} catch (e: any) {
					console.error(e);
					e.response.data &&
						rsToastify.error(
							typeof e.response.data.msg == 'string' ? e.response.data.msg : 'Something went wrong.',
							e.response.data.err
						);
					popupController.close(SpinningLoaderPopup);
				}
			}
		);

		return () => {
			paymentService.unsubscribeToSpreedlyPaymentMethod(paymentMethodId);
			paymentService.unsubscribeToSpreedlyReady(readyId);
			paymentService.unsubscribeToSpreedlyFieldEvent(fieldEventId);
			paymentService.unsubscribeToSpreedlyError(errorId);
		};
	}, [existingCardList, isAuthorized]);

	useEffect(() => {
		if (!user) return;
		setExistingCardList(user.paymentMethods);
	}, [user]);

	useEffect(() => {
		if (!user) navigate('/page-not-found');
	}, []);

	function isFormFilledOut(): boolean {
		return (
			!!creditCardObj.get('full_name').value.toString().length &&
			!!creditCardObj.get('expDate').value.toString().length
		);
	}

	async function updateCreditCardObj(control: RsFormControl) {
		if (
			control.key === 'expDate' &&
			!control.value.toString().includes('/') &&
			control.value.toString().length === 4
		) {
			control.value = control.value.toString().slice(0, 2) + '/' + control.value.toString().slice(2, 4);
		}
		if (control.key === 'expDate') {
			const digitsOnly = /[^\d\/]/g;
			control.value = control.value.toString().replace(digitsOnly, '');
		}
		creditCardObj.update(control);
		let isFormValid = await creditCardObj.isValid();
		setIsValidForm(isFormFilledOut() && isFormValid);
		setCreditCardObj(creditCardObj.clone());
	}

	const updateExpDate = (control: RsFormControl) => {
		if (
			control.key === 'expDate' &&
			!control.value.toString().includes('/') &&
			control.value.toString().length === 4
		) {
			control.value = control.value.toString().slice(0, 2) + '/' + control.value.toString().slice(2, 4);
		}
		setExpDate(control.value.toString());
	};

	async function deletePaymentCard(id: number) {
		if (!user) return;
		popupController.open(SpinningLoaderPopup);
		try {
			await paymentService.delete(id);
			setExistingCardList((prevState) =>
				prevState.filter((card) => card.id !== id).sort((card1, card2) => card2.isPrimary - card1.isPrimary)
			);

			setExpDate('');
			setEditPaymentMethod(null);
			window.Spreedly.reload();
			popupController.close(SpinningLoaderPopup);
		} catch (e: any) {
			popupController.close(SpinningLoaderPopup);
			console.error(e.message);
			const message = `Getting error :${e.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	async function setCardToPrimary(data: Api.Payment.Req.Update) {
		popupController.open(SpinningLoaderPopup);
		try {
			let res = await paymentService.update(data);
			let newExistingCardList: Api.User.PaymentMethod[] = existingCardList.map((item) => {
				return { ...item, isPrimary: item.id === res.id ? data.isPrimary : 0 };
			});
			newExistingCardList.sort((a, b) => b.isPrimary - a.isPrimary);
			setExistingCardList(newExistingCardList);
			popupController.close(SpinningLoaderPopup);
		} catch (e: any) {
			console.error(e);
			const message = `Getting error :${e.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
			popupController.close(SpinningLoaderPopup);
		}
	}
	function renderPaymentMethods() {
		if (!ObjectUtils.isArrayWithData(existingCardList)) return;
		let cardList = [...existingCardList];
		cardList.sort((card1, card2) => card2.isPrimary - card1.isPrimary);
		return cardList.map((item) => (
			<OtherPaymentCard
				key={item.id}
				id={item.id}
				isPrimary={item.isPrimary}
				cardType={item.type}
				name={item.nameOnCard}
				last4={item.last4}
				onDelete={() => {
					if (item.isPrimary) return;
					deletePaymentCard(item.id).catch(console.error);
				}}
				onEdit={() => {
					setEditPaymentMethod(item);
					setIsPrimary(item.isPrimary);
					setExpDate(('0' + item.expirationMonth).slice(-2) + '/' + item.expirationYear);
				}}
				onSetPrimary={() => {
					if (item.isPrimary) return;
					setCardToPrimary({ id: item.id, isPrimary: 1 }).catch(console.error);
				}}
			/>
		));
	}

	async function save() {
		try {
			let newCreditCardObj: any = creditCardObj.toModel();
			newCreditCardObj.month = parseInt(newCreditCardObj.expDate.split('/')[0]);
			newCreditCardObj.year = parseInt(newCreditCardObj.expDate.split('/')[1]);
			newCreditCardObj.isPrimary = isPrimary;
			delete newCreditCardObj.expDate;
			popupController.open(SpinningLoaderPopup);
			window.Spreedly.tokenizeCreditCard(newCreditCardObj);
			setIsFormComplete(false);
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	const update = async () => {
		try {
			popupController.open(SpinningLoaderPopup);
			const result = await paymentService.update({
				id: editPaymentMethod.id,
				isPrimary: isPrimary,
				offsiteLoyaltyEnrollment: isAuthorized ? 1 : 0,
				expirationMonth: Number(expDate.slice(0, expDate.indexOf('/'))),
				expirationYear: Number(expDate.slice(expDate.indexOf('/') + 1, 7))
			});
			if (result) rsToastify.success('Card successfully updated.', 'Card Updated!');
			setIsAuthorized(false);
			setEditPaymentMethod(null);
			popupController.close(SpinningLoaderPopup);
			const gatewayDetails: Api.Payment.Res.PublicData = await paymentService.getGateway();
			window.Spreedly.init(gatewayDetails.publicData.token, {
				numberEl: 'spreedly-number',
				cvvEl: 'spreedly-cvv'
			});
		} catch (e: any) {
			console.error(e);
			e.response.data &&
				rsToastify.error(
					typeof e.response.data.msg == 'string' ? e.response.data.msg : 'Something went wrong.',
					e.response.data.err
				);
			const message = `Getting error :${e.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
			popupController.close(SpinningLoaderPopup);
		}
	};

	function getPaymentLogo(cardType: string) {
		let logo = '';
		switch (cardType.toLowerCase()) {
			case 'visa':
				logo = '../../images/creditCardLogos/Visa.svg';
				break;
			case 'master':
				logo = '../../images/creditCardLogos/MasterCard.svg';
				break;
			case 'discover':
				logo = '../../images/creditCardLogos/Discover.svg';
				break;
			case 'american_express':
				logo = '../../images/creditCardLogos/AmEx.svg';
				break;
			case 'amex':
				logo = '../../images/creditCardLogos/AmEx.svg';
				break;
		}
		return <img src={logo} alt={'credit Card Logo'} width={56} height={34} />;
	}

	function renderCardType() {
		try {
			if (!editPaymentMethod?.type) return;
			if (!editPaymentMethod?.type.includes('_')) return StringUtils.capitalizeFirst(editPaymentMethod?.type);
			let cardType = editPaymentMethod?.type;
			let tempCardType = cardType.split('_');
			return tempCardType.map((nameOnCard: any) => StringUtils.capitalizeFirst(nameOnCard)).join(' ');
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	if (!user) return <LoadingPage />;
	return (
		<Page className={'rsAccountPaymentMethodsPage'}>
			<SubNavMenu title={'Payment methods'} />
			<Paper boxShadow={!isMobile} className="paymentContainerPaper">
				<Box className={classNames('paymentMethods', { boxShadow: isMobile })}>
					<Label variant={'buttonMdLg'} mb={48}>
						Payment methods
					</Label>
					<Box className={'otherPaymentCardWrapper'}>{renderPaymentMethods()}</Box>
				</Box>

				<Box className={classNames('newPaymentForm', { boxShadow: isMobile })}>
					{!editPaymentMethod ? (
						<div>
							<Label variant={'buttonMdLg'} mb={48}>
								Add a New Payment Method
							</Label>
							<form id={'payment-form'} action={'/card-payment'}>
								<div ref={numberRef} id={'spreedly-number'}>
									<Label id={'Number'} variant={'h6'} mb={10}>
										<span className="requiredAsterisk">*</span>Card Number
									</Label>
								</div>
								<LabelInput
									labelVariant={'h6'}
									title={'Name on Card'}
									inputType={'text'}
									control={creditCardObj.get('full_name')}
									updateControl={updateCreditCardObj}
									isRequired
								/>
								<div className={'expDateAndCVV'}>
									<LabelInput
										labelVariant={'h6'}
										className={'creditCardExpInput'}
										maxLength={7}
										title={'Expiration'}
										inputType={'text'}
										control={creditCardObj.get('expDate')}
										updateControl={updateCreditCardObj}
										placeholder={'MM/YYYY'}
										isRequired
									/>
									<div ref={cvvRef} id={'spreedly-cvv'}>
										<Box display={'flex'} alignItems={'center'} mb={8}>
											<Label id={'Cvv'} variant={'h6'} mr={8}>
												<span className="requiredAsterisk">*</span>CVV
											</Label>
											<Box className={'questionMarkWrapper'}>
												<Icon iconImg={'icon-solid-question-circle'} cursorPointer size={16} />
												<div className={'compareToolTip'}>
													<div className={'toolTipTriangle'} />
													<Label className={'caption'}>
														3-digit security code on back of card
													</Label>
												</div>
											</Box>
										</Box>
									</div>
								</div>
							</form>

							<LabelCheckbox
								value={1}
								labelVariant={'h6'}
								text={
									<React.Fragment>
										* By submitting your card information and checking this box, you authorize your
										payment card network to monitor and share transaction data with our service
										provider to participate in the Spire Loyalty program. You also acknowledge and
										agree that our service provider may share certain details of your qualifying
										transactions with Spire Loyalty to enable your participation in the Spire
										Loyalty program and for other purposes in accordance with the{' '}
										<Link path={`/legal/terms-and-conditions`} external target={'blank'}>
											<span> Spire Loyalty Terms and Conditions</span>
										</Link>
										,{' '}
										<Link path={'/legal/privacy'} external target={'blank'}>
											<span>Spire Loyalty privacy policy</span>
										</Link>{' '}
										and{' '}
										<Link path={'https://fidel.uk/legal/privacy/'} external target={'blank'}>
											<span>Fidel’s Privacy Policy</span>
										</Link>
										. You may opt-out of transaction monitoring on the linked card at any time by
										emailing support@spireloyalty.com or calling (321) 888 – 3509.
									</React.Fragment>
								}
								isChecked={isAuthorized}
								onSelect={() => {
									setIsAuthorized(true);
								}}
								onDeselect={() => {
									setIsAuthorized(false);
								}}
								lineClamp={isMobile ? 269 : 13}
							/>
							<div className={'primaryCheckboxAndBtn'}>
								<LabelCheckbox
									className={classNames('isPrimaryCheckbox', {
										disabled: !ObjectUtils.isArrayWithData(existingCardList)
									})}
									labelVariant={'h6'}
									value={'isPrimary'}
									text={'Set as primary'}
									isChecked={!!isPrimary}
									isDisabled={!ObjectUtils.isArrayWithData(existingCardList)}
									onSelect={() => {
										setIsPrimary(1);
									}}
									onDeselect={() => {
										setIsPrimary(1);
									}}
								/>
								<LabelButton
									look={'containedPrimary'}
									variant={'buttonMdLg'}
									label={'Add New Payment'}
									disabled={!isFormComplete}
									onClick={() => {
										save();
									}}
								/>
							</div>
						</div>
					) : (
						<div>
							<Label variant={'buttonMdLg'} mb={20}>
								Update a Payment Method
							</Label>
							<div className={'updateImageAndCardDetailsWrapper'}>
								<div className={'updateCreditCardImage'}>
									{getPaymentLogo(get(editPaymentMethod, 'type'))}
								</div>
								<Box
									display={isMobile ? 'flex' : ''}
									alignItems={isMobile ? 'center' : ''}
									width={isMobile ? '100%' : ''}
									justifyContent={isMobile ? 'space-between' : ''}
								>
									<div>
										<Label variant={'body1'} className="nameLabel">
											{get(editPaymentMethod, 'nameOnCard')}
										</Label>
										<Label
											variant={'body1'}
											className={'cardName'}
										>{`${renderCardType()} ending in ${get(editPaymentMethod, 'last4')}`}</Label>
									</div>
								</Box>
							</div>
							{/* <form id={'payment-form'} action={'/card-payment'}>
							</form> */}
							<div className={'expDateAndCVV'}>
								<LabelInput
									labelVariant={'h6'}
									className={'creditCardExpInput'}
									maxLength={7}
									title={'Expiration'}
									inputType={'text'}
									initialValue={expDate}
									updateControl={updateExpDate}
									placeholder={'MM/YYYY'}
									isRequired
								/>
							</div>

							<LabelCheckbox
								value={1}
								labelVariant={'h6'}
								text={
									<React.Fragment>
										* By submitting your card information and checking this box, you authorize your
										payment card network to monitor and share transaction data with our service
										provider to participate in the Spire Loyalty program. You also acknowledge and
										agree that our service provider may share certain details of your qualifying
										transactions with Spire Loyalty to enable your participation in the Spire
										Loyalty program and for other purposes in accordance with the{' '}
										<Link path={`/legal/terms-and-conditions`} external target={'blank'}>
											<span> Spire Loyalty Terms and Conditions</span>
										</Link>
										,{' '}
										<Link path={'/legal/privacy'} external target={'blank'}>
											<span>Spire Loyalty privacy policy</span>
										</Link>{' '}
										and{' '}
										<Link path={'https://fidel.uk/legal/privacy/'} external target={'blank'}>
											<span>Fidel’s Privacy Policy</span>
										</Link>
										. You may opt-out of transaction monitoring on the linked card at any time by
										emailing support@spireloyalty.com or calling (321) 888 – 3509.
									</React.Fragment>
								}
								isChecked={isAuthorized}
								onSelect={() => {
									setIsAuthorized(true);
								}}
								onDeselect={() => {
									setIsAuthorized(false);
								}}
								lineClamp={isMobile ? 269 : 13}
							/>
							<div className={'primaryCheckboxAndBtn'}>
								{/* <LabelCheckbox
									className={classNames('isPrimaryCheckbox', {
										disabled: !ObjectUtils.isArrayWithData(existingCardList)
									})}
									labelVariant={'h6'}
									value={'isPrimary'}
									text={'Set as primary'}
									isChecked={!!isPrimary}
									isDisabled={!ObjectUtils.isArrayWithData(existingCardList)}
									onSelect={() => {
										setIsPrimary(1);
									}}
									onDeselect={() => {
										setIsPrimary(0);
									}}
								/> */}
								<div className="cancelAndUpdateBtnContainer">
									<LabelButton
										className="cancelBtn"
										look={'containedSecondary'}
										variant={'buttonMdLg'}
										label={'Cancel'}
										onClick={async () => {
											setEditPaymentMethod(null);
											const gatewayDetails: Api.Payment.Res.PublicData =
												await paymentService.getGateway();
											window.Spreedly.init(gatewayDetails.publicData.token, {
												numberEl: 'spreedly-number',
												cvvEl: 'spreedly-cvv'
											});
										}}
									/>
									<LabelButton
										look={'containedPrimary'}
										variant={'buttonMdLg'}
										label={'Update'}
										// disabled={!isFormComplete}
										onClick={() => {
											update();
										}}
									/>
								</div>
							</div>
						</div>
					)}
				</Box>
			</Paper>
		</Page>
	);
};

export default AccountPaymentMethodsPage;
