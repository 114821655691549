Object.defineProperty(exports, '__esModule', { value: true });
var Events = (function () {
	function Events() {
		this.handlers = {};
		this.lastId = Date.now();
	}
	Events.prototype.on = function (event, callback) {
		var id = ++this.lastId;
		if (!this.handlers[event]) this.handlers[event] = {};
		this.handlers[event][id] = callback;
	};
	Events.prototype.off = function (id) {
		for (var i in this.handlers) {
			for (var j in this.handlers[i]) {
				if (parseInt(j) === id) {
					delete this.handlers[i][j];
					return;
				}
			}
		}
	};
	Events.prototype.dispatch = function (event, data) {
		for (var i in this.handlers[event]) {
			if (typeof this.handlers[event][i] === 'function') this.handlers[event][i](data);
		}
	};
	return Events;
})();
exports.default = new Events();
