import * as React from 'react';
import './UserBasicInfoPaperResponsive.scss';
import Paper from '../../paper/Paper';
import { Box, popupController } from '../../../lib/@bit/redsky.framework.rs.996';
import Label from '../../../lib/@bit/redsky.framework.rs.label';
import { StringUtils } from '../../../utils/utils';
import LabelButton from '../../labelButton/LabelButton';
import LoyaltyTierPopup from '../../../popups/loyaltyTierPopup/LoyaltyTierPopup';
import Icon from '../../../lib/@bit/redsky.framework.rs.icon';
import { useState } from 'react';
import themes from '../../../themes/themes.scss';

interface UserBasicInfoPaperResponsiveProps {
	userData: Api.User.Res.Detail;
	onLogOut: () => void;
}

const UserBasicInfoPaperResponsive: React.FC<UserBasicInfoPaperResponsiveProps> = (props) => {
	const [visibilityToggle, setVisibilityToggle] = useState<boolean>(true);
	function renderLoadingBarPercent(): string {
		return `${Math.min(
			100,
			Math.floor(
				props?.userData?.lifeTimePoints /
					(props?.userData?.nextTierThreshold ? props?.userData?.nextTierThreshold / 100 : 100)
			)
		)}%`;
	}
	function replaceLettersWithStars(accountNumber: any): string {
		let lastFour = accountNumber.slice(-4);
		return accountNumber.replace(/\d/g, '*').slice(0, -4) + lastFour;
	}
	return (
		<Paper className={'rsUserBasicInfoPaperResponsive'} boxShadow borderRadius={'20px'}>
			<Box display={'flex'} justifyContent={'space-between'} mb={16}>
				<Box display={'flex'}>
					<Label variant={'buttonMdLg'} mr={3}>
						{`${props?.userData?.firstName} ${props?.userData?.lastName}`},
					</Label>
					<Label variant={'h6'}>{props?.userData?.primaryEmail}</Label>
				</Box>
				<Box display={'flex'}>
					<Label variant={'body1'}>Not you?</Label>
					<Label
						ml={8}
						variant={'body1B'}
						color={themes.primaryTextColor}
						onClick={props.onLogOut}
						className={'primaryTextColor'}
					>
						Log Out
					</Label>
				</Box>
			</Box>
			<Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} mb={30}>
				<Box display={'flex'} alignItems={'center'}>
					<img
						src={`../../images/tierIcons/${props?.userData?.tierTitle || 'Bronze'}.png`}
						alt={'Tier Badge'}
					/>
					<Box ml={15}>
						<Label variant={'h6'}>{props?.userData?.tierTitle || 'Bronze'}</Label>
						{props?.userData?.accountNumber && (
							<Box display={'flex'} alignItems={'center'}>
								<Label variant={'h6'} marginRight={'16px'}>
									Account{' '}
									{!visibilityToggle
										? props?.userData?.accountNumber
										: replaceLettersWithStars(props?.userData?.accountNumber)}
								</Label>
								<Icon
									iconImg={visibilityToggle ? 'icon-visibility-false' : 'icon-visibility-true'}
									onClick={() => {
										setVisibilityToggle(!visibilityToggle);
									}}
									cursorPointer
								/>
							</Box>
						)}
					</Box>
				</Box>
				<Box textAlign={'end'}>
					<Label variant={'body1'} mb={6}>
						You have reached the {props?.userData?.tierTitle || 'Bronze'} Spire Level! Hooray!
					</Label>
					<div className={'loadingBarContainer'}>
						<div className={'loadingBar'} style={{ width: renderLoadingBarPercent() }}>
							<img src={`../../images/Chevron-Pattern.svg`} />
						</div>
					</div>
				</Box>
			</Box>
			<Box className={'pointsContainer'}>
				<div className={'pointsDiv'}>
					<Label variant={'h5'} mb={8}>
						Points Available
					</Label>
					<Label variant={'h2'} className={'secondaryTextColor'}>
						{StringUtils.addCommasToNumber(props?.userData?.availablePoints)}
					</Label>
				</div>
				<div className={'pointsDiv'}>
					<Label variant={'h5'} mb={8}>
						Points Pending
					</Label>
					<Label variant={'h2'}>{StringUtils.addCommasToNumber(props?.userData?.pendingPoints)}</Label>
				</div>
				<div className={'pointsDiv'}>
					<Label variant={'h5'} mb={8}>
						Lifetime Points
					</Label>
					<Label variant={'h2'}>{StringUtils.addCommasToNumber(props?.userData?.lifeTimePoints)}</Label>
				</div>
				<div className={'loyaltyTierButtonContainer'}>
					<LabelButton
						look={'containedPrimary'}
						variant={'buttonMdLg'}
						label={'See Loyalty Tiers'}
						onClick={() => {
							popupController.open(LoyaltyTierPopup);
						}}
					/>
				</div>
			</Box>
		</Paper>
	);
};

export default UserBasicInfoPaperResponsive;
