// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rsImg:not([src]){visibility:hidden}
`, "",{"version":3,"sources":["webpack://./src/lib/@bit/redsky.framework.rs.img/dist/Img.scss"],"names":[],"mappings":"AAAA,kBAIE,iBAAkB","sourcesContent":[".rsImg {\n\t// Hides alt tags when image is not visible due to lazy loading\n\t// See: https://stackoverflow.com/questions/36993874/is-there-a-way-to-hide-alt-tags-for-unloaded-lazyload-images\n\t&:not([src]) {\n\t\tvisibility: hidden;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
