// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("fonts/rsSearch.eot?8z9n8m", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("fonts/rsSearch.woff2?8z9n8m", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("fonts/rsSearch.ttf?8z9n8m", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("fonts/rsSearch.woff?8z9n8m", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("fonts/rsSearch.svg?8z9n8m", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___, { hash: "#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___, { hash: "#rsSearch" });
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
	font-family: 'rsSearch';
	src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
	src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('embedded-opentype'),
		url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('woff2'), url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('truetype'),
		url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format('woff'), url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^='rsInput-'],
[class*=' rsInput-'] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'rsSearch' !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.rsInput-search:before {
	content: '\\e900';
}
`, "",{"version":3,"sources":["webpack://./src/lib/@bit/redsky.framework.rs.input/dist/icons/style.css"],"names":[],"mappings":"AAAA;CACC,uBAAuB;CACvB,4CAAqC;CACrC;;+GAE0G;CAC1G,mBAAmB;CACnB,kBAAkB;CAClB,mBAAmB;AACpB;;AAEA;;CAEC,+EAA+E;CAC/E,kCAAkC;CAClC,YAAY;CACZ,kBAAkB;CAClB,mBAAmB;CACnB,oBAAoB;CACpB,oBAAoB;CACpB,cAAc;;CAEd,sCAAsC;CACtC,mCAAmC;CACnC,kCAAkC;AACnC;;AAEA;CACC,gBAAgB;AACjB","sourcesContent":["@font-face {\n\tfont-family: 'rsSearch';\n\tsrc: url('fonts/rsSearch.eot?8z9n8m');\n\tsrc: url('fonts/rsSearch.eot?8z9n8m#iefix') format('embedded-opentype'),\n\t\turl('fonts/rsSearch.woff2?8z9n8m') format('woff2'), url('fonts/rsSearch.ttf?8z9n8m') format('truetype'),\n\t\turl('fonts/rsSearch.woff?8z9n8m') format('woff'), url('fonts/rsSearch.svg?8z9n8m#rsSearch') format('svg');\n\tfont-weight: normal;\n\tfont-style: normal;\n\tfont-display: block;\n}\n\n[class^='rsInput-'],\n[class*=' rsInput-'] {\n\t/* use !important to prevent issues with browser extensions that change fonts */\n\tfont-family: 'rsSearch' !important;\n\tspeak: never;\n\tfont-style: normal;\n\tfont-weight: normal;\n\tfont-variant: normal;\n\ttext-transform: none;\n\tline-height: 1;\n\n\t/* Better Font Rendering =========== */\n\t-webkit-font-smoothing: antialiased;\n\t-moz-osx-font-smoothing: grayscale;\n}\n\n.rsInput-search:before {\n\tcontent: '\\e900';\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
