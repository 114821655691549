import * as React from 'react';
import './ReservationDetailsPage.scss';
import { Box, Page } from '../../lib/@bit/redsky.framework.rs.996';
import { useNavigate } from 'react-router-dom';
import router from '../../utils/router';
import { useEffect, useState } from 'react';
import Label from '../../lib/@bit/redsky.framework.rs.label/dist/Label';
import serviceFactory from '../../services/serviceFactory';
import ReservationsService from '../../services/reservations/reservations.service';
import LoadingPage from '../loadingPage/LoadingPage';
import { useRecoilState, useRecoilValue } from 'recoil';
import { DateUtils, StringUtils, WebUtils } from '../../utils/utils';
import { rsToastify } from '../../lib/@bit/redsky.framework.rs.toastify';
import globalState from '../../state/globalState';
import ReservationDetailsPaper from '../../components/reservationDetailsPaper/ReservationDetailsPaper';
import SubNavMenu from '../../components/subNavMenu/SubNavMenu';
import useIsAtBreakpoint from '../../customHooks/useIsAtBreakpoint';
import BookingSummaryCard from '../../components/bookingSummaryCard/BookingSummaryCard';
import moment from 'moment';
import { axiosErrorHandler } from '../../utils/errorHandler';
import { HttpStatusCode } from '../../utils/http';
import { getPageFinder, undefinedHandler } from '../../utils/undefinedHandler';

const ReservationDetailsPage: React.FC = () => {
	let navigate = useNavigate();
	const isMobile = useIsAtBreakpoint();
	const reservationsService = serviceFactory.get<ReservationsService>('ReservationsService');
	const user = useRecoilValue<Api.User.Res.Detail | undefined>(globalState.user);
	const [accommodation, setAccommodation] = useState<Api.Reservation.Res.Verification>();
	const params = router.getPageUrlParams<{ reservationId: number }>([
		{ key: 'ri', default: 0, type: 'integer', alias: 'reservationId' }
	]);
	const [reservation, setReservation] = useState<Api.Reservation.Res.Get>();

	useEffect(() => {
		async function getReservationData(id: number) {
			try {
				let res = await reservationsService.get(id);
				setReservation(res);
			} catch (e: any) {
				axiosErrorHandler(e, {
					[HttpStatusCode.NOT_FOUND]: () => {
						navigate('/reservation-not-found');
					},
					[HttpStatusCode.UNAUTHORIZED]: () => {
						navigate('/reservation-not-found');
					}
				});
				rsToastify.error(
					WebUtils.getRsErrorMessage(e, 'Unable to get reservation information.'),
					'Server Error'
				);
				const message = `Getting error :${e.message} on ${getPageFinder(window.location.pathname)} page.`;
				undefinedHandler(message);
			}
		}
		getReservationData(params.reservationId).catch(console.error);
	}, []);

	useEffect(() => {
		if (!reservation) return;
		let formattedAccommodation: Api.Reservation.Res.Verification = {
			accommodationId: reservation.accommodation.id,
			accommodationName: reservation.accommodation.name,
			adultCount: reservation.adultCount,
			arrivalDate: DateUtils.displayUserDate(reservation.arrivalDate, 'MM-DD-YYYY'),
			checkInTime: StringUtils.convertTwentyFourHourTime(getPoliciesValue('CheckIn')),
			checkOutTime: StringUtils.convertTwentyFourHourTime(getPoliciesValue('CheckOut')),
			childCount: reservation.childCount,
			departureDate: DateUtils.displayUserDate(reservation.departureDate, 'MM-DD-YYYY'),
			destinationName: reservation.destination.name,
			maxOccupantCount: reservation.accommodation.maxOccupantCount,
			policies: reservation.destination.policies,
			prices: reservation.priceDetail,
			rateCode: reservation.rateCode,
			upsellPackages: reservation.upsellPackages
		};
		setAccommodation(formattedAccommodation);
	}, [reservation]);

	function getPoliciesValue(option: 'CheckIn' | 'CheckOut' | 'Cancellation') {
		if (!reservation) return '';
		let time = reservation.destination.policies.find((item) => {
			return item.type === option;
		});
		if (time !== undefined) return time.value;
		else return '';
	}

	return !reservation || !user ? (
		<LoadingPage />
	) : (
		<Page className={'rsReservationDetailsPage'}>
			<div className={'rs-page-content-wrapper'}>
				<SubNavMenu
					title={isMobile ? 'Your Itinerary' : `Your itinerary at ${reservation.destination.name}`}
					navigateTo={'RESERVATIONS'}
				/>
				<div id="desktopView">
					{accommodation && (
						<Box className={'bookingCard'}>
							<BookingSummaryCard
								onRedeem={false}
								destinationId={reservation.destination.id}
								bookingData={accommodation}
								canHide={isMobile}
								usePoints={!reservation.paymentMethod}
							/>
						</Box>
					)}
					<ReservationDetailsPaper reservationData={reservation} id="reservationDetails" />
					<Box className="policyCard">
						<Label variant={'buttonMdLg'}>Policies</Label>
						<Box display={'flex'} justifyContent={'space-between'} maxWidth={500} mb={24} m={'32px 0 24px'}>
							<div>
								<Label variant={'buttonMdLg'} mb={8}>
									Check in
								</Label>
								<Label variant={'body2'}>
									After {StringUtils.convertTwentyFourHourTime(getPoliciesValue('CheckIn'))}
								</Label>
							</div>
							<div>
								<Label variant={'buttonMdLg'} mb={8}>
									Check out
								</Label>
								<Label variant={'body2'}>
									Before {StringUtils.convertTwentyFourHourTime(getPoliciesValue('CheckOut'))}
								</Label>
							</div>
						</Box>
						<Label variant={'body2'} mb={24}>
							{reservation.accommodation.name.toUpperCase()}
						</Label>

						<div>
							<Label className={'medGray2'} variant={'h6'} mb={8}>
								Cancellation Policy
							</Label>
							<Label variant={'body2'} className={'medGray2'}>
								{getPoliciesValue('Cancellation')}
							</Label>
						</div>
					</Box>
				</div>
			</div>
		</Page>
	);
};

export default ReservationDetailsPage;
