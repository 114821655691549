// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rsLink{text-decoration:inherit;color:inherit;cursor:pointer}.rsLink.noPointer{cursor:inherit}
`, "",{"version":3,"sources":["webpack://./src/lib/@bit/redsky.framework.link/dist/Link.scss"],"names":[],"mappings":"AAAA,QACC,uBAAwB,CACxB,aAAc,CACd,cAAe,CAHhB,kBAME,cAAe","sourcesContent":[".rsLink {\n\ttext-decoration: inherit;\n\tcolor: inherit;\n\tcursor: pointer;\n\n\t&.noPointer {\n\t\tcursor: inherit;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
