import * as React from 'react';
import './ReservationDetailsPaper.scss';
import Paper from '../paper/Paper';
import Label from '../../lib/@bit/redsky.framework.rs.label/dist/Label';
import Img from '../../lib/@bit/redsky.framework.rs.img';
import { Box } from '../../lib/@bit/redsky.framework.rs.996';
import IconLabel from '../iconLabel/IconLabel';
import TitleDescription from '../titleDescription/TitleDescription';
import { DateUtils, StringUtils } from '../../utils/utils';
import useGetCountryList from '../../customHooks/useGetCountryList';
import useIsAtBreakpoint from '../../customHooks/useIsAtBreakpoint';
import { get } from 'lodash';
import { getPageFinder, undefinedHandler } from '../../utils/undefinedHandler';

interface ReservationDetailsPaperProps {
	reservationData: Api.Reservation.Res.Get;
	id?: string;
}

const ReservationDetailsPaper: React.FC<ReservationDetailsPaperProps> = (props) => {
	const isMobile = useIsAtBreakpoint();
	const countryList = useGetCountryList();

	function renderAddress() {
		try {
			let destination = props.reservationData.destination;
			const address = `${destination.address1} ${destination.address2 || ''}`;
			let cityState = '';
			if (!destination.state) {
				let countryName: Api.Country.Res.Country | undefined = countryList.find(
					(item) => item.isoCode === destination.country
				);
				if (!countryName) return <></>;
				cityState = `${destination.city},  ${countryName.name}`;
			} else {
				cityState = `${destination.city},  ${destination.state} ${destination.zip}`;
			}
			return (
				<>
					<IconLabel
						iconImg={'icon-pin'}
						iconPosition={'left'}
						labelName={isMobile ? address || '' : address + cityState || ''}
						labelVariant={isMobile ? 'subtitle1' : 'h6'}
						iconSize={13}
					/>
					{isMobile && (
						<Label variant={isMobile ? 'subtitle1' : 'h6'} ml={23}>
							{cityState}
						</Label>
					)}
				</>
			);
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	function renderGuestInfo() {
		try {
			let guest = props.reservationData.guest;
			return (
				<React.Fragment>
					<TitleDescription
						title={'Primary guest name'}
						description={`${guest?.firstName} ${guest?.lastName}`}
					/>
					<TitleDescription title={'Email'} description={guest.email} />
					<TitleDescription title={'Phone'} description={StringUtils.formatPhoneNumber(guest.phone)} />
				</React.Fragment>
			);
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	function renderReservationDetails() {
		try {
			let reservation = props.reservationData;
			return (
				<React.Fragment>
					<TitleDescription title={'Reservation name'} description={reservation.accommodation.name} />
					<TitleDescription
						title={'Reservation date'}
						description={`${DateUtils.displayUserDate(
							reservation.arrivalDate,
							'ddd MMM d YYYY'
						)} - ${DateUtils.displayUserDate(reservation.departureDate, 'ddd MMM d YYYY')}`}
					/>
					<TitleDescription title={'Itinerary number'} description={reservation.itineraryId} />
				</React.Fragment>
			);
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	function renderAccommodationDetails() {
		try {
			let accommodation = props.reservationData.accommodation;
			return (
				<React.Fragment>
					<TitleDescription title={'Max occupancy'} description={accommodation.maxOccupantCount} />
					<TitleDescription title={'Sleeps'} description={accommodation.maxSleeps} />
					<TitleDescription title={'Property type'} description={accommodation.propertyType} />
					<TitleDescription title={'Ada compliant'} description={accommodation.adaCompliant ? 'Yes' : 'No'} />
					<TitleDescription title={'Extra bed'} description={accommodation.extraBeds} />
					<TitleDescription title={'Floor Count'} description={accommodation.floorCount} />
				</React.Fragment>
			);
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	function renderAmenities() {
		try {
			let amenities = props.reservationData.destination.experiences;
			return amenities.map((item) => {
				return (
					<IconLabel
						className={'amenitiesIcons'}
						key={item.id}
						labelName={item.title}
						iconImg={item.icon}
						iconPosition={'top'}
						iconSize={37}
						labelVariant={'body1'}
					/>
				);
			});
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	return (
		<Paper borderRadius={'20px'} boxShadow className={`rsReservationDetailsPaper${props.id ? ` ${props.id}` : ''}`}>
			<Label variant={'buttonMdLg'} mb={40}>
				Itinerary Details
			</Label>
			<Img src={props.reservationData.destination.heroUrl} alt={'Destination Hero'} width={980} height={470} />
			<Box
				display={'flex'}
				alignItems={'center'}
				mt={isMobile ? 24 : 48}
				flexWrap={'wrap'}
				mb={isMobile ? 16 : 48}
			>
				<Label variant={isMobile ? 'h6' : 'h5'} mr={30} mb={isMobile ? 16 : 0}>
					{get(props, 'reservationData.destination.name')}
				</Label>
				{renderAddress()}
			</Box>
			<Label variant={isMobile ? 'subtitle1' : 'h6'} mt={5} mb={isMobile ? 24 : 40}>
				Confirmation Number: #{get(props, 'reservationData.externalConfirmationId')}
			</Label>
			<Box className={'infoSection'} mb={40}>
				{renderGuestInfo()}
				{renderReservationDetails()}
				{renderAccommodationDetails()}
			</Box>
			<Box className={'amenitiesList'}>{renderAmenities()}</Box>
		</Paper>
	);
};

export default ReservationDetailsPaper;
