import * as React from 'react';
import './LoyaltyTierPopup.scss';
import { PopupProps } from '../../lib/@bit/redsky.framework.rs.996/dist/popup/Popup';
import { Box, Popup, popupController } from '../../lib/@bit/redsky.framework.rs.996';
import Icon from '../../lib/@bit/redsky.framework.rs.icon';
import Label from '../../lib/@bit/redsky.framework.rs.label/dist/Label';
import TierDescriptionCard from '../../components/tierDescriptionCard/TierDescriptionCard';
import Paper from '../../components/paper/Paper';
import useIsAtBreakpoint from '../../customHooks/useIsAtBreakpoint';
import serviceFactory from '../../services/serviceFactory';
import UserService from '../../services/user/user.service';
import { getPageFinder, undefinedHandler } from '../../utils/undefinedHandler';
import { StringUtils } from '../../utils/utils';
import { get } from 'lodash';

export interface LoyaltyTierPopupProps extends PopupProps {}

const LoyaltyTierPopup: React.FC<LoyaltyTierPopupProps> = (props) => {
	const userService = serviceFactory.get<UserService>('UserService');
	const [tierDetails, setTierDetails] = React.useState<Api.User.Res.TierDetails>();

	React.useEffect(() => {
		getTierDetails();
	}, []);

	const getTierDetails = async () => {
		try {
			const res = await userService.getTierDetails();
			setTierDetails(res);
		} catch (error: any) {
			console.log('check platform error', error);
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	};

	const fullProps = popupController.convertProps(props);
	const isMobile = useIsAtBreakpoint();
	return (
		<Popup {...props}>
			<Paper className={'rsLoyaltyTierPopup'}>
				<Icon
					className={'closeIcon'}
					iconImg={'icon-close'}
					size={32}
					color="#797979"
					onClick={() => {
						popupController.closeById(fullProps.popupId);
					}}
				/>
				<Box className={'popupHeader'}>
					<Label variant={'h3'} className="headerText primaryTextColor">
						{isMobile ? 'Tiers' : 'Membership Tiers and Benefits'}
					</Label>
				</Box>
				<Box className={'tierCardContainer'}>
					<TierDescriptionCard
						tierImage={'../../images/tierIcons/Bronze.png'}
						tierName={`${get(tierDetails, '0.name', '')} Spire`}
						tierPointValue={`${StringUtils.addCommasToNumber(
							get(tierDetails, '0.threshold', '')
						)} - ${StringUtils.addCommasToNumber(get(tierDetails, '1.threshold', ''))}`}
						tierDescription={
							'Welcome to Spire, begin earning at our entry level and aspire to higher levels.'
						}
					/>
					<TierDescriptionCard
						tierImage={'../../images/tierIcons/Silver.png'}
						tierName={`${get(tierDetails, '1.name', '')} Spire`}
						tierPointValue={`${StringUtils.addCommasToNumber(
							get(tierDetails, '1.threshold', '')
						)} - ${StringUtils.addCommasToNumber(get(tierDetails, '2.threshold', ''))}`}
						tierDescription={`As a Silver Spire Member you'll be entitled to the privileges of our elevated earning members and earn a bonus ${get(
							tierDetails,
							'1.accrualRate',
							''
						)}% points for your activities and spending.`}
					/>
					<TierDescriptionCard
						tierImage={'../../images/tierIcons/Gold.png'}
						tierName={`${get(tierDetails, '2.name', '')} Spire`}
						tierPointValue={`${StringUtils.addCommasToNumber(
							get(tierDetails, '2.threshold', '')
						)} - ${StringUtils.addCommasToNumber(get(tierDetails, '3.threshold', ''))}`}
						tierDescription={`Our Gold Spire membership level is reserved for our esteemed Spire members who receive a ${get(
							tierDetails,
							'2.accrualRate',
							''
						)}% bonus on top of their points earning programs. In addition, Gold Spire Members receive access to special events and exclusive discounts.`}
					/>
					<TierDescriptionCard
						tierImage={'../../images/tierIcons/Platinum.png'}
						tierName={`${get(tierDetails, '3.name', '')} Spire`}
						tierPointValue={`${StringUtils.addCommasToNumber(get(tierDetails, '3.threshold', ''))}+`}
						tierDescription={`A very exclusive membership group that give you ${get(
							tierDetails,
							'3.accrualRate',
							''
						)}% bonus earnings points for your Spire transactions, and access to an exclusive club that has the benefits of all levels and VIP access to events and Gold Spire exclusive opportunities and recognition.`}
					/>
				</Box>
			</Paper>
		</Popup>
	);
};

export default LoyaltyTierPopup;
