import * as React from 'react';
import './MobileAccommodationOverviewPopup.scss';
import { Box, Popup, popupController, PopupProps } from '../../../lib/@bit/redsky.framework.rs.996';
import Paper from '../../../components/paper/Paper';
import Label from '../../../lib/@bit/redsky.framework.rs.label';
import Icon from '../../../lib/@bit/redsky.framework.rs.icon';
import CarouselV2 from '../../../components/carouselV2/CarouselV2';
import MobileLightBox, { MobileLightBoxProps } from '../../mobileLightBox/MobileLightBox';
import ImageLabel from '../../../components/imageLabel/ImageLabel';
import { useRecoilValue } from 'recoil';
import globalState from '../../../state/globalState';
import { StringUtils } from '../../../utils/utils';
import LabelButton from '../../../components/labelButton/LabelButton';
import themes from '../../../themes/themes.scss';
import { IKImage } from 'imagekitio-react';
import useMerchantReward from '../../../customHooks/useMerchantReward';

export interface MobileAccommodationOverviewPopupProps extends PopupProps {
	destinationDetails: Api.Destination.Res.Details | undefined;
	pointPerDollar?: number;
	destinationName: string;
	renderAmenities: () => JSX.Element | JSX.Element[];
	getAccommodationImages: () => any;
	packageCount: number | undefined;
	renderAccommodationSize: () => any;
	renderLayoutImages: () => React.ReactNodeArray;
	handleFloorPlanExpand: () => void;
	popUp: React.ReactNode;
	accommodationStay: Api.Accommodation.Res.Availability;
	loyaltyStatus: Model.LoyaltyStatus;
	pointsEarnable: number;
	resortPointsPrice: number;
	handleReserveStay: () => void;
	closeSelf: () => void;
	isCustomResort?: boolean;
	phone?: number;
}

const MobileAccommodationOverviewPopup: React.FC<MobileAccommodationOverviewPopupProps> = (props) => {
	const reservationFilters = useRecoilValue<Misc.ReservationFilters>(globalState.reservationFilters);

	const [destinationObj, setDestinationObj] = React.useState({
		campaigns: props.accommodationStay.prices[0].rate.campaigns,
		minAccommodationPriceInCents: props.accommodationStay.prices[0].baseRates
	});
	const userTierValue = useRecoilValue<number>(globalState.userTierValue);
	const user = useRecoilValue<Api.User.Res.Detail | undefined>(globalState.user);

	const [pointPerDollar, setPointPerDollar] = React.useState<number>(0);

	const setAccommodationCampaign = () => {
		const price = props.accommodationStay.prices.reduce((minPrice, currentPrice) => {
			return currentPrice.pricePoints < minPrice.pricePoints ? currentPrice : minPrice;
		}, props.accommodationStay.prices[0]);
		setDestinationObj({
			campaigns: price.rate.campaigns,
			minAccommodationPriceInCents: price.baseRates
		});
	};

	React.useEffect(() => {
		if (typeof props.pointPerDollar === 'undefined') {
			setPointPerDollar(1);
		} else {
			setPointPerDollar(props.pointPerDollar);
		}
		setAccommodationCampaign();
	}, []);

	function renderPricePerNight() {
		if (!props.accommodationStay) return;
		if (reservationFilters.redeemPoints && props.loyaltyStatus === 'ACTIVE') {
			return (
				<Box className={'pricePerNightContainer'}>
					<Box className={'price'}>
						<Label variant={'h2'} className={'yellowText'}>
							{StringUtils.addCommasToNumber(
								Math.min(...props.accommodationStay.prices.map((price) => price.pricePoints))
							)}
						</Label>
						<Label variant={'body1'} ml={5}>
							points per night
						</Label>
					</Box>
					<LabelButton
						look={'containedPrimary'}
						variant={'buttonMdLg'}
						label={props.isCustomResort ? 'Call to Book' : 'Book Now'}
						className={'yellow'}
						onClick={() => {
							props.handleReserveStay();
							popupController.closeAll();
						}}
					/>
				</Box>
			);
		} else {
			return (
				<Box className={'pricePerNightContainer'}>
					<Box className={'price'}>
						<Label variant={'h2'}>
							$
							{StringUtils.formatMoney(
								Math.min(...props.accommodationStay.prices.map((price) => price.priceCents))
							)}
						</Label>
						<Label variant={'body1'} ml={5}>
							$
							{StringUtils.formatMoney(
								Math.min(...props.accommodationStay.prices.map((price) => price.grandTotalCents))
							)}
						</Label>
						<Label variant={'body1'} ml={5}>
							/ including taxes and fees
						</Label>
					</Box>

					<LabelButton
						look={'containedPrimary'}
						variant={'buttonMdLg'}
						label={props.isCustomResort ? 'Call to Book' : 'Book Now'}
						className={'yellow'}
						onClick={() => {
							props.handleReserveStay();
							popupController.closeAll();
						}}
					/>
					{!reservationFilters.redeemPoints && props.loyaltyStatus === 'ACTIVE' && (
						<div style={{ marginTop: '10px' }}>
							<Label className={'secondaryTextColor alignmentCenter'} variant={'captionBI'}>
								{/* {useMerchantReward.pointEarningCalculation(
									destinationObj,
									pointPerDollar,
									userTierValue,
									user,
									!!props.accommodationStay.campaigns ? props.accommodationStay.campaigns.length : 0
								)} */}
								{useMerchantReward.rewardPointsCalculation(
									destinationObj,
									pointPerDollar,
									userTierValue,
									user
								)}
							</Label>
						</div>
					)}
				</Box>
			);
		}
	}

	return (
		<Popup {...props}>
			<Paper className={'rsMobileAccommodationOverviewPopup'}>
				<div className={'popupHeader'}>
					<Label variant={'h5'}>{props.accommodationStay.name}</Label>
					<Icon
						iconImg={'icon-close'}
						color={'#797979'}
						onClick={() => {
							props.closeSelf();
						}}
					/>
				</div>

				<div className={'popupContent'}>
					<CarouselV2
						path={() => {}}
						resortPoint={props.pointsEarnable}
						imgPaths={props.getAccommodationImages()}
						onGalleryClick={() => {
							popupController.open<MobileLightBoxProps>(MobileLightBox, {
								imageData: props.accommodationStay.media
							});
						}}
						hideCompareButton
					/>

					<Box
						className={'accommodationIcons'}
						justifyContent={props.renderAccommodationSize() ? 'space-between' : 'space-around'}
					>
						<ImageLabel
							labelName={`${props.accommodationStay.bedroomCount.toString()} Bed`}
							imgSrc={'sleep.png'}
							imgWidth={'30px'}
							imgHeight={'20px'}
							iconPosition={'left'}
							labelVariant={'caption'}
						/>
						<Box borderRight={`1px solid ${themes.borderGray}`} />
						<ImageLabel
							className={'bathroomIcon'}
							labelName={`${props.accommodationStay.bathroomCount.toString()} Bath`}
							imgSrc={'shower.png'}
							imgWidth={'30px'}
							imgHeight={'20px'}
							iconPosition={'left'}
							labelVariant={'caption'}
						/>
						{props.renderAccommodationSize() && <Box borderRight={`1px solid ${themes.borderGray}`} />}
						{props.renderAccommodationSize() && (
							<ImageLabel
								className={'sizeIcon'}
								labelName={props.renderAccommodationSize()}
								imgSrc={'square-foot.png'}
								imgWidth={'30px'}
								imgHeight={'20px'}
								iconPosition={'left'}
								labelVariant={'caption'}
							/>
						)}
					</Box>

					<Label variant={'body2'} margin={'24px auto'}>
						{props.accommodationStay.longDescription || props.accommodationStay.shortDescription}
					</Label>
					{renderPricePerNight()}
					{props.accommodationStay.amenities && !!props.accommodationStay.amenities.length && (
						<div className={'amenitiesList'}>
							<Label variant={'h6'} mt={24} mb={28}>
								Amenities
							</Label>
							<Box className={'amenityIcons'}>{props.renderAmenities()}</Box>
						</div>
					)}

					{!!props.accommodationStay.layout.length ? (
						<>
							<Label variant={'h6'} margin={'20px auto'}>
								Floor Plan
							</Label>
							<Box display={'flex'} alignItems={'center'} justifyContent={'center'} marginBottom={40}>
								<IKImage
									className={'floorPlanImg'}
									src={props.accommodationStay.layout[0].media.urls.large}
									alt={''}
									loading="lazy"
								/>
							</Box>

							{!!props.accommodationStay.layout.length ? (
								<div className={'expandFloorPlanDiv'} onClick={props.handleFloorPlanExpand}>
									<Label>Expand floor plans</Label>
									<Icon iconImg={'cms-icon-0055'} size={22} />
								</div>
							) : (
								<Box margin={40} />
							)}
						</>
					) : (
						<Box margin={40} />
					)}
				</div>
			</Paper>
		</Popup>
	);
};
export default MobileAccommodationOverviewPopup;
