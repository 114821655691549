// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rs-tabs{position:absolute;bottom:0;z-index:20;width:100%;height:calc(50px + env(safe-area-inset-bottom));box-sizing:border-box;padding-bottom:env(safe-area-inset-bottom);display:flex;justify-content:space-evenly}
`, "",{"version":3,"sources":["webpack://./src/lib/@bit/redsky.framework.rs.996/dist/tabs/Tabs.scss","webpack://./src/lib/@bit/redsky.framework.rs.996/dist/variables.scss"],"names":[],"mappings":"AAEA,SACC,iBAAkB,CAClB,QAAS,CACT,UAAW,CACX,UAAW,CACX,+CCC+D,CDA/D,qBAAsB,CACtB,0CAA2C,CAC3C,YAAa,CACb,4BAA6B","sourcesContent":["@import '../variables.scss';\n\n.rs-tabs {\n\tposition: absolute;\n\tbottom: 0;\n\tz-index: 20;\n\twidth: 100%;\n\theight: $tabsHeight;\n\tbox-sizing: border-box;\n\tpadding-bottom: env(safe-area-inset-bottom);\n\tdisplay: flex;\n\tjustify-content: space-evenly;\n}\n","$spacingXS: 2px;\n$spacingSmall: 4px;\n$spacingMedium: 8px;\n$spacingLarge: 16px;\n$spacingXL: 24px;\n$spacingXXL: 32px;\n$spacingXXXL: 50px;\n$navBarHeight: calc(#{$spacingXXXL} + env(safe-area-inset-top));\n$tabsHeight: calc(#{$spacingXXXL} + env(safe-area-inset-bottom));\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
