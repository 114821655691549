import * as React from 'react';
import './FilterBarDropDown.scss';
import Box from '../../lib/@bit/redsky.framework.rs.996/dist/box/Box';
import Label from '../../lib/@bit/redsky.framework.rs.label';
import Icon from '../../lib/@bit/redsky.framework.rs.icon';
import { useState } from 'react';
import LabelButton from '../labelButton/LabelButton';
import useOnClickOutsideRef from '../../customHooks/useOnClickOutsideRef';
import classNames from 'classnames';

interface FilterBarDropDownProps {
	title?: string | React.ReactNode;
	fieldName?: string;
	placeholder?: string;
	onChangeCallBack: () => void;
	onClearCallback: () => void;
	onClickOutsideCallback?: () => void;
	isSortField?: boolean;
	isPromotion?: boolean;
	removeFilterByLabel?: boolean;
	className?: string;
	dropdownContentClassName?: string;
	icon?: string;
	hasIconSpinAnimation: boolean;
	dropdownFooterClass?: string;
	setPromoCodeType?: any;
	savePromoCodeDatas?: any;
	saveAgentId?: any;
	AgentId?: string;
	promoCode?: string;
	promoCodeType?: any;
	chainId?: number;
	arriveDate?: any;
	departDate?: any;
	hide?: boolean;
}

const FilterBarDropDown: React.FC<FilterBarDropDownProps> = (props) => {
	const [toggleContent, setToggleContent] = useState<boolean>(false);
	const modalRef = useOnClickOutsideRef(() => {
		if (toggleContent) {
			setToggleContent(false);
			if (!props.onClickOutsideCallback) return;
			props.onClickOutsideCallback();

			props.setPromoCodeType('PR');
			props.savePromoCodeDatas('');
			props.saveAgentId('');
		}
	});
	function applyBtnCallback() {
		if (props.onChangeCallBack) props.onChangeCallBack();
		if (props.promoCodeType === 'PR' && props.promoCode !== '' && props.AgentId === '') {
			const synxisPromoUrl = `https://be.synxis.com/?arrive=${props.arriveDate}&chain=${props.chainId}&depart=${props.departDate}&level=chain&nights=3&promo=${props.promoCode}`;
			window.open(synxisPromoUrl, '_blank');
		}

		if (props.promoCodeType === 'GC' && props.promoCode !== '' && props.AgentId === '') {
			const synxisPromoUrl = `https://be.synxis.com/?arrive=${props.arriveDate}&chain=${props.chainId}&depart=${props.departDate}&level=chain&nights=3&group=${props.promoCode}`;
			window.open(synxisPromoUrl, '_blank');
		}

		if (props.promoCodeType !== '' && props.promoCode === '' && props.AgentId !== '') {
			const synxisPromoUrl = `https://be.synxis.com/?arrive=${props.arriveDate}&chain=${props.chainId}&depart=${props.departDate}&agencyid=${props.AgentId}&level=chain&nights=3`;
			window.open(synxisPromoUrl, '_blank');
		}

		if (props.promoCodeType === 'PR' && props.promoCode !== '' && props.AgentId !== '') {
			const synxisPromoUrl = `https://be.synxis.com/?arrive=${props.arriveDate}&chain=${props.chainId}&depart=${props.departDate}&agencyid=${props.AgentId}&level=chain&nights=3&promo=${props.promoCode}`;
			window.open(synxisPromoUrl, '_blank');
		}

		if (props.promoCodeType === 'GC' && props.promoCode !== '' && props.AgentId !== '') {
			const synxisPromoUrl = `https://be.synxis.com/?arrive=${props.arriveDate}&chain=${props.chainId}&depart=${props.departDate}&agencyid=${props.AgentId}&level=chain&nights=3&group=${props.promoCode}`;
			window.open(synxisPromoUrl, '_blank');
		}
		setToggleContent(false);
		props.setPromoCodeType('PR');
		props.savePromoCodeDatas('');
		props.saveAgentId('');
	}
	function onClearBtnCallBack() {
		if (props.onClearCallback) props.onClearCallback();
		if (props.onChangeCallBack) props.onChangeCallBack();
		setToggleContent(false);
		props.setPromoCodeType('PR');
		props.savePromoCodeDatas('');
		props.saveAgentId('');
	}
	return (
		<div className="rsFilterBarDropDown">
			<Box
				className={`rsDropdownBtn ${props.className || ''}`}
				onClick={() => setToggleContent((prevState) => !prevState)}
				height={props.removeFilterByLabel || props.isPromotion ? '42px' : 'auto'}
			>
				<Box id="labelContainer">
					{!props.removeFilterByLabel && (
						<Label variant="caption" className="filterByLabel" paddingBottom={4}>
							{/* {props.fieldName ? props.fieldName : props.isSortField ? 'Sort By' : 'Filter By'} */}
							{props.fieldName
								? props.fieldName
								: !props.isPromotion && !props.isSortField
								? 'Filter By'
								: ' '}
						</Label>
					)}
					<Label className="filterTitleLabel" variant={props.isSortField ? 'body1' : 'h6'} lineClamp={1}>
						{props.title}
					</Label>
				</Box>
				<Box
					className={classNames('chevronIcon iconSpin', {
						down: props.hasIconSpinAnimation && toggleContent,
						up: props.hasIconSpinAnimation && !toggleContent
					})}
				>
					<Icon iconImg={props.icon ? props.icon : `icon-chevron-thin-down`} size={25} color={'#000000BD'} />
				</Box>
			</Box>
			<Box
				boxRef={modalRef}
				className={`dropdownContent ${toggleContent ? 'show' : 'hide'} ${props.dropdownContentClassName || ''}`}
			>
				{props.children}
				{!props.isSortField && (
					<>
						<hr />
						<Box className={`dropdownFooter ${props.dropdownFooterClass}`} padding="24px">
							<LabelButton variant="buttonSm" label="Clear" look="none" onClick={onClearBtnCallBack} />
							<LabelButton
								variant="buttonSm"
								label="Apply"
								look="containedPrimary"
								onClick={applyBtnCallback}
								disabled={props.chainId === undefined && props.hide === true}
							/>
						</Box>
					</>
				)}
			</Box>
		</div>
	);
};

export default FilterBarDropDown;
