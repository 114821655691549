import React, { useEffect, Dispatch } from 'react';
import './mapModal.scss';
import { Wrapper, Status } from '@googlemaps/react-wrapper';
import Map from '../../../../components/googleMap/gMap';
import Icon from '../../../../lib/@bit/redsky.framework.rs.icon';
import { get } from 'lodash';

interface MapModalProps {
	destinationDetails: Api.Destination.Res.Details;
	setShowMapModal: Dispatch<React.SetStateAction<boolean>>;
	GOOGLE_MAPS_API: string;
	renderGoogleMapsRenderProp: (status: Status) => React.ReactElement<any, string | React.JSXElementConstructor<any>>;
}

const MapModal: React.FC<MapModalProps> = (props) => {
	return (
		<>
			<div className="mapModalContainer animate__animated animate__fadeIn">
				<div className="mapModal-Header-container">
					<div title="Close Map">
						<Icon
							iconImg={'icon-close'}
							className={'mapModal-closeBtn'}
							onClick={() => {
								document.body.classList.remove('body-overall-container');
								props.setShowMapModal(false);
							}}
							size={14}
							cursorPointer
						/>
					</div>

					<div className="mapModal-title">{get(props, 'destinationDetails.name')}</div>
				</div>

				<div className="mapModal-Body-container">
					<Wrapper apiKey={props.GOOGLE_MAPS_API} render={props.renderGoogleMapsRenderProp}>
						<Map
							style={{ width: '100%', height: '100%' }}
							onClickPicker={() => null}
							destinationDetails={props.destinationDetails}
							currentLocation={{
								latitude:
									props.destinationDetails.latitude !== undefined
										? props.destinationDetails.latitude
										: 0,
								longitude:
									props.destinationDetails.longitude !== undefined
										? props.destinationDetails.longitude
										: 0
							}}
						/>
					</Wrapper>
				</div>
			</div>
		</>
	);
};

export default MapModal;
