import { Service } from '../Service';
import http, { microServiceHttp } from '../../utils/http';
import { RsResponseData } from '../../lib/@bit/redsky.framework.rs.http';
import { NumberUtils } from '../../utils/utils';

export default class DestinationService extends Service {
	async getDestinations(): Promise<Api.Destination.Res.Details[]> {
		let response = await http.get<Api.Destination.Res.Details[]>('destination/paged');
		return response.data;
	}
	async getDestinationById(id: Api.Destination.Req.Get): Promise<Api.Destination.Res.Get> {
		let response = await http.get<RsResponseData<Api.Destination.Res.Get>>('destination', id);
		return response.data.data;
	}

	async getDestinationByIds({ ids }: Api.Destination.Req.Get): Promise<Api.Destination.Res.Get[]> {
		let response = await http.get<RsResponseData<Api.Destination.Res.Get[]>>('destination', ids);
		return response.data.data;
	}
	async getDestinationDetails(data: Api.Destination.Req.Details): Promise<Api.Destination.Res.Details> {
		let response = await http.get<RsResponseData<Api.Destination.Res.Details>>('destination/details', data);
		return response.data.data;
	}

	async getFavouriteDestinations(companyId?: number): Promise<any> {
		let response = await http.get<RsResponseData<any>>('destination/favouritedestinations');
		return response.data.data;
	}

	async searchAvailableReservations(
		data: Api.Destination.Req.Availability
	): Promise<RedSky.RsPagedResponseData<Api.Destination.Res.AvailabilitySummary[]>> {
		if (
			(data.redeemPoints && data.priceRangeMax === 1430000) ||
			(!data.redeemPoints && data.priceRangeMax === 1000)
		)
			delete data.priceRangeMax;
		if (!data.redeemPoints && data.priceRangeMin)
			data.priceRangeMin = NumberUtils.dollarsToCents(data.priceRangeMin);
		if (!data.redeemPoints && data.priceRangeMax)
			data.priceRangeMax = NumberUtils.dollarsToCents(data.priceRangeMax);
		let key: keyof Api.Destination.Req.Availability;
		for (key in data) {
			if (data[key] === undefined) delete data[key];
		}
		// const response = await http.get<RedSky.RsPagedResponseData<Api.Destination.Res.AvailabilitySummary[]>>(
		// 	'destination/availability/summary',
		// 	data
		// );
		const response = await microServiceHttp.get<
			RedSky.RsPagedResponseData<Api.Destination.Res.AvailabilitySummary[]>
		>('destination-summary', data);
		return response.data;
	}

	async getAllPropertyTypes(): Promise<Api.Destination.Res.PropertyType[]> {
		let res = await http.get<RsResponseData<Api.Destination.Res.PropertyType[]>>('destination/allPropertyTypes');
		return res.data.data;
	}

	async getPropertyTypesForDestination(destinationId: number): Promise<Api.Destination.Res.PropertyType[]> {
		const destination = await this.getDestinationDetails({ destinationId });
		return destination.propertyTypes;
	}

	async getExperienceTypes(): Promise<Api.Experience.Res.Get[]> {
		const res = await http.get<RsResponseData<Api.Experience.Res.Get[]>>('experience');
		return res.data.data;
	}

	async getPublicSlim(): Promise<Api.Destination.Res.PublicSlim[]> {
		const res = await http.get<RsResponseData<Api.Destination.Res.PublicSlim[]>>('destination/public-slim');
		return res.data.data;
	}

	async getGroupPackages(id: any): Promise<any> {
		const res = await http.get<RsResponseData<any>>('destination/getGroupPackages', { id });
		return res.data.data;
	}

	async getDestinationReviews(
		data: Api.Destination.Req.DestinationReviews
	): Promise<RedSky.RsPagedResponseData<Api.Destination.Res.DestinationReviews[]>> {
		const response = await http.get<RedSky.RsPagedResponseData<Api.Destination.Res.DestinationReviews[]>>(
			'destination/review/list',
			data
		);
		return response.data;
	}

	async getPlatformVariables(): Promise<RedSky.RsPagedResponseData<Api.Company.Res.PlatformVariables[]>> {
		const response = await http.get<RedSky.RsPagedResponseData<Api.Company.Res.PlatformVariables[]>>(
			'company/platform-and-variables'
		);
		return response.data.data;
	}

	async getCampaignByDestination(
		destinationId: number,
		startDate: string | Date,
		endDate: string | Date,
		accommodationId: number,
		rateCode: string
	): Promise<RedSky.RsPagedResponseData<Api.Campaign.Res.CampaignsByDestination[]>> {
		const response = await http.get<RedSky.RsPagedResponseData<Api.Campaign.Res.CampaignsByDestination[]>>(
			'campaign/getbydestination',
			{
				destinationId,
				startDate,
				endDate,
				accommodationId,
				rateCode
			}
		);
		return response.data.data;
	}
}
