// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rsSlider{display:flex;align-items:center;height:50px}.rsSlider .sliderStyle{position:relative;width:100%;font-weight:100}.rsSlider img{max-width:100%;max-height:100%}.rsSlider .sliderIcon{width:100%;height:100%;position:absolute;top:0;bottom:0;left:0;right:0;display:flex;justify-content:center;align-items:center}.rsSlider .railStyle{position:absolute;width:100%;height:4px;border-radius:5px}.rsSlider .handlerStyle{position:absolute;margin:auto 0 auto -15px;z-index:2;width:25px;height:25px;textalign:center;cursor:pointer;background-color:white;top:0;bottom:0}.rsSlider .handlerStyle:hover{box-shadow:1px 3px 8px -4px rgba(0,0,0,0.3)}.rsSlider .trackStyle{position:absolute;z-index:0;border-radius:5px;cursor:pointer;height:4px;padding:0}.rsSlider.isDisabled .trackStyle{cursor:not-allowed}.rsSlider.isDisabled .handlerStyle{cursor:not-allowed}
`, "",{"version":3,"sources":["webpack://./src/lib/@bit/redsky.framework.rs.slider/dist/slider/Slider.scss"],"names":[],"mappings":"AAAA,UACC,YAAa,CACb,kBAAmB,CACnB,WAAY,CAHb,uBAME,iBAAkB,CAClB,UAAW,CACX,eAAgB,CARlB,cAYE,cAAe,CACf,eAAgB,CAblB,sBAiBE,UAAW,CACX,WAAY,CACZ,iBAAkB,CAClB,KAAM,CACN,QAAS,CACT,MAAO,CACP,OAAQ,CACR,YAAa,CACb,sBAAuB,CACvB,kBAAmB,CA1BrB,qBA8BE,iBAAkB,CAClB,UAAW,CACX,UAAW,CACX,iBAAkB,CAjCpB,wBAqCE,iBAAkB,CAClB,wBAAyB,CACzB,SAAU,CACV,UAAW,CACX,WAAY,CACZ,gBAAiB,CACjB,cAAe,CACf,sBAAuB,CACvB,KAAM,CACN,QAAS,CA9CX,8BAgDG,2CAA+C,CAhDlD,sBAqDE,iBAAkB,CAClB,SAAU,CACV,iBAAkB,CAClB,cAAe,CACf,UAAW,CACX,SAAU,CA1DZ,iCA+DG,kBAAmB,CA/DtB,mCAmEG,kBAAmB","sourcesContent":[".rsSlider {\n\tdisplay: flex;\n\talign-items: center;\n\theight: 50px;\n\n\t.sliderStyle {\n\t\tposition: relative;\n\t\twidth: 100%;\n\t\tfont-weight: 100;\n\t}\n\n\timg {\n\t\tmax-width: 100%;\n\t\tmax-height: 100%;\n\t}\n\n\t.sliderIcon {\n\t\twidth: 100%;\n\t\theight: 100%;\n\t\tposition: absolute;\n\t\ttop: 0;\n\t\tbottom: 0;\n\t\tleft: 0;\n\t\tright: 0;\n\t\tdisplay: flex;\n\t\tjustify-content: center;\n\t\talign-items: center;\n\t}\n\n\t.railStyle {\n\t\tposition: absolute;\n\t\twidth: 100%;\n\t\theight: 4px;\n\t\tborder-radius: 5px;\n\t}\n\n\t.handlerStyle {\n\t\tposition: absolute;\n\t\tmargin: auto 0 auto -15px;\n\t\tz-index: 2;\n\t\twidth: 25px;\n\t\theight: 25px;\n\t\ttextalign: center;\n\t\tcursor: pointer;\n\t\tbackground-color: white;\n\t\ttop: 0;\n\t\tbottom: 0;\n\t\t&:hover {\n\t\t\tbox-shadow: 1px 3px 8px -4px rgba(0, 0, 0, 0.3);\n\t\t}\n\t}\n\n\t.trackStyle {\n\t\tposition: absolute;\n\t\tz-index: 0;\n\t\tborder-radius: 5px;\n\t\tcursor: pointer;\n\t\theight: 4px;\n\t\tpadding: 0;\n\t}\n\n\t&.isDisabled {\n\t\t.trackStyle {\n\t\t\tcursor: not-allowed;\n\t\t}\n\n\t\t.handlerStyle {\n\t\t\tcursor: not-allowed;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
