import React, { useState } from 'react';
import { Box, Popup, popupController, PopupProps } from '../../lib/@bit/redsky.framework.rs.996';
import './VerifyEmailpopup.scss';
import Paper from '../../components/paper/Paper';
import Label from '../../lib/@bit/redsky.framework.rs.label';
import useIsAtBreakpoint from '../../customHooks/useIsAtBreakpoint';
import UserService from '../../services/user/user.service';
import serviceFactory from '../../services/serviceFactory';
import SpinningLoaderPopup from '../spinningLoaderPopup/SpinningLoaderPopup';
import { rsToastify } from '../../lib/@bit/redsky.framework.rs.toastify';
import Icon from '../../lib/@bit/redsky.framework.rs.icon';

export interface VerifyEmailPopupProps extends PopupProps {
	email: string;
	fromLogin: boolean;
}

const VerifyEmailPopup: React.FC<VerifyEmailPopupProps> = (props) => {
	const isMobile = useIsAtBreakpoint();
	const fullProps = popupController.convertProps(props);

	const [count, setCount] = useState<number>(0);
	const userService = serviceFactory.get<UserService>('UserService');

	// /user/resend/verification/mail call api

	const sendAgain = async () => {
		try {
			setCount(count + 1);
			popupController.open(SpinningLoaderPopup);
			let response = await userService.resendVerificationMail(props.email.toString());
			rsToastify.success('Verification Mail send successfully', 'Success!');
			popupController.close(SpinningLoaderPopup);
		} catch (e) {
			popupController.close(SpinningLoaderPopup);
			console.log(e);

			rsToastify.error('Email and token does not match!', 'Error!');
		}
	};
	return (
		<Popup {...props}>
			<Paper className={'verifyEmailPopup'}>
				<Box className={'titleContainer'}>
					<Label variant={'h5'} className={'primaryTextColor'}>
						{'Verify your email'}
					</Label>
					<Icon
						className={'closeIcon'}
						iconImg={'icon-close'}
						size={16}
						onClick={() => {
							popupController.closeById(fullProps.popupId);
						}}
					/>
				</Box>
				{props.fromLogin ? (
					<Box className={'body'} textAlign={'center'}>
						<div className="imageContainer">
							<img src={require('../../images/verify-email.png')} className="image" />
						</div>
						<Label marginTop={15} textAlign="center">
							Your email address is not verified. Please verify first to active your account.
						</Label>
						{count <= 2 ? (
							<Label marginTop={15} textAlign="center" variant="p">
								<a className="clickBtn" onClick={count <= 2 ? sendAgain : () => {}}>
									Click here
								</a>
								&nbsp; if you did not receive an email.
							</Label>
						) : null}
					</Box>
				) : (
					<Box className={'body'} textAlign={'center'}>
						<div className="imageContainer">
							<img src={require('../../images/verify-email.png')} className="image" />
						</div>

						<Label marginTop={15} textAlign="center">
							We have send an email to <b>{props.email}</b> to verify your email address and active your
							account. The link in the email will expire in 24 hours.
						</Label>
						{count <= 2 ? (
							<Label marginTop={15} textAlign="center" variant="p">
								<a className="clickBtn" onClick={count <= 2 ? sendAgain : () => {}}>
									Click here
								</a>{' '}
								if you did not receive an email.
							</Label>
						) : null}
					</Box>
				)}
			</Paper>
		</Popup>
	);
};

export default VerifyEmailPopup;
