import React, { useLayoutEffect, useEffect } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';

// The following components need to be added to the top level dom since they are full screen overlays
import popupController from './lib/@bit/redsky.framework.rs.996/dist/popupController';
import globalState, { setRecoilExternalValue, getRecoilExternalValue } from './state/globalState';
import DestinationService from './services/destination/destination.service';
import useFlipTo from './customHooks/useFlipTo';
import serviceFactory from './services/serviceFactory';
import { WebUtils } from './utils/utils';
import useIsAtBreakpoint from './customHooks/useIsAtBreakpoint';
import DestinationDetailsMobilePopup, {
	DestinationDetailsMobilePopupProps
} from './popups/destinationDetailsMobilePopup/DestinationDetailsMobilePopup';
import UserService from './services/user/user.service';
import ChatbotService from './services/chatBot/chatbot.service';
import { useRecoilState } from 'recoil';

function Applayer() {
	let navigate = useNavigate();
	const userService = serviceFactory.get<UserService>('UserService');
	const chatbot = serviceFactory.get<ChatbotService>('ChatbotService');
	const destinationService: DestinationService = serviceFactory.get<DestinationService>('DestinationService');
	const isMobile = useIsAtBreakpoint();
	const location = useLocation();
	const [user, setUser] = useRecoilState<Api.User.Res.Detail | undefined>(globalState.user);
	const [searchParams, setSearchParams] = useSearchParams();
	// Do Not Remove - This is a helpful tool to use to debug the browser logs in you developers console. Uncomment to use.
	// @ts-ignore
	// window.showBrowseLogs = process.env.NODE_ENV === 'development';

	useLayoutEffect(() => {
		const userToken: string = searchParams.get('user')?.toString() || '';

		(async function setReservationFilters() {
			const newFilters = WebUtils.parseURLParamsToFilters();
			const destinationId = await getDestinationIdFromParams(newFilters);

			if (!!userToken) {
				await userService.loginUserByToken(userToken);
				if (searchParams?.has('user')) {
					searchParams.delete('user');
					setSearchParams(searchParams);
				}
			}

			setRecoilExternalValue<Misc.ReservationFilters>(globalState.reservationFilters, {
				...newFilters,
				destinationId,
				init: true
			});

			if (destinationId && isMobile) {
				popupController.open<DestinationDetailsMobilePopupProps>(DestinationDetailsMobilePopup, {
					destinationId: destinationId,
					resortPoint: 0,
					resortPointsPrice: 0,
					startDate: newFilters.startDate as string,
					endDate: newFilters.endDate as string
				});
			}
		})().catch(console.error);
	}, []);

	useEffect(() => {
		//  Flip.to - load on everypage
		useFlipTo.loadFlipToScript();
		chatbot.getChatbot();
	}, [location.pathname, user]);

	async function getDestinationIdFromParams(reservationFilter: Misc.ReservationFilters) {
		const results: Api.Destination.Res.PublicSlim[] = await destinationService.getPublicSlim();
		const destinationId = results.find(
			(result) => parseInt(result?.externalSystemId?.toString()) === reservationFilter.hotelId
		)?.id;

		const destinationName = results
			.find((result) => parseInt(result?.externalSystemId?.toString()) === reservationFilter.hotelId)
			?.name.split(' ')
			.join('-')
			.toLocaleLowerCase();

		if (destinationId) {
			navigate(`/destination/${destinationName}`, {
				state: {
					reservationFilter,
					destinationId: destinationId
				}
			});
			// navigate(`/destination/details${WebUtils.createUrlParams(reservationFilter)}`);
			return destinationId;
		}
		return reservationFilter.destinationId;
	}

	return <>{popupController.instance} </>;
}
export default Applayer;
