import * as React from 'react';
import './CallToActionPopup.scss';
import { useEffect } from 'react';
import { Box, Popup, popupController } from '../../lib/@bit/redsky.framework.rs.996';
import Label from '../../lib/@bit/redsky.framework.rs.label/dist/Label';
import Paper from '../../components/paper/Paper';
import Icon from '../../lib/@bit/redsky.framework.rs.icon';
import { PopupProps } from '../../lib/@bit/redsky.framework.rs.996/dist/popup/Popup';
import Button from '../../lib/@bit/redsky.framework.rs.button';
import router from '../../utils/router';
import LabelButton from '../../components/labelButton/LabelButton';

export interface CallToActionPopupProps extends PopupProps {
	successCallback?: () => void;
}

const CallToActionPopup: React.FC<CallToActionPopupProps> = (props) => {
	const fullProps = popupController.convertProps(props);

	useEffect(() => {
		window.history.pushState(null, 'CallToActionPopup Close', window.location.pathname + window.location.search);
		window.addEventListener('popstate', (e: PopStateEvent) => {
			e.preventDefault();
			popupController.close(CallToActionPopup);
		});
		const id = router.subscribeToBeforeRouterNavigate(async () => {
			router.getCurrentPath();
			return true;
		});
		return () => {
			router.unsubscribeFromBeforeRouterNavigate(id);
			window.removeEventListener('popstate', (e: PopStateEvent) => {
				e.preventDefault();
				popupController.close(CallToActionPopup);
			});
		};
	}, []);

	return (
		<Popup {...props} preventCloseByBackgroundClick>
			<Paper className={'rsCallToActionPopup'} position={'relative'}>
				<Icon
					iconImg={'icon-close'}
					onClick={() => {
						popupController.closeById(fullProps.popupId);
					}}
					size={16}
					className={'closeBtn'}
					cursorPointer
				/>
				<Box className={'popupTitle'}>
					<Label variant={'h5'} className={'primaryTextColor'}>
						Hello!
					</Label>
				</Box>
				<Box className={'popupBody'}>
					<div className={'girlContanar'}>
						<div>
							<p className={'girlContanarDis'}>
								We see this destination has limited
								<br />
								availability for the dates you selected.
								<br />
								Would you like to speak with a reservation
								<br />
								specialist to help you explore all options.
							</p>
							<LabelButton
								className={'yellow numberCall'}
								look={'containedPrimary'}
								onClick={() => {
									window.open(`tel:+321-888-3509`, '_self');
								}}
								label={'321-888-3509'}
								variant={'buttonSm'}
							/>
							{/* <LabelButton
								className={'NoThankYouText'}
								look={'none'}
								variant={'h6'}
								label={'No, Thank You'}
								onClick={() => {
									popupController.close(CallToActionPopup);
									// popupController.open<ForgotPasswordPopupProps>(ForgotPasswordPopup);
								}}
							/> */}
							<Button
								look={'none'}
								className={'NoThankYouText'}
								onClick={() => {
									popupController.close(CallToActionPopup);
								}}
							>
								<Label variant={'buttonSm'}>No, Thank You</Label>
							</Button>
						</div>
						<div className={'PopupGirlblock'}>
							<img className={'PopupGirl'} src={require('../../images/PopupGirl.png')} />
						</div>
					</div>
				</Box>
			</Paper>
		</Popup>
	);
};

export default CallToActionPopup;
