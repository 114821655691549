import * as React from 'react';
import './ComparisonPopup.scss';
import { Box, Popup, popupController, PopupProps } from '../../lib/@bit/redsky.framework.rs.996';
import Paper from '../../components/paper/Paper';
import Icon from '../../lib/@bit/redsky.framework.rs.icon';
import Label from '../../lib/@bit/redsky.framework.rs.label';
import Select from '../../lib/@bit/redsky.framework.rs.select';
import { RsFormControl } from '../../lib/@bit/redsky.framework.rs.form';
import { useEffect, useState } from 'react';
import useIsAtBreakpoint from '../../customHooks/useIsAtBreakpoint';
import { useRecoilValue } from 'recoil';
import globalState from '../../state/globalState';
import serviceFactory from '../../services/serviceFactory';
import AccommodationService from '../../services/accommodation/accommodation.service';
import Img from '../../lib/@bit/redsky.framework.rs.img';
import ComparisonService from '../../services/comparison/comparison.service';
import { IKImage } from 'imagekitio-react';
import { getPageFinder, undefinedHandler } from '../../utils/undefinedHandler';
import CarouselV2 from '../../components/carouselV2/CarouselV2';

export interface ComparisonPopupProps extends PopupProps {}
const ComparisonPopup: React.FC<ComparisonPopupProps> = (props) => {
	const isMobile = useIsAtBreakpoint();
	const comparisonService = serviceFactory.get<ComparisonService>('ComparisonService');
	const comparisonState = useRecoilValue<Misc.ComparisonState>(globalState.destinationComparison);
	const accommodationService = serviceFactory.get<AccommodationService>('AccommodationService');
	const [accommodations, setAccommodations] = useState<Api.Accommodation.Res.Details[]>([]);
	const fullProps = popupController.convertProps(props);

	useEffect(() => {
		window.history.pushState(null, 'ComparisonPopup Close', window.location.pathname);
		window.addEventListener('popstate', (e: PopStateEvent) => {
			e.preventDefault();
			popupController.close(ComparisonPopup);
		});
		return () => {
			window.removeEventListener('popstate', (e: PopStateEvent) => {
				e.preventDefault();
				popupController.close(ComparisonPopup);
			});
		};
	}, []);

	useEffect(() => {
		document.body.style.overflow = 'hidden';
		document.body.style.top = '0';
		return () => {
			document.body.style.overflow = 'unset';
			document.body.style.top = 'unset';
		};
	}, []);
	useEffect(() => {
		async function getAccommodations() {
			try {
				const result = await accommodationService.getManyAccommodationDetails(
					comparisonState.destinationDetails.map((item) => item.selectedAccommodationId)
				);
				setAccommodations(result);
			} catch (error: any) {
				const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
				undefinedHandler(message);
			}
		}
		getAccommodations().catch(console.error);
	}, [comparisonState]);

	function getImageUrls(accommodation: any): string[] {
		try {
			if (accommodation?.media) {
				let images = accommodation?.media.sort((a: any, b: any) => a.mediaIndex - b.mediaIndex);
				images.sort((a: any, b: any) => {
					return b.isPrimary - a.isPrimary;
				});

				return images.map((urlObj: any) => {
					return urlObj.urls.large?.toString() || urlObj.urls.thumb;
				});
			}
			return [];
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
			return [];
		}
	}

	function renderImageRow() {
		let row: React.ReactNodeArray = [
			<div />,
			...accommodations.map((accommodation, index) => {
				const urls = getImageUrls(accommodation);

				return (
					<div className={'carouselWrapper'} key={accommodation.id}>
						{/* <IKImage
							src={
								accommodation.media.find((image) => image.isPrimary)?.urls.imageKit ||
								accommodation.media[0].urls.imageKit
							}
							alt={accommodation.name}
							width={300}
							height={300}
							loading="lazy"
						/> */}

						<CarouselV2
							path={() => {}}
							// resortPoint={props?.resortPoint}
							imgPaths={urls}
							imageWidth={416}
							hideCompareButton={true}
							onGalleryClick={() => {
								try {
									// if (isMobile) {
									// 	popupController.open<MobileLightBoxProps>(MobileLightBox, {
									// 		imageData: props?.accommodation.media
									// 	});
									// } else {
									// 	popupController.open<TabbedCarouselPopupProps>(LightBoxCarouselPopup, {
									// 		imageData: props?.accommodation.media.sort(
									// 			(a: any, b: any) => a.mediaIndex - b.mediaIndex
									// 		)
									// 	});
									// }
								} catch (error: any) {
									const message = `Getting error :${error.message} on ${getPageFinder(
										window.location.pathname
									)} page.`;
									undefinedHandler(message);
								}
							}}
						/>
					</div>
				);
			})
		];
		return <Box className={'row images'}>{row}</Box>;
	}

	function renderTitleRow() {
		let row: React.ReactNodeArray = [
			<div />,
			...accommodations.map((accommodation, index) => {
				return (
					<Label variant={isMobile ? 'caption3' : 'h5'} paddingLeft={isMobile ? '0' : '16px'}>
						{comparisonState.destinationDetails[index].title}
					</Label>
				);
			})
		];
		return <Box className={'row resortName'}>{row}</Box>;
	}

	function renderPropertyTypeRow() {
		let row: React.ReactNodeArray = [
			<Label className={'title'} variant={isMobile ? 'customEight' : 'customSeventeen'}>
				Property type
			</Label>,
			...accommodations.map((accommodation, index) => {
				return (
					<Select
						key={index}
						control={new RsFormControl('', accommodation.id, [])}
						options={comparisonState.destinationDetails[index].accommodationOptions}
						updateControl={(control) => {
							comparisonService.changeAccommodation(
								control.value as number,
								comparisonState.destinationDetails[index].destinationId
							);
						}}
					/>
				);
			})
		];
		return <Box className={'row propertyType'}>{row}</Box>;
	}

	function renderGuestLimitRow() {
		let row: React.ReactNodeArray = [
			<Label className={'title'} variant={isMobile ? 'customEight' : 'customSeventeen'}>
				Guest limit
			</Label>,
			...accommodations.map((accommodation, index) => {
				return (
					<Label key={index} variant={isMobile ? 'customEight' : 'customSeventeen'}>
						{accommodation.maxOccupantCount}
					</Label>
				);
			})
		];
		return <Box className={'row guestLimit'}>{row}</Box>;
	}

	function renderExtraBeddingRow() {
		let row: React.ReactNodeArray = [
			<Label className={'title'} variant={isMobile ? 'customEight' : 'customSeventeen'}>
				Extra bedding
			</Label>,
			...accommodations.map((accommodation, index) => {
				return (
					<Label key={index} variant={isMobile ? 'customEight' : 'customSeventeen'}>
						{accommodation.extraBeds ? 'yes' : 'no'}
					</Label>
				);
			})
		];
		return <Box className={'row extraBedding'}>{row}</Box>;
	}

	function renderAccessibilityRow() {
		let row: React.ReactNodeArray = [
			<Label className={'title'} variant={isMobile ? 'customEight' : 'customSeventeen'}>
				Accessible
			</Label>,
			...accommodations.map((accommodation, index) => {
				return (
					<Label key={index} variant={isMobile ? 'customEight' : 'customSeventeen'}>
						{accommodation.adaCompliant ? 'yes' : 'no'}
					</Label>
				);
			})
		];
		return <Box className={'row accessibility'}>{row}</Box>;
	}

	function renderFeaturesRow() {
		let row: React.ReactNodeArray = [
			<Label className={'title'} variant={isMobile ? 'customEight' : 'customSeventeen'}>
				Features
			</Label>,
			...accommodations.map((accommodation, index) => {
				return (
					<Label variant={isMobile ? 'customEight' : 'customSeventeen'} key={index}>
						<ul>
							{accommodation.amenities.map((amenity, idx) => {
								return <li key={idx}>{amenity.title}</li>;
							})}
						</ul>
					</Label>
				);
			})
		];
		return <Box className={'row features'}>{row}</Box>;
	}

	function renderOverviewRow() {
		let row: React.ReactNodeArray = [
			<Label className={'title'} variant={isMobile ? 'customEight' : 'customSeventeen'}>
				Overview
			</Label>,
			...accommodations.map((accommodation, index) => {
				return (
					<Label key={index} variant={isMobile ? 'customEight' : 'customSeventeen'}>
						{accommodation.longDescription}
					</Label>
				);
			})
		];
		return <Box className={'row overview'}>{row}</Box>;
	}

	return (
		<Popup {...props} preventCloseByBackgroundClick>
			<Paper className={'rsComparisonPopup'}>
				<Box className={'popupTitle'}>
					<Label variant={'h4'}>Compare</Label>
					<Icon
						iconImg={'icon-close'}
						onClick={() => {
							popupController.closeById(fullProps.popupId);
						}}
						cursorPointer
					/>
				</Box>
				<Box className={'comparisonTable'}>
					{renderImageRow()}
					{renderTitleRow()}
					{renderPropertyTypeRow()}
					{renderGuestLimitRow()}
					{renderExtraBeddingRow()}
					{renderAccessibilityRow()}
					{renderFeaturesRow()}
					{renderOverviewRow()}
				</Box>
			</Paper>
		</Popup>
	);
};
export default ComparisonPopup;
