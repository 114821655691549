import * as React from 'react';
import './ComparisonDrawer.scss';
import useIsAtBreakpoint from '../../customHooks/useIsAtBreakpoint';
import ResortComparisonCard from '../../components/resortComparisonCard/ResortComparisonCard';
import LabelButton from '../../components/labelButton/LabelButton';
import { useRecoilState } from 'recoil';
import globalState from '../../state/globalState';
import { ObjectUtils } from '../../utils/utils';
import { Box, popupController } from '../../lib/@bit/redsky.framework.rs.996';
import Icon from '../../lib/@bit/redsky.framework.rs.icon';
import Button from '../../lib/@bit/redsky.framework.rs.button';
import Label from '../../lib/@bit/redsky.framework.rs.label/dist/Label';
import ComparisonPopup, { ComparisonPopupProps } from '../comparisonPopup/ComparisonPopup';
import { useEffect } from 'react';
import themes from '../../themes/themes.scss';
import classNames from 'classnames';

const ComparisonDrawer: React.FC = () => {
	const isMobile = useIsAtBreakpoint();
	const [recoilComparisonState, setRecoilComparisonState] = useRecoilState<Misc.ComparisonState>(
		globalState.destinationComparison
	);

	const [destinationComparisonError, setDestinationComparisonError] = useRecoilState<boolean>(
		globalState.destinationComparisonError
	);

	useEffect(() => {
		setRecoilComparisonState((prev) => {
			return {
				destinationDetails: prev.destinationDetails,
				showCompareButton: true
			};
		});
	}, []);

	function renderComparisonCard() {
		if (isMobile) return;
		if (
			!ObjectUtils.isArrayWithData(recoilComparisonState.destinationDetails) ||
			recoilComparisonState.destinationDetails.length > 3
		) {
			return;
		}
		return recoilComparisonState.destinationDetails.map((item) => {
			return (
				<Box display={'flex'}>
					<ResortComparisonCard key={item.destinationId} destinationDetails={item} />
					<hr />
				</Box>
			);
		});
		return;
	}

	return (
		<Box
			className={classNames('rsComparisonDrawer', {
				show: ObjectUtils.isArrayWithData(recoilComparisonState.destinationDetails)
			})}
		>
			{!!recoilComparisonState && <Box display={'flex'}>{renderComparisonCard()}</Box>}

			<Box style={{ display: 'grid' }}>
				{!destinationComparisonError && (
					<p style={{ color: 'red', margin: 5 }}>Select a maximum of 3 resorts to compare</p>
				)}
				{isMobile ? (
					<Box className={'comparisonButtons'}>
						<LabelButton
							look={'containedPrimary'}
							variant={'body1'}
							label={'Compare'}
							disabled={
								isMobile &&
								recoilComparisonState.showCompareButton &&
								recoilComparisonState.destinationDetails.length < 2
							}
							onClick={() => {
								if (
									ObjectUtils.isArrayWithData(recoilComparisonState.destinationDetails) &&
									recoilComparisonState.destinationDetails.length > 1
								) {
									popupController.open<ComparisonPopupProps>(ComparisonPopup, {});
								}
							}}
						>
							{!ObjectUtils.isArrayWithData(recoilComparisonState.destinationDetails) ? (
								<div className={'plusCompareIcon'}>
									<Icon iconImg={'icon-plus'} size={13} color={themes.white} />
								</div>
							) : (
								<Label variant={'caption1'}>{recoilComparisonState.destinationDetails.length}</Label>
							)}
						</LabelButton>
						{(recoilComparisonState.showCompareButton ||
							ObjectUtils.isArrayWithData(recoilComparisonState.destinationDetails)) && (
							<Button
								className={'clearButton'}
								look={'none'}
								onClick={() => {
									setRecoilComparisonState({ ...recoilComparisonState, destinationDetails: [] });
								}}
							>
								<Icon iconImg={'icon-close'} color={themes.white} />
							</Button>
						)}
					</Box>
				) : (
					<LabelButton
						label={'Compare Resorts'}
						variant={'buttonMdLg'}
						look={'containedPrimary'}
						className={'yellow'}
						onClick={() => {
							popupController.open<ComparisonPopupProps>(ComparisonPopup, {});
						}}
						disabled={recoilComparisonState.destinationDetails.length < 2}
					/>
				)}
			</Box>
		</Box>
	);
};

export default ComparisonDrawer;
