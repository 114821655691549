import * as React from 'react';
import './ExistingItineraryPage.scss';
import { Link, Page } from '../../lib/@bit/redsky.framework.rs.996';
import ItineraryCard from '../../components/itineraryCard/ItineraryCard';
import { useRecoilValue } from 'recoil';
import globalState from '../../state/globalState';
import { useEffect, useState } from 'react';
import serviceFactory from '../../services/serviceFactory';
import ReservationsService from '../../services/reservations/reservations.service';
import { ObjectUtils } from '../../lib/@bit/redsky.framework.rs.utils';
import { rsToastify } from '../../lib/@bit/redsky.framework.rs.toastify';
import { DateUtils, StringUtils, WebUtils } from '../../utils/utils';
import Label from '../../lib/@bit/redsky.framework.rs.label/dist/Label';
import useIsAtBreakpoint from '../../customHooks/useIsAtBreakpoint';
import SubNavMenu from '../../components/subNavMenu/SubNavMenu';
import Paper from '../../components/paper/Paper';
import PaginationViewMore from '../../components/paginationViewMore/PaginationViewMore';
import Loader from '../../components/loader/Loader';
import useGetCountryList from '../../customHooks/useGetCountryList';
import itineraryCard from '../../components/itineraryCard/ItineraryCard';
import { useNavigate } from 'react-router-dom';
import { get } from 'lodash';
import { getPageFinder, undefinedHandler } from '../../utils/undefinedHandler';

const ExistingItineraryPage: React.FC = () => {
	let navigate = useNavigate();
	const user = useRecoilValue<Api.User.Res.Get | undefined>(globalState.user);
	const isMobile = useIsAtBreakpoint();
	const countryList = useGetCountryList();
	const reservationService = serviceFactory.get<ReservationsService>('ReservationsService');
	const [loading, setLoading] = useState<boolean>(true);
	const [itineraries, setItineraries] = useState<Api.Reservation.Res.Itinerary.Get[]>([]);
	const [totalItineraries, setTotalItineraries] = useState<number>(0);
	const [upcomingItineraries, setUpcomingItineraries] = useState<Api.Reservation.Res.Itinerary.Get[]>([]);
	const [cancelledItineraries, setCancelledItineraries] = useState<Api.Reservation.Res.Itinerary.Get[]>([]);
	const [previousItineraries, setPreviousItineraries] = useState<Api.Reservation.Res.Itinerary.Get[]>([]);
	const [pagination, setPagination] = useState<RedSky.PagePagination>({ page: 1, perPage: 10 });
	const filter: RedSky.FilterQuery = {
		matchType: 'exact',
		searchTerm: [
			{
				column: 'userId',
				value: get(user, 'id', '')
			}
		]
	};
	const sort: RedSky.SortQuery = {
		field: 'reservation.createdOn',
		order: 'DESC'
	};

	useEffect(() => {
		if (!user) {
			navigate('/reservation-not-found');
			return;
		}

		async function getReservationsForUser() {
			setLoading(true);
			try {
				let res = await reservationService.getByPage({ pagination, sort, filter });
				let cancelledRes = await reservationService.getCancelledReservation({ pagination, sort, filter });

				if (pagination.page === 1) {
					setItineraries(res.data);
					setCancelledItineraries(cancelledRes.data);
				} else {
					setItineraries((prev) => {
						return [...prev, ...res.data];
					});
					setCancelledItineraries((prev) => {
						return [...prev, ...cancelledRes.data];
					});
				}
				setTotalItineraries(res.total || 0);
			} catch (e: any) {
				console.error(e);
				const message = `Getting error :${e.message} on ${getPageFinder(window.location.pathname)} page.`;
				undefinedHandler(message);
			}
			setLoading(false);
		}
		getReservationsForUser().catch(console.error);
	}, [pagination]);

	useEffect(() => {
		try {
			if (!ObjectUtils.isArrayWithData(itineraries)) return;
			let prevItineraries = itineraries.filter((itinerary) => {
				let sortedStays = itinerary.stays;
				if (sortedStays === null) return [];
				sortedStays.sort(
					(stay1, stay2) => new Date(stay2.departureDate).getTime() - new Date(stay1.departureDate).getTime()
				);
				let date = DateUtils.serverToClientDate(sortedStays[0].departureDate as string);

				return date.getTime() < Date.now();
			});

			let currentItineraries = itineraries.filter((itinerary) => {
				let sortedStays = itinerary.stays;

				if (sortedStays === null) return [];
				sortedStays.sort(
					(stay1, stay2) => new Date(stay2.departureDate).getTime() - new Date(stay1.departureDate).getTime()
				);
				let date = DateUtils.serverToClientDate(sortedStays[0].departureDate as string);
				return date.getTime() >= Date.now();
			});

			// let prevItineraries = itineraries.filter((itinerary) => {
			// 	let sortedStays = itinerary.stays;
			// 	if (sortedStays === null) return false; // Return false to exclude itinerary
			// 	sortedStays.sort(
			// 		(stay1, stay2) => new Date(stay2.departureDate).getTime() - new Date(stay1.departureDate).getTime()
			// 	);
			// 	let date = DateUtils.serverToClientDate(sortedStays[0].departureDate as string);
			// 	return date.getTime() < Date.now();
			// });

			// let currentItineraries = itineraries.filter((itinerary) => {
			// 	let sortedStays = itinerary.stays;
			// 	if (sortedStays === null) return false; // Return false to exclude itinerary
			// 	sortedStays.sort(
			// 		(stay1, stay2) => new Date(stay2.departureDate).getTime() - new Date(stay1.departureDate).getTime()
			// 	);
			// 	let date = DateUtils.serverToClientDate(sortedStays[0].departureDate as string);
			// 	return date.getTime() >= Date.now();
			// });

			setUpcomingItineraries(currentItineraries);
			setPreviousItineraries(prevItineraries);
			setLoading(false);
		} catch (e: any) {
			rsToastify.error(
				WebUtils.getRsErrorMessage(e, 'There was a problem getting your reservations'),
				"Can't Get Reservations"
			);
			const message = `Getting error :${e.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}, [itineraries]);

	function renderUpcomingReservations() {
		try {
			if (!ObjectUtils.isArrayWithData(upcomingItineraries)) {
				return (
					<Label variant="h6" textAlign={'center'}>
						No Upcoming Reservations
					</Label>
				);
			}

			return upcomingItineraries.map((itinerary) => {
				let pointTotal = itinerary.stays.reduce((total, reservation) => {
					return total + reservation.priceDetail.grandTotalPoints;
				}, 0);
				let cashTotal = itinerary.stays.reduce((total, reservation) => {
					return total + reservation.priceDetail?.grandTotalCents;
				}, 0);
				let countryNameStr: string = '';
				if (itinerary.destination.country !== 'US') {
					let countryName: Api.Country.Res.Country | undefined = countryList.find(
						(item) => item.isoCode === itinerary.destination.country
					);
					if (!countryName) return;
					countryNameStr = countryName.name || '';
				}
				return (
					<ItineraryCard
						key={itinerary.stays[0].reservationId}
						destinationExperiences={itinerary.destination.experiences}
						itineraryId={itinerary.itineraryId}
						logo={itinerary.destination.logoUrl || ''}
						title={itinerary.destination.name}
						address={StringUtils.buildAddressString(itinerary.destination, countryNameStr) || ''}
						reservationDates={{
							startDate: itinerary.stays[0].arrivalDate,
							endDate: itinerary.stays[0].departureDate
						}}
						currencyCode={itinerary.stays[0].priceDetail?.currencyCode || ''}
						propertyType={'VIP Suite'}
						maxOccupancy={itinerary.stays[0].accommodation.maxOccupantCount}
						amenities={itinerary.stays[0].accommodation.featureIcons}
						totalPoints={pointTotal}
						linkPath={'/reservations/details?ri=' + itinerary.stays[0].reservationId}
						cancelPermitted={itinerary.stays[0].cancellationPermitted}
						itineraryTotal={cashTotal}
						paidWithPoints={!itinerary.paymentMethod}
						city={itinerary.destination.city}
						state={itinerary.destination.state}
						country={itinerary.destination.country}
						media={itinerary.destination.media}
					/>
				);
			});
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	function renderPrevReservations() {
		try {
			if (!ObjectUtils.isArrayWithData(previousItineraries)) {
				return (
					<Label variant="h6" textAlign={'center'}>
						No Past Reservations
					</Label>
				);
			}

			return previousItineraries.map((itinerary) => {
				let pointTotal = itinerary.stays.reduce((total, reservation) => {
					return total + reservation.priceDetail?.grandTotalPoints;
				}, 0);
				let cashTotal = itinerary.stays.reduce((total, reservation) => {
					return total + reservation.priceDetail?.grandTotalCents;
				}, 0);
				let countryNameStr: string = '';
				if (itinerary.destination.country !== 'US') {
					let countryName: Api.Country.Res.Country | undefined = countryList.find(
						(item) => item.isoCode === itinerary.destination.country
					);
					if (!countryName) return;
					countryNameStr = countryName.name || '';
				}

				return (
					<ItineraryCard
						key={itinerary.stays[0].reservationId}
						destinationExperiences={itinerary.destination.experiences}
						itineraryId={itinerary.itineraryId}
						logo={typeof itinerary.destination.logoUrl === 'string' ? itinerary.destination.logoUrl : ''}
						title={itinerary.destination.name}
						address={StringUtils.buildAddressString(itinerary.destination, countryNameStr) || ''}
						reservationDates={{
							startDate: itinerary.stays[0].arrivalDate,
							endDate: itinerary.stays[0].departureDate
						}}
						media={itinerary.destination.media}
						propertyType={'VIP Suite'}
						maxOccupancy={itinerary.stays[0].accommodation.maxOccupantCount}
						amenities={itinerary.stays[0].accommodation.featureIcons}
						totalPoints={pointTotal}
						linkPath={'/reservations/details?ri=' + itinerary.stays[0].reservationId}
						cancelPermitted={0}
						itineraryTotal={cashTotal}
						paidWithPoints={!itinerary.paymentMethod}
						city={itinerary.destination.city}
						state={itinerary.destination.state}
						country={itinerary.destination.country}
						currencyCode={itinerary.stays[0]?.priceDetail?.currencyCode || ''}
					/>
				);
			});
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	function renderCancelReservations() {
		try {
			if (!ObjectUtils.isArrayWithData(cancelledItineraries)) {
				return (
					<Label variant="h6" textAlign={'center'}>
						No Cancelled Reservations
					</Label>
				);
			}

			return cancelledItineraries.map((itinerary) => {
				let pointTotal = itinerary.stays.reduce((total, reservation) => {
					return total + reservation.priceDetail.grandTotalPoints;
				}, 0);
				let cashTotal = itinerary.stays.reduce((total, reservation) => {
					return total + reservation.priceDetail?.grandTotalCents;
				}, 0);
				let countryNameStr: string = '';
				if (itinerary.destination.country !== 'US') {
					let countryName: Api.Country.Res.Country | undefined = countryList.find(
						(item) => item.isoCode === itinerary.destination.country
					);
					if (!countryName) return;
					countryNameStr = countryName.name || '';
				}
				return (
					<ItineraryCard
						key={itinerary.stays[0].reservationId}
						destinationExperiences={itinerary.destination.experiences}
						itineraryId={itinerary.itineraryId}
						logo={itinerary.destination.logoUrl || ''}
						title={itinerary.destination.name}
						address={StringUtils.buildAddressString(itinerary.destination, countryNameStr) || ''}
						reservationDates={{
							startDate: itinerary.stays[0].arrivalDate,
							endDate: itinerary.stays[0].departureDate
						}}
						currencyCode={itinerary.stays[0].priceDetail?.currencyCode || ''}
						propertyType={'VIP Suite'}
						maxOccupancy={itinerary.stays[0].accommodation.maxOccupantCount}
						amenities={itinerary.stays[0].accommodation.featureIcons}
						totalPoints={pointTotal}
						linkPath={'/reservations/details?ri=' + itinerary.stays[0].reservationId}
						cancelPermitted={itinerary.stays[0].cancellationPermitted}
						itineraryTotal={cashTotal}
						paidWithPoints={!itinerary.paymentMethod}
						city={itinerary.destination.city}
						state={itinerary.destination.state}
						country={itinerary.destination.country}
						media={itinerary.destination.media}
					/>
				);
			});
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	return !loading && !ObjectUtils.isArrayWithData(itineraries) ? (
		<Page className={'rsExistingItineraryPage noAvailable'}>
			<Label variant={'h1'}>
				No Reservations Booked. <Link path={'/reservation/availability'}>Book Now</Link>
			</Label>
		</Page>
	) : (
		<Page className={'rsExistingItineraryPage'}>
			<SubNavMenu title={'Reservations'} navigateTo={'HOME'} />
			<Paper
				boxShadow
				borderRadius={'20px'}
				className="upcomingReservationSection upcomingReservationSectionResponsive"
			>
				<Label variant={'buttonMdLg'} marginBottom={isMobile ? '48px' : '54px'}>
					Upcoming reservations
				</Label>
				<div className={'wrapper'}>{renderUpcomingReservations()}</div>
			</Paper>
			{loading && <Loader />}
			<Paper className=" upcomingReservationSectionResponsive" boxShadow borderRadius={'20px'}>
				<Label variant={'buttonMdLg'} marginBottom={isMobile ? '48px' : '54px'} className={'pastStays'}>
					Past reservations
				</Label>
				<div className={'wrapper'}>{renderPrevReservations()}</div>
			</Paper>
			{loading && <Loader />}
			<Paper className=" upcomingReservationSectionResponsive" boxShadow borderRadius={'20px'}>
				<Label variant={'buttonMdLg'} marginBottom={isMobile ? '48px' : '54px'} className={'pastStays'}>
					Cancelled reservations
				</Label>
				<div className={'wrapper'}>{renderCancelReservations()}</div>
			</Paper>
			{loading && <Loader />}
			<PaginationViewMore
				selectedRowsPerPage={pagination.perPage}
				total={totalItineraries}
				currentPageNumber={pagination.page}
				viewMore={(page) => setPagination({ page, perPage: pagination.perPage })}
				text={
					<Label variant="caption1" className="loadMoreButton">
						Load More
					</Label>
				}
			/>
		</Page>
	);
};

export default ExistingItineraryPage;
