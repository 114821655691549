import React from 'react';
import { useNavigate } from 'react-router-dom';
import './AccommodationSearchCardMobile.scss';
import { Box, popupController } from '../../../lib/@bit/redsky.framework.rs.996';
import Label from '../../../lib/@bit/redsky.framework.rs.label';
import Icon from '../../../lib/@bit/redsky.framework.rs.icon';
import { NumberUtils, ObjectUtils, StringUtils, WebUtils } from '../../../utils/utils';
import Accordion from '../../../lib/@bit/redsky.framework.rs.accordion';
import CarouselV2 from '../../carouselV2/CarouselV2';
import serviceFactory from '../../../services/serviceFactory';
import debounce from 'lodash.debounce';
import AccommodationService from '../../../services/accommodation/accommodation.service';
import { useEffect, useState } from 'react';
import { rsToastify } from '../../../lib/@bit/redsky.framework.rs.toastify';
import LabelButton from '../../labelButton/LabelButton';
import RateCodeCard from '../../rateCodeCard/RateCodeCard';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import globalState from '../../../state/globalState';
import MobileLightBox, { MobileLightBoxProps } from '../../../popups/mobileLightBox/MobileLightBox';
import Loader from '../../loader/Loader';
import DestinationService from '../../../services/destination/destination.service';
import metaCapi from '../../../customHooks/useMetaCapi';
import TagManager from 'react-gtm-module';
import themes from '../../../themes/themes.scss';
import RateDetailPopup, { RateDetailPopupProps } from '../../../popups/rateDetailPopup/RateDetailPopup';
import { getPageFinder, undefinedHandler } from '../../../utils/undefinedHandler';
import parse from 'html-react-parser';
import { get } from 'lodash';
import useMerchantReward from '../../../customHooks/useMerchantReward';

interface AccommodationSearchCardMobileProps {
	pointPerDollar?: number;
	accommodation: Api.Destination.Res.Accommodation;
	destinationId: number;
	openAccordion?: boolean;
	showInfoIcon?: boolean;
	packageLength: number;
	promotedRate?: any;
	nonPromotedRate?: any;
	onClickInfoIcon?: (accommodationId: number) => void;
	loyaltyStatus: Model.LoyaltyStatus;
	upfrontCashRequired: boolean;
	resortPoint: number;
	resortPointsPrice: number;
	isCustomResort?: boolean;
	phone?: number;
	destinationDetails: Api.Destination.Res.Details | undefined;
	isOpen?: () => void;
	accessCode?: string;
}

const AccommodationSearchCardMobile: React.FC<AccommodationSearchCardMobileProps> = (props) => {
	const setVerifiedAccommodation = useSetRecoilState<Api.Reservation.Res.Verification | undefined>(
		globalState.verifiedAccommodation
	);
	const user = useRecoilValue<Api.User.Res.Detail | undefined>(globalState.user);
	const userTierValue = useRecoilValue<number>(globalState.userTierValue);
	let navigate = useNavigate();
	const reservationFilters = useRecoilValue<Misc.ReservationFilters>(globalState.reservationFilters);
	const [isOpen, setIsOpen] = useState<boolean>(props?.openAccordion || false);

	const [destinationObj, setDestinationObj] = useState<any>({
		campaigns: '',
		minAccommodationPriceInCents: 0
	});

	const [pointPerDollar, setPointPerDollar] = useState<number>(0);

	useEffect(() => {
		if (typeof props.pointPerDollar === 'undefined') {
			setPointPerDollar(0);
		} else {
			setPointPerDollar(props.pointPerDollar);
		}
		setCampaign();
	}, []);

	const setCampaign = () => {
		let code = props.promotedRate.rate.code;
		let promotedRate = props.accommodation.prices.filter((item) => item.rate.code === code);

		setDestinationObj({
			campaigns: promotedRate[0]?.rate.campaigns,
			minAccommodationPriceInCents: promotedRate[0]?.baseRates
		});
	};

	const googleAddToCartHandler = (productData: any) => {
		const add_to_cart = {
			id: productData,
			item_name: props.accommodation?.name,
			item_id: get(props, 'accommodation.externalSystemId', props?.accommodation?.id),
			price: NumberUtils.centsToDollars(props?.promotedRate.accommodationTotalInCents),
			item_brand: props.destinationDetails?.name,
			item_category: get(props, 'accommodation.contentLists.RoomCategoryList.0.Name', null),
			item_category2: get(props, 'accommodation.contentLists.RoomCategoryList.0.Name', null),
			item_list_name: 'Rooms',
			quantity: 1,
			arrival_date: reservationFilters.startDate,
			depart_date: reservationFilters.endDate,
			total_cost: NumberUtils.centsToDollars(props?.promotedRate.grandTotalCents),
			currency: props?.promotedRate.rate.currencyCode
		};

		try {
			TagManager.dataLayer({
				dataLayer: {
					event: 'add_to_cart',
					ecommerce: null
				}
			});

			TagManager.dataLayer({
				dataLayer: {
					event: 'add_to_cart',
					ecommerce: {
						detail: {
							products: [add_to_cart]
						}
					}
				}
			});
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	};

	async function onBookNow() {
		try {
			if (props?.isCustomResort) {
				window.open(`tel:+${props?.phone}`, '_self');
			} else {
				setVerifiedAccommodation(undefined);
				let data: any = { ...reservationFilters, redeemPoints: props?.loyaltyStatus === 'ACTIVE' };
				let code = props.promotedRate.rate.code;
				let promotedRate = props.accommodation.prices.filter((item) => item.rate.code === code);
				let newRoom: Misc.StayParams = {
					uuid: Date.now(),
					adults: data.adultCount,
					children: data.childCount || 0,
					accommodationId: props?.accommodation?.id,
					arrivalDate: data.startDate,
					departureDate: data.endDate,
					packages: [],
					// rateCode: props?.accommodation?.prices[0]?.rate?.code || ''
					rateCode: promotedRate[0].rate.code || ''
				};

				let isRedeemPoints = reservationFilters.redeemPoints ? '&redeemPoints=true' : '';
				data = StringUtils.setAddPackagesParams({ destinationId: props?.destinationId, newRoom });
				popupController.closeAll();

				const checkoutData = {
					destinationId: props?.destinationId,
					stays: [newRoom]
				};

				//google analytics add to cart
				googleAddToCartHandler(props?.destinationId);
				metaCapi.addToCart(props?.destinationDetails);

				if (props?.packageLength === 0) {
					navigate(`/booking/checkout?data=${encodeURI(JSON.stringify(checkoutData))}${isRedeemPoints}`);
				} else {
					navigate(`/booking/packages?data=${data}${isRedeemPoints}`);
				}
			}
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	function getImageUrls(): string[] {
		try {
			if (props?.accommodation?.media) {
				let images = props?.accommodation?.media;
				images.sort((a, b) => {
					return b.isPrimary - a.isPrimary;
				});
				return images.map((urlObj) => {
					return urlObj.urls.large?.toString();
				});
			}
			return [];
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
			return [];
		}
	}

	function renderImageCarousel() {
		const urls = getImageUrls();
		return (
			<CarouselV2
				path={() => {}}
				resortPoint={props?.resortPoint}
				imgPaths={urls}
				hideCompareButton={true}
				onGalleryClick={() => {
					popupController.open<MobileLightBoxProps>(MobileLightBox, {
						imageData: props?.accommodation?.media
					});
				}}
				imageWidth={327}
			/>
		);
	}

	function renderPointsOrCash() {
		if (reservationFilters.redeemPoints && props?.loyaltyStatus === 'ACTIVE') {
			return (
				<Box className={'pricePerNight'}>
					<Label variant={'h2'} className={'yellowText'}>
						{/* {StringUtils.addCommasToNumber(props.promotedRate?.pricePoints) + 'pts'} */}
						{props.promotedRate ? StringUtils.addCommasToNumber(props.promotedRate.pricePoints) + 'pts' : 0}
					</Label>
					<Label variant={'body1'}> / night</Label>
				</Box>
			);
		} else {
			return (
				<>
					<Box className={'pricePerNight'}>
						<Label variant={'h2'} className={'price'}>
							{StringUtils.formatCurrency(props.promotedRate?.rate?.currencyCode)}
							{StringUtils.formatMoney(props.promotedRate?.priceCents)}
						</Label>
					</Box>
					<Label variant={'body1'}>
						{StringUtils.formatCurrency(props.promotedRate?.rate?.currencyCode)}
						{StringUtils.formatMoney(props.promotedRate?.grandTotalCents)}
					</Label>
					<Label variant={'body1'}> / including taxes and fees</Label>
				</>
			);
		}
	}

	function renderAccommodationSize() {
		if (!props.accommodation.minSquareFt && !props.accommodation.maxSquareFt) return;
		if (props.accommodation.minSquareFt == 0 && props.accommodation.maxSquareFt == 0) {
			return;
		}
		if (
			!props.accommodation.minSquareFt ||
			!props.accommodation.maxSquareFt ||
			props.accommodation.minSquareFt === props.accommodation.maxSquareFt
		) {
			return (
				<Label variant={'h6'} paddingLeft={10} className="borderLeft">
					{'| '}
					{props.accommodation.minSquareFt || props.accommodation.maxSquareFt} ft&sup2;
				</Label>
			);
		} else {
			return (
				<Label variant={'h6'} paddingLeft={10} className="borderLeft">
					{'| '}
					{!!props.accommodation.minSquareFt && props.accommodation.minSquareFt} to{' '}
					{props.accommodation.maxSquareFt} ft&sup2;
				</Label>
			);
		}
	}

	function renderCampaignMessage() {
		if (!!props.promotedRate.rate.campaigns && props.promotedRate.rate.campaigns.length === 0) {
			return '';
		}
		if (!!props.accommodation.campaigns && props.accommodation.campaigns.length === 0) {
			return '';
		}
		try {
			if (
				!!props?.accommodation?.prices[0].rate.campaigns &&
				props?.accommodation?.prices[0].rate.campaigns.length === 0
			) {
				return '';
			}
			let campaignsData = props?.accommodation?.prices[0].rate.campaigns.sort(function (a: any, b: any) {
				return new Date(b.createdOn).valueOf() - new Date(a.createdOn).valueOf();
			});
			let index = campaignsData.length - 1;
			return <>{parse(get(campaignsData, index + '.campaignMessage', ''))}</>;
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	return (
		<Box className={'rsAccommodationSearchCardMobile'}>
			{!props?.destinationDetails?.allowOversell && props.promotedRate?.quantityAvailable <= 0 ? (
				<></>
			) : (
				<>
					{renderImageCarousel()}
					<Box className={'accommodationDetailsContainer'}>
						<Box className={'accommodationTitle'}>
							<Label variant={'h6'}>{props.accommodation.name}</Label>
							{props.showInfoIcon && (
								<Icon
									iconImg={'icon-info-outline'}
									cursorPointer
									onClick={debounce((value) => {
										if (props.onClickInfoIcon) return props.onClickInfoIcon(props.accommodation.id);
									}, 500)}
									size={22}
								/>
							)}
						</Box>
						<Box
							mb={props.promotedRate?.quantityAvailable <= 5 ? 8 : 24}
							display={'flex'}
							alignItems={'center'}
						>
							<Label marginRight={5} variant={'caption'}>
								Sleeps {props.accommodation.maxOccupantCount}
							</Label>
							{/* <Label variant={'caption'}>| {props.accommodation.bedDetails.length} Various Bed Types</Label> */}
							{renderAccommodationSize()}
						</Box>

						{!props?.destinationDetails?.allowOversell &&
							!props?.destinationDetails?.isUrgencyMessage &&
							props.promotedRate?.quantityAvailable > 0 &&
							props.promotedRate?.quantityAvailable <= 5 && (
								<Box mb={24} display={'flex'} alignItems={'center'}>
									<Label ml={10} variant={'caption'} color={themes.errorColor}>
										only{' '}
										{props.promotedRate?.quantityAvailable < 0
											? '0'
											: props.promotedRate?.quantityAvailable}{' '}
										left
									</Label>
								</Box>
							)}
						{/* 
						{props?.destinationDetails?.isUrgencyMessage && (
							<Box mb={24} display={'flex'} alignItems={'center'}>
								<Label variant={'caption'} color={themes.errorColor}>
									Only 1 left.
								</Label>
							</Box>
						)} */}

						<Label variant={'buttonSm'} mb={8}>
							{props?.promotedRate?.rate?.name}
						</Label>
						<Label variant={'subtitle1'} mb={8}>
							{props?.promotedRate?.rate?.description}
						</Label>
						<Label
							variant={'button'}
							color={themes.errorColor}
							className={'rateDetailBtn'}
							onClick={() => {
								popupController.open<RateDetailPopupProps>(RateDetailPopup, {
									priceDetail: props.promotedRate,
									contentList: props?.accommodation?.contentLists
								});
							}}
						>
							Rate Details
						</Label>
						{renderPointsOrCash()}
						{!reservationFilters.redeemPoints && props.loyaltyStatus === 'ACTIVE' && (
							<Label variant={'body1BI'} className={'secondaryTextColor'} mb={8}>
								{/* {useMerchantReward.pointEarningCalculation(
									destinationObj,
									pointPerDollar,
									userTierValue,
									user
								)} */}
								{useMerchantReward.rewardPointsCalculation(
									destinationObj,
									pointPerDollar,
									userTierValue,
									user
								)}
							</Label>
						)}
						<div className={'campaignMessage'}>{renderCampaignMessage()}</div>
						<Box className={'buttonContainer'}>
							<LabelButton
								look={'containedPrimary'}
								variant={'buttonMdLg'}
								label={props.isCustomResort ? 'Call to Book' : 'Book Now'}
								className={'yellow'}
								onClick={onBookNow}
							/>
						</Box>
					</Box>
					<Box className={'accordionContainer'}>
						{ObjectUtils.isArrayWithData(props.accommodation.prices) && (
							// props.accommodation.prices.length > 1 && (
							<Accordion
								titleReact={
									<Label variant={'buttonMdLg'} className={'primaryTextColor'}>
										{!!props.accessCode && props.accessCode.length > 0
											? isOpen
												? 'View Less Rates'
												: 'View All Rates'
											: isOpen
											? 'View less rates'
											: 'View more rates'}
									</Label>
								}
								isOpen={props.openAccordion}
								onClick={() => {
									setIsOpen(!isOpen);
									props.isOpen?.();
								}}
							>
								{props?.nonPromotedRate?.map((priceObj: any) => {
									return (
										<RateCodeCard
											key={priceObj?.rate?.code}
											pointPerDollar={props.pointPerDollar}
											destinationDetails={props.destinationDetails}
											priceObj={priceObj}
											accommodation={props.accommodation}
											destinationId={props.destinationId}
											packageLength={props.packageLength}
											accommodationId={props.accommodation.id}
											pointsEarnable={props.accommodation.pointsEarnable}
											loyaltyStatus={props.loyaltyStatus}
											upfrontCashRequired={props.upfrontCashRequired}
											isCustomResort={props.isCustomResort}
											phone={props.phone}
											contentList={props.accommodation.contentLists}
											campaignLength={
												!!props.accommodation.campaigns
													? props.accommodation.campaigns.length
													: 0
											}
										/>
									);
								})}
							</Accordion>
						)}
					</Box>
				</>
			)}
		</Box>
	);
};

export default AccommodationSearchCardMobile;
