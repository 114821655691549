import * as React from 'react';
import './TitleLabel.scss';
import Label from '../../../lib/@bit/redsky.framework.rs.label/dist/Label';
import { Box } from '../../../lib/@bit/redsky.framework.rs.996';
import { RefObject, useEffect, useState } from 'react';
import useOnClickOutsideRef from '../../../customHooks/useOnClickOutsideRef';
import Icon from '../../../lib/@bit/redsky.framework.rs.icon';
import Button from '../../../lib/@bit/redsky.framework.rs.icon';

interface TitleLabelProps {
	title: string;
	label: string;
	popoutBoxPadding?: string;
	className?: string;
	titleLabelRef?: RefObject<any> | undefined;
	labelVariant?: string;
	titleVariant?: string;
	titleMarginBottom?: number;
	titleColor?: string;
	dropdownBoxId?: string;
	isDropdownOpen?: boolean;
	iconDown?: boolean;
	iconDownCss?: string;
	screenSize?: number;
}

const TitleLabel: React.FC<TitleLabelProps> = (props) => {
	const [popupOpened, setPopupOpened] = useState<boolean>(props.isDropdownOpen || false);
	const dropdownRef = useOnClickOutsideRef(() => {
		if (popupOpened) setPopupOpened(false);
	});

	return (
		<div className="rsTitleLabel">
			<Box
				className={`rsTitleLabelContent ${props.className || ''}`}
				onClick={() => setPopupOpened((prevState) => !prevState)}
			>
				<Box>
					<Label
						variant={`${props.titleVariant ? ` ${props.titleVariant}` : 'caption3'}`}
						marginBottom={props.titleMarginBottom ?? 10}
						color={props.titleColor}
					>
						{props.title}
					</Label>
					<Label variant={`${props.labelVariant ? `${props.labelVariant}` : 'subtitle3'}`}>
						{props.label}
					</Label>
				</Box>
				{props.iconDown && <Icon className={props.iconDownCss} size={25} iconImg={'icon-chevron-thin-down'} />}
			</Box>
			<Box
				boxRef={dropdownRef}
				className={`popupBox ${popupOpened ? 'show' : 'hide'}`}
				padding={props.popoutBoxPadding || '24px'}
				id={props.dropdownBoxId || 'dropdownBoxId'}
			>
				{props.children}
			</Box>
		</div>
	);
};

export default TitleLabel;
