import React from 'react';
import { FormEvent, useEffect, useRef, useState } from 'react';
import './CheckOutUserInfoFrom.scss';
import { Box } from '../../lib/@bit/redsky.framework.rs.996';
import Label from '../../lib/@bit/redsky.framework.rs.label/dist/Label';
import { RsFormControl, RsFormGroup, RsValidator, RsValidatorEnum } from '../../lib/@bit/redsky.framework.rs.form';
import LabelInput from '../labelInput/LabelInput';
import LabelSelect from '../labelSelect/LabelSelect';
import { rsToastify } from '../../lib/@bit/redsky.framework.rs.toastify';
import RsPagedResponseData = RedSky.RsPagedResponseData;
import Button from '../../lib/@bit/redsky.framework.rs.button';
import { NumberUtils, ObjectUtils, StringUtils, WebUtils } from '../../utils/utils';
import serviceFactory from '../../services/serviceFactory';
import CountryService from '../../services/country/country.service';
import LabelButton from '../labelButton/LabelButton';
import LabelCheckboxFilterBar from '../labelCheckbox/LabelCheckboxFilterBar';
import UserService from '../../services/user/user.service';
import { OptionType } from '../../lib/@bit/redsky.framework.rs.select';
import { useRecoilState, useRecoilValue } from 'recoil';
import globalState from '../../state/globalState';
import classNames from 'classnames';
import useGetCountryList from '../../customHooks/useGetCountryList';
import TagManager from 'react-gtm-module';
import { getPageFinder, undefinedHandler } from '../../utils/undefinedHandler';
import AccommodationService from '../../services/accommodation/accommodation.service';
import router from '../../utils/router';
import { get } from 'lodash';
import { CLIENT_RENEG_LIMIT } from 'tls';

export interface CheckOutUserInfoFromProps {
	form: RsFormGroup;
	onUpdate: (control: RsFormControl) => void;
	onFormError: (isError: boolean) => void;
	stateKey: number;
	phoneError: boolean;
}

enum FormKeys {
	FIRST_NAME = 'firstName',
	LAST_NAME = 'lastName',
	PHONE = 'phone',
	EMAIL = 'email',
	ADDRESS1 = 'address1',
	ADDRESS2 = 'address2',
	CITY = 'city',
	STATE = 'state',
	ZIP = 'zip',
	COUNTRY = 'country'
}

const CheckOutUserInfoFrom: React.FC<CheckOutUserInfoFromProps> = (props) => {
	const [stateList, setStateList] = useState<Misc.OptionType[]>([]);
	const countryService = serviceFactory.get<CountryService>('CountryService');
	const countryList = useGetCountryList();
	const [countries, setCountries] = useState<OptionType[]>([]);

	useEffect(() => {
		setCountries(formatStateOrCountryListForSelect(countryList));
	}, [countryList]);

	useEffect(() => {
		let cancelFetch: boolean = false;
		async function getStates() {
			try {
				const states = await countryService.getStates(props.form.get(FormKeys.COUNTRY).value.toString());
				if (cancelFetch) return;
				if (states.states.length === 0) {
					setStateList([{ value: 'N/A', label: 'N/A' }]);
				} else {
					setStateList(formatStateOrCountryListForSelect(states.states));
				}
			} catch (e: any) {
				rsToastify.error(
					WebUtils.getRsErrorMessage(e, 'Unable to get states for the selected country.'),
					'Server Error'
				);

				const message = `Getting error :${e.message} on ${getPageFinder(window.location.pathname)} page.`;
				undefinedHandler(message);
			}
		}
		getStates().catch(console.error);
		return () => {
			cancelFetch = true;
		};
	}, [props.form.get(FormKeys.COUNTRY)]);

	function formatStateOrCountryListForSelect(statesOrCountries: any[]) {
		return statesOrCountries.map((item) => {
			return { value: item.isoCode, label: item.name };
		});
	}

	return (
		<Box className="rsCheckOutUserInfoCard">
			<form>
				<h3>User Info</h3>
				<Box className={'fieldGroup'}>
					<LabelInput
						labelVariant={'h6'}
						title={'First Name'}
						inputType={'text'}
						control={props.form.get(FormKeys.FIRST_NAME)}
						updateControl={props.onUpdate}
						isRequired
					/>
					<LabelInput
						labelVariant={'h6'}
						title={'Last Name'}
						inputType={'text'}
						control={props.form.get(FormKeys.LAST_NAME)}
						updateControl={props.onUpdate}
						isRequired
					/>
				</Box>
				<Box className={'fieldGroup stretchedInput'}>
					<LabelInput
						labelVariant={'h6'}
						title={'Address Line 1'}
						inputType={'text'}
						control={props.form.get(FormKeys.ADDRESS1)}
						updateControl={props.onUpdate}
						isRequired
					/>
				</Box>
				<Box className={'fieldGroup stretchedInput'}>
					<LabelInput
						labelVariant={'h6'}
						title={'Address Line 2'}
						inputType={'text'}
						control={props.form.get(FormKeys.ADDRESS2)}
						updateControl={props.onUpdate}
					/>
				</Box>
				<Box className={'fieldGroup'}>
					<LabelInput
						labelVariant={'h6'}
						title={'Postal/Zip code'}
						inputType={'text'}
						control={props.form.get(FormKeys.ZIP)}
						updateControl={props.onUpdate}
						isRequired
					/>
					<LabelInput
						labelVariant={'h6'}
						title={'City'}
						inputType={'text'}
						control={props.form.get(FormKeys.CITY)}
						updateControl={props.onUpdate}
						isRequired
					/>
				</Box>
				<Box className={'fieldGroup'}>
					<LabelSelect
						key={`select-${props.stateKey}`}
						title={'State'}
						options={stateList}
						control={props.form.get(FormKeys.STATE)}
						updateControl={props.onUpdate}
						labelVariant={'h6'}
						isRequired
					/>
					<LabelSelect
						title={'Country'}
						options={countries}
						control={props.form.get(FormKeys.COUNTRY)}
						updateControl={props.onUpdate}
						labelVariant={'h6'}
						isRequired
					/>
				</Box>
				<Box className={'fieldGroup'}>
					<LabelInput
						labelVariant={'h6'}
						title={'Email'}
						inputType={'text'}
						control={props.form.get(FormKeys.EMAIL)}
						updateControl={props.onUpdate}
						isRequired
					/>
					<LabelInput
						className={classNames('phoneInput', { phoneError: props.phoneError })}
						labelVariant={'h6'}
						inputType={'tel'}
						title={'Phone'}
						isPhoneInput
						// control={props.form.get(FormKeys.PHONE)}
						isRequired
						onChange={(value) => {
							let updatedPhone = props.form.get(FormKeys.PHONE);
							updatedPhone.value = value;
							props.onUpdate(updatedPhone);
						}}
						initialValue={String(props.form.get(FormKeys.PHONE).value)}
					/>
				</Box>
				{/* {!user && (
					<Box className={'fieldGroup stretchedInput'}>
				
						{stretchedInputhandler(resortId)}
					</Box>
				)} */}
				{/* <Box className={'fieldGroup stretchedInput rightSide'}>{buttonHandler(resortId)}</Box> */}
			</form>
		</Box>
	);
};
export default CheckOutUserInfoFrom;
