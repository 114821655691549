import React from 'react';
import { useEffect, useState } from 'react';
import './AccountAddressPage.scss';
import { rsToastify } from '../../lib/@bit/redsky.framework.rs.toastify';
import { StringUtils, WebUtils } from '../../utils/utils';
import { RsFormControl, RsFormGroup, RsValidator, RsValidatorEnum } from '../../lib/@bit/redsky.framework.rs.form';
import { OptionType } from '../../lib/@bit/redsky.framework.rs.select';
import serviceFactory from '../../services/serviceFactory';
import UserAddressService from '../../services/userAddress/userAddress.service';
import { useRecoilValue } from 'recoil';
import globalState from '../../state/globalState';
import CountryService from '../../services/country/country.service';
import { ObjectUtils } from '../../lib/@bit/redsky.framework.rs.utils';
import AccountAddressTile from '../../components/accountAddressTile/AccountAddressTile';
import { Page, popupController } from '../../lib/@bit/redsky.framework.rs.996';
import EditUserAddressPopup, {
	EditUserAddressPopupProps
} from '../../popups/editUserAddressPopup/EditUserAddressPopup';
import SubNavMenu from '../../components/subNavMenu/SubNavMenu';
import Paper from '../../components/paper/Paper';
import Box from '../../lib/@bit/redsky.framework.rs.996/dist/box/Box';
import Label from '../../lib/@bit/redsky.framework.rs.label';
import LabelInput from '../../components/labelInput/LabelInput';
import LabelSelect from '../../components/labelSelect/LabelSelect';
import LabelCheckbox from '../../components/labelCheckbox/LabelCheckbox';
import LabelButton from '../../components/labelButton/LabelButton';
import LoadingPage from '../loadingPage/LoadingPage';
import useIsAtBreakpoint from '../../customHooks/useIsAtBreakpoint';
import classNames from 'classnames';
import useGetCountryList from '../../customHooks/useGetCountryList';
import { useNavigate } from 'react-router-dom';
import { getPageFinder, undefinedHandler } from '../../utils/undefinedHandler';

enum FormKeys {
	FIRST_NAME = 'firstName',
	LAST_NAME = 'lastName',
	PHONE = 'phone',
	EMAIL = 'email',
	ADDRESS1 = 'address1',
	ADDRESS2 = 'address2',
	CITY = 'city',
	STATE = 'state',
	ZIP = 'zip',
	COUNTRY = 'country'
}

const AccountAddressPage: React.FC = () => {
	let navigate = useNavigate();
	const user = useRecoilValue<Api.User.Res.Detail | undefined>(globalState.user);
	const countryService = serviceFactory.get<CountryService>('CountryService');
	const userAddressService = serviceFactory.get<UserAddressService>('UserAddressService');
	const namePattern = new RegExp(/^([a-zA-Z][A-Za-z ,.'`-]*)$/);
	const streetAddressCityPattern = new RegExp(
		`^(?![ -.&,_'":?!])(?!.*[- &_'":]$)(?!.*[-.#@&,:?!]{2})[a-zA-Z0-9- .#@&,_'":.?!]+$`
	);
	const isMobile = useIsAtBreakpoint();
	const [addressList, setAddressList] = useState<Api.User.Address[]>([]);
	const [isValidForm, setIsValidForm] = useState<boolean>(false);
	const [countries, setCountries] = useState<OptionType[]>([]);
	const countryList = useGetCountryList();
	const [stateList, setStateList] = useState<OptionType[]>([]);
	const [isDefault, setIsDefault] = useState<0 | 1>(0);
	const [onSave, setOnSave] = useState<boolean>(false);
	const [stateNumber, setStateNumber] = useState<number>(1);
	const [newAddressObj, setNewAddressObj] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl(FormKeys.FIRST_NAME, '', [
				new RsValidator(RsValidatorEnum.REQ, 'First name is required'),
				new RsValidator(RsValidatorEnum.CUSTOM, 'First name is required', (control: RsFormControl) => {
					return namePattern.test(control.value.toString());
				})
			]),
			new RsFormControl(FormKeys.LAST_NAME, '', [
				new RsValidator(RsValidatorEnum.REQ, 'Last name is required'),
				new RsValidator(RsValidatorEnum.CUSTOM, 'Last name is required', (control: RsFormControl) => {
					return namePattern.test(control.value.toString());
				})
			]),
			new RsFormControl(FormKeys.ADDRESS1, '', [
				new RsValidator(RsValidatorEnum.REQ, 'Address is required'),
				new RsValidator(RsValidatorEnum.CUSTOM, 'Address is required', (control: RsFormControl) => {
					return streetAddressCityPattern.test(control.value.toString());
				})
			]),
			new RsFormControl(FormKeys.ADDRESS2, '', []),
			new RsFormControl(FormKeys.CITY, '', [
				new RsValidator(RsValidatorEnum.REQ, 'City is required'),
				new RsValidator(RsValidatorEnum.CUSTOM, 'City is required', (control: RsFormControl) => {
					return streetAddressCityPattern.test(control.value.toString());
				})
			]),
			new RsFormControl(FormKeys.ZIP, '', [
				new RsValidator(RsValidatorEnum.REQ, 'Zip is required'),
				new RsValidator(RsValidatorEnum.CUSTOM, 'Invalid Zip Code', (control) => {
					return StringUtils.zipPattern.test(control.value.toString());
				})
			]),
			new RsFormControl(FormKeys.STATE, '', []),
			new RsFormControl(FormKeys.COUNTRY, 'US', [new RsValidator(RsValidatorEnum.REQ, 'Country is required')])
		])
	);

	useEffect(() => {
		window.scrollTo(0, 0);
		if (!user) return;
		setAddressList(user.address);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		if (!user) navigate('/page-not-found');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setCountries(formatStateOrCountryListForSelect(countryList));
	}, [countryList]);

	useEffect(() => {
		async function getStates() {
			setStateNumber((prevState) => ++prevState);
			let updatedState = newAddressObj.getClone(FormKeys.STATE);
			updatedState.value = '';
			setNewAddressObj(newAddressObj.clone().update(updatedState));
			try {
				let response = await countryService.getStates(`${newAddressObj.get(FormKeys.COUNTRY).value}` || 'US');
				if (response.states) {
					let newStates = formatStateOrCountryListForSelect(response.states);
					if (newStates.length === 0) {
						setStateList([{ value: 'N/A', label: 'N/A' }]);
					} else {
						setStateList(newStates);
					}
				}
			} catch (e) {
				rsToastify.error(
					WebUtils.getRsErrorMessage(e, 'Unable to get states for the selected country.'),
					'Server Error'
				);
			}
		}
		getStates().catch(console.error);
	}, [countryList, newAddressObj.get(FormKeys.COUNTRY).value, onSave]);

	useEffect(() => {
		if (!newAddressObj.isModified()) {
			setIsValidForm(false);
			return;
		}
	}, [newAddressObj]);

	async function updateAddressToDefault(addressId: number) {
		let data = { id: addressId, isDefault: 1 };
		try {
			let response = await userAddressService.update(data);
			if (response) rsToastify.success('Address successfully updated.', 'Update Successful');

			let addresses = [...addressList];
			addresses = addresses.map((item) => {
				return { ...item, isDefault: item.id === addressId ? 1 : 0 };
			});
			setAddressList(addresses);
		} catch (e: any) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Address update failed, try again.'), 'Server Error');
			const message = `Getting error :${e.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	const ConTwoDecDigit = (digit: any) => {
		return digit.indexOf('.') > 0
			? digit.split('.').length <= 4
				? digit.split('.')[0] + '.' + digit.split('.')[1].substring(-1, 4)
				: digit
			: digit;
	};

	async function updateNewAddressObj(control: RsFormControl) {
		if (control.key === 'firstName') {
			if (!isNaN(Number(control.value))) {
				// If the value is a number, clear it
				control.value = '';
			} else {
				// Remove special characters and ensure two decimal digits
				control.value = control.value.toString().replace(/[^A-Za-z]/g, '');
				control.value = ConTwoDecDigit(control.value);
			}
		}

		if (control.key === 'lastName') {
			if (!isNaN(Number(control.value))) {
				// If the value is a number, clear it
				control.value = '';
			} else {
				// Remove special characters and ensure two decimal digits
				control.value = control.value.toString().replace(/[^A-Za-z]/g, '');
				control.value = ConTwoDecDigit(control.value);
			}
		}

		if (control.key === FormKeys.COUNTRY) {
			const stateControl = newAddressObj.get(FormKeys.STATE);
			stateControl.value = '';
			newAddressObj.update(stateControl);
			setStateNumber((prevState) => ++prevState);
		}
		newAddressObj.update(control);
		setIsValidForm(isFormFilledOut(newAddressObj));
		setNewAddressObj(newAddressObj.clone());
	}

	function convertObj(obj: Api.UserAddress.Res.Create): Api.User.Address {
		return {
			address1: obj.address1,
			address2: obj.address2,
			city: obj.city,
			country: obj.country,
			id: obj.id,
			isDefault: obj.isDefault,
			name: obj.name,
			state: obj.state,
			type: obj.type,
			zip: obj.zip
		};
	}

	function isFormFilledOut(addressObj: RsFormGroup): boolean {
		return (
			!!addressObj.get(FormKeys.FIRST_NAME).value.toString().length &&
			!!addressObj.get(FormKeys.LAST_NAME).value.toString().length &&
			!!addressObj.get(FormKeys.ADDRESS1).value.toString().length &&
			!!addressObj.get(FormKeys.CITY).value.toString().length &&
			addressObj.get(FormKeys.ZIP).value.toString().length > 4 &&
			addressObj.get(FormKeys.ZIP).value.toString().length < 12 &&
			!!addressObj.get(FormKeys.COUNTRY).value.toString().length &&
			!!addressObj.get(FormKeys.STATE).value.toString().length
		);
	}

	function formatStateOrCountryListForSelect(statesOrCountries: Misc.IBaseCountry[]) {
		return statesOrCountries.map((item: Misc.IBaseCountry) => {
			return { value: item.isoCode, label: item.name };
		});
	}

	async function save() {
		setOnSave(true);
		if (!user) return;
		let addressObj: Api.UserAddress.Req.Create = newAddressObj.toModel();
		addressObj['userId'] = user.id;
		addressObj['type'] = 'BOTH';
		addressObj['isDefault'] = isDefault;
		addressObj['name'] = `${newAddressObj.get(FormKeys.FIRST_NAME).value} ${
			newAddressObj.get(FormKeys.LAST_NAME).value
		}`;
		try {
			let response = await userAddressService.create(addressObj);
			let newAddressList: Api.User.Address[] = [...addressList, convertObj(response)];
			if (response.isDefault) {
				newAddressList = newAddressList.map((item) => {
					return { ...item, isDefault: response.id === item.id ? 1 : 0 };
				});
			}
			newAddressObj.resetToInitialValue();
			setNewAddressObj(newAddressObj.clone());
			setAddressList(newAddressList);
			rsToastify.success('Address successfully added.', 'Address Created');
			setIsDefault(0);
			setIsValidForm(false);
			setOnSave(false);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unable to save address, try again'), 'Server Error');
			setOnSave(false);
		}
	}

	async function deleteAddress(id: number) {
		if (!addressList) return;
		try {
			let response = await userAddressService.delete(id);
			let newAddressList = addressList.filter((item) => item.id !== id);
			setAddressList(newAddressList);
			if (response) rsToastify.success('Address successfully removed.', 'Delete Successful');
		} catch (e) {
			console.error(`${e}`);
		}
	}

	async function editAddress(address: Api.UserAddress.Req.Update) {
		try {
			const updatedAddress = await userAddressService.update(address);
			setAddressList((prevState) => {
				let newList = [...prevState];
				return newList.map((address) => {
					if (address.id !== updatedAddress.id)
						return { ...address, isDefault: updatedAddress.isDefault ? 0 : address.isDefault };
					return convertObj(updatedAddress);
				});
			});
			rsToastify.success('Address has been updated!', 'Address Updated!');
		} catch (e) {
			rsToastify.error('There was an error updating your address.', 'Update Failed');
		}
	}

	function renderPrimaryAddress() {
		try {
			if (!addressList || !ObjectUtils.isArrayWithData(addressList)) return;
			const address = addressList.find((item) => item.isDefault);
			if (!address) return;
			return (
				<AccountAddressTile
					key={address.id}
					address={address}
					isDefault={isDefault}
					onEdit={() => {
						popupController.open<EditUserAddressPopupProps>(EditUserAddressPopup, {
							countryList: countries,
							existingAddress: address,
							editAddressCallback: (updatedAddress: Api.UserAddress.Req.Update) => {
								editAddress({ ...updatedAddress, id: address.id });
							}
						});
					}}
					onDelete={() => {
						deleteAddress(address.id);
					}}
					onPrimaryChange={(addressId) => {
						updateAddressToDefault(addressId);
					}}
				/>
			);
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	function renderAddresses() {
		try {
			if (!addressList || !ObjectUtils.isArrayWithData(addressList)) return;
			return addressList
				.filter((item) => !item.isDefault)
				.map((item) => {
					return (
						<AccountAddressTile
							key={item.id}
							address={item}
							isDefault={isDefault}
							onEdit={() =>
								popupController.open<EditUserAddressPopupProps>(EditUserAddressPopup, {
									countryList: countries,
									existingAddress: item,
									editAddressCallback: (updatedAddress: Api.UserAddress.Req.Update) => {
										return editAddress({ ...updatedAddress, id: item.id });
									}
								})
							}
							onDelete={() => {
								deleteAddress(item.id);
							}}
							onPrimaryChange={(addressId) => {
								updateAddressToDefault(addressId);
							}}
						/>
					);
				});
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	if (!user) return <LoadingPage />;
	return (
		<Page className={'rsAccountAddressPage'}>
			<SubNavMenu title={'Addresses'} />
			<Paper className="addressContainerPaper" boxShadow={!isMobile}>
				<Box className={classNames('addressList', { boxShadow: isMobile })}>
					<Label variant={'buttonMdLg'} mb={48}>
						Addresses
					</Label>
					{renderPrimaryAddress()}
					{renderAddresses()}
				</Box>
				<Box className={classNames('newAddress', { boxShadow: isMobile })}>
					<Label variant={'buttonMdLg'} mb={48}>
						Add a New Address
					</Label>
					<Box
						className="nameInputsContainer"
						display={'flex'}
						justifyContent={'space-between'}
						gap={32}
						pb={10}
					>
						<LabelInput
							labelVariant={'h6'}
							className={'addressInput'}
							title={'First Name'}
							inputType={'text'}
							control={newAddressObj.get(FormKeys.FIRST_NAME)}
							updateControl={updateNewAddressObj}
							isRequired
						/>
						<LabelInput
							labelVariant={'h6'}
							className={'addressInput'}
							title={'Last Name'}
							inputType={'text'}
							control={newAddressObj.get(FormKeys.LAST_NAME)}
							updateControl={updateNewAddressObj}
							isRequired
						/>
					</Box>
					<LabelInput
						labelVariant={'h6'}
						className={'addressInput'}
						title={'Address Line 1'}
						inputType={'text'}
						control={newAddressObj.get(FormKeys.ADDRESS1)}
						updateControl={updateNewAddressObj}
						isRequired
					/>
					<LabelInput
						labelVariant={'h6'}
						className={'addressInput'}
						title={'Address Line 2'}
						inputType={'text'}
						control={newAddressObj.get(FormKeys.ADDRESS2)}
						updateControl={updateNewAddressObj}
					/>
					<Box display={'flex'} justifyContent={'space-between'} gap={32} className="cityAndZipContainer">
						<LabelInput
							className={'addressInput'}
							labelVariant={'h6'}
							title={'City'}
							inputType={'text'}
							control={newAddressObj.get(FormKeys.CITY)}
							updateControl={updateNewAddressObj}
							isRequired
						/>
						<LabelInput
							className={'addressInput'}
							labelVariant={'h6'}
							title={'Zip Code'}
							inputType={'text'}
							control={newAddressObj.get(FormKeys.ZIP)}
							updateControl={updateNewAddressObj}
							isRequired
						/>
					</Box>
					<Box
						display={'flex'}
						justifyContent={'space-between'}
						gap={32}
						mb={32}
						className="stateAndCountryContainer"
					>
						<LabelSelect
							className={'addressInput'}
							labelVariant={'h6'}
							title={'State'}
							options={stateList}
							control={newAddressObj.get(FormKeys.STATE)}
							updateControl={updateNewAddressObj}
							isRequired
							key={`stateKey${stateNumber}`}
						/>
						<LabelSelect
							className={'addressInput'}
							labelVariant={'h6'}
							title={'Country'}
							updateControl={updateNewAddressObj}
							options={countries}
							control={newAddressObj.get(FormKeys.COUNTRY)}
							isRequired
						/>
					</Box>
					<Box className="primaryAndButtonContainer" display="flex" justifyContent="space-between">
						<LabelCheckbox
							value={'isDefault'}
							labelVariant={'h6'}
							text={'Set as primary'}
							isChecked={!!isDefault}
							onSelect={() => {
								setIsDefault(1);
							}}
							onDeselect={() => {
								setIsDefault(0);
							}}
						/>
						<LabelButton
							look={'containedPrimary'}
							variant={'buttonMdLg'}
							label={'Add New Address'}
							disabled={!isValidForm}
							onClick={() => {
								save();
							}}
						/>
					</Box>
				</Box>
			</Paper>
		</Page>
	);
};

export default AccountAddressPage;
