import * as React from 'react';
import './OtherPaymentCard.scss';
import { Box } from '../../lib/@bit/redsky.framework.rs.996';
import Label from '../../lib/@bit/redsky.framework.rs.label/dist/Label';
import LabelRadioButton from '../labelRadioButton/LabelRadioButton';
import Icon from '../../lib/@bit/redsky.framework.rs.icon';
import useIsAtBreakpoint from '../../customHooks/useIsAtBreakpoint';
import { StringUtils } from '../../utils/utils';
import { getPageFinder, undefinedHandler } from '../../utils/undefinedHandler';
import { get } from 'lodash';

interface OtherPaymentCardProps {
	name: string;
	id: number;
	last4: number;
	cardType: string;
	onEdit?: () => void;
	onDelete?: () => void;
	onSetPrimary?: (value: number) => void;
	isPrimary: 1 | 0;
}

const OtherPaymentCard: React.FC<OtherPaymentCardProps> = (props) => {
	const isMobile = useIsAtBreakpoint();

	function getPaymentLogo(cardType: string) {
		let logo = '';
		switch (cardType.toLowerCase()) {
			case 'visa':
				logo = '../../images/creditCardLogos/Visa.svg';
				break;
			case 'master':
				logo = '../../images/creditCardLogos/MasterCard.svg';
				break;
			case 'discover':
				logo = '../../images/creditCardLogos/Discover.svg';
				break;
			case 'american_express':
				logo = '../../images/creditCardLogos/AmEx.svg';
				break;
			case 'amex':
				logo = '../../images/creditCardLogos/AmEx.svg';
				break;
		}
		return <img src={logo} alt={'credit Card Logo'} width={56} height={34} />;
	}
	function renderCardType() {
		try {
			if (!props.cardType) return;
			if (!props.cardType.includes('_')) return StringUtils.capitalizeFirst(props.cardType);
			let cardType = props.cardType;
			let tempCardType = cardType.split('_');
			return tempCardType.map((cardName) => StringUtils.capitalizeFirst(cardName)).join(' ');
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	return (
		<Box className={'rsOtherPaymentCard'}>
			{!props.isPrimary ? (
				<Label
					variant={'h6'}
					className={'primaryTextColor'}
					onClick={() => {
						if (props.onSetPrimary) props.onSetPrimary(props.id);
					}}
					mb={16}
				>
					Set as Primary
				</Label>
			) : (
				<Label variant={'h6'}>Primary</Label>
			)}
			<div className={'imageAndCardDetailsWrapper'}>
				<div className={'creditCardImage'}>{getPaymentLogo(get(props, 'cardType'))}</div>
				<Box
					display={isMobile ? 'flex' : ''}
					alignItems={isMobile ? 'center' : ''}
					width={isMobile ? '100%' : ''}
					justifyContent={isMobile ? 'space-between' : ''}
				>
					<div>
						<Label variant={'body1'} className="nameLabel">
							{get(props, 'name')}
						</Label>
						<Label variant={'body1'} className={'cardName'}>{`${renderCardType()} ending in ${
							// props.last4
							get(props, 'last4')
						}`}</Label>
					</div>
					<div>
						{!props.isPrimary && isMobile && (
							<Icon
								className={'marginLeft'}
								iconImg={'icon-edit'}
								color={'#000000'}
								onClick={props.onEdit}
								cursorPointer
								size={21}
							/>
						)}
						{!props.isPrimary && isMobile && (
							<Icon
								className={'marginLeft'}
								iconImg={'icon-trash'}
								color={'#000000'}
								onClick={props.onDelete}
								cursorPointer
								size={21}
							/>
						)}
					</div>
				</Box>
			</div>
			{!props.isPrimary && !isMobile && (
				<Icon iconImg={'icon-edit'} onClick={props.onEdit} color={'#000000'} cursorPointer size={21} />
			)}
			{!props.isPrimary && !isMobile && (
				<Icon iconImg={'icon-trash'} color={'#000000'} onClick={props.onDelete} cursorPointer size={21} />
			)}
		</Box>
	);
};

export default OtherPaymentCard;
