import * as React from 'react';
import './MobilePackageDescriptionPopup.scss';
import { Box, Popup, popupController } from '../../lib/@bit/redsky.framework.rs.996';
import { PopupProps } from '../../lib/@bit/redsky.framework.rs.996/dist/popup/Popup';
import Label from '../../lib/@bit/redsky.framework.rs.label/dist/Label';
import Icon from '../../lib/@bit/redsky.framework.rs.icon';
import Img from '../../lib/@bit/redsky.framework.rs.img';
import Button from '../../lib/@bit/redsky.framework.rs.button';
import Paper from '../../components/paper/Paper';
import { IKImage } from 'imagekitio-react';

export interface MobilePackageDescriptionPopupProps extends PopupProps {
	packageImage: string;
	description: string;
	addPackage: () => void;
	isAdded: boolean;
}

const MobilePackageDescriptionPopup: React.FC<MobilePackageDescriptionPopupProps> = (props) => {
	const fullProps = popupController.convertProps(props);
	return (
		<Popup {...props}>
			<Paper className={'rsMobilePackageDescriptionPopup'}>
				<Box className="popupHeader">
					<Label variant="h5">Add Package</Label>
					<Icon
						iconImg={'icon-close'}
						size={15}
						color="#797979"
						onClick={() => {
							popupController.closeById(fullProps.popupId);
						}}
					/>
				</Box>
				<Box className="popupBody">
					<IKImage
						src={props.packageImage}
						height={204}
						width={304}
						className="packageImage"
						alt={`package-display`}
						loading="lazy"
					/>
					<Label variant="body1" paddingY={10}>
						{props.description}
					</Label>
				</Box>
				<Box className="popupFooter">
					<Button
						look={'none'}
						onClick={() => {
							props.addPackage();
							popupController.closeById(fullProps.popupId);
						}}
						className="addPackageButton"
					>
						<Label variant={'buttonSm'}>{props.isAdded ? 'Remove Package' : 'Add Package'}</Label>
					</Button>
				</Box>
			</Paper>
		</Popup>
	);
};

export default MobilePackageDescriptionPopup;
