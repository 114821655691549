import React, { useState, FC, useEffect, FormEvent } from 'react';
import { Box, Popup, popupController, PopupProps } from '../../lib/@bit/redsky.framework.rs.996';
import './OnboardingFlowOtp.scss';
import useIsAtBreakpoint from '../../customHooks/useIsAtBreakpoint';
import OnboardingFlowTwo from './OnboardingFlowTwo';
import Paper from '../paper/Paper';
import LabelInput from '../labelInput/LabelInput';
import { RsFormControl, RsFormGroup, RsValidator, RsValidatorEnum } from '../../lib/@bit/redsky.framework.rs.form';
import LabelButton from '../labelButton/LabelButton';
import serviceFactory from '../../services/serviceFactory';
import UserService from '../../services/user/user.service';
import Label from '../../lib/@bit/redsky.framework.rs.label';
import { rsToastify } from '../../lib/@bit/redsky.framework.rs.toastify';
import SpinningLoaderPopup from '../../popups/spinningLoaderPopup/SpinningLoaderPopup';
import OnboardingFlowOne from './OnboardingFlowOne';
import ProgressBar from '../progressBar/ProgressBar';

export interface OnboardingFlowOtpProps extends PopupProps {
	userResponse: any;
	successCallback?: any;
}

const OnboardingFlowOtp: React.FC<OnboardingFlowOtpProps> = (props) => {
	const progressbar1 = '../../images/onboardingFlow/progressbar1.svg';
	const card = '../../images/onboardingFlow/card.svg';
	const gift = '../../images/onboardingFlow/gift.svg';
	const money = '../../images/onboardingFlow/money.svg';
	const earn = '../../images/onboardingFlow/earn.svg';
	const secure = '../../images/onboardingFlow/secure.svg';
	const reward = '../../images/onboardingFlow/reward.svg';

	const isMobile = useIsAtBreakpoint();
	const [clickConfirmPointBtn, setClickConfirmPointBtn] = useState<boolean>(false);
	const userService = serviceFactory.get<UserService>('UserService');
	const [errorMessage, setErrorMessage] = useState<string>('');
	const [isFormComplete, setIsFormComplete] = useState<boolean>(false);
	const [otpForm, setOtpForm] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl('otp', '', [
				new RsValidator(RsValidatorEnum.REQ, 'Please enter a otp'),
				new RsValidator(RsValidatorEnum.MIN, 'Enter a valid phone number', 6),
				new RsValidator(RsValidatorEnum.MAX, 'Enter a valid phone number', 6)
			])
		])
	);

	const openNextOnboardingFlow = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		popupController.open(SpinningLoaderPopup);
		if (!(await otpForm.isValid())) {
			setOtpForm(otpForm.clone());
			setErrorMessage('Missing information');
			return;
		}

		const { otp } = otpForm.toModel<{ otp: number }>();

		// alert('next onboardomg');
		try {
			let response = await userService.verifyUserOtp({ userId: props.userResponse.id, otp: otp });
			popupController.close(SpinningLoaderPopup);
			popupController.close(OnboardingFlowOtp);

			// props.successCallback()

			await userService.loginUserByPasswordOtpAuthData(props.userResponse);

			popupController.open(OnboardingFlowOne);

			// Check if there is an error in the response
		} catch (error: any) {
			popupController.close(SpinningLoaderPopup);
			if (error.response && error.response.status) {
				// Access the status code

				if (error.response.status == 409) {
					setErrorMessage('otp is not valid');
				} else if (error.response.status == 410) {
					setErrorMessage('otp is expired');
				} else {
					setErrorMessage(error.response);
				}
			} else {
			}
		}
	};

	const skipOnboarding = () => {
		popupController.close(OnboardingFlowOtp);
	};

	async function updateForm(control: RsFormControl) {
		setOtpForm(otpForm.clone().update(control));
		if (errorMessage !== '') {
			setErrorMessage('');
		}

		let isFormValid = await otpForm.isValid();
		setIsFormComplete(isFormValid);
	}

	const SendOtpHendler = async () => {
		popupController.open(SpinningLoaderPopup);
		let result = await userService.sendOtp({
			id: props.userResponse.id,
			primaryEmail: props.userResponse.primaryEmail,
			firstName: props.userResponse.firstName,
			lastName: props.userResponse.lastName
		});

		if (result) {
			popupController.close(SpinningLoaderPopup);
			rsToastify.success('OTP send successfully.');
		}
	};

	return (
		<Popup {...props} preventCloseByBackgroundClick>
			{/* <Paper className={'rsSignupPopup'} position={'relative'}>
				<Box className="onboardingflow-container"> */}
			<div className="onboardingflowOtp-container">
				{/* <div className="onboardingflowOtp-header">
					<Box className={'popupTitle'}>
						<img src={`../../images/rentyl-rewards.png`} className="rr-logo" alt="Rentyl Reward" />
					</Box>
					<div className="onboarding-progressbarOtp-container">
					<ProgressBar totalProgressBar={1} currentStep={2} />
						<span className="onboarding-progressbar-title">1/2</span>
					</div>
				</div> */}

				<Box className={'title'}>
					<Label variant={isMobile ? 'h5' : 'h3'} className={'primaryTextColor'}>
						<img src={`../../images/rentyl-rewards.png`} className="rr-logo" alt="Rentyl Reward" />
					</Label>

					<ProgressBar totalProgressBar={2} currentStep={1} />
				</Box>

				<div className="onboardingflowOtp-body">
					<h3 className="onboardingflowOtp-body__toptextlabel">Let's confirm your email</h3>
					<div className="onboardingflowOtp-body__toptextlabel">
						<p>
							To continue, enter the secure code we sent to {props.userResponse.primaryEmail} Check junk
							mail if it's not in your inbox.
						</p>
					</div>

					<div className="onboardingflowOtp-body-container">
						<div className="onboardingflowOtp-body2">
							<form id={'otp'} onSubmit={openNextOnboardingFlow}>
								<LabelInput
									title={''}
									inputType={'number'}
									control={otpForm.get('otp')}
									updateControl={updateForm}
									labelVariant={'h6'}
									placeholder="6-digit code"
								/>

								<Label variant={'body2'} color={'red'} marginBottom={24}>
									{errorMessage}
								</Label>

								<LabelButton
									look={'containedPrimary'}
									className={'yellow'}
									disabled={!isFormComplete}
									label={'Continue'}
									variant={'buttonMdLg'}
									buttonType={'submit'}
								/>

								<Label
									onClick={() => {
										SendOtpHendler();
									}}
									variant={'body2'}
									className="request-another-secure-code-text"
									color={'#2c3c60'}
									marginBottom={24}
								>
									Request another secure code
								</Label>
							</form>
						</div>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default OnboardingFlowOtp;
