import * as React from 'react';
import './TitleDescription.scss';
import Label from '../../lib/@bit/redsky.framework.rs.label';
import { ReactNode, ReactNodeArray } from 'react';
import useIsAtBreakpoint from '../../customHooks/useIsAtBreakpoint';

interface TitleDescriptionProps {
	title: string;
	description: string | number | ReactNodeArray | ReactNode;
	className?: string;
}

const TitleDescription: React.FC<TitleDescriptionProps> = (props) => {
	const isMobile = useIsAtBreakpoint();
	return (
		<div className={`rsTitleDescription ${props.className || ''}`}>
			<Label variant={isMobile ? 'caption' : 'body1'} marginBottom={7}>
				{props.title}
			</Label>
			<Label variant={isMobile ? 'subtitle1' : 'h6'}>{props.description}</Label>
		</div>
	);
};

export default TitleDescription;
