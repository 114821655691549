import * as React from 'react';
import './MobileLightBox.scss';
import { Box, Popup, popupController, PopupProps } from '../../lib/@bit/redsky.framework.rs.996';
import IconLabel from '../../components/iconLabel/IconLabel';
import Paper from '../../components/paper/Paper';
import Label from '../../lib/@bit/redsky.framework.rs.label/dist/Label';
import CarouselButtons from '../../components/carouselButtons/CarouselButtons';
import Button from '../../lib/@bit/redsky.framework.rs.button';
import { ObjectUtils } from '../../utils/utils';
import { useEffect, useRef, useState } from 'react';
import Icon from '../../lib/@bit/redsky.framework.rs.icon';
import classNames from 'classnames';
import themes from '../../themes/themes.scss';
import { IKImage } from 'imagekitio-react';
import { getPageFinder, undefinedHandler } from '../../utils/undefinedHandler';

interface ObserverAttributes extends NamedNodeMap {
	id: any;
	tabIndex: any;
	src: any;
}

export interface MobileLightBoxProps extends PopupProps {
	imageData?: Api.Media[];
	featureData?: Misc.ImageTabProp[];
	activeTabName?: string;
	customOnBack?: () => void;
	floorPlanClass?: boolean;
}

const MobileLightBox: React.FC<MobileLightBoxProps> = (props) => {
	const carouselButtonRef = useRef<HTMLDivElement>(null);
	const imageContainerRef = useRef<HTMLDivElement>(null);
	const [activeTab, setActiveTab] = useState<Misc.ImageTabProp | undefined>(
		!!props.featureData ? props.featureData[0] : undefined
	);
	const [titleDescription, setTitleDescription] = useState<{ title: string; description: string }>();
	const [imageIndex, setImageIndex] = useState<number>(0);
	let totalChildren = getTotalImagesCount();
	const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
	const imgOptions = {
		threshold: 0.8
	};
	const imgOptionsLazy = {
		rootmargin: '0px 20% 0px 0px',
		threshold: 0
	};
	const fullProps = popupController.convertProps(props);

	useEffect(() => {
		window.history.pushState(null, 'MobileLightBox Close', window.location.pathname);
		window.addEventListener('popstate', (event: PopStateEvent) => {
			event.preventDefault();
			popupController.close(MobileLightBox);
		});
		return () => {
			window.removeEventListener('popstate', (event: PopStateEvent) => {
				event.preventDefault();
				popupController.close(MobileLightBox);
			});
		};
	}, []);

	useEffect(() => {
		let popup: NodeListOf<HTMLDivElement> = document.querySelectorAll('.rs-popup');
		if (!popup.length) return;
		popup.forEach((item) => {
			item!.style.backgroundColor = 'rgba(0,0,0,.8)';
		});
		return () => {
			popup.forEach((item) => {
				item!.style.backgroundColor = 'rgba(0,0,0,.18)';
			});
		};
	}, []);

	useEffect(() => {
		try {
			if (!props.activeTabName || !props.featureData) return;
			let newActiveTab = props.featureData.find((item) => item.title === props.activeTabName);
			if (!newActiveTab) return;

			setActiveTab(newActiveTab);
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}, [props.activeTabName]);

	useEffect(() => {
		setTimeout(() => {
			imageContainerRef.current!.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
		}, 500);
	}, [activeTab]);

	useEffect(() => {
		const imgObserver = new IntersectionObserver((entries, observer) => {
			entries.forEach((entry) => {
				if (!entry.isIntersecting) {
					return;
				} else {
					let attributes = entry.target.attributes as ObserverAttributes;
					// entry.target.setAttribute('src', attributes.datasrc.value)
					if (!!props.featureData && activeTab) {
						let activeImage = activeTab.otherMedia.find(
							(item) => item.id === parseInt(attributes.id.value)
						);
						if (!activeImage) return;
						setImageIndex(parseInt(attributes.tabIndex.value));
						setTitleDescription({ title: activeImage.title, description: activeImage.description });
					} else if (!!props.imageData) {
						let activeImage = props.imageData.find((item) => item.id === parseInt(attributes.id.value));
						if (!activeImage) return;
						setImageIndex(parseInt(attributes.tabIndex.value));
						setTitleDescription({ title: activeImage.title, description: activeImage.description });
					}
					let carouselBtnRef = carouselButtonRef.current;
					if (!carouselBtnRef) return;
					if (carouselBtnRef.classList.contains('hide')) {
						carouselBtnRef.classList.remove('hide');
					}
				}
			});
		}, imgOptions);

		const imgObserverLazy = new IntersectionObserver((entries, observer) => {
			entries.forEach((entry) => {
				if (!entry.isIntersecting) {
					return;
				} else {
					let attributes = entry.target.attributes as ObserverAttributes;
					entry.target.setAttribute('src', attributes.src.value);
				}
			});
		}, imgOptionsLazy);

		let images = document.querySelectorAll('.lightBoxImage');

		images.forEach((item) => {
			imgObserver.observe(item);
			imgObserverLazy.observe(item);
		});

		return () => {
			images.forEach((item) => {
				imgObserver.unobserve(item);
				imgObserverLazy.unobserve(item);
			});
		};
	}, [activeTab]);

	function getTotalImagesCount() {
		if (!!activeTab) {
			return activeTab.otherMedia.length - 1;
		} else if (!!props.imageData && ObjectUtils.isArrayWithData(props.imageData)) {
			return props.imageData.length - 1;
		} else {
			return 0;
		}
	}

	function renderFeatureNavButtons() {
		if (!props.featureData || !ObjectUtils.isArrayWithData(props.featureData)) return;

		return props.featureData.map((item, index) => {
			let activeTabName = activeTab ? activeTab.name : '';
			return (
				<Button
					look={'none'}
					className={'tab' + (activeTabName === item.name ? ' selected' : '')}
					onClick={() => {
						setActiveTab(item);
					}}
					key={item.name}
				>
					<Label variant="h6">{item.name}</Label>
				</Button>
			);
		});
	}

	function handleImageErrors(image: Api.Media) {
		const imageElement = document.querySelector(`[src="${image.urls.large}"]`) as HTMLImageElement;
		if (imageElement) imageElement.style.display = 'none';
	}

	function renderImages() {
		if (!!props.featureData) {
			if (!activeTab) return;
			return activeTab.otherMedia.map((image, index) => {
				return (
					<IKImage
						key={index}
						// dataid={image.id}
						// dataindex={index}
						// datasrc={image.urls.imageKit}
						id={image.id.toString()}
						tabIndex={index}
						src={image.urls.large}
						className={'lightBoxImage'}
						alt={image.title}
						width={'100%'}
						height={'auto'}
						loading="lazy"
						onError={() => handleImageErrors(image)}
					/>
				);
			});
		} else if (!!props.imageData) {
			return props.imageData.map((image, index) => {
				return (
					<IKImage
						key={index}
						// dataid={image.id}
						// dataindex={index}
						// datasrc={image.urls.imageKit}
						id={image.id.toString()}
						tabIndex={index}
						src={image.urls.large}
						className={'lightBoxImage'}
						alt={image.title}
						width={'100%'}
						height={'auto'}
						loading="lazy"
						onError={() => handleImageErrors(image)}
					/>
				);
			});
		} else {
			return '';
		}
	}
	function handleCarouselButtonClickLeft() {
		let val = imageContainerRef.current!.scrollLeft - imageContainerRef.current!.offsetWidth;
		if (imageIndex <= 0) {
			val = imageContainerRef.current!.offsetWidth * totalChildren;
			setImageIndex(totalChildren);
		}
		imageContainerRef.current!.scrollTo({ top: 0, left: val, behavior: 'smooth' });
	}
	function handleCarouselButtonClickRight() {
		let val = imageContainerRef.current!.offsetWidth + imageContainerRef.current!.scrollLeft;
		if (imageIndex >= totalChildren) {
			val = 0;
		}
		imageContainerRef.current!.scrollTo({ top: 0, left: val, behavior: 'smooth' });
	}

	function renderCarouselButtons() {
		return (
			<CarouselButtons
				carouselButtonRef={carouselButtonRef}
				className={'floorPlanCarouselButtons'}
				onClickLeft={handleCarouselButtonClickLeft}
				onClickRight={handleCarouselButtonClickRight}
			/>
		);
	}

	function renderDescriptionPaper() {
		if (!titleDescription || !titleDescription.description) return;

		return (
			<Paper borderRadius={'7px'} boxShadow className="descriptionPaper">
				<Label
					key={Date.now()} //THIS IS NEEDED TO RE-RENDER THE SHOW-MORE BTN
					variant={'body2'}
					lineClamp={4}
					showMoreButton
					showLessText={'View Less'}
					showMoreText={'View More'}
					onShowMoreTextClick={() => {
						hideShowCarouselButtons();
					}}
				>
					{titleDescription ? titleDescription.description : ''}
				</Label>
			</Paper>
		);
	}

	function hideShowCarouselButtons() {
		let carouselBtnRef = carouselButtonRef.current;
		if (!carouselBtnRef) return;
		if (carouselBtnRef.classList.contains('hide')) {
			carouselBtnRef.classList.remove('hide');
		} else {
			carouselBtnRef.classList.add('hide');
		}
	}

	function renderImageGalleryCount() {
		return (
			<Box className="imageGalleryCount">
				<Icon iconImg="icon-gallery" color={themes.white} size={16} />
				<Label className={'imageCount'} variant={'buttonSm'} ml={8} color={themes.white} showMoreButton>{`${
					imageIndex + 1
				}/${totalChildren + 1}`}</Label>
			</Box>
		);
	}

	return (
		<Popup {...props}>
			<div className={classNames('rsMobileLightBox', { floorPlan: props.floorPlanClass })}>
				<div className={'topNav'}>
					<IconLabel
						labelVariant={'h6'}
						labelName={'Back'}
						iconImg={'icon-chevron-left'}
						iconPosition={'left'}
						iconSize={12}
						onClick={() => {
							if (props.customOnBack) props.customOnBack();
							else popupController.closeById(fullProps.popupId);
						}}
					/>
				</div>
				<div className={'featureNav'}>{renderFeatureNavButtons()}</div>
				<Box className={'bottomContent'}>
					<Box className={'imageContainer'} boxRef={imageContainerRef}>
						{renderImages()}
					</Box>
					<Box className="countAndButtonRow">
						{renderImageGalleryCount()}
						{renderCarouselButtons()}
					</Box>
					{renderDescriptionPaper()}
				</Box>
			</div>
		</Popup>
	);
};

export default MobileLightBox;
