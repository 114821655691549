import * as React from 'react';
import { FormEvent, useEffect, useRef, useState, useLayoutEffect } from 'react';
import './CheckOutPaymentCard.scss';
import { Box, popupController, PopupProps } from '../../lib/@bit/redsky.framework.rs.996';
import LabelRadioButton from '../labelRadioButton/LabelRadioButton';
import { RsFormControl, RsFormGroup, RsValidator, RsValidatorEnum } from '../../lib/@bit/redsky.framework.rs.form';
import RedeemRewardCard from '../redeemRewardCard/RedeemRewardCard';
import { useRecoilState, useRecoilValue } from 'recoil';
import globalState, { setRecoilExternalValue } from '../../state/globalState';
import { rsToastify } from '../../lib/@bit/redsky.framework.rs.toastify';
import RsPagedResponseData = RedSky.RsPagedResponseData;
import useIsAtBreakpoint from '../../customHooks/useIsAtBreakpoint';
import serviceFactory from '../../services/serviceFactory';
import LabelButton from '../labelButton/LabelButton';
import CardInfoCard from '../cardInfoCard/CardInfoCard';
import UserService from '../../services/user/user.service';
import FilterBarDropDown from '../filterBarDropDown/FilterBarDropDown';
import PaymentMethod from '../paymentMethod/PaymentMethod';
import TagManager from 'react-gtm-module';
import Label from '../../lib/@bit/redsky.framework.rs.label';
import affirm_base64 from '../../images/affirm_logo';
import { NumberUtils, ObjectUtils, StringUtils, WebUtils } from '../../utils/utils';
import PaymentService from '../../services/payment/payment.service';
import { useNavigate } from 'react-router-dom';
import router from '../../utils/router';
import SpinningLoaderPopup from '../../popups/spinningLoaderPopup/SpinningLoaderPopup';
import DestinationService from '../../services/destination/destination.service';
import { getPageFinder, undefinedHandler } from '../../utils/undefinedHandler';
import AccommodationService from '../../services/accommodation/accommodation.service';
import { get } from 'lodash';

export interface CheckOutPaymentCardProps extends PopupProps {
	userPrimaryPaymentMethod: Api.User.PaymentMethod | undefined;
	bookingData: Api.Reservation.Res.Verification | undefined;
	onContinue: VoidFunction;
	onRedemption: (val: string) => void;
	isFormFilledOutCallback: (val: boolean) => void;
	onCheckoutPaymentButtonClick?: (buttonRef: React.RefObject<any>) => void;
}

enum FormKeys {
	FIRST_NAME = 'firstName',
	LAST_NAME = 'lastName',
	PHONE = 'phone',
	EMAIL = 'email',
	ADDRESS1 = 'address1',
	ADDRESS2 = 'address2',
	CITY = 'city',
	STATE = 'state',
	ZIP = 'zip',
	COUNTRY = 'country',
	NAME_ON_CARD = 'nameOnCard',
	EXPIRATION_DATE = 'expiration'
}

const CheckOutPaymentCard: React.FC<CheckOutPaymentCardProps> = (props) => {
	const isMobile = useIsAtBreakpoint();
	let navigate = useNavigate();
	const params = router.getPageUrlParams<{
		stage: number;
		data: any;
		reservationId: number;
		destinationId: number;
	}>([
		{ key: 's', default: 0, type: 'integer', alias: 'stage' },
		{ key: 'data', default: 0, type: 'string', alias: 'data' },
		{ key: 'ri', default: '', type: 'string', alias: 'reservationId' }
	]);
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const resortId: any = urlParams.get('data');
	const paymentService = serviceFactory.get<PaymentService>('PaymentService');
	const destinationService = serviceFactory.get<DestinationService>('DestinationService');
	const [isAffirmPay, setIsAffirmPay] = useRecoilState<number>(globalState.isAffirmPay);
	const [company, setCompany] = useRecoilState<Api.Company.Res.GetCompanyAndClientVariables>(globalState.company);
	const [existingCardList, setExistingCardList] = useState<Api.User.PaymentMethod[]>([]);
	const [UseYourSavedPaymentShow, setUseYourSavedPaymentShow] = useState<Api.User.PaymentMethod[]>([]);
	const [selectedPaymentMethord, setPaymentMethod] = useState<Api.User.PaymentMethod | null>(null);
	const checkoutPaymentButtonRef = useRef<HTMLButtonElement | null>();
	const user = useRecoilValue<Api.User.Res.Get | undefined>(globalState.user);
	const [checkoutUser, setCheckoutUser] = useRecoilState<Misc.Checkout | undefined>(globalState.checkoutUser);
	const reservationFilters = useRecoilValue<Misc.ReservationFilters>(globalState.reservationFilters);
	const userService = serviceFactory.get<UserService>('UserService');
	const firstLastNamePattern = new RegExp(/[a-zA-Z]+\s[a-zA-Z]+/);
	const [isTimeToSubmit, setIsTimeToSubmit] = useState<boolean>(false);
	const [differentBillingAddress, setDifferentBillingAddress] = useState<boolean>(false);
	const [isSavePaymentMethod, setIsSavePaymentMethod] = useState<boolean>(
		checkoutUser?.isExistingCard == undefined ? true : checkoutUser?.isExistingCard
	);

	const [isSavePaymentMethodGlobal, setIsSavePaymentMethodGlobal] = useRecoilState<boolean>(
		globalState.isSavePaymentMethodGlobal
	);
	const accommodationService = serviceFactory.get<AccommodationService>('AccommodationService');
	const [verifiedAccommodation, setVerifiedAccommodation] = useRecoilState<
		Api.Reservation.Res.Verification | undefined
	>(globalState.verifiedAccommodation);
	const [checkoutData, setCheckoutData] = useState<any>();
	const [isErrorOnForm, setErrorOnForm] = useState<boolean>(false);
	const [radioStep, setRadioStep] = useState(0);
	const [radioStepNew, setRadioStepNew] = useState(false);
	const checkoutFormHasError = useRecoilValue<Misc.CheckoutFormError>(globalState.checkoutFormHasError);
	const [paymentForm, setPaymentForm] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl(FormKeys.NAME_ON_CARD, checkoutUser?.paymentInfo?.nameOnCard || '', [
				new RsValidator(RsValidatorEnum.CUSTOM, 'Card Name is required', customPaymentRequired),
				new RsValidator(RsValidatorEnum.CUSTOM, '', (control: RsFormControl) => {
					return firstLastNamePattern.test(control.value.toString());
				})
			]),
			new RsFormControl(FormKeys.EXPIRATION_DATE, checkoutUser?.paymentInfo?.expiration || '', [
				new RsValidator(RsValidatorEnum.CUSTOM, 'Expiration required', customPaymentRequired),
				new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
					if (isPayingWithPoints() || isUsingExistingPaymentMethod()) return true;

					let month = parseInt(control.value.toString().slice(0, 2));
					let year = parseInt(control.value.toString().slice(3, 7));
					let currentYear = new Date().getFullYear();
					let currentMonth = new Date().getMonth() + 1;
					if (month > 12) return false;
					if (year === currentYear) return month >= currentMonth;
					else return year > currentYear;
				})
			])
		])
	);

	useLayoutEffect(() => {
		const paramsdestinationId = JSON.parse(String(params.data));
		async function getDestinationDetails(id: number) {
			// if (isMobile) return;
			try {
				const dest = await destinationService.getDestinationDetails({
					destinationId: id,
					startDate: reservationFilters.startDate,
					endDate: reservationFilters.endDate
				});
				const affirmData = dest?.installmentPaymentRate;
				let hasAffirmOption: number = 0;
				affirmData?.forEach((e: any) => {
					if (dest.isAffirmPay && e.value === paramsdestinationId?.stays[0]?.rateCode) {
						hasAffirmOption = 1;
					}
				});
				setIsAffirmPay(hasAffirmOption);
			} catch (e: any) {
				const message = `Getting error :${e.message} on ${getPageFinder(window.location.pathname)} page.`;
				undefinedHandler(message);
				rsToastify.error(
					WebUtils.getRsErrorMessage(e, 'Cannot get details for this destination.'),
					'Server Error ',
					{
						onClose: () => {
							navigate(-1);
						}
					}
				);
			}
		}

		getDestinationDetails(paramsdestinationId.destinationId).catch(console.error);
		return () => {
			setIsAffirmPay(0);
		};
	}, []);

	useEffect(() => {
		if (!user) setIsSavePaymentMethod(false);
		if (!user) return;
		let cardList: any = [];
		if (user.paymentMethods.length !== 0) {
			user.paymentMethods.map((detail) => {
				if (!detail.metaData?.isAffirmCheckout) {
					cardList.push(detail);
				}
			});
		}
		setUseYourSavedPaymentShow(cardList);

		setExistingCardList(user.paymentMethods);
		if (user.paymentMethods.length == 0) {
			setIsSavePaymentMethod(false);
		}
		if (selectedPaymentMethord == null) {
			let card = user.paymentMethods.find((card) => card.isPrimary);
			card && setPaymentMethod(card);
		}
	}, [user]);

	useEffect(() => {
		if (checkoutUser?.isAffirmCheckout) {
			setIsSavePaymentMethod(false);
			setRadioStep(2);
			setCheckoutUser({
				...checkoutUser,
				isAffirmCheckout: false
			});
		}
	}, []);

	useEffect(() => {
		const IsSavePaymentMethodGlobal = async () => {
			if (user && isSavePaymentMethod) {
				setIsSavePaymentMethodGlobal(true);
			} else if (
				!(isSavePaymentMethod
					? await paymentForm.isValid()
					: !checkoutUser?.usePoints
					? isErrorOnForm || checkoutFormHasError.card || checkoutFormHasError.cvv
					: false)
			) {
				setIsSavePaymentMethodGlobal(true);
			} else {
				setIsSavePaymentMethodGlobal(false);
			}
		};

		IsSavePaymentMethodGlobal();
	}, [isSavePaymentMethod, radioStep, isErrorOnForm, checkoutFormHasError]);

	useEffect(() => {
		debounceAffirmPromotionalMessage();
		if (checkoutUser && props.bookingData) {
			setCheckoutData({
				merchant: {
					use_vcn: true,
					name: StringUtils.getCorporateName()
				},
				shipping: {
					name: {
						full: `${checkoutUser?.billing?.firstName} ${checkoutUser?.billing?.lastName}`
					},
					address: {
						line1: `${checkoutUser?.billing?.address1}`,
						line2: `${checkoutUser?.billing?.address2}`,
						city: `${checkoutUser?.billing?.city}`,
						state: `${checkoutUser?.billing?.state}`,
						zipcode: `${checkoutUser?.billing?.zip}`,
						country: `${checkoutUser?.billing?.country}`
					},
					email: `${checkoutUser?.billing?.email}`
				},
				billing: {
					name: {
						full: `${checkoutUser?.billing?.firstName} ${checkoutUser?.billing?.lastName}`
					},
					address: {
						line1: `${checkoutUser?.billing?.address1}`,
						line2: `${checkoutUser?.billing?.address2}`,
						city: `${checkoutUser?.billing?.city}`,
						state: `${checkoutUser?.billing?.state}`,
						zipcode: `${checkoutUser?.billing?.zip}`,
						country: `${checkoutUser?.billing?.country}`
					},
					email: `${checkoutUser?.billing?.email}`
				},
				items: [
					{
						display_name: `${props?.bookingData?.destinationName}`,
						sku: '',
						unit_price: `${props?.bookingData?.prices?.accommodationTotalInCents}`,
						qty: props.bookingData?.adultCount,
						item_image_url: '',
						item_url: ''
					}
				],
				metadata: {
					mode: 'modal'
				},
				currency: 'USD',
				tax_amount: props.bookingData?.prices?.taxAndFeeTotalInCents,
				total: props.bookingData?.prices?.grandTotalCents
			});
		}
	}, [props.bookingData]);

	useEffect(() => {
		if (!isTimeToSubmit || !checkoutUser) return;
		if (checkoutUser.paymentInfo && !isSavePaymentMethod) {
			let paymentObj = {
				full_name: checkoutUser.paymentInfo.nameOnCard,
				month: Number(checkoutUser.paymentInfo.expiration.split('/')[0]),
				year: Number(checkoutUser.paymentInfo.expiration.split('/')[1])
			};
			window.Spreedly.tokenizeCreditCard(paymentObj);
			setIsTimeToSubmit(false);
		} else {
			props.onContinue();
		}
	}, [isTimeToSubmit, checkoutUser]);

	useEffect(() => {
		if (!checkoutPaymentButtonRef) return;
		(function bubbleUpButtonRef() {
			if (!props.onCheckoutPaymentButtonClick) return;
			props.onCheckoutPaymentButtonClick(checkoutPaymentButtonRef);
		})();
	}, [checkoutPaymentButtonRef]);

	useEffect(() => {
		setPaymentForm(paymentForm.clone());
	}, [checkoutUser, differentBillingAddress]);

	// useEffect(() => {
	// 	if (
	// 		paymentForm.get(FormKeys.NAME_ON_CARD).value.toString() &&
	// 		paymentForm.get(FormKeys.EXPIRATION_DATE).value.toString()
	// 	) {
	// 		setErrorOnForm(true);
	// 	}
	// }, [paymentForm]);

	/***
	 * 		BELOW IS THE MOCK DATA OF "AFFIRM CHECKOUT RESPONSE" 
			// {
			//     "checkout_token": "F53AOSF8BB0NVMG6",
			//     "cvv": "123",
			//     "created": "2022-12-08T13:06:39.494217",
			//     "number": "4111111111111111",
			//     "charge_ari": "3KES-TOKA",
			//     "cardholder_name": "AffirmInc Gaurav Vanani",
			//     "expiration": "0923",
			//     "billing_address": {
			//         "city": "San Francisco",
			//         "state": "CA",
			//         "zipcode": "94108",
			//         "line1": "650 California St.",
			//         "line2": "12th Floor"
			//     },
			//     "callback_id": "b019dfe7-4130-49d6-8d49-a06a616b1d20",
			//     "id": "F53AOSF8BB0NVMG6"
			// }
	 */

	const affirmCheckout = () => {
		if (checkoutData && props.bookingData) {
			popupController.open(SpinningLoaderPopup);
			window.affirm.checkout.open_vcn({
				checkout_data: checkoutData,
				success: async function (card_response: any) {
					try {
						const cardholder_name = card_response.cardholder_name.split(' ');
						const year = card_response.expiration.slice(2);
						const month = card_response.expiration.slice(0, 2);

						const isProduction =
							window.location.href.split('?')[0].indexOf('https://book.rentylresorts.com') > -1;
						const isUAT =
							window.location.href.split('?')[0].indexOf('https://uat.book.rentylresorts.com/') > -1;

						const affirmData = {
							payment_method: {
								credit_card: {
									first_name: cardholder_name[1],
									last_name: cardholder_name[2],
									number: card_response.number,
									verification_value: card_response.cvv,
									month: month,
									year: isProduction || isUAT ? `20${year}` : `2029`
								}
							}
						};

						const spreedlyRes = await paymentService.checkoutInSpreedly(affirmData);

						if (spreedlyRes && spreedlyRes?.status && spreedlyRes?.status !== 200) {
							popupController.close(SpinningLoaderPopup);
							rsToastify.error(`${JSON.parse(spreedlyRes?.msg).errors[0]?.message}`, 'Server Error');
							return;
						}

						const CheckoutUserData: any = {
							...checkoutUser,
							existingCardId: undefined,
							isExistingCard: false,
							pmData: spreedlyRes.transaction.payment_method,
							paymentInfo: {
								nameOnCard: card_response.cardholder_name,
								expiration: `${month}/20${year}`
							},
							isAffirmCheckout: true
						};
						// if(card_response && card_response.cardholder_name && card_response.expiration  && result && result.id && spreedlyRes && spreedlyRes.transaction  && spreedlyRes.transaction.payment_method ){
						setCheckoutUser(CheckoutUserData);
						userService.setCheckoutUserInLocalStorage(CheckoutUserData);
						const isRedeemPoints = reservationFilters.redeemPoints ? '&redeemPoints=true' : '';
						popupController.close(SpinningLoaderPopup);
						navigate(`/booking/checkout?s=${params.stage + 1}&data=${params.data}${isRedeemPoints}`);
						// }
					} catch (e: any) {
						popupController.close(SpinningLoaderPopup);
					}
				},
				error: function (error_response: any) {
					popupController.close(SpinningLoaderPopup);
					//add logic on handling a declination or abandoned checkout here. We recommend redirecting users to the payment selection screen to choose an alternative payment method.
				},
				onValidationError: function (checkout_validation_error: any) {
					popupController.close(SpinningLoaderPopup);
				}
			});
		}
	};

	function checkPointEligibility(userPoint: any, bookingPriceInPoint: any) {
		/* To activate RedeemRewardCard
			if (userPoint >= bookingPriceInPoint) {
				return true;
			} else {
				return false;
			}
		*/

		return false;
	}

	// checkPointEligibility(user?.availablePoints, props.bookingData?.prices.grandTotalPoints);

	function isPayingWithPoints() {
		// This is used because for some reason I can't access stateful variables from within form controls
		return (document.querySelector('.isPayingWithPoints [id^="RsSwitch_"]') as HTMLInputElement)?.checked || false;
	}

	function isUsingExistingPaymentMethod() {
		// This is used because for some reason I can't access stateful variables from within form controls
		return (
			(document.querySelector('.isUsingExistingPaymentMethod [id^="RsSwitch_"]') as HTMLInputElement)?.checked ||
			false
		);
	}

	function debounceAffirmPromotionalMessage() {
		setTimeout(() => {
			window.affirm.ui.ready(function () {
				window.affirm.ui.refresh();
			});
		}, 400);
	}

	function customPaymentRequired(control: RsFormControl) {
		return isPayingWithPoints() || isUsingExistingPaymentMethod() || !!control.value.toString();
	}

	function updateForm(control: RsFormControl) {
		if (control.key === FormKeys.COUNTRY) {
			const stateControl = paymentForm.get(FormKeys.STATE);
			stateControl.value = '';
			paymentForm.update(stateControl);
		}
		if (control.key == FormKeys.EXPIRATION_DATE) {
			const digitsOnly = /[^\d\/]/g;
			control.value = control.value.toString().replace(digitsOnly, '');
		}
		setPaymentForm(paymentForm.clone().update(control));
	}

	function buildCheckoutUser(): Misc.Checkout | undefined {
		if (!checkoutUser) return;
		let newCheckoutUser = {
			...checkoutUser,
			isExistingCard: isSavePaymentMethod,
			existingCardId: selectedPaymentMethord?.id
		};
		if (!reservationFilters.redeemPoints) {
			newCheckoutUser.paymentInfo = {
				expiration: paymentForm.get(FormKeys.EXPIRATION_DATE).value.toString(),
				nameOnCard: paymentForm.get(FormKeys.NAME_ON_CARD).value.toString()
			};
			setCheckoutUser(newCheckoutUser);
			userService.setCheckoutUserInLocalStorage(newCheckoutUser);
		} else delete newCheckoutUser.paymentInfo;
		return newCheckoutUser;
	}

	const googleCheckoutProcess2 = async () => {
		const destinationId = JSON.parse(params.data);

		const searchQueryObj: Misc.ReservationFilters = { ...reservationFilters };
		let key: keyof Misc.ReservationFilters;
		for (key in searchQueryObj) {
			if (searchQueryObj[key] === undefined) delete searchQueryObj[key];
		}
		const results: RsPagedResponseData<Api.Accommodation.Res.MinMaxAvailability> =
			await accommodationService.availability(destinationId.destinationId, searchQueryObj);

		const accommodationStay = results?.data.find((stay: any) => stay.id === verifiedAccommodation?.accommodationId);

		const prices = accommodationStay?.prices.find(
			(stay: any) => stay.rate.code === verifiedAccommodation?.rateCode
		);

		const checkout_step_2 = {
			item_name: verifiedAccommodation?.accommodationName,
			item_id: get(accommodationStay, 'externalSystemId', verifiedAccommodation?.accommodationId),
			price: NumberUtils.centsToDollars(prices?.accommodationTotalInCents),
			item_brand: verifiedAccommodation?.destinationName,
			item_category: get(accommodationStay, 'contentLists.RoomCategoryList.0.Name', null),
			item_category2: get(accommodationStay, 'contentLists.RoomCategoryList.0.Name', null),
			quantity: 1, // we'll consider 1 room as a item
			item_list_name: 'Rooms',
			arrival_date: searchQueryObj.startDate,
			depart_date: searchQueryObj.endDate,
			total_cost: NumberUtils.centsToDollars(prices.grandTotalCents),
			payment_type: radioStep === 2 ? 'Monthly Payment' : 'Card Payment',
			currency: prices.rate.currencyCode
		};

		try {
			TagManager.dataLayer({
				// Clear the previous ecommerce object.
				dataLayer: {
					event: 'checkout-step-2',
					ecommerce: null
				}
			});

			TagManager.dataLayer({
				dataLayer: {
					event: 'checkout-step-2',
					ecommerce: {
						checkout: {
							actionField: {
								step: 2,
								option: 'Payment'
							},
							products: [checkout_step_2]
						}
					}
				}
			});
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	};

	async function submitInfo(event: FormEvent<HTMLFormElement>): Promise<void> {
		event.preventDefault();
		try {
			if (!isSavePaymentMethod && !reservationFilters.redeemPoints && !(await paymentForm.isValid())) {
				setPaymentForm(paymentForm.clone());
				props.isFormFilledOutCallback(false);
				rsToastify.error('Please ensure you have filled out all fields', 'Missing or Incorrect Information');
				return;
			}
			props.isFormFilledOutCallback(true);
			const newCheckoutUser = buildCheckoutUser();
			if (newCheckoutUser) {
				userService.setCheckoutUserInLocalStorage(newCheckoutUser);
				setCheckoutUser(newCheckoutUser);
				setIsTimeToSubmit(true);
			}
		} catch (e) {
			props.isFormFilledOutCallback(false);
			rsToastify.error('Payment information is invalid', 'Missing or Incorrect Information');
		}

		googleCheckoutProcess2();
	}

	function canShowCardForm() {
		if (!user) return true;
		return !reservationFilters.redeemPoints && !isSavePaymentMethod && radioStepNew;
	}

	const buttonHandler = (brandType: any) => {
		let companyid;
		if (brandType) {
			companyid = JSON.parse(brandType).themeId;
		} else {
			companyid = null;
		}

		let bgColor = '';
		switch (companyid) {
			case 3:
				bgColor = 'encorereunion';
				// bgColor = '#e51c14';
				break;
			case 78:
				bgColor = 'cottagesbyrentyl';
				break;

				bgColor = 'cottagesbyrentyl';
				break;

			case 9:
				bgColor = 'bearsdenorlando';
				break;
			case 10:
				bgColor = 'resortsbyspectrum';
				break;
			default:
				bgColor = 'yellow';
				break;
		}

		return (
			<LabelButton
				className={`${bgColor} primaryButtonPayment`}
				look={'none'}
				variant={'buttonMdLg'}
				label={'Review & Book'}
				buttonType={'submit'}
				buttonRef={checkoutPaymentButtonRef}
				disabled={
					isSavePaymentMethod
						? !selectedPaymentMethord
						: !checkoutUser?.usePoints
						? isErrorOnForm || checkoutFormHasError.card || checkoutFormHasError.cvv
						: false
				}
			/>
		);
	};

	return (
		<>
			<div className="checkoutpayment-col-container">
				<div
					className={
						!checkoutUser?.usePoints &&
						user &&
						existingCardList.length > 0 &&
						checkPointEligibility(user?.availablePoints, props.bookingData?.prices.grandTotalPoints) &&
						'checkoutpayment-col-1'
					}
				>
					<Box className={`rsCheckOutPaymentCard`}>
						{!reservationFilters.redeemPoints && (
							<Box className="payment-tabs">
								{!checkoutUser?.usePoints && user && existingCardList.length >= 0 ? (
									<>
										<div
											className={`${
												radioStep === 0
													? 'payment-option-button'
													: 'payment-option-button-active'
											}`}
											onClick={() => {
												// setIsSavePaymentMethod(true);
												setPaymentForm(paymentForm.resetToInitialValue());
												setRecoilExternalValue<Misc.CheckoutFormError>(
													globalState.checkoutFormHasError,
													{
														cvv: true,
														card: true
													}
												);
												setRadioStep(0);
												setRadioStepNew(false);
											}}
										>
											Credit/Debit Card
										</div>
										{isAffirmPay === 1 && (
											<div
												className={` ${
													radioStep === 2
														? 'payment-option-button'
														: 'payment-option-button-active'
												}`}
												onClick={() => {
													setIsSavePaymentMethod(false);
													setPaymentForm(paymentForm.resetToInitialValue());
													setRecoilExternalValue<Misc.CheckoutFormError>(
														globalState.checkoutFormHasError,
														{
															cvv: true,
															card: true
														}
													);
													debounceAffirmPromotionalMessage();
													setRadioStep(2);
													setRadioStepNew(false);
												}}
											>
												Monthly Payment
											</div>
										)}
									</>
								) : (
									<>
										<div
											className={` ${
												radioStep === 0
													? 'payment-option-button'
													: 'payment-option-button-active'
											}`}
											onClick={() => {
												setPaymentForm(paymentForm.resetToInitialValue());
												setRecoilExternalValue<Misc.CheckoutFormError>(
													globalState.checkoutFormHasError,
													{
														cvv: true,
														card: true
													}
												);
												setRadioStep(0);
												setRadioStepNew(false);
											}}
										>
											Credit/Debit Card
										</div>
										{isAffirmPay === 1 && (
											<div
												className={` ${
													radioStep === 2
														? 'payment-option-button'
														: 'payment-option-button-active'
												}`}
												onClick={() => {
													setPaymentForm(paymentForm.resetToInitialValue());
													setRecoilExternalValue<Misc.CheckoutFormError>(
														globalState.checkoutFormHasError,
														{
															cvv: true,
															card: true
														}
													);
													debounceAffirmPromotionalMessage();
													setRadioStep(2);
													setRadioStepNew(false);
												}}
											>
												Monthly Payments
											</div>
										)}
									</>
								)}
							</Box>
						)}
						<form onSubmit={submitInfo}>
							{!reservationFilters.redeemPoints && (
								<>
									{/* {!checkoutUser?.usePoints && user && existingCardList.length >= 0 ? (
											// className="paymentMethodSelector"
											<Box className="payment-tabs">
												{UseYourSavedPaymentShow && UseYourSavedPaymentShow.length ? (
													<LabelRadioButton
														radioName="paymentMethod"
														value="isSavePaymentMethod"
														checked={radioStep == 0}
														text={`Use your saved payment method`}
														onSelect={() => {
															setIsSavePaymentMethod(true);
															setRadioStep(0);
														}}
														labelVariant="h6"
														className="priceHighRadio"
													/>
												) : null}
												<LabelRadioButton
													radioName="paymentMethod"
													value="isSavePaymentMethod"
													checked={radioStep == 1}
													text="Add new payment detail"
													onSelect={() => {
														setIsSavePaymentMethod(false);
														setRadioStep(1);
													}}
													labelVariant="h6"
												/>
												{isAffirmPay === 1 && (
													<LabelRadioButton
														radioName="paymentMethod"
														value="isSavePaymentMethod"
														checked={radioStep == 2}
														text="Monthly Payment"
														onSelect={() => {
															setIsSavePaymentMethod(false);
															debounceAffirmPromotionalMessage();
															setRadioStep(2);
														}}
														labelVariant="h6"
													/>
												)}
											</Box>
										) : (
											<>
												<LabelRadioButton
													radioName="paymentMethod"
													value="isSavePaymentMethod"
													checked={radioStep == 1}
													text="Add new payment detail"
													onSelect={() => {
														setIsSavePaymentMethod(false);
														setRadioStep(1);
													}}
													labelVariant="h6"
												/>
												
												{isAffirmPay === 1 && (
													<LabelRadioButton
														radioName="paymentMethod"
														value="isSavePaymentMethod"
														checked={radioStep == 2}
														text="Monthly Payment"
														onSelect={() => {
															setIsSavePaymentMethod(false);
															debounceAffirmPromotionalMessage();
															setRadioStep(2);
														}}
														labelVariant="h6"
													/>
												)}
											</>
										)} */}
									{/* {!checkoutUser?.usePoints && user && existingCardList.length >= 0 ? (
											<Box className="payment-tabs">
												{UseYourSavedPaymentShow && UseYourSavedPaymentShow.length ? (
													<button
														className={`payment-tab ${radioStep === 0 ? 'active' : ''}`}
														onClick={() => {
															setIsSavePaymentMethod(true);
															setRadioStep(0);
														}}
													>
														Use your saved payment method
													</button>
												) : null}
												<button
													className={`payment-tab ${radioStep === 1 ? 'active' : ''}`}
													onClick={() => {
														setIsSavePaymentMethod(false);
														setRadioStep(1);
													}}
												>
													Add new payment detail
												</button>
												{isAffirmPay === 1 && (
													<button
														className={`payment-tab ${radioStep === 2 ? 'active' : ''}`}
														onClick={() => {
															setIsSavePaymentMethod(false);
															debounceAffirmPromotionalMessage();
															setRadioStep(2);
														}}
													>
														Monthly Payment
													</button>
												)}
											</Box>
										) : (
											<>
												<button
													className={`payment-tab ${radioStep === 1 ? 'active' : ''}`}
													onClick={() => {
														setIsSavePaymentMethod(false);
														setRadioStep(1);
													}}
												>
													Add new payment detail
												</button>
												{isAffirmPay === 1 && (
													<button
														className={`payment-tab ${radioStep === 2 ? 'active' : ''}`}
														onClick={() => {
															setIsSavePaymentMethod(false);
															debounceAffirmPromotionalMessage();
															setRadioStep(2);
														}}
													>
														Monthly Payment
													</button>
												)}
											</>
										)} */}

									{/* 
										{radioStep == 2 && (
											<div className={'rsPersonalInformationWrapper'}>
												<div className={'rsPersonalInformation'}>
													<div className={'rsPersonalInformationWrapper'}>
														<p className={'payLaterTextInfo'}>
															Book now, pay later with
															<img className={'affirmlogo'} src={affirm_base64} />
														</p>
													</div>
													<p
														className="affirm-as-low-as textInfo"
														data-page-type="category"
														data-amount={props.bookingData?.prices?.grandTotalCents}
													></p>
													<div className={'rsPersonalInformationWrapper'}>
														<p className="textInfo">
															Affirm makes it easy to book your getaway today, then pay
															over time - even past the dates you book. Find out if you're
															eligible by filling out a few pieces of basic information.
															Your credit score won't be affected by checking.
														</p>
													</div>
												</div>
												<div className={'rsPersonalInformation'}>
													<div className={'billingAddressWrapper'}>
														<p className="impInfoText">Important information</p>
														<ul className="textInfo">
															<li>
																This rate is non-refundable. If you change or cancel
																your booking you will not get a refund or credit to use
																for a future stay. This policy will apply regardless of
																COVID-19, subject to any local consumer laws.
															</li>
															<li>
																No refunds will be issued for late check-in or early
																check-out.
															</li>
															<li>Stay extensions require a new reservation.</li>
															<li>Front desk staff will greet guests on arrival.</li>
														</ul>
														<p className="textInfo">
															By clicking on the button below, I acknowledge that I have
															reviewed the{' '}
															<a
																href="https://www.affirm.com/privacy"
																className="learnMoreAffirm"
															>
																Privacy Statement
															</a>{' '}
															and{' '}
															<a href="#" className="learnMoreAffirm">
																Government Travel Advice
															</a>{' '}
															and have reviewed and accept the{' '}
															<a href="#" className="learnMoreAffirm">
																Rules & Restrictions
															</a>{' '}
															and{' '}
															<a
																href="https://www.affirm.com/terms"
																className="learnMoreAffirm"
															>
																Terms of Use
															</a>
															.
														</p>
													</div>
													<div className="continueButton" onClick={affirmCheckout}>
														Continue to Affirm
													</div>
													<p className="textInfo">
														We use secure transmission and encrypted storage to protect your
														personal information.
													</p>
													<p className="textInfo">
														This payment will be processed in the U.S. This does not apply
														when the travel provider (airline/hotel/rail, etc.) processes
														your payment.
													</p>
												</div>
											</div>
										)} */}
									{radioStep === 0 && (
										<Box className="cardSelectWarper">
											<>
												{user && (
													<Box className="cardSelectWarper-iner">
														<Box className="FilterBarDropDown">
															<FilterBarDropDown
																onChangeCallBack={() => {
																	setRadioStepNew(false);
																	setIsSavePaymentMethod(true);
																}}
																fieldName={'Selected Card'}
																onClearCallback={() => {
																	setPaymentMethod(null);
																}}
																title={
																	selectedPaymentMethord
																		? `${selectedPaymentMethord.nameOnCard} - ${
																				selectedPaymentMethord.type
																		  } ending in ${selectedPaymentMethord.cardNumber.slice(
																				selectedPaymentMethord.cardNumber
																					.length - 4,
																				selectedPaymentMethord.cardNumber.length
																		  )}`
																		: 'Select payment method'
																}
																dropdownContentClassName="cardDropdown"
																hasIconSpinAnimation
															>
																<Box padding={24}>
																	{existingCardList.map((card, index) => {
																		if (!card?.metaData?.isAffirmCheckout) {
																			return (
																				<LabelRadioButton
																					key={index}
																					radioName="cardSelector"
																					value="sortHigh"
																					checked={
																						card.id ==
																						selectedPaymentMethord?.id
																					}
																					text={`${card.nameOnCard} - ${
																						card.type
																					} ending in ${card.cardNumber.slice(
																						card.cardNumber.length - 4,
																						card.cardNumber.length
																					)}`}
																					onSelect={() => {
																						setIsSavePaymentMethod(true);
																						setPaymentMethod(card);
																					}}
																					labelVariant="h6"
																					className="priceHighRadio"
																				/>
																			);
																		}
																	})}
																</Box>
															</FilterBarDropDown>
															<Box
																style={{
																	display: 'flex',
																	justifyContent: 'center',
																	alignItems: 'center'
																}}
																onClick={() => {
																	setIsSavePaymentMethod(false);
																	// setRadioStep(1);
																	setRadioStep(0);
																	setRadioStepNew(true);
																}}
															>
																<p style={{ margin: '20px', cursor: 'pointer' }}>
																	Add New Card
																</p>
															</Box>
														</Box>
														{selectedPaymentMethord && radioStep == 0 && !radioStepNew && (
															<Box className="selectedCardView">
																<PaymentMethod
																	userCheckout={{
																		pmData: {
																			first_name:
																				selectedPaymentMethord.nameOnCard,
																			last_name: ' ',
																			cardType: selectedPaymentMethord.type,
																			card_type: selectedPaymentMethord.type,
																			last_four_digits:
																				selectedPaymentMethord.cardNumber.slice(
																					selectedPaymentMethord.cardNumber
																						.length - 4,
																					selectedPaymentMethord.cardNumber
																						.length
																				)
																		}
																	}}
																	radioStepId={radioStep}
																	userPrimaryPaymentMethod={undefined}
																	onEditClickCallback={undefined}
																/>

																{!checkoutUser?.usePoints &&
																	user &&
																	existingCardList.length > 0 &&
																	isMobile &&
																	checkPointEligibility(
																		user?.availablePoints,
																		props.bookingData?.prices.grandTotalPoints
																	) && (
																		<RedeemRewardCard
																			bookingData={props.bookingData}
																			onRedemption={props.onRedemption}
																		/>
																	)}
															</Box>
														)}
													</Box>
												)}
												{canShowCardForm() && (
													<CardInfoCard
														form={paymentForm}
														onUpdate={updateForm}
														onFormError={(isError) => {
															setErrorOnForm(isError);
														}}
													/>
												)}
											</>
										</Box>
									)}
									{radioStep == 2 && (
										<div className={'rsPersonalInformation-affim'}>
											<div className={'affirm-as-low-as textInfo'}>
												<p className={'payLaterTextInfo'}>
													Book now, pay later with
													<img className={'affirmlogo'} src={affirm_base64} />
												</p>
											</div>
											<p
												className="affirm-as-low-as textInfo"
												data-page-type="category"
												data-amount={props.bookingData?.prices?.grandTotalCents}
											></p>
											<div className={'affirm-as-low-as textInfo'}>
												<p className="textInfo">
													Affirm makes it easy to book your getaway today, then pay over time
													- even past the dates you book. Find out if you're eligible by
													filling out a few pieces of basic information. Your credit score
													won't be affected by checking.
												</p>
											</div>
											<div className={'affirm-as-low-as textInfo'}>
												<p style={{ color: '#4A4AF4' }} className={'payLaterTextInfo'}>
													Import information
												</p>
											</div>
											<div className={'affirm-as-low-as textInfo'}>
												<ul>
													<li>
														This rate is non-refundable. If you change or cancel your
														booking you will not get a refund or credit to use for a future
														stay. This policy will apply regardless of COVID-19, subject to
														any local consumer laws.
													</li>
													<li>
														No refunds will be issued for late check-in or early check-out.
													</li>
													<li>Stay extensions require a new reservation.</li>
													<li>Front desk staff will greet guests on arrival.</li>
												</ul>
											</div>
											<div className={'affirm-as-low-as textInfo'}>
												<p className="textInfo">
													By clicking on the button below, I acknowledge that I have reviewed
													the Privacy Statement and Government Travel Advice and have reviewed
													and accept the Rules & Restrictions and Terms of Use .
												</p>
											</div>
											<div
												style={{ backgroundColor: '#4A4AF4', color: 'white' }}
												className="continueButton"
												onClick={affirmCheckout}
											>
												Continue to Affirm
											</div>
											<div className={'affirm-as-low-as textInfo'}>
												<p className="textInfo">
													We use secure transmission and encrypted storage to protect your
													personal information.
												</p>
											</div>
											<div className={'affirm-as-low-as textInfo'}>
												<p className="textInfo">
													This payment will be processed in the U.S. This does not apply when
													the travel provider (airline/hotel/rail, etc.) processes your
													payment.
												</p>
											</div>
										</div>
									)}
								</>
							)}
							<Box className={'fieldGroup stretchedInput rightSide'}>{buttonHandler(resortId)}</Box>
						</form>
					</Box>
				</div>
				{!checkoutUser?.usePoints &&
					user &&
					existingCardList.length > 0 &&
					!isMobile &&
					checkPointEligibility(user?.availablePoints, props.bookingData?.prices.grandTotalPoints) && (
						<div className="checkoutpayment-col-2">
							<RedeemRewardCard bookingData={props.bookingData} onRedemption={props.onRedemption} />
						</div>
					)}
			</div>
		</>
	);
};

export default CheckOutPaymentCard;
