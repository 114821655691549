import * as React from 'react';
import Label from '../../../lib/@bit/redsky.framework.rs.label';
import moment from 'moment';
import { useRecoilState } from 'recoil';
import globalState from '../../../state/globalState';
import { StringUtils } from '../../../utils/utils';
import './FlexibleRatesResponsive.scss';
import Icon from '../../../lib/@bit/redsky.framework.rs.icon';

export interface FlexibleRatesResponsiveProps {
	onRateClick: (sDate: string, eDate: string, rate: any, item?: any, prices?: any) => void;
	departureDateArray: string[];
	tableData: any;
	minMaxPrice: {
		min: number | null;
		max: number | null;
	};
}

const FlexibleRatesResponsive: React.FC<FlexibleRatesResponsiveProps> = (props) => {
	const [reservationFilters, setReservationFilters] = useRecoilState<Misc.ReservationFilters>(
		globalState.reservationFilters
	);

	return (
		<div className="flexibleRaterResponsive">
			{!!props.departureDateArray && !!props.tableData && props.departureDateArray.length > 0 && (
				<table className={'flexibleRateTable'}>
					<thead>
						<tr className={'headerRow'}>
							<td colSpan={7} className="departureTab">
								<Label variant="body1" className="boldText">
									Departure &nbsp;
									<Icon iconImg={'icon-chevron-right'} size={16} />
								</Label>
							</td>
							<td rowSpan={2} className="returnTab">
								<Label variant="body1" className="boldText">
									Return &nbsp;
									<Icon iconImg={'icon-chevron-down'} size={16} />
								</Label>
							</td>
						</tr>
						<tr className={'headerRow'}>
							{props.departureDateArray.map((date: string) => {
								let active = date == reservationFilters.startDate ? true : false;
								return (
									<td className={active ? 'activeDate' : ''}>
										<Label variant={'body1'}>
											{moment(date, 'YYYY-MM-DD').format('ddd, MMM D')}
										</Label>
									</td>
								);
							})}
						</tr>
					</thead>
					<tbody>
						{Object.keys(props.tableData).map((key: string, index: number) => {
							let active = key == reservationFilters.endDate ? true : false;
							return (
								<tr key={index} className={'bodyRow'}>
									{props.tableData[key].map((item: any, i: number) => {
										let isSearchedDate =
											item.startDate == reservationFilters.startDate ||
											item.endDate == reservationFilters.endDate
												? true
												: false;
										let searchedDate =
											item.startDate == reservationFilters.startDate &&
											item.endDate == reservationFilters.endDate
												? true
												: false;

										const price = !!item.prices && item?.prices[0]?.grandTotalCents;

										return (
											<td
												className={
													searchedDate
														? 'searchedDate'
														: isSearchedDate
														? 'activeCol'
														: !item.prices || price == null || price == undefined
														? 'cursorDefault'
														: ''
												}
												onClick={() => {
													if (!item.prices || price == null || price == undefined) {
														return null;
													} else {
														props.onRateClick(
															item.startDate,
															item.endDate,
															item?.prices[0]?.rate,
															item,
															item?.prices[0]
														);
													}
												}}
											>
												<Label
													variant={'body1'}
													className={
														props.minMaxPrice.min == price
															? 'minValue'
															: props.minMaxPrice.max == price
															? 'maxValue'
															: ''
													}
												>
													{!item.prices || price == null || price == undefined
														? '-'
														: StringUtils.formatCurrency(
																item?.prices[0]?.rate?.currencyCode
														  ) + StringUtils.formatMoney(price)}
												</Label>
											</td>
										);
									})}

									<td className={active ? 'activeDate' : 'endDateTitle'}>
										<Label variant={'body1'}>
											{moment(key, 'YYYY-MM-DD').format('ddd, MMM D')}
										</Label>
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			)}
		</div>
	);
};

export default FlexibleRatesResponsive;
