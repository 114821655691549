import * as React from 'react';
import './RecoverPasswordPopup.scss';
import { Box, Popup, popupController, PopupProps } from '../../lib/@bit/redsky.framework.rs.996';
import Label from '../../lib/@bit/redsky.framework.rs.label';
import LabelInput from '../../components/labelInput/LabelInput';
import LabelButton from '../../components/labelButton/LabelButton';
import serviceFactory from '../../services/serviceFactory';
import UserService from '../../services/user/user.service';
import { useState, useEffect } from 'react';
import { RsFormControl, RsFormGroup, RsValidator, RsValidatorEnum } from '../../lib/@bit/redsky.framework.rs.form';
import { rsToastify } from '../../lib/@bit/redsky.framework.rs.toastify';
import SigninPopup from '../signin/SigninPopup';
import { useNavigate } from 'react-router-dom';
import PasswordValidator from '../../components/passwordValidator/PasswordValidator';

export interface RecoverPasswordPopupProps extends PopupProps {
	accountAccess: 'recovery' | 'welcome';
	passwordResetGuid: string;
}

const RecoverPasswordPopup: React.FC<RecoverPasswordPopupProps> = (props) => {
	let navigate = useNavigate();
	const userService = serviceFactory.get<UserService>('UserService');
	const [canSubmit, setCanSubmit] = useState<boolean>(false);
	const [passwordValidatorCheck, setPasswordValidatorCheck] = useState<boolean>(false);
	const [passwordResetFormGroup, setPasswordResetFormGroup] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl('newPassword', '', [new RsValidator(RsValidatorEnum.REQ, 'Password is required')]),
			new RsFormControl('confirmPassword', '', [
				new RsValidator(RsValidatorEnum.REQ, 'Password is required'),
				new RsValidator(RsValidatorEnum.CUSTOM, 'Passwords do not match', (control): boolean => {
					return control.value.toString() === passwordResetFormGroup.get('newPassword').value.toString();
				})
			])
		])
	);

	async function handleReset() {
		try {
			const newPassword = passwordResetFormGroup.get('newPassword').value.toString();

			if (!canSubmit) {
				setPasswordResetFormGroup(passwordResetFormGroup.clone());
				rsToastify.error('Invalid password.', 'Failed to set Password');
				return;
			}
			await userService.resetPasswordByGuid(props.passwordResetGuid, newPassword);
			popupController.close(RecoverPasswordPopup);
			popupController.open(SigninPopup);
			rsToastify.success('Password update was successful', 'Password Successfully Updated');
			navigate('/reservation/availability');
		} catch (e) {
			rsToastify.error('Password update failed. Try again.', 'Failed to Update Password');
		}
	}

	function updatePasswordResetFormGroup(control: RsFormControl) {
		setPasswordResetFormGroup(passwordResetFormGroup.clone().update(control));
	}

	function renderPopupTitle() {
		if (props.accountAccess === 'recovery') {
			return 'Recover Password';
		} else {
			return 'Welcome! Set Password';
		}
	}

	useEffect(() => {
		(async function () {
			const isFormValid: boolean = await passwordResetFormGroup.isValid();
			setCanSubmit(isFormValid && passwordValidatorCheck);
		})();
	}, [passwordResetFormGroup, passwordValidatorCheck]);

	return (
		<Popup {...props} preventCloseByBackgroundClick>
			<Box className={'rsRecoverPasswordPopup'}>
				<Box className={'popupTitle'}>
					<Label variant={'h5'} className={'primaryTextColor'}>
						{renderPopupTitle()}
					</Label>
				</Box>
				<Box className={'popupBody'}>
					<LabelInput
						className={'passwordInput'}
						title={'Create New Password'}
						inputType={'password'}
						labelVariant={'h6'}
						control={passwordResetFormGroup.get('newPassword')}
						updateControl={updatePasswordResetFormGroup}
					/>
					<LabelInput
						className={'passwordInput'}
						title={'Confirm New Password'}
						inputType={'password'}
						labelVariant={'h6'}
						control={passwordResetFormGroup.get('confirmPassword')}
						updateControl={updatePasswordResetFormGroup}
					/>
					<Box>
						<PasswordValidator
							password={passwordResetFormGroup.get('newPassword').value.toString()}
							onValidationStatusUpdate={(isValid) => setPasswordValidatorCheck(isValid)}
						/>
					</Box>

					<LabelButton
						look={'containedPrimary'}
						variant={'buttonMdLg'}
						label={'Update'}
						className={'yellow'}
						disabled={!canSubmit}
						onClick={handleReset}
					/>
				</Box>
			</Box>
		</Popup>
	);
};

export default RecoverPasswordPopup;
