import * as React from 'react';
import './AccountAddressTile.scss';
import Label from '../../lib/@bit/redsky.framework.rs.label/dist/Label';
import { Box } from '../../lib/@bit/redsky.framework.rs.996';
import Icon from '../../lib/@bit/redsky.framework.rs.icon';
import useIsAtBreakpoint from '../../customHooks/useIsAtBreakpoint';
import { NumberUtils } from '../../utils/utils';
import { get } from 'lodash';
import { getPageFinder, undefinedHandler } from '../../utils/undefinedHandler';

interface AddressTileProps {
	address: Api.User.Address;
	onDelete: () => void;
	onEdit: () => void;
	onPrimaryChange: (addressId: number) => void;
	isDefault: 1 | 0;
}

const AccountAddressTile: React.FC<AddressTileProps> = (props) => {
	const isMobile = useIsAtBreakpoint();
	return (
		<div className={'rsAccountAddressTile'}>
			{/* 	<Box className="labelContainer">
				{props.address.isDefault ? (
					<Label variant={'h6'} mb={16}>
						Primary
					</Label>
				) : (
					<Label
						variant={'h6'}
						className={'primaryTextColor'}
						onClick={() => {
							props.onPrimaryChange(props.address.id);
						}}
					>
						Set as Primary
					</Label>
				)}
				{!isMobile && (
					<Label variant={'h6'} className={'goldTextColor'} onClick={props.onEdit}>
						Edit
					</Label>
				)}
			</Box> */}

			<Box
				// display={isMobile ? 'flex' : ''}
				alignItems={isMobile ? 'center' : ''}
				justifyContent={isMobile ? 'space-between' : ''}
			>
				<div>
					<Label variant={'h6Address'}>{get(props, 'address.name')}</Label>
					<Label variant={'h6Address'}>{get(props, 'address.address1')}</Label>
					{!!props.address.address2 && <Label variant={'h6Address'}>{get(props, 'address.address2')}</Label>}
					<Label variant={'h6Address'}>
						{get(props, 'address.city')},{' '}
						{NumberUtils.isANumber(props.address.state) ? '' : props.address.state}{' '}
						{get(props, 'address.zip')} {get(props, 'address.country')}
					</Label>
				</div>

				<Box flexDirection="row" className="aligned_form">
					{props.address.isDefault ? (
						<Label className={'primaryTextColor'} variant={'h6'} mb={16}>
							Primary
						</Label>
					) : (
						<Label
							variant={'h6'}
							className={'primaryTextColor'}
							onClick={() => {
								try {
									props.onPrimaryChange(props.address.id);
								} catch (error: any) {
									const message = `Getting error :${error.message} on ${getPageFinder(
										window.location.pathname
									)} page.`;
									undefinedHandler(message);
								}
							}}
						>
							Set as Primary
						</Label>
					)}
					{/* {!isMobile && (
						<Label variant={'h6'} className={'goldTextColor'} onClick={props.onEdit}>
							Edit
						</Label>
					)}

					{!props.address.isDefault && !isMobile && (
						<Icon
							iconImg={'icon-trash'}
							color={'#000000'}
							onClick={props.onDelete}
							cursorPointer
							size={21}
						/>
					)} */}
					<Box
						display="flex"
						flexDirection="column"
						justifyContent={'space-between'}
						alignItems="flex-end"
						height="100%"
						gap={45}
					>
						<Label variant={'h6'} className={'goldTextColor'} onClick={props.onEdit}>
							Edit
						</Label>
						{!props.address.isDefault && (
							<Icon
								className={'marginLeft'}
								iconImg={'icon-trash'}
								color={'#000000'}
								onClick={props.onDelete}
								cursorPointer
								size={21}
							/>
						)}
					</Box>
				</Box>
				{/* {isMobile && (
					<Box
						display="flex"
						flexDirection="column"
						justifyContent={'space-between'}
						alignItems="flex-end"
						height="100%"
						gap={45}
					>
						<Label variant={'h6'} className={'goldTextColor'} onClick={props.onEdit}>
							Edit
						</Label>
						{!props.address.isDefault && (
							<Icon
								className={'marginLeft'}
								iconImg={'icon-trash'}
								color={'#000000'}
								onClick={props.onDelete}
								cursorPointer
								size={21}
							/>
						)}
					</Box>
				)} */}
			</Box>
		</div>
	);
};

export default AccountAddressTile;
