import React from 'react';
import './AccountNavigationTile.scss';
import Paper from '../paper/Paper';
import Icon from '../../lib/@bit/redsky.framework.rs.icon';
import Label from '../../lib/@bit/redsky.framework.rs.label/dist/Label';
import { Box } from '../../lib/@bit/redsky.framework.rs.996';
import { useNavigate } from 'react-router-dom';

interface AccountNavigationTileProps {
	imgSrc: 'id' | 'house' | 'medal' | 'credit' | 'travel-preferences';
	route: string;
	title: string;
}

const AccountNavigationTile: React.FC<AccountNavigationTileProps> = (props) => {
	let navigate = useNavigate();
	function getImgSrc() {
		let imageSrc: string;
		switch (props.imgSrc) {
			case 'id':
				imageSrc = require('../../images/accountNavigationTileImages/id.png');
				break;
			case 'house':
				imageSrc = require('../../images/accountNavigationTileImages/house.png');
				break;
			case 'medal':
				imageSrc = require('../../images/accountNavigationTileImages/medal.png');
				break;
			case 'credit':
				imageSrc = require('../../images/accountNavigationTileImages/credit.png');
				break;
			case 'travel-preferences':
				imageSrc = require('../../images/accountNavigationTileImages/travel-preferences.png');
				break;
		}
		return imageSrc;
	}

	return (
		<Paper
			className={'rsAccountNavigationTile'}
			boxShadow
			borderRadius={'20px'}
			padding={'38px 24px 24px'}
			onClick={() => {
				navigate(props.route);
			}}
		>
			<img src={getImgSrc()} alt={'Account Tile Img'} />
			<Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} mt={18}>
				<Label variant={'h5'}>{props.title}</Label>
				<Icon iconImg={'icon-chevron-right'} size={17} />
			</Box>
		</Paper>
	);
};

export default AccountNavigationTile;
