import React, { useEffect, useState } from 'react';
import './RateCodeCardMobile.scss';
import { Box, popupController } from '../../../lib/@bit/redsky.framework.rs.996';
import Label from '../../../lib/@bit/redsky.framework.rs.label';
import { NumberUtils, StringUtils, WebUtils } from '../../../utils/utils';
import LabelButton from '../../labelButton/LabelButton';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import globalState from '../../../state/globalState';
import TagManager from 'react-gtm-module';
import metaCapi from '../../../customHooks/useMetaCapi';
import DestinationDetailsMobilePopup from '../../../popups/destinationDetailsMobilePopup/DestinationDetailsMobilePopup';
import themes from '../../../themes/themes.scss';
import useMerchantReward from '../../../customHooks/useMerchantReward';
import { getPageFinder, undefinedHandler } from '../../../utils/undefinedHandler';
import parse from 'html-react-parser';
import { get } from 'lodash';

interface RateCodeCardMobileProps {
	pointPerDollar?: number;
	priceObj: Misc.Pricing;
	accommodationId: number;
	destinationId: number;
	packageLength?: number;
	pointsEarnable: number;
	loyaltyStatus: Model.LoyaltyStatus;
	upfrontCashRequired: boolean;
	isCustomResort?: boolean;
	phone?: number;
	destinationDetails: Api.Destination.Res.Details | undefined;
	onRateDetail: VoidFunction;
	campaignLength: number;
	accommodation?: Api.Destination.Res.Accommodation;
}

const RateCodeCardMobile: React.FC<RateCodeCardMobileProps> = (props) => {
	let navigate = useNavigate();
	const setVerifiedAccommodation = useSetRecoilState<Api.Reservation.Res.Verification | undefined>(
		globalState.verifiedAccommodation
	);
	const [reservationFilters, setReservationFilters] = useRecoilState<Misc.ReservationFilters>(
		globalState.reservationFilters
	);

	const [destinationObj, setDestinationObj] = useState({
		// campaigns: props.destinationDetails?.campaigns,
		campaigns: props.priceObj.rate.campaigns,
		minAccommodationPriceInCents: props.priceObj.baseRates
	});
	const userTierValue = useRecoilValue<number>(globalState.userTierValue);
	const user = useRecoilValue<Api.User.Res.Detail | undefined>(globalState.user);

	const [pointPerDollar, setPointPerDollar] = useState<number>(0);

	useEffect(() => {
		if (typeof props.pointPerDollar === 'undefined') {
			setPointPerDollar(1);
		} else {
			setPointPerDollar(props.pointPerDollar);
		}
		setDestinationObj({
			campaigns: props.priceObj.rate.campaigns,
			minAccommodationPriceInCents: props.priceObj.baseRates
		});
	}, []);

	const googleAddToCartHandler = (productData: any) => {
		const add_to_cart = {
			id: productData,
			item_name: props.accommodation?.name,
			item_id: get(props, 'accommodation.externalSystemId', props?.accommodation?.id),
			price: NumberUtils.centsToDollars(Number(props.priceObj?.baseRates)),
			item_brand: props.destinationDetails?.name,
			item_category: get(props, 'accommodation.contentLists.RoomCategoryList.0.Name', null),
			item_category2: get(props, 'accommodation.contentLists.RoomCategoryList.0.Name', null),
			item_list_name: 'Rooms',
			quantity: 1,
			arrival_date: reservationFilters.startDate,
			depart_date: reservationFilters.endDate,
			total_cost: NumberUtils.centsToDollars(props.priceObj.grandTotalCents),
			currency: props.priceObj.rate.currencyCode
		};
		try {
			TagManager.dataLayer({
				dataLayer: {
					event: 'add_to_cart',
					ecommerce: null
				}
			});

			TagManager.dataLayer({
				dataLayer: {
					event: 'add_to_cart',
					ecommerce: {
						detail: {
							products: [add_to_cart]
						}
					}
				}
			});
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	};

	function renderCampaignMessage() {
		if (props.campaignLength == 0) {
			return '';
		}
		try {
			if (props.priceObj.rate.campaigns && props.priceObj.rate.campaigns.length === 0) {
				return '';
			}
			let campaignsData = props.priceObj.rate.campaigns.sort(function (a: any, b: any) {
				return new Date(b.createdOn).valueOf() - new Date(a.createdOn).valueOf();
			});
			let index = campaignsData.length - 1;
			return <>{parse(get(campaignsData, index + '.campaignMessage', ''))}</>;
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	function onBookNow() {
		try {
			if (props.isCustomResort) {
				window.open(`tel:+${props.phone}`, '_self');
			} else {
				setVerifiedAccommodation(undefined);
				if (props.loyaltyStatus !== 'ACTIVE') {
					setReservationFilters({ ...reservationFilters, redeemPoints: false });
				}
				let data: any = { ...reservationFilters, redeemPoints: props.loyaltyStatus === 'ACTIVE' };
				let rateCode = '';
				if (typeof props.priceObj.rate === 'string') {
					rateCode = props.priceObj.rate;
				} else if (typeof props.priceObj.rate === 'object') {
					rateCode = props.priceObj.rate.code;
				}
				let newRoom: Misc.StayParams = {
					uuid: Date.now(),
					adults: data.adultCount,
					children: data.childCount || 0,
					accommodationId: props.accommodationId,
					arrivalDate: data.startDate,
					departureDate: data.endDate,
					packages: [],
					rateCode: rateCode
				};
				let isRedeemPoints = reservationFilters.redeemPoints ? '&redeemPoints=true' : '';
				data = StringUtils.setAddPackagesParams({ destinationId: props.destinationId, newRoom });
				popupController.closeLast();

				const checkoutData = {
					destinationId: props.destinationId,
					stays: [newRoom]
				};

				//google analytics add to cart
				googleAddToCartHandler(props.destinationId);
				metaCapi.addToCart(props.destinationDetails);

				if (props.packageLength === 0) {
					navigate(`/booking/checkout?data=${encodeURI(JSON.stringify(checkoutData))}${isRedeemPoints}`);
				} else {
					navigate(`/booking/packages?data=${data}${isRedeemPoints}`);
				}
			}
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	function renderPointsOrCash() {
		if (reservationFilters.redeemPoints && props.loyaltyStatus === 'ACTIVE') {
			return (
				<Box className={'pricePerNight'}>
					<Label variant={'h2'} className={'yellowText'}>
						{StringUtils.addCommasToNumber(props.priceObj.pricePoints)}pts
					</Label>
					<Label variant={'body1'}> / night</Label>
				</Box>
			);
		} else {
			return (
				<>
					<Box className={'pricePerNight'}>
						<Label variant={'h2'} className={'price'}>
							{StringUtils.formatCurrency(props.priceObj.rate.currencyCode)}{' '}
							{StringUtils.formatMoney(props.priceObj.priceCents)}
						</Label>
					</Box>
					<Label variant={'body1'}>
						{StringUtils.formatCurrency(props.priceObj.rate.currencyCode)}{' '}
						{StringUtils.formatMoney(props.priceObj.grandTotalCents)}
					</Label>

					<Label variant={'body1'}> / including taxes and fees</Label>
				</>
			);
		}
	}

	return (
		<Box className={'rsRateCodeCardMobile'}>
			<Box>
				<Label variant={'buttonSm'} mb={8}>
					{props.priceObj.rate.name || 'Promotional Rate'}
				</Label>
				<Label variant={'subtitle1'} mb={8}>
					{props.priceObj.rate.description || 'Promotional Rate'}
				</Label>
				<Label variant={'button'} color={themes.errorColor} onClick={props.onRateDetail}>
					Rate Details
				</Label>
				{renderPointsOrCash()}
				{!reservationFilters.redeemPoints && props.loyaltyStatus === 'ACTIVE' && (
					<div style={{ marginTop: '10px' }}>
						<Label variant={'body1BI'} className={'earnText secondaryTextColor'} mb={8}>
							{/* {useMerchantReward.pointEarningCalculation(
								destinationObj,
								pointPerDollar,
								userTierValue,
								user,
								props.campaignLength
							)} */}
							{useMerchantReward.rewardPointsCalculation(
								destinationObj,
								pointPerDollar,
								userTierValue,
								user
							)}
						</Label>
					</div>
				)}
				<div className={'campaignMessage'}>{renderCampaignMessage()}</div>
			</Box>

			<LabelButton
				look={'containedPrimary'}
				variant={'buttonMdLg'}
				label={props.isCustomResort ? 'Call to Book' : 'Book Now'}
				className={'yellow'}
				onClick={onBookNow}
			/>
		</Box>
	);
};

export default RateCodeCardMobile;
