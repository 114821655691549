import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Popup, popupController } from '../../lib/@bit/redsky.framework.rs.996';
import { PopupProps } from '../../lib/@bit/redsky.framework.rs.996/dist/popup/Popup';
import './FilterReservationPopup.scss';
import Label from '../../lib/@bit/redsky.framework.rs.label/dist/Label';
import Paper from '../../components/paper/Paper';
import LabelButton from '../../components/labelButton/LabelButton';
import { WebUtils } from '../../utils/utils';
import { RsFormControl, RsFormGroup, RsValidator, RsValidatorEnum } from '../../lib/@bit/redsky.framework.rs.form';
import { rsToastify } from '../../lib/@bit/redsky.framework.rs.toastify';
import DestinationService from '../../services/destination/destination.service';
import serviceFactory from '../../services/serviceFactory';
import Box from '../../lib/@bit/redsky.framework.rs.996/dist/box/Box';
import Icon from '../../lib/@bit/redsky.framework.rs.icon';
import LabelRadioButton from '../../components/labelRadioButton/LabelRadioButton';
import Counter from '../../components/counter/Counter';
import Switch from '../../lib/@bit/redsky.framework.rs.switch';
import LabelCheckboxFilterBar from '../../components/labelCheckbox/LabelCheckboxFilterBar';
import globalState from '../../state/globalState';
import { useRecoilState, useSetRecoilState } from 'recoil';
import AccommodationService from '../../services/accommodation/accommodation.service';
import router from '../../utils/router';
import PriceSlider from '../../components/priceSlider/PriceSlider';
import PromoAgentCode from '../../components/promoAgentCode/PromoAgentCode';
import { getPageFinder, undefinedHandler } from '../../utils/undefinedHandler';
import SpinningLoaderPopup from '../spinningLoaderPopup/SpinningLoaderPopup';
import SigninPopup, { SigninPopupProps } from '../signin/SigninPopup';

export interface FilterReservationPopupProps extends PopupProps {
	className?: string;
	reservationFilters: Misc.ReservationFilters;
	chainId: number;
	arriveDate: any;
	departDate: any;
}

const TIMEOUT_INTERVAL = 1500;

const FilterReservationPopup: React.FC<FilterReservationPopupProps> = (props) => {
	const location = useLocation();
	const [trackPromoCodeType, setTrackPromoCodeType] = useState<string>('PR');
	const [promoCodeDatas, setPromoCodeDatas] = useState<string>('');
	const [agentIdData, setAgentIdData] = useState<string>('');
	const destinationService = serviceFactory.get<DestinationService>('DestinationService');
	const accommodationService = serviceFactory.get<AccommodationService>('AccommodationService');
	const setReservationFilters = useSetRecoilState<Misc.ReservationFilters>(globalState.reservationFilters);
	const [keyCounter, setKeyCounter] = useState<number>(1);
	const [propertyTypes, setPropertyTypes] = useState<Model.PropertyType[]>([]);
	const [experienceOptions, setExperienceOptions] = useState<Misc.OptionType[]>([]);
	const [amenityOptions, setAmenityOptions] = useState<Misc.OptionType[]>([]);
	const [user, setUser] = useRecoilState<Api.User.Res.Detail | undefined>(globalState.user);

	const [redeemPoints, setRedeemPoints] = useState<boolean>(!!props.reservationFilters.redeemPoints);
	const [filterForm, setFilterForm] = useState<RsFormGroup>(
		new RsFormGroup([
			//propertyTypeIds are the text accommodationType on the front end.
			//We already have accommodationType and this was already listed as propertyType on the backend.
			new RsFormControl('propertyTypeIds', props.reservationFilters.propertyTypeIds || [], []),
			new RsFormControl('adultCount', props.reservationFilters.adultCount || 1, [
				new RsValidator(RsValidatorEnum.REQ, '# Of Adults Required')
			]),
			new RsFormControl('childCount', props.reservationFilters.childCount || 0, []),
			new RsFormControl('bedroomCount', props.reservationFilters.bedroomCount || 0, [
				new RsValidator(RsValidatorEnum.REQ, '# Of Bedrooms Required')
			]),
			new RsFormControl('bathroomCount', props.reservationFilters.bathroomCount || 0, [
				new RsValidator(RsValidatorEnum.REQ, '# Of Bathrooms Required')
			]),
			new RsFormControl('priceRangeMax', props.reservationFilters.priceRangeMax || 1000, []),
			new RsFormControl('priceRangeMin', props.reservationFilters.priceRangeMin || 1, []),
			new RsFormControl('experienceIds', props.reservationFilters.experienceIds || [], []),
			new RsFormControl('amenityIds', props.reservationFilters.amenityIds || [], []),
			new RsFormControl('sortOrder', props.reservationFilters.sortOrder || 'ASC', [])
		])
	);
	let timeout: number;
	const fullProps = popupController.convertProps(props);

	useEffect(() => {
		window.history.pushState(null, 'FilterReservationPopup Close', window.location.pathname);
		window.addEventListener('popstate', (e: PopStateEvent) => {
			e.preventDefault();
			popupController.close(FilterReservationPopup);
		});
		const id = router.subscribeToBeforeRouterNavigate(async () => {
			router.getCurrentPath();
			return true;
		});
		return () => {
			router.unsubscribeFromBeforeRouterNavigate(id);
			window.removeEventListener('popstate', (e: PopStateEvent) => {
				e.preventDefault();
				popupController.close(FilterReservationPopup);
			});
		};
	}, []);

	useEffect(() => {
		async function getAllFiltersOptions() {
			try {
				const propertyTypes = await destinationService.getAllPropertyTypes();
				const experiences = await destinationService.getExperienceTypes();
				const amenities = await accommodationService.getAllAmenities();
				setPropertyTypes(propertyTypes);
				setExperienceOptions(
					experiences.map((experience) => {
						return { value: experience.id, label: experience.title };
					})
				);
				setAmenityOptions(
					amenities.map((amenity) => {
						return { value: amenity.id, label: amenity.title };
					})
				);
			} catch (e: any) {
				rsToastify.error(
					'An unexpected error occurred on the server, unable to get all the options.',
					'Server Error!'
				);
				const message = `Getting error :${e.message} on ${getPageFinder(window.location.pathname)} page.`;
				undefinedHandler(message);
			}
		}

		getAllFiltersOptions().catch(console.error);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setKeyCounter(keyCounter + 1);
	}, [redeemPoints]);
	function getAllControls(): { [key: string]: RsFormControl } {
		let propertyTypeControl = filterForm.get('propertyTypeIds');
		let adultCountControl = filterForm.get('adultCount');
		let childCountControl = filterForm.get('childCount');
		let bedroomCountControl = filterForm.get('bedroomCount');
		let bathroomCountControl = filterForm.get('bathroomCount');
		let priceRangeMinControl = filterForm.get('priceRangeMin');
		let priceRangeMaxControl = filterForm.get('priceRangeMax');
		let experienceIdsControl = filterForm.get('experienceIds');
		let amenityIdsControl = filterForm.get('amenityIds');
		let sortOrderControl = filterForm.get('sortOrder');
		return {
			propertyTypeControl,
			adultCountControl,
			childCountControl,
			bedroomCountControl,
			bathroomCountControl,
			priceRangeMinControl,
			priceRangeMaxControl,
			experienceIdsControl,
			amenityIdsControl,
			sortOrderControl
		};
	}

	function updateAllControls(controls: RsFormControl[]) {
		let formClone = filterForm.clone();
		controls.forEach((control) => {
			formClone.update(control);
		});
		setFilterForm(formClone);
	}

	function sanitizePriceFieldsAndUpdate(control: RsFormControl) {
		if (!control) return;
		control.value = control.value.toString().replaceAll(/[^0-9]/g, '');
		updateFilterFormWithTimeout(control);
	}

	function updateFilterFormWithTimeout(control: RsFormControl | undefined) {
		if (timeout) window.clearTimeout(timeout);
		timeout = window.setTimeout(() => updateFilterForm(control), TIMEOUT_INTERVAL);
	}

	function updateFilterForm(control: RsFormControl | undefined) {
		if (!control) return;
		filterForm.update(control);
		setFilterForm(filterForm.clone());
	}

	function clearAll() {
		let {
			propertyTypeControl,
			bedroomCountControl,
			adultCountControl,
			childCountControl,
			bathroomCountControl,
			priceRangeMinControl,
			priceRangeMaxControl,
			experienceIdsControl,
			amenityIdsControl,
			sortOrderControl
		} = getAllControls();

		propertyTypeControl.value = [];
		adultCountControl.value = 1;
		childCountControl.value = 0;
		bedroomCountControl.value = 0;
		bathroomCountControl.value = 0;
		priceRangeMinControl.value = 10;
		priceRangeMaxControl.value = 1000;
		experienceIdsControl.value = [];
		amenityIdsControl.value = [];
		sortOrderControl.value = 'ASC';

		setTrackPromoCodeType('PR');
		setPromoCodeDatas('');
		setAgentIdData('');

		updateAllControls([
			propertyTypeControl,
			bedroomCountControl,
			adultCountControl,
			childCountControl,
			bathroomCountControl,
			priceRangeMinControl,
			priceRangeMaxControl,
			experienceIdsControl,
			amenityIdsControl,
			sortOrderControl
		]);
		setKeyCounter(keyCounter + 1);
		setRedeemPoints(false);
	}

	function saveFilter() {
		try {
			if (trackPromoCodeType === 'PR' && promoCodeDatas !== '' && agentIdData === '') {
				const synxisPromoUrl = `https://be.synxis.com/?arrive=${props.arriveDate}&chain=${props.chainId}&depart=${props.departDate}&level=chain&nights=3&promo=${promoCodeDatas}`;
				window.open(synxisPromoUrl, '_blank');
			}

			if (trackPromoCodeType === 'GC' && promoCodeDatas !== '' && agentIdData === '') {
				const synxisPromoUrl = `https://be.synxis.com/?arrive=${props.arriveDate}&chain=${props.chainId}&depart=${props.departDate}&level=chain&nights=3&group=${promoCodeDatas}`;
				window.open(synxisPromoUrl, '_blank');
			}

			if (trackPromoCodeType !== '' && promoCodeDatas === '' && agentIdData !== '') {
				const synxisPromoUrl = `https://be.synxis.com/?arrive=${props.arriveDate}&chain=${props.chainId}&depart=${props.departDate}&agencyid=${agentIdData}&level=chain&nights=3`;
				window.open(synxisPromoUrl, '_blank');
			}

			if (trackPromoCodeType === 'PR' && promoCodeDatas !== '' && agentIdData !== '') {
				const synxisPromoUrl = `https://be.synxis.com/?arrive=${props.arriveDate}&chain=${props.chainId}&depart=${props.departDate}&agencyid=${agentIdData}&level=chain&nights=3&promo=${promoCodeDatas}`;
				window.open(synxisPromoUrl, '_blank');
			}

			if (trackPromoCodeType === 'GC' && promoCodeDatas !== '' && agentIdData !== '') {
				const synxisPromoUrl = `https://be.synxis.com/?arrive=${props.arriveDate}&chain=${props.chainId}&depart=${props.departDate}&agencyid=${agentIdData}&level=chain&nights=3&group=${promoCodeDatas}`;
				window.open(synxisPromoUrl, '_blank');
			}

			setReservationFilters((prev) => {
				const form = filterForm.toModel<{
					adultCount: number;
					childCount: number;
					priceRangeMin: number;
					priceRangeMax: number;
					accommodationType: number[];
					bedroomCount: number;
					bathroomCount: number;
					propertyTypeIds: number[];
				}>();
				const newFilter: Misc.ReservationFilters = { ...prev, ...form, redeemPoints };

				//  WebUtils.updateUrlParams(newFilter);
				return newFilter;
			});
			popupController.closeById(fullProps.popupId);
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	function renderAccommodationCheckboxes() {
		try {
			return propertyTypes.map((item) => (
				<LabelCheckboxFilterBar
					key={item.id}
					value={item.id}
					text={item.name}
					onSelect={() => {
						let tempControl = filterForm.get('propertyTypeIds');
						tempControl.value = [...(tempControl.value as number[]), item.id];
						updateFilterForm(tempControl);
					}}
					isChecked={(filterForm.get('propertyTypeIds').value as number[]).includes(item.id as number)}
					onDeselect={() => {
						filterForm.get('propertyTypeIds').value = (
							filterForm.get('propertyTypeIds').value as number[]
						).filter((type) => type !== item.id);
						updateFilterForm(filterForm.get('propertyTypeIds'));
					}}
				/>
			));
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	function renderResortExperiences() {
		try {
			return (
				<>
					{experienceOptions.map((item) => (
						<Box marginY={10}>
							<LabelCheckboxFilterBar
								key={item.value}
								value={item.value}
								text={item.label}
								onSelect={() => {
									let tempControl = filterForm.get('experienceIds');
									tempControl.value = [...(tempControl.value as number[]), item.value as number];
									updateFilterForm(tempControl);
								}}
								isChecked={(filterForm.get('experienceIds').value as number[]).includes(
									item.value as number
								)}
								onDeselect={() => {
									filterForm.get('experienceIds').value = (
										filterForm.get('experienceIds').value as number[]
									).filter((id) => id !== item.value);
									updateFilterForm(filterForm.get('propertyTypeIds'));
								}}
							/>
						</Box>
					))}
				</>
			);
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	function renderInUnitAmenities() {
		try {
			return (
				<>
					{amenityOptions.map((item) => (
						<Box marginY={10}>
							<LabelCheckboxFilterBar
								key={item.value}
								value={item.value}
								text={item.label}
								onSelect={() => {
									let tempControl = filterForm.get('amenityIds');
									tempControl.value = [...(tempControl.value as number[]), item.value as number];
									updateFilterForm(tempControl);
								}}
								isChecked={(filterForm.get('amenityIds').value as number[]).includes(
									item.value as number
								)}
								onDeselect={() => {
									filterForm.get('amenityIds').value = (
										filterForm.get('amenityIds').value as number[]
									).filter((id) => id !== item.value);
									updateFilterForm(filterForm.get('propertyTypeIds'));
								}}
							/>
						</Box>
					))}
				</>
			);
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	return (
		<Popup {...props} preventCloseByBackgroundClick>
			<div className={'rsFilterReservationPopup'}>
				<Paper className={'paperWrapper'}>
					<Box className="paperHeader">
						<Label variant={'h6'} className={'primaryTextColor'}>
							Filters
						</Label>
						<Icon
							iconImg="icon-close"
							size={16}
							onClick={() => popupController.closeById(fullProps.popupId)}
						/>
					</Box>
					<Box className="paperBody">
						<div className="formDiv" id="sortByDiv">
							<Label variant="h6" marginBottom={15}>
								Sort by
							</Label>
							<LabelRadioButton
								radioName="highestRadioBtn"
								value="sortHigh"
								checked={filterForm.get('sortOrder').value === 'DESC'}
								text="Highest Price"
								onSelect={() => {
									let tempControl = filterForm.get('sortOrder');
									tempControl.value = 'DESC';
									updateFilterForm(tempControl);
								}}
								labelVariant="h6"
								className="labelRadio"
							/>
							<LabelRadioButton
								radioName="lowestRadioBtn"
								value="sortLow"
								checked={filterForm.get('sortOrder').value === 'ASC'}
								text="Lowest Price"
								onSelect={() => {
									let tempControl = filterForm.get('sortOrder');
									tempControl.value = 'ASC';
									updateFilterForm(tempControl);
								}}
								labelVariant="h6"
								className="labelRadio"
							/>
						</div>
						<div className="formDiv" id="guestsDiv">
							<Counter
								labelVariant={'h6'}
								title="Adults"
								control={filterForm.get('adultCount')}
								updateControl={updateFilterForm}
								className={'filterCounter'}
								minCount={1}
								maxCount={99}
								labelMarginRight={5}
							/>
							<Counter
								className={'filterCounter'}
								labelVariant={'h6'}
								title={'Children'}
								control={filterForm.get('childCount')}
								updateControl={updateFilterForm}
								labelMarginRight={5}
								minCount={0}
								maxCount={99}
							/>
							<Counter
								labelVariant={'h6'}
								title="Bedrooms"
								control={filterForm.get('bedroomCount')}
								updateControl={updateFilterForm}
								className={'filterCounter'}
								minCount={0}
								maxCount={99}
								labelMarginRight={5}
							/>
							<Counter
								labelVariant={'h6'}
								title="Bathrooms"
								control={filterForm.get('bathroomCount')}
								updateControl={updateFilterForm}
								className={'filterCounter'}
								minCount={0}
								maxCount={99}
								labelMarginRight={5}
							/>
						</div>
						<div className="formDiv" id="redeemPointsDiv">
							<Box className="redeemPointsContainer">
								<Label variant="h6">Redeem Points</Label>
								{/* <Switch
									className={'toggleButton'}
									label={'{"left":"" }'}
									onChange={() => {
										try {
											setRedeemPoints((prev) => {
												let newMin: RsFormControl = filterForm.get('priceRangeMin');
												let newMax = filterForm.get('priceRangeMax');
												if (!prev) {
													newMin.value = 1000;
													updateFilterForm(newMin);
													newMax.value = 143000;
													updateFilterForm(newMax);
												} else {
													newMin.value = 10;
													updateFilterForm(newMin);
													newMax.value = 1000;
													updateFilterForm(newMax);
												}
												return !prev;
											});
										} catch (error: any) {
											const message = `Getting error :${error.message} on ${getPageFinder(
												window.location.pathname
											)} page.`;
											undefinedHandler(message);
										}
									}}
									checked={redeemPoints}
								/> */}

								<label class="switch">
									<input
										type="checkbox"
										checked={redeemPoints ? true : false}
										onChange={(e: any) => {
											let paymentMethods = 0;
											user?.paymentMethods.map((item: any) => {
												if (item.metaData.offsiteLoyaltyEnrollment == 1) {
													paymentMethods = 1;
												}
											});
											// TEMPORARY CODE IS COMMENT FOR FIDEL
											// if (user && paymentMethods === 1) {
											if (user) {
												// props.updateRedeemPoints(!props.redeemPoints);
												// setReservationFilters((prev) => {
												// 	const priceRangeMin: number = prev.redeemPoints ? 10 : 1000;
												// 	const priceRangeMax: number = prev.redeemPoints ? 1000 : 143000;
												// 	return {
												// 		...prev,
												// 		priceRangeMin,
												// 		priceRangeMax,
												// 		redeemPoints: !prev.redeemPoints
												// 	};
												// });

												try {
													setRedeemPoints((prev) => {
														let newMin: RsFormControl = filterForm.get('priceRangeMin');
														let newMax = filterForm.get('priceRangeMax');
														if (!prev) {
															newMin.value = 1000;
															updateFilterForm(newMin);
															newMax.value = 1430000;
															updateFilterForm(newMax);
														} else {
															newMin.value = 10;
															updateFilterForm(newMin);
															newMax.value = 1000;
															updateFilterForm(newMax);
														}
														return !prev;
													});
												} catch (error: any) {
													const message = `Getting error :${error.message} on ${getPageFinder(
														window.location.pathname
													)} page.`;
													undefinedHandler(message);
												}
												// } else if (user && paymentMethods !== 1) {
												//	alert('you are not eligible');
											} else {
												// props.onClose();
												popupController.open<SigninPopupProps>(SigninPopup, {
													user: true,
													successCallback: (data: any) => {
														// props.updateRedeemPoints(!props.redeemPoints);
														let newPaymentMethods = 0;
														data?.paymentMethods.map((item: any) => {
															if (item.metaData.offsiteLoyaltyEnrollment == 1) {
																newPaymentMethods = 1;
															}
														});

														// TEMPORARY CODE IS COMMENT FOR FIDEL
														// if (data && newPaymentMethods === 1) {
														if (data) {
															// props.updateRedeemPoints(!props.redeemPoints);
															// setReservationFilters((prev) => {
															// 	const priceRangeMin: number = prev.redeemPoints
															// 		? 10
															// 		: 1000;
															// 	const priceRangeMax: number = prev.redeemPoints
															// 		? 1000
															// 		: 143000;
															// 	return {
															// 		...prev,
															// 		priceRangeMin,
															// 		priceRangeMax,
															// 		redeemPoints: !prev.redeemPoints
															// 	};
															// });
															try {
																setRedeemPoints((prev) => {
																	let newMin: RsFormControl =
																		filterForm.get('priceRangeMin');
																	let newMax = filterForm.get('priceRangeMax');
																	if (!prev) {
																		newMin.value = 1000;
																		updateFilterForm(newMin);
																		newMax.value = 1430000;
																		updateFilterForm(newMax);
																	} else {
																		newMin.value = 10;
																		updateFilterForm(newMin);
																		newMax.value = 1000;
																		updateFilterForm(newMax);
																	}
																	return !prev;
																});
															} catch (error: any) {
																const message = `Getting error :${
																	error.message
																} on ${getPageFinder(window.location.pathname)} page.`;
																undefinedHandler(message);
															}
														} else {
															popupController.close(SpinningLoaderPopup);
														}
													}
												});
											}
										}}
									/>
									<span class="slider round"></span>
								</label>
							</Box>
						</div>
						<div className="formDiv" id="priceSliderDiv">
							<Label variant={'h6'} marginY={15}>
								{redeemPoints ? 'Point' : 'Price'}
							</Label>
							<PriceSlider
								key={`slider${keyCounter}`}
								updateFilterForm={updateFilterForm}
								minControl={filterForm.get('priceRangeMin')}
								maxControl={filterForm.get('priceRangeMax')}
								// promoCodeType={setTrackPromoCodeType}
								// savePromoCodeDatas={setPromoCodeDatas}
								// saveAgentId={setAgentIdData}
								sanitizePriceFieldsAndUpdate={sanitizePriceFieldsAndUpdate}
								redeemPoints={redeemPoints}
							/>
						</div>

						<div className="formDiv" id="accommodationDiv">
							<Label variant="h6" marginY={15}>
								Accommodation
							</Label>
							{renderAccommodationCheckboxes()}
						</div>
						<div className="formDiv" id="resortExperiencesDiv">
							<Label variant="h6" marginY={15}>
								Resort Experiences
							</Label>
							{renderResortExperiences()}
						</div>
						<div className="formDiv bottomForm" id="resortExperiencesDiv">
							<Label variant="h6" marginY={15}>
								In Unit Amenities
							</Label>
							{renderInUnitAmenities()}
						</div>
						<div className="formDiv" id="priceSliderDiv">
							<Label variant={'h6'} marginY={15}>
								Promo/Agent code
							</Label>
							<PromoAgentCode
								key={`PromoAgentCode${keyCounter}`}
								updateFilterForm={updateFilterForm}
								// minControl={filterForm.get('priceRangeMin')}
								// maxControl={filterForm.get('priceRangeMax')}
								promoCodeType={setTrackPromoCodeType}
								savePromoCodeDatas={setPromoCodeDatas}
								saveAgentId={setAgentIdData}
								// sanitizePriceFieldsAndUpdate={sanitizePriceFieldsAndUpdate}
								redeemPoints={redeemPoints}
							/>
						</div>
					</Box>
					<div className={'paperFooter'}>
						<LabelButton
							className={'cancelButton'}
							look={'containedSecondary'}
							variant={'buttonSm'}
							label={'Clear'}
							onClick={clearAll}
						/>
						<LabelButton
							className={'applyButton'}
							look={'containedPrimary'}
							variant={'buttonSm'}
							label={'Apply'}
							onClick={() => {
								saveFilter();
							}}
						/>
					</div>
				</Paper>
			</div>
		</Popup>
	);
};

export default FilterReservationPopup;
