import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import './FilterBarLimited.scss';
import { Box, popupController } from '../../lib/@bit/redsky.framework.rs.996';
import { formatFilterDateForServer, ObjectUtils, WebUtils } from '../../utils/utils';
import { RsFormControl, RsFormGroup } from '../../lib/@bit/redsky.framework.rs.form';
import { useRecoilState } from 'recoil';
import globalState from '../../state/globalState';
import { rsToastify } from '../../lib/@bit/redsky.framework.rs.toastify';
import MaskedDateRangeSelector from '../maskedDateRangeSelector/MaskedDateRangeSelector';
import Button from '../../lib/@bit/redsky.framework.rs.button';
import DateRangeSelector from '../dateRangeSelector/DateRangeSelector';
import Counter from '../counter/Counter';
import TitleLabel from '../topSearchBar/titleLabel/TitleLabel';
import Label from '../../lib/@bit/redsky.framework.rs.label/dist/Label';
import Icon from '../../lib/@bit/redsky.framework.rs.icon';
import Switch from '../../lib/@bit/redsky.framework.rs.switch';
import useIsAtBreakpoint from '../../customHooks/useIsAtBreakpoint';
import LabelButton from '../labelButton/LabelButton';
import useFilterOptions from '../../customHooks/useFilterOptions';
import themes from '../../themes/themes.scss';
import classNames from 'classnames';
import OtherFilters from './otherFilters/OtherFilters';
import SigninPopup, { SigninPopupProps } from '../../popups/signin/SigninPopup';
import SpinningLoaderPopup from '../../popups/spinningLoaderPopup/SpinningLoaderPopup';

export interface FilterBarProps {
	destinationId?: number;
	hide?: boolean;
	minStay?: number;
	isMobile?: boolean;
	loyaltyStatus: string;
	externalId?: string;
	chainId?: number;
	setIsOtherFilterPopupOpen?: any;
	isCustomResort?: boolean;
}

enum FormKeys {
	ADULT_COUNT = 'adultCount',
	CHILD_COUNT = 'childCount',
	START_DATE = 'startDate',
	END_DATE = 'endDate'
}

const FilterBarLimited: React.FC<FilterBarProps> = (props) => {
	const isMobile = useIsAtBreakpoint();
	const [reservationFilters, setReservationFilters] = useRecoilState<Misc.ReservationFilters>(
		globalState.reservationFilters
	);
	const filterOptions = useFilterOptions(reservationFilters.destinationId);
	const [user, setUser] = useRecoilState<Api.User.Res.Detail | undefined>(globalState.user);

	const [filterForm, setFilterForm] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl(FormKeys.ADULT_COUNT, reservationFilters.adultCount || 1, []),
			new RsFormControl(FormKeys.CHILD_COUNT, reservationFilters.childCount || 0, []),
			new RsFormControl(FormKeys.START_DATE, (reservationFilters.startDate as string) || '', []),
			new RsFormControl(FormKeys.END_DATE, (reservationFilters.endDate as string) || '', [])
		])
	);
	const [focusedInput, setFocusedInput] = useState<'startDate' | 'endDate' | null>(null);
	const [toggleOtherFilters, setToggleOtherFilters] = useState<boolean>(false);
	const [keyCounter, setKeyCounter] = useState<number>(1);
	const [startDateControl, setStartDateControl] = useState<moment.Moment | null>(
		moment(reservationFilters.startDate)
	);
	const [endDateControl, setEndDateControl] = useState<moment.Moment | null>(moment(reservationFilters.endDate));

	const labelInputRef = useRef<HTMLElement>(null);
	const [isDisabled, setIsDisabled] = useState<boolean>(false);
	const [screenSize, setScreenSize] = useState<number>(window.screen.width);

	useEffect(() => {
		window.addEventListener('resize', onResize);
		function onResize(event: UIEvent) {
			const screen = event.target as Window;
			renderSize(screen.innerWidth);
		}

		function renderSize(screen: number) {
			setScreenSize(screen);
		}

		renderSize(window.innerWidth);

		return () => {
			window.removeEventListener('resize', onResize);
		};
	}, []);

	// useEffect(() => {

	// 	WebUtils.updateUrlParams({ ...reservationFilters, destinationId: props.destinationId });
	// }, [reservationFilters]);

	useEffect(() => {
		setKeyCounter(keyCounter + 1);
	}, [reservationFilters.redeemPoints]);

	async function onApply() {
		setReservationFilters((prevState) => {
			const newFilter: Misc.ReservationFilters = {
				...prevState,
				startDate: formatFilterDateForServer(startDateControl, 'start'),
				endDate: formatFilterDateForServer(endDateControl, 'end'),
				adultCount: filterForm.get(FormKeys.ADULT_COUNT).value as number,
				childCount: filterForm.get(FormKeys.CHILD_COUNT).value as number,
				destinationId: props.destinationId
			};

			// WebUtils.updateUrlParams(newFilter);
			return newFilter;
		});
	}

	async function updateFilterForm(control: RsFormControl | undefined) {
		if (!control) return;
		filterForm.update(control);
		await filterForm.isValid();
		setFilterForm(filterForm.clone());
	}

	function onDatesChange(startDate: moment.Moment | null, endDate: moment.Moment | null): void {
		setStartDateControl(startDate);
		setEndDateControl(endDate);
		if (endDate === null) {
			setIsDisabled(true);
			rsToastify.warning('Make sure you select a check out date!', 'No Check Out Date Selected');
		} else {
			setIsDisabled(false);
		}
	}

	function getAllControls(): { [key: string]: RsFormControl } {
		let formClone = filterForm.clone();
		let adultCountControl = formClone.get(FormKeys.ADULT_COUNT);
		let childCountControl = formClone.get(FormKeys.CHILD_COUNT);
		return {
			adultCountControl,
			childCountControl
		};
	}

	function areFiltersApplied(): boolean {
		let { adultCountControl, childCountControl } = getAllControls();
		if ((adultCountControl.value as number) > 1) return true;
		if ((childCountControl.value as number) > 0) return true;
		if (!!reservationFilters.bathroomCount) return true;
		if (!!reservationFilters.bedroomCount) return true;
		if (reservationFilters.redeemPoints) return true;
		if (reservationFilters.priceRangeMin && reservationFilters.priceRangeMin > 10) return true;
		if (reservationFilters.priceRangeMax && reservationFilters.priceRangeMax < 1000) return true;
		if (ObjectUtils.isArrayWithData(reservationFilters.propertyTypeIds)) return true;
		if (ObjectUtils.isArrayWithData(reservationFilters.amenityIds)) return true;
		return false;
	}

	function clearAll() {
		let { adultCountControl, childCountControl } = getAllControls();
		const resetFilterObj: Misc.ReservationFilters = {
			destinationId: reservationFilters.destinationId,
			startDate: reservationFilters.startDate,
			endDate: reservationFilters.endDate,
			adultCount: 1,
			childCount: 0,
			priceRangeMin: 10,
			priceRangeMax: 1000,
			propertyTypeIds: [],
			experienceIds: [],
			amenityIds: [],
			bedroomCount: 0,
			bathroomCount: 0,
			rateCodes: [],
			sortOrder: 'ASC',
			pagination: { page: 1, perPage: 10 },
			redeemPoints: false
		};
		adultCountControl.value = 1;
		childCountControl.value = 0;

		setReservationFilters(resetFilterObj);
	}

	function renderDateRangeSelector() {
		// if (screenSize < 1350) {
		// 	return (
		// 		<DateRangeSelector
		// 			startDate={startDateControl}
		// 			endDate={endDateControl}
		// 			onDatesChange={onDatesChange}
		// 			monthsToShow={1}
		// 			startDateLabel={'Selected dates'}
		// 			focusedInput={focusedInput}
		// 			onFocusChange={(input) => setFocusedInput(input)}
		// 			labelVariant={'h6'}
		// 			showSeparator={false}
		// 		/>
		// 	);
		// }

		return (
			<MaskedDateRangeSelector
				startDate={startDateControl}
				endDate={endDateControl}
				minStay={props.minStay}
				onDatesChange={onDatesChange}
				monthsToShow={2}
				startDateLabel={'Check in'}
				endDateLabel={'Check out'}
				labelVariant={'h6'}
			/>
		);
	}

	function renderGuestCounter() {
		return (
			<TitleLabel
				className={'guestInput2'}
				title={'Guests'}
				label={`${filterForm.get(FormKeys.ADULT_COUNT).value} Adults, ${
					filterForm.get(FormKeys.CHILD_COUNT).value
				} Children`}
				titleLabelRef={labelInputRef}
				titleVariant="buttonSm"
				labelVariant="h6"
				titleMarginBottom={0}
				popoutBoxPadding={screenSize < 1350 ? '10px 5px' : ''}
				titleColor={themes.primaryTextColor}
				iconDown={screenSize > 1530 ? true : false}
				iconDownCss={screenSize > 1350 ? 'iconDownCss2' : 'iconDownCss'}
				screenSize={screenSize}
			>
				<Counter
					title={'Adult'}
					className="guestsInput"
					control={filterForm.get(FormKeys.ADULT_COUNT)}
					updateControl={updateFilterForm}
					minCount={1}
					maxCount={99}
					labelMarginRight={84}
					// labelVariant="h6"
				/>
				<Counter
					title={'Children'}
					className="guestsInput"
					control={filterForm.get(FormKeys.CHILD_COUNT)}
					updateControl={updateFilterForm}
					minCount={0}
					maxCount={99}
					labelMarginRight={84}
					// labelVariant="h6"
				/>
			</TitleLabel>
		);
	}

	function renderAccommodationTagButtons() {
		let newArray = filterOptions.propertyTypes.filter(({ id: propertyId }) => {
			if (!reservationFilters.propertyTypeIds) return false;
			return reservationFilters.propertyTypeIds.includes(propertyId);
		});
		return newArray.map((item) => (
			<Label variant="button" key={item.id} className="accommodationTagButton">
				<Icon
					iconImg="icon-close"
					className="accommodationButtonX"
					onClick={() => {
						setReservationFilters((prevState) => {
							return {
								...prevState,
								propertyTypeIds: prevState.propertyTypeIds?.filter((type) => type !== item.id)
							};
						});
					}}
				/>
				{item.name}
			</Label>
		));
	}

	return (
		<Box className={classNames('rsFilterBarLimited')}>
			<Box className={'filterBar'}>
				<Label className={'chooseYourAccommodation'}>Choose your accommodation</Label>

				{!props.isCustomResort && renderDateRangeSelector()}
				{renderGuestCounter()}
				{isMobile && (
					<LabelButton
						look={'containedPrimary'}
						className={'updateButton'}
						onClick={onApply}
						disabled={isDisabled}
						label={'Update'}
						variant={'buttonMdLg'}
					/>
				)}
				<Box className="redeemPointsContainer borderBottom">
					<Button
						look="textTertiary"
						className="otherFiltersButton"
						onClick={() => {
							setToggleOtherFilters((prevState) => !prevState);
							if (props.setIsOtherFilterPopupOpen)
								props.setIsOtherFilterPopupOpen((prevState: any) => !prevState);
						}}
					>
						<Label className="Filters" variant="h6">
							{!isMobile && 'Other'} Filters
						</Label>
						<Icon iconImg="icon-slider" className="FiltersIcon" size={24} />
					</Button>
				</Box>

				<Box
					className="redeemPoints"
					style={{ visibility: props.loyaltyStatus == 'ACTIVE' ? 'visible' : 'visible' }}
				>
					<Label>Redeem Points</Label>
					{/* <Switch
						checked={!!reservationFilters.redeemPoints}
						labelPosition={'top'}
						className={'toggleButton'}
						onChange={() => {
							setReservationFilters((prev) => {
								const priceRangeMin: number = prev.redeemPoints ? 10 : 1000;
								const priceRangeMax: number = prev.redeemPoints ? 1000 : 143000;
								return {
									...prev,
									priceRangeMin,
									priceRangeMax,
									redeemPoints: !prev.redeemPoints
								};
							});

							// WebUtils.updateUrlParams(reservationFilters);
						}}
					/> */}

					<label className="switch">
						<input
							type="checkbox"
							checked={reservationFilters.redeemPoints ? true : false}
							onChange={(e: any) => {
								let paymentMethods = 0;
								user?.paymentMethods.map((item) => {
									if (item.metaData.offsiteLoyaltyEnrollment == 1) {
										paymentMethods = 1;
									}
								});
								// TEMPORARY CODE IS COMMENT FOR FIDEL
								// if (user && paymentMethods === 1) {
								if (user) {
									setReservationFilters((prev) => {
										const priceRangeMin: number = prev.redeemPoints ? 10 : 1000;
										const priceRangeMax: number = prev.redeemPoints ? 1000 : 1430000;
										return {
											...prev,
											priceRangeMin,
											priceRangeMax,
											redeemPoints: !prev.redeemPoints
										};
									});
									// } else if (user && paymentMethods !== 1) {
									// alert('you are not eligible');
								} else {
									// props.onClose();
									popupController.open<SigninPopupProps>(SigninPopup, {
										user: true,
										successCallback: (data: any) => {
											// props.updateRedeemPoints(!props.redeemPoints);
											let newPaymentMethods = 0;
											data?.paymentMethods.map((item: any) => {
												if (item.metaData.offsiteLoyaltyEnrollment == 1) {
													newPaymentMethods = 1;
												}
											});
											// TEMPORARY CODE IS COMMENT FOR FIDEL
											// if (data && newPaymentMethods === 1) {
											if (data) {
												setReservationFilters((prev) => {
													const priceRangeMin: number = prev.redeemPoints ? 10 : 1000;
													const priceRangeMax: number = prev.redeemPoints ? 1000 : 1430000;
													return {
														...prev,
														priceRangeMin,
														priceRangeMax,
														redeemPoints: !prev.redeemPoints
													};
												});
											} else {
												popupController.close(SpinningLoaderPopup);
											}
										}
									});
								}
							}}
						/>
						<span className="slider round"></span>
					</label>
				</Box>
				{!isMobile && (
					<LabelButton
						look={'containedPrimary'}
						className={'updateButton'}
						onClick={onApply}
						disabled={isDisabled}
						label={'Update'}
						variant={'buttonMdLg'}
					/>
				)}
				{toggleOtherFilters && (
					<OtherFilters
						onApplyCallback={(newFilters: Misc.ReservationFilters) => {
							// WebUtils.updateUrlParams(newFilters);
							setToggleOtherFilters(false);
							if (props.setIsOtherFilterPopupOpen) props.setIsOtherFilterPopupOpen(false);
							setReservationFilters(newFilters);
						}}
						reservationFilters={reservationFilters}
						externalId={props?.externalId || ''}
						chainId={props?.chainId || 0}
						amenityOptions={filterOptions.amenities}
						propertyTypes={filterOptions.propertyTypes}
						closeOtherFilters={() => {
							setToggleOtherFilters(false);
							if (props.setIsOtherFilterPopupOpen) props.setIsOtherFilterPopupOpen(false);
						}}
					/>
				)}
			</Box>
			{/* <Box className={'tagsAndClear'}>
				<Box className="accommodationTagsContainer">{renderAccommodationTagButtons()}</Box>
				{areFiltersApplied() && (
					<LabelButton
						look={'none'}
						label={'Clear All Filters'}
						className={'clearAllButton'}
						variant={'body1'}
						onClick={clearAll}
					/>
				)}
			</Box> */}
		</Box>
	);
};

export default FilterBarLimited;
