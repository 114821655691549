import { Box, Popup, PopupProps, popupController } from '../../lib/@bit/redsky.framework.rs.996';
import React from 'react';
import Label from '../../lib/@bit/redsky.framework.rs.label';
import Button from '../../lib/@bit/redsky.framework.rs.button';
import './ConfirmBoxPopup.scss';
import { useNavigate } from 'react-router-dom';
import Icon from '../../lib/@bit/redsky.framework.rs.icon';

export interface ConfirmBoxPopupProps extends PopupProps {}

const ConfirmBoxPopup: React.FC<ConfirmBoxPopupProps> = (props) => {
	let navigate = useNavigate();

	const cancelHandler = async () => {
		await navigate(-1);
		popupController.closeAll();
	};
	const reloadHandler = () => {
		popupController.closeAll();
		window.location.reload();
	};
	return (
		<Popup {...props} preventCloseByBackgroundClick>
			<Box className="popupBox confirmPopBox">
				<Icon iconImg={'icon-close'} onClick={cancelHandler} size={16} className={'closeBtn'} cursorPointer />
				<Label className="popupBoxText" variant="h5" paddingBottom={25} paddingTop={15}>
					You have not done any activity since last 10 minutes. Do you want to reload the page?
				</Label>
				<Box className="popupBoxButton">
					<Button look="containedPrimary" className="Button" onClick={reloadHandler}>
						Yes
					</Button>
					<Button look="containedSecondary" className="Button cancelButton" onClick={cancelHandler}>
						No
					</Button>
				</Box>
				{/* </Box> */}
			</Box>
		</Popup>
	);
};

export default ConfirmBoxPopup;
