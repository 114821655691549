import * as React from 'react';
import './BookingSummaryCard.scss';
import Label from '../../lib/@bit/redsky.framework.rs.label/dist/Label';
import Icon from '../../lib/@bit/redsky.framework.rs.icon';
import { useEffect, useState } from 'react';
import { Box, popupController } from '../../lib/@bit/redsky.framework.rs.996';
import { DateUtils, StringUtils } from '../../utils/utils';
import Accordion from '../../lib/@bit/redsky.framework.rs.accordion';
import serviceFactory from '../../services/serviceFactory';
import DestinationService from '../../services/destination/destination.service';
import useIsAtBreakpoint from '../../customHooks/useIsAtBreakpoint';
import { ObjectUtils } from '../../lib/@bit/redsky.framework.rs.utils';
import classNames from 'classnames';
import SigninPopup from '../../popups/signin/SigninPopup';
import { useRecoilState, useRecoilValue } from 'recoil';
import globalState from '../../state/globalState';
import parse from 'html-react-parser';
import moment from 'moment';
import { getPageFinder, undefinedHandler } from '../../utils/undefinedHandler';
import { get } from 'lodash';
import UserService from '../../services/user/user.service';
import useMerchantReward from '../../customHooks/useMerchantReward';

interface BookingSummaryCardProps {
	bookingData: Api.Reservation.Res.Verification;
	canHide: boolean;
	usePoints: boolean;
	isOpen?: boolean;
	onRedeem: boolean;
	showExpectedPoint?: boolean;
	destinationId?: number;
}

const BookingSummaryCard: React.FC<BookingSummaryCardProps> = (props) => {
	const arrivalDate = new Date(props.bookingData.arrivalDate);
	const departureDate = new Date(props.bookingData.departureDate);
	const destinationService = serviceFactory.get<DestinationService>('DestinationService');
	const [hideTaxesAndFees, setHideTaxesAndFees] = useState<boolean>(true);
	const [hideNights, setHideNights] = useState<boolean>(true);
	const isMobile = useIsAtBreakpoint();
	const user = useRecoilValue<Api.User.Res.Detail | undefined>(globalState.user);
	const userTierValue = useRecoilValue<number>(globalState.userTierValue);

	const [reservationFilters, setReservationFilters] = useRecoilState<Misc.ReservationFilters>(
		globalState.reservationFilters
	);
	const companyData = useRecoilValue<any>(globalState.company);
	const [userToken, setUserToken] = useRecoilState<string>(globalState.userToken);
	const [rewardPointPerDollar, setRewardPointPerDollar] = useState<number>(0);
	const [campaignVariable, setCampaignVariable] = useState<any>([]);
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const resortId: any = urlParams.get('data');

	useEffect(() => {
		getCampaignVariables();
		getPlatformVariables();

		window.scrollTo(0, 0);
	}, []);

	function calculateGrandTotalWithPackagesCentsOrPoints() {
		try {
			if (props.usePoints) {
				let packagesTotalInPoints = 0;
				props.bookingData.upsellPackages.forEach(
					(packageItem) => (packagesTotalInPoints += packageItem.priceDetail.amountPoints)
				);
				const bookingPointsLessPackagePoints =
					props.bookingData.prices.grandTotalPoints - props.bookingData.prices.upsellPackageTotalPoints || 0;
				return `${StringUtils.addCommasToNumber(bookingPointsLessPackagePoints + packagesTotalInPoints)} ${
					isMobile ? 'pts' : 'points'
				}`;
			}

			let packagesTotalInCents = 0;
			props.bookingData.upsellPackages.forEach(
				(packageItem) => (packagesTotalInCents += packageItem.priceDetail.amountAfterTax)
			);
			const bookingPriceLessPackagePrice =
				props.bookingData.prices?.grandTotalCents - props.bookingData.prices.upsellPackageTotalInCents;
			return (
				StringUtils.formatCurrency(props.bookingData.prices.currencyCode) +
				`${StringUtils.formatMoney(bookingPriceLessPackagePrice + packagesTotalInCents)}`
			);
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	function renderStayInfo() {
		try {
			let numberOfNights = DateUtils.daysBetweenStartAndEndDates(arrivalDate, departureDate);
			return (
				<div>
					<Label variant={'h6'} className={'primaryTextColor'}>
						{get(props, 'bookingData.accommodationName')}
					</Label>
					<Box display={'flex'} justifyContent={'space-between'} marginTop={8}>
						<Accordion
							hideChevron
							hideHoverEffect
							isOpen={props.isOpen}
							titleReact={
								<Box display={'flex'}>
									<Box display={'flex'}>
										<Label variant={'body1'}>
											{`${numberOfNights} night${numberOfNights > 1 ? 's' : ''}`}
										</Label>
										<Icon
											iconImg={'icon-chevron-up'}
											className={classNames('taxIcon', { down: hideNights })}
											cursorPointer
										/>
									</Box>
									<Box display={'flex'}>
										{props.usePoints ? (
											<Label variant={'h6'} className={'totalTax'}>
												{StringUtils.addCommasToNumber(
													props.bookingData.prices.accommodationTotalInPoints
												)}
											</Label>
										) : (
											<Label variant={'h6'}>
												{props.usePoints
													? StringUtils.addCommasToNumber(
															props.bookingData.prices.accommodationTotalInPoints
													  )
													: StringUtils.formatCurrency(
															props.bookingData.prices.currencyCode
													  ) +
													  `${StringUtils.formatMoney(
															props.bookingData.prices.accommodationTotalInCents
													  )}`}
											</Label>
										)}
									</Box>
								</Box>
							}
							onClick={() => setHideNights(!hideNights)}
						>
							{!hideNights && renderNightsPrice()}
						</Accordion>
					</Box>
				</div>
			);
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	function renderPackages() {
		try {
			let packageItems: JSX.Element[] = [];

			props.bookingData.upsellPackages.forEach((packageItem: Api.UpsellPackage.Res.Complete, index: number) => {
				packageItems.push(
					<div key={`package${index}`}>
						<Label variant={'h6'} className={'primaryTextColor'} marginTop={16}>
							{packageItem.title}
						</Label>
						<Box display={'flex'} justifyContent={'space-between'} marginTop={8}>
							<Label variant={'body1'}>
								x
								{props.bookingData.upsellPackages.filter((value) => value.id === packageItem.id).length}
							</Label>
							<Label variant={'h6'}>
								{props.usePoints
									? StringUtils.addCommasToNumber(packageItem.priceDetail.amountPoints)
									: StringUtils.formatCurrency(props.bookingData.prices.currencyCode) +
									  `${StringUtils.formatMoney(packageItem.priceDetail.amountBeforeTax)}`}
							</Label>
						</Box>
					</div>
				);
			});
			return packageItems;
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	function renderTaxesAndFees() {
		try {
			let packageSalesTax = props.bookingData.upsellPackages.reduce(
				(total, packageItem) =>
					total + (packageItem.priceDetail.amountAfterTax - packageItem.priceDetail.amountBeforeTax),
				0
			);

			if (props.usePoints) {
				packageSalesTax = props.bookingData.upsellPackages.reduce(
					(total, packageItem) =>
						total + (packageItem.priceDetail.pointsAfterTax - packageItem.priceDetail.pointsBeforeTax),
					0
				);
			}

			return (
				<div className={'taxesAndFees'}>
					<div className={'taxBreakdown'}>
						<Label variant={'h6'} className={'primaryTextColor'}>
							Taxes
						</Label>
						{packageSalesTax > 0 && (
							<Box display={'flex'} justifyContent={'space-between'} marginTop={8}>
								<Label variant={'body1'} className={'primaryTextColor'} paddingLeft={5}>
									Package Sales Tax
								</Label>
								{props.usePoints ? (
									<Label variant={'h6'} className={'medGray2'} paddingLeft={5}>
										{StringUtils.addCommasToNumber(packageSalesTax)}
									</Label>
								) : (
									<Label variant={'h6'} className={'medGray2'} paddingLeft={5}>
										{StringUtils.formatCurrency(props.bookingData.prices.currencyCode)}{' '}
										{`${StringUtils.formatMoney(packageSalesTax)}`}
									</Label>
								)}
							</Box>
						)}
						{props.usePoints
							? props.bookingData.prices.taxTotalsInPoints.map((value, index) => {
									return (
										<Box
											display={'flex'}
											justifyContent={'space-between'}
											key={`tax_${index}`}
											marginTop={8}
										>
											<Label variant={'body1'} paddingLeft={5}>
												{value.name}
											</Label>
											<Label variant={'h6'} className={'medGray2'} paddingLeft={5}>
												{value.amount}
											</Label>
										</Box>
									);
							  })
							: props.bookingData.prices.taxTotalsInCents.map((value, index) => {
									return (
										<Box
											display={'flex'}
											justifyContent={'space-between'}
											key={`tax_${index}`}
											marginTop={8}
										>
											<Label variant={'body1'} paddingLeft={5}>
												{value.name}
											</Label>
											<Label variant={'h6'} className={'medGray2'} paddingLeft={5}>
												{StringUtils.formatCurrency(value.currencyCode)}{' '}
												{`${StringUtils.formatMoney(value.amount)}`}
											</Label>
										</Box>
									);
							  })}
					</div>

					<div className={'feeBreakdown'}>
						<Label variant={'h6'} className={'primaryTextColor'}>
							Fees
						</Label>
						{props.usePoints ? (
							ObjectUtils.isArrayWithData(props.bookingData.prices.feeTotalsInCents) ? (
								props.bookingData.prices.feeTotalsInPoints.map((value, index) => {
									return (
										<Box
											display={'flex'}
											justifyContent={'space-between'}
											key={`fee_${index}`}
											marginTop={8}
										>
											<Label variant={'body1'} paddingLeft={5}>
												{value.name}
											</Label>
											<Label variant={'h6'} className={'medGray2'} paddingLeft={5}>
												{StringUtils.addCommasToNumber(value.amount)}
											</Label>
										</Box>
									);
								})
							) : (
								<Box display={'flex'} justifyContent={'space-between'} marginTop={8}>
									<Label variant={'body1'} paddingLeft={5}>
										No fees
									</Label>
									<Label variant={'h6'} className={'medGray2'} paddingLeft={5}>
										{`00`}
									</Label>
								</Box>
							)
						) : ObjectUtils.isArrayWithData(props.bookingData.prices.feeTotalsInCents) ? (
							props.bookingData.prices.feeTotalsInCents.map((value, index) => {
								return (
									<Box
										display={'flex'}
										justifyContent={'space-between'}
										key={`fee_${index}`}
										marginTop={8}
									>
										<Label variant={'body1'} paddingLeft={5}>
											{value.name}
										</Label>
										<Label variant={'h6'} className={'medGray2'} paddingLeft={5}>
											{StringUtils.formatCurrency(props.bookingData.prices.currencyCode)}{' '}
											{`${StringUtils.formatMoney(value.amount)}`}
										</Label>
									</Box>
								);
							})
						) : (
							<Box display={'flex'} justifyContent={'space-between'} marginTop={8}>
								<Label variant={'body1'} paddingLeft={5}>
									No fees
								</Label>
								<Label variant={'h6'} className={'medGray2'} paddingLeft={5}>
									{StringUtils.formatCurrency(props.bookingData.prices.currencyCode)} {`0.00`}
								</Label>
							</Box>
						)}
					</div>
				</div>
			);
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	function renderNightsPrice() {
		try {
			var covert;

			if (props.usePoints) {
				covert = Object.keys(props.bookingData.prices.accommodationDailyCostsInPoints).map(function (key) {
					return { dates: key, price: props.bookingData.prices.accommodationDailyCostsInPoints[key] };
				});
			} else {
				covert = Object.keys(props.bookingData.prices.accommodationDailyCostsInCents).map(function (key) {
					return { dates: key, price: props.bookingData.prices.accommodationDailyCostsInCents[key] };
				});
			}

			return (
				<div className={'taxesAndFees'}>
					<div className={'taxBreakdown'}>
						{covert.map((value, index) => {
							return (
								<Box
									display={'flex'}
									justifyContent={'space-between'}
									key={`tax_${index}`}
									marginTop={8}
								>
									<Label variant={'body1'} paddingLeft={5}>
										{moment(value.dates).format('LL')}
									</Label>
									<Label variant={'h6'} className={'medGray2'} paddingLeft={5}>
										{props.usePoints
											? value.price
											: StringUtils.formatCurrency(props.bookingData.prices.currencyCode) +
											  `${StringUtils.formatMoney(value.price)}`}
									</Label>
								</Box>
							);
						})}
					</div>
				</div>
			);
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	function renderTaxesAndFeesAccordion() {
		return (
			<Box display={'flex'} justifyContent={'space-between'} marginTop={20}>
				<Box display={'flex'} className={'accordionContainerBookingSummary'}>
					<Accordion
						hideChevron
						hideHoverEffect
						isOpen={props.isOpen}
						titleReact={
							<Box display={'flex'}>
								<Label variant={'buttonMdLg'} className={'primaryTextColor'}>
									Taxes and fees
								</Label>
								<Box display={'flex'}>
									<Icon
										iconImg={'icon-chevron-up'}
										className={classNames('taxIcon', { down: hideTaxesAndFees })}
										cursorPointer
									/>
									{props.usePoints ? (
										<Label variant={'h6'} className={'totalTax'}>
											{StringUtils.addCommasToNumber(
												props.bookingData.prices.taxAndFeeTotalInPoints
											)}
										</Label>
									) : (
										<Label variant={'h6'} className={'totalTax'}>
											{StringUtils.formatCurrency(props.bookingData.prices.currencyCode)}
											{StringUtils.formatMoney(
												props.bookingData.prices.taxAndFeeTotalInCents
											)}{' '}
										</Label>
									)}
								</Box>
							</Box>
						}
						onClick={() => setHideTaxesAndFees(!hideTaxesAndFees)}
					>
						{!hideTaxesAndFees && renderTaxesAndFees()}
					</Accordion>
				</Box>
			</Box>
		);
	}

	function renderGuests() {
		try {
			const adultString = `${props.bookingData.adultCount} Adult${props.bookingData.adultCount === 1 ? '' : 's'}`;
			const childString = `${props.bookingData.childCount} Child${
				props.bookingData.childCount === 1 ? '' : 'ren'
			}`;
			return <Label variant={'body1'} marginTop={4}>{`${adultString}, ${childString}`}</Label>;
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	function renderTotal() {
		return (
			<Box display={'flex'} justifyContent={'space-between'}>
				<Label variant={'buttonMdLg'} marginTop={20}>
					Total
				</Label>
				<Label variant={'h6'} marginTop={20}>
					{calculateGrandTotalWithPackagesCentsOrPoints()}
				</Label>
			</Box>
		);
	}

	function renderCheckInInfo() {
		return (
			<div className={'checkInInfo'}>
				<div className={'cell'}>
					<Label variant={'buttonSm'} className={'primaryTextColor'}>
						Check In
					</Label>
					<Label variant={'body1'} marginTop={4}>
						{DateUtils.displayUserDate(get(props, 'bookingData.arrivalDate'), 'MM-DD-YYYY')}
					</Label>
				</div>
				<div className={'cell'}>
					<Label variant={'buttonSm'} className={'primaryTextColor'}>
						Check Out
					</Label>
					<Label variant={'body1'} marginTop={4}>
						{DateUtils.displayUserDate(get(props, 'bookingData.departureDate'), 'MM-DD-YYYY')}
					</Label>
				</div>
				<div className={'cell'}>
					<Label variant={'buttonSm'} className={'primaryTextColor'}>
						Guests
					</Label>
					{renderGuests()}
				</div>
			</div>
		);
	}

	function renderDestinationName() {
		return (
			<Label variant={'buttonMdLg'} marginTop={20}>
				{get(props, 'bookingData.destinationName')}
			</Label>
		);
	}

	function renderCurrentPointsBalance() {
		return (
			<Box
				display={'flex'}
				justifyContent={'space-between'}
				flexWrap={isMobile ? 'wrap' : 'nowrap'}
				width={'100%'}
			>
				<Label variant={'h6'} className={'primaryTextColor'} marginTop={20}>
					Current Balance
				</Label>
				<Label variant={'h6'} marginTop={20} textAlign={isMobile ? 'right' : ''} className="mobilePoints">
					{props.showExpectedPoint ? StringUtils.addCommasToNumber(user?.availablePoints) : expectedPoints()}{' '}
					{isMobile ? 'pts' : 'points'}
				</Label>
			</Box>
		);
	}
	function expectedPoints() {
		try {
			return StringUtils.addCommasToNumber(
				(user?.availablePoints || 0) - props.bookingData.prices.grandTotalPoints
			);
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}
	function renderExpectedPointsBalance() {
		return (
			<Box
				display={'flex'}
				justifyContent={'space-between'}
				flexWrap={isMobile ? 'wrap' : 'nowrap'}
				width={'100%'}
			>
				<Label variant={'h6'} className={'primaryTextColor'} marginTop={20}>
					Expected Balance
				</Label>
				<Label variant={'h6'} marginTop={20} textAlign={isMobile ? 'right' : ''} className="mobilePoints">
					{expectedPoints()} {isMobile ? 'pts' : 'points'}
				</Label>
			</Box>
		);
	}

	async function getPlatformVariables() {
		try {
			const rewardPointPerDollar = (await destinationService.getPlatformVariables()).rewardPointsPerDollar; //global reward point per dollar
			setRewardPointPerDollar(rewardPointPerDollar);
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	async function getCampaignVariables() {
		try {
			const urlParams = new URLSearchParams(window.location.search);
			const urlData = urlParams.get('data');
			const urlDataToObject = JSON.parse(urlData || '{}');

			let campaignVariables;

			if (props.destinationId) {
				campaignVariables = await destinationService.getCampaignByDestination(
					props.destinationId,
					reservationFilters.startDate,
					reservationFilters.endDate,
					props.bookingData.accommodationId,
					props.bookingData.rateCode
				); //global reward point per dollar
			} else {
				campaignVariables = await destinationService.getCampaignByDestination(
					urlDataToObject.destinationId,
					reservationFilters.startDate,
					reservationFilters.endDate,
					props.bookingData.accommodationId,
					props.bookingData.rateCode
				); //global reward point per dollar
			}

			setCampaignVariable(campaignVariables);
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	const customeragentcontainer = (brandType: any) => {
		let companyid;
		if (brandType) {
			companyid = JSON.parse(brandType).themeId;
		} else {
			companyid = null;
		}

		let bgColor = '';
		switch (companyid) {
			case 3:
				bgColor = '#e51c14';
				break;
			case 78:
				bgColor = '#5d995d';
				break;
			case 19:
				bgColor = '#5d995d';
				break;
			case 9:
				bgColor = '#907326';
				break;
			case 10:
				bgColor = '#2a8293';
				break;
			default:
				bgColor = '#2c3c60';
				break;
		}
		return (
			<div style={{ backgroundColor: bgColor }} className="customeragentcontainer">
				<div className="customeragentcontainer-body">{parse(companyData.callCenter)}</div>
			</div>
		);
	};

	const pointMsg = (brandType: any) => {
		let companyid;
		if (brandType) {
			companyid = JSON.parse(brandType).themeId;
		} else {
			companyid = null;
		}

		let bgColor = '';
		switch (companyid) {
			case 3:
				bgColor = '#e51c14';
				break;
			case 78:
				bgColor = '#5d995d';
				break;
			case 19:
				bgColor = '#5d995d';
				break;
			case 9:
				bgColor = '#907326';
				break;
			case 10:
				bgColor = '#2a8293';
				break;
			default:
				bgColor = '#ffa022';
				break;
		}
		return (
			<div style={{ color: bgColor }} className="pointEarnedOnBookSummary">
				{user ? (
					<>
						{/* <div>You could receive {pointEarningCalculation()} points for this stay</div> */}
						{useMerchantReward.rewardPointsCalculation(
							{
								campaigns: campaignVariable,
								minAccommodationPriceInCents: props.bookingData.prices.accommodationTotalInCents
							},
							rewardPointPerDollar,
							userTierValue,
							user,
							true
						)}
					</>
				) : (
					<>
						{!['/booking/checkout'].includes(window.location.pathname) && (
							<>
								<div>
									{/* You could receive {pointEarningCalculation()} points for this stay{' '} */}
									{useMerchantReward.rewardPointsCalculation(
										{
											campaigns: campaignVariable,
											minAccommodationPriceInCents:
												props.bookingData.prices.accommodationTotalInCents
										},
										rewardPointPerDollar,
										userTierValue,
										user,
										true
									)}{' '}
									<span
										className="pointEarnedIndicator"
										onClick={() => {
											popupController.open(SigninPopup);
										}}
									>
										Login
									</span>
								</div>
								<br />
								<div>
									If you are not a Rentyl Rewards Loyalty Member{' '}
									<span
										className="pointEarnedIndicator"
										onClick={() => {
											window.open('https://rentylresorts.com/loyalty/', '_blank');
										}}
									>
										click here
									</span>{' '}
									to Sign up
								</div>
							</>
						)}
					</>
				)}
			</div>
		);
	};

	function pointEarningCalculation(): string {
		try {
			let calc = 0;

			if (campaignVariable === undefined || campaignVariable === null || campaignVariable.length === 0) {
				const pointValueMultiplier = 1;
				const bulkPoint = 0;

				if (user) {
					calc =
						(Number(
							StringUtils.formatMoney(props.bookingData.prices.accommodationTotalInCents).replace(
								/,/g,
								''
							)
						) *
							rewardPointPerDollar *
							pointValueMultiplier +
							bulkPoint) *
						(1 + userTierValue / 100);
				} else {
					calc =
						Number(
							StringUtils.formatMoney(props.bookingData.prices.accommodationTotalInCents).replace(
								/,/g,
								''
							)
						) *
							rewardPointPerDollar *
							1 +
						0;
				}

				return StringUtils.addCommasToNumber(Math.floor(calc * 100) / 100);
			}

			let campaignsData = campaignVariable?.sort(function (a: any, b: any) {
				return new Date(b.createdOn).valueOf() - new Date(a.createdOn).valueOf();
			});

			const pointValueMultiplier = get(campaignsData, '0.pointMultiplier', 1);
			const bulkPoint = get(campaignsData, '0.bulkPoint', 0);

			const currentDate = new Date();
			const startDate = new Date(campaignsData[0]?.startOn);
			const endDate = new Date(campaignsData[0]?.endOn);

			const daysOfTheWeek = campaignsData[0]?.activeOnDays;

			if ((daysOfTheWeek === undefined || daysOfTheWeek === '') && campaignsData[0]?.alwaysActive !== 1) {
				if (user) {
					calc =
						(Number(
							StringUtils.formatMoney(props.bookingData.prices.accommodationTotalInCents).replace(
								/,/g,
								''
							)
						) *
							rewardPointPerDollar *
							pointValueMultiplier +
							bulkPoint) *
						(1 + userTierValue / 100);
				} else {
					calc =
						Number(
							StringUtils.formatMoney(props.bookingData.prices.accommodationTotalInCents).replace(
								/,/g,
								''
							)
						) *
							rewardPointPerDollar *
							1 +
						0;
				}

				return StringUtils.addCommasToNumber(Math.floor(calc * 100) / 100);
			}
			const arrDaysOfTheWeek = daysOfTheWeek.split(',');
			let getTodayDates = new Date().getDay();
			let count = 0;

			if (getTodayDates === 0) {
				getTodayDates = 7;
			}

			arrDaysOfTheWeek.forEach((item: any) => {
				if (getTodayDates === Number(item)) {
					count++;
				}
			});

			if (count !== 1) {
				if (user) {
					calc =
						(Number(
							StringUtils.formatMoney(props.bookingData.prices.accommodationTotalInCents).replace(
								/,/g,
								''
							)
						) *
							rewardPointPerDollar *
							pointValueMultiplier +
							bulkPoint) *
						(1 + userTierValue / 100);
				} else {
					calc =
						Number(
							StringUtils.formatMoney(props.bookingData.prices.accommodationTotalInCents).replace(
								/,/g,
								''
							)
						) *
							rewardPointPerDollar *
							1 +
						0;
				}

				return StringUtils.addCommasToNumber(Math.floor(calc * 100) / 100);
			}

			if (campaignsData.length === 0) {
				if (user) {
					calc =
						(Number(
							StringUtils.formatMoney(props.bookingData.prices.accommodationTotalInCents).replace(
								/,/g,
								''
							)
						) *
							rewardPointPerDollar *
							pointValueMultiplier +
							bulkPoint) *
						(1 + userTierValue / 100);
				} else {
					calc =
						Number(
							StringUtils.formatMoney(props.bookingData.prices.accommodationTotalInCents).replace(
								/,/g,
								''
							)
						) *
							rewardPointPerDollar *
							1 +
						0;
				}
			} else {
				if (campaignsData[0]?.alwaysActive == true) {
					if (user) {
						calc =
							(Number(
								StringUtils.formatMoney(props.bookingData.prices.accommodationTotalInCents).replace(
									/,/g,
									''
								)
							) *
								rewardPointPerDollar *
								pointValueMultiplier +
								bulkPoint) *
							(1 + userTierValue / 100);
					} else {
						calc =
							Number(
								StringUtils.formatMoney(props.bookingData.prices.accommodationTotalInCents).replace(
									/,/g,
									''
								)
							) *
								rewardPointPerDollar *
								campaignsData[0]?.pointMultiplier +
							campaignsData[0]?.bulkPoint;
					}
				} else {
					// Check if the current date is between the start and end dates
					if (currentDate >= startDate && currentDate <= endDate) {
						if (user) {
							calc =
								(Number(
									StringUtils.formatMoney(props.bookingData.prices.accommodationTotalInCents).replace(
										/,/g,
										''
									)
								) *
									rewardPointPerDollar *
									pointValueMultiplier +
									bulkPoint) *
								(1 + userTierValue / 100);
						} else {
							calc =
								Number(
									StringUtils.formatMoney(props.bookingData.prices.accommodationTotalInCents).replace(
										/,/g,
										''
									)
								) *
									rewardPointPerDollar *
									campaignsData[0]?.pointMultiplier +
								campaignsData[0]?.bulkPoint;
						}
					} else {
						if (user) {
							calc =
								(Number(
									StringUtils.formatMoney(props.bookingData.prices.accommodationTotalInCents).replace(
										/,/g,
										''
									)
								) *
									rewardPointPerDollar *
									pointValueMultiplier +
									bulkPoint) *
								(1 + userTierValue / 100);
						} else {
							calc =
								Number(
									StringUtils.formatMoney(props.bookingData.prices.accommodationTotalInCents).replace(
										/,/g,
										''
									)
								) *
									rewardPointPerDollar *
									1 +
								0;
						}
					}
				}
			}

			return StringUtils.addCommasToNumber(Math.floor(calc * 100) / 100);
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
			return '';
		}
	}
	return (
		<>
			<div className={'rsBookingSummaryCard'}>
				{isMobile ? (
					<Accordion hideHoverEffect isOpen={true} titleReact={<Label variant={'h5'}>Booking Summary</Label>}>
						{renderDestinationName()}
						{renderCheckInInfo()}
						{renderStayInfo()}
						<hr />
						{renderPackages()}
						{renderTaxesAndFeesAccordion()}
						{renderTotal()}
						{props.onRedeem && userToken && (
							<>
								{renderCurrentPointsBalance()}
								{props.showExpectedPoint ? renderExpectedPointsBalance() : null}
							</>
						)}
					</Accordion>
				) : (
					<>
						<Box display={'flex'}>
							<Label variant={'h5'}>Booking Summary</Label>
						</Box>
						{renderDestinationName()}
						{renderCheckInInfo()}
						{renderStayInfo()}
						<hr />
						{renderPackages()}
						{renderTaxesAndFeesAccordion()}
						{renderTotal()}
						{props.onRedeem && userToken && (
							<>
								{renderCurrentPointsBalance()}
								{props.showExpectedPoint ? renderExpectedPointsBalance() : null}
							</>
						)}
					</>
				)}
			</div>

			{!props.usePoints && pointMsg(resortId)}
			{customeragentcontainer(resortId)}
		</>
	);
};
export default BookingSummaryCard;
