import React from 'react';
import { useEffect, useState } from 'react';
import './AccountPersonalInfoPage.scss';
import { Page, popupController } from '../../lib/@bit/redsky.framework.rs.996';
import serviceFactory from '../../services/serviceFactory';
import UserService from '../../services/user/user.service';
import Box from '../../lib/@bit/redsky.framework.rs.996/dist/box/Box';
import Label from '../../lib/@bit/redsky.framework.rs.label/dist/Label';
import LoadingPage from '../loadingPage/LoadingPage';
import LabelInput from '../../components/labelInput/LabelInput';
import { RsFormControl, RsFormGroup, RsValidator, RsValidatorEnum } from '../../lib/@bit/redsky.framework.rs.form';
import LabelButton from '../../components/labelButton/LabelButton';
import { useRecoilState, useRecoilValue } from 'recoil';
import globalState from '../../state/globalState';
import { useNavigate } from 'react-router-dom';
import { StringUtils, WebUtils } from '../../utils/utils';
import { rsToastify } from '../../lib/@bit/redsky.framework.rs.toastify';
import useIsAtBreakpoint from '../../customHooks/useIsAtBreakpoint';
import Paper from '../../components/paper/Paper';
import SubNavMenu from '../../components/subNavMenu/SubNavMenu';
import UpdatePasswordPopup, { UpdatePasswordPopupProps } from '../../popups/updatePasswordPopup/UpdatePasswordPopup';
import { getPageFinder, undefinedHandler } from '../../utils/undefinedHandler';
import { get } from 'lodash';

interface AccountPersonalInfoPageProps {}

const AccountPersonalInfoPage: React.FC<AccountPersonalInfoPageProps> = () => {
	let navigate = useNavigate();
	const isMobile = useIsAtBreakpoint();
	const userService = serviceFactory.get<UserService>('UserService');
	const [user, setUser] = useRecoilState<Api.User.Res.Detail | undefined>(globalState.user);
	const [accountInfoChanged, setAccountInfoChanged] = useState<boolean>(false);
	const [isUserFormValid, setIsUserFormValid] = useState<boolean>(false);
	const [updateUserForm, setUpdateUserForm] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl('firstName', user?.firstName || '', [
				new RsValidator(RsValidatorEnum.CUSTOM, 'Invalid First Name', (control) => {
					return StringUtils.namePattern.test(control.value.toString());
				}),
				new RsValidator(RsValidatorEnum.MIN, 'Enter a First Name', 1)
			]),
			new RsFormControl('lastName', user?.lastName || '', [
				new RsValidator(RsValidatorEnum.CUSTOM, 'Invalid Last Name', (control) => {
					return StringUtils.namePattern.test(control.value.toString());
				}),
				new RsValidator(RsValidatorEnum.MIN, 'Enter a Last Name', 1)
			]),
			new RsFormControl('phone', user?.phone || '', [
				new RsValidator(RsValidatorEnum.REQ, 'Enter a valid phone number'),
				new RsValidator(RsValidatorEnum.MIN, 'Enter a valid phone number', 7)
			])
		])
	);

	useEffect(() => {
		window.scrollTo(0, 0);
		async function validateForm() {
			setAccountInfoChanged(updateUserForm.isModified());
			let isValid = await updateUserForm.isValid();
			setIsUserFormValid(isValid);
			if (!updateUserForm.isModified()) {
				setIsUserFormValid(false);
			}
		}
		validateForm().catch(console.error);
	}, [updateUserForm]);

	useEffect(() => {
		if (!user) navigate('/page-not-found');
	}, [user]);

	const ConTwoDecDigit = (digit: any) => {
		return digit.indexOf('.') > 0
			? digit.split('.').length <= 4
				? digit.split('.')[0] + '.' + digit.split('.')[1].substring(-1, 4)
				: digit
			: digit;
	};

	async function updateUserObjForm(control: RsFormControl) {
		if (control.key === 'firstName') {
			if (!isNaN(Number(control.value))) {
				// If the value is a number, clear it
				control.value = '';
			} else {
				// Remove special characters and ensure two decimal digits
				control.value = control.value.toString().replace(/[^A-Za-z]/g, '');
				control.value = ConTwoDecDigit(control.value);
			}
		}

		if (control.key === 'lastName') {
			if (!isNaN(Number(control.value))) {
				// If the value is a number, clear it
				control.value = '';
			} else {
				// Remove special characters and ensure two decimal digits
				control.value = control.value.toString().replace(/[^A-Za-z]/g, '');
				control.value = ConTwoDecDigit(control.value);
			}
		}

		setUpdateUserForm(updateUserForm.clone().update(control));
	}

	async function saveAccountInfo() {
		if (!isUserFormValid) {
			rsToastify.error('Missing some information. Make sure everything is filled out', 'Missing Information');
			return;
		}
		if (!user) return;
		let newUpdatedUserObj: any = updateUserForm.toModel();
		newUpdatedUserObj.id = get(user, 'id');
		try {
			let response = await userService.update(newUpdatedUserObj);
			rsToastify.success('Account information successfully updated. ', 'Update Successful!');
			setUser(response);
		} catch (e: any) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Update failed, try again'), 'Server Error');
			const message = `Getting error :${e.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	return !user ? (
		<LoadingPage />
	) : (
		<Page className={'rsAccountPersonalInfoPage'}>
			<SubNavMenu title={'Personal Information'} />
			<div className={'rs-page-content-wrapper'}>
				<Paper borderRadius={'20px'} boxShadow>
					<Label variant={'buttonMdLg'} mb={isMobile ? 25 : 32}>
						Account Info
					</Label>
					<div className={'emailAddressTitle'}>
						<Label variant={'h6'}>Email Address</Label>
						<Label variant={'caption'} className={'contactSupport'}>
							Please contact support to update your email.
						</Label>
					</div>
					<Box className={'fakeEmailInput'}>
						<Label variant={'body1'}>{get(user, 'primaryEmail')}</Label>
					</Box>
					<Box className={'nameEntries'} mb={isMobile ? 0 : 26} flexDirection={isMobile ? 'column' : ''}>
						<LabelInput
							labelVariant={'h6'}
							title={'First Name'}
							inputType={'text'}
							control={updateUserForm.get('firstName')}
							updateControl={updateUserObjForm}
						/>
						<LabelInput
							labelVariant={'h6'}
							title={'Last Name'}
							inputType={'text'}
							control={updateUserForm.get('lastName')}
							updateControl={updateUserObjForm}
						/>
					</Box>

					<Box className={'phonePasswordRow'} mb={isMobile ? 0 : 26} flexDirection={isMobile ? 'column' : ''}>
						<LabelInput
							className={'phoneInput'}
							labelVariant={'h6'}
							inputType={'tel'}
							title={'Phone'}
							isPhoneInput
							onChange={(value) => {
								let updatedPhone = updateUserForm.get('phone');
								updatedPhone.value = value;
								setUpdateUserForm(updateUserForm.clone().update(updatedPhone));
							}}
							initialValue={updateUserForm.get('phone').value.toString()}
						/>
						<Box>
							<Label variant={'h6'}>Password</Label>
							<LabelButton
								className={'updatePasswordButton'}
								onClick={() => popupController.open<UpdatePasswordPopupProps>(UpdatePasswordPopup)}
								variant={'buttonSm'}
								label={'Update my password'}
								look={'none'}
							/>
						</Box>
					</Box>
					<LabelButton
						className={'saveBtn'}
						look={'containedPrimary'}
						variant={'buttonMdLg'}
						label={'Save'}
						disabled={!isUserFormValid}
						onClick={saveAccountInfo}
					/>
				</Paper>
			</div>
		</Page>
	);
};

export default AccountPersonalInfoPage;
