import React from 'react';
import { Box, Popup, popupController, PopupProps } from '../../lib/@bit/redsky.framework.rs.996';
import './RateDetailPopup.scss';
import Paper from '../../components/paper/Paper';
import Icon from '../../lib/@bit/redsky.framework.rs.icon';
import Label from '../../lib/@bit/redsky.framework.rs.label';
import useIsAtBreakpoint from '../../customHooks/useIsAtBreakpoint';

export interface RateDetailPopupProps extends PopupProps {
	priceDetail: Api.Accommodation.Res.Availability.Price;
	contentList: any;
}

const RateDetailPopup: React.FC<RateDetailPopupProps> = (props) => {
	const isMobile = useIsAtBreakpoint();
	const fullProps = popupController.convertProps(props);

	const rateDetail: any = props?.contentList.RateList.find((o: any) => o.Code === props.priceDetail.rate.code);
	const cancelPolicy: any = props?.contentList.PolicyList.CancelPolicy.find(
		(o: any) => o.Code === props.priceDetail.cancelPolicy
	);
	const guaranteePolicy: any = props?.contentList.PolicyList.BookingPolicy.find(
		(o: any) => o.Code === props.priceDetail.bookingPolicy
	);

	return (
		<Popup {...props}>
			<Paper className={'rsRateDetailPopup'}>
				<Box className={'titleContainer'}>
					<Box>
						<Label variant={'h4'} className={'primaryTextColor'}>
							{'Rate Details'}
						</Label>
					</Box>
					{/* {isMobile && ( */}
					<Icon
						className={'closeIcon'}
						iconImg={'icon-close'}
						size={32}
						onClick={() => {
							popupController.closeById(fullProps.popupId);
						}}
					/>
					{/* )} */}
				</Box>
				<Box className={'rateDetailView'}>
					<div className="detailSection">
						{!!rateDetail?.Details?.DetailedDescription && (
							<Box className="" marginBottom={24}>
								<Label variant="h6" className="darkText">
									Additional Information
								</Label>
								<div
									dangerouslySetInnerHTML={{ __html: rateDetail?.Details.DetailedDescription }}
								></div>
							</Box>
						)}

						{!!cancelPolicy && (
							<Box className="" marginBottom={24}>
								<Label variant="h6" className="darkText">
									Cancellation Policy
								</Label>
								<Label variant="p" margin={0}>
									{cancelPolicy?.Description}
								</Label>
							</Box>
						)}
						{!!guaranteePolicy && (
							<Box className="" marginBottom={24}>
								<Label variant="h6" className="darkText">
									Guarantee Policy
								</Label>
								<Label variant="p" margin={0}>
									{guaranteePolicy?.Description}
								</Label>
							</Box>
						)}
					</div>
					<div className={'PopupGirlblock'}>
						<img className={'PopupGirl'} src={require('../../images/RateDetailGirl.png')} />
					</div>
				</Box>
			</Paper>
		</Popup>
	);
};

export default RateDetailPopup;
