import * as React from 'react';
import { useEffect, useState } from 'react';
import './EditUserAddressPopup.scss';
import { Box, Popup, popupController, PopupProps } from '../../lib/@bit/redsky.framework.rs.996';
import Paper from '../../components/paper/Paper';
import { RsFormControl, RsFormGroup, RsValidator, RsValidatorEnum } from '../../lib/@bit/redsky.framework.rs.form';
import Icon from '../../lib/@bit/redsky.framework.rs.icon';
import Label from '../../lib/@bit/redsky.framework.rs.label';
import LabelInput from '../../components/labelInput/LabelInput';
import LabelSelect from '../../components/labelSelect/LabelSelect';
import LabelButton from '../../components/labelButton/LabelButton';
import { StringUtils, WebUtils } from '../../utils/utils';
import LabelCheckbox from '../../components/labelCheckbox/LabelCheckbox';
import { rsToastify } from '../../lib/@bit/redsky.framework.rs.toastify';
import serviceFactory from '../../services/serviceFactory';
import CountryService from '../../services/country/country.service';
import useGetCountryList from '../../customHooks/useGetCountryList';
import OptionType = Misc.OptionType;

export interface EditUserAddressPopupProps extends PopupProps {
	countryList: OptionType[];
	existingAddress: Api.User.Address;
	editAddressCallback: (address: Api.UserAddress.Req.Update) => void;
}

enum FormKeys {
	FIRST_NAME = 'firstName',
	LAST_NAME = 'lastName',
	ADDRESS1 = 'address1',
	ADDRESS2 = 'address2',
	CITY = 'city',
	ZIP = 'zip',
	STATE = 'state',
	COUNTRY = 'country'
}

const EditUserAddressPopup: React.FC<EditUserAddressPopupProps> = (props) => {
	const fullProps = popupController.convertProps(props);
	const countryList = useGetCountryList();
	const [countries, setCountries] = useState<OptionType[]>(props.countryList);
	const [isDefault, setIsDefault] = useState<0 | 1>(props.existingAddress.isDefault);
	const countryService = serviceFactory.get<CountryService>('CountryService');
	const [stateList, setStateList] = useState<OptionType[]>([]);
	const [selectKey, setSelectKey] = useState<number>(0);
	const [editAddressForm, setEditAddressForm] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl(FormKeys.ADDRESS1, props.existingAddress.address1, [
				new RsValidator(RsValidatorEnum.REQ, 'Address is required'),
				new RsValidator(RsValidatorEnum.CUSTOM, 'Address is required', (control: RsFormControl) => {
					return StringUtils.streetAddressCityPattern.test(control.value.toString());
				})
			]),
			new RsFormControl(FormKeys.ADDRESS2, props.existingAddress.address2, []),
			new RsFormControl(FormKeys.CITY, props.existingAddress.city, [
				new RsValidator(RsValidatorEnum.REQ, 'City is required'),
				new RsValidator(RsValidatorEnum.CUSTOM, 'City is required', (control: RsFormControl) => {
					return StringUtils.streetAddressCityPattern.test(control.value.toString());
				})
			]),
			new RsFormControl(FormKeys.ZIP, props.existingAddress.zip, [
				new RsValidator(RsValidatorEnum.REQ, 'Zip is required'),
				new RsValidator(RsValidatorEnum.CUSTOM, 'Invalid Zip Code', (control) => {
					return StringUtils.zipPattern.test(control.value.toString());
				})
			]),
			new RsFormControl(FormKeys.STATE, props.existingAddress.state, []),
			new RsFormControl(FormKeys.COUNTRY, props.existingAddress.country, [
				new RsValidator(RsValidatorEnum.REQ, 'Country is required')
			])
		])
	);
	const [nameForm, setNameForm] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl(
				FormKeys.FIRST_NAME,
				props.existingAddress.name ? props.existingAddress.name.split(' ')[0] : '',
				[
					new RsValidator(RsValidatorEnum.REQ, 'First name is required'),
					new RsValidator(RsValidatorEnum.CUSTOM, 'First name is required', (control: RsFormControl) => {
						return StringUtils.namePattern.test(control.value.toString());
					})
				]
			),
			new RsFormControl(
				FormKeys.LAST_NAME,
				props.existingAddress.name ? props.existingAddress.name.split(' ')[1] : '',
				[
					new RsValidator(RsValidatorEnum.REQ, 'Last name is required'),
					new RsValidator(RsValidatorEnum.CUSTOM, 'Last name is required', (control: RsFormControl) => {
						return StringUtils.namePattern.test(control.value.toString());
					})
				]
			)
		])
	);

	useEffect(() => {
		setCountries(formatStateOrCountryListForSelect(countryList));
	}, [countryList]);

	useEffect(() => {
		async function getStates() {
			try {
				let response = await countryService.getStates(`${editAddressForm.get(FormKeys.COUNTRY).value}` || 'US');
				if (response.states) {
					let newStates = formatStateOrCountryListForSelect(response.states);
					if (newStates.length === 0) {
						setStateList([{ value: 'N/A', label: 'N/A' }]);
					} else {
						setStateList(newStates);
					}
				}
			} catch (e) {
				rsToastify.error(
					WebUtils.getRsErrorMessage(e, 'Unable to get states for the selected country.'),
					'Server Error'
				);
			}
		}
		getStates().catch(console.error);
	}, [countryList, editAddressForm.get(FormKeys.COUNTRY).value]);

	function formatStateOrCountryListForSelect(statesOrCountries: Misc.IBaseCountry[]) {
		return statesOrCountries.map((item: Misc.IBaseCountry) => {
			return { value: item.isoCode, label: item.name };
		});
	}

	function updateNewAddressObj(control: RsFormControl) {
		if (control.key === FormKeys.COUNTRY) {
			let stateControl = editAddressForm.get(FormKeys.STATE);
			stateControl.value = '';
			editAddressForm.update(stateControl);
			setSelectKey((prevState) => ++prevState);
		}
		editAddressForm.update(control);
		setEditAddressForm(editAddressForm.clone());
	}

	function isFormFilledOut(): boolean {
		return (
			!!nameForm.get(FormKeys.FIRST_NAME).value.toString().length &&
			!!nameForm.get(FormKeys.LAST_NAME).value.toString().length &&
			!!editAddressForm.get(FormKeys.ADDRESS1).value.toString().length &&
			!!editAddressForm.get(FormKeys.CITY).value.toString().length &&
			editAddressForm.get(FormKeys.ZIP).value.toString().length > 4 &&
			editAddressForm.get(FormKeys.ZIP).value.toString().length < 12 &&
			!!editAddressForm.get(FormKeys.STATE).value.toString().length &&
			!!editAddressForm.get(FormKeys.COUNTRY).value.toString().length
		);
	}

	const ConTwoDecDigit = (digit: any) => {
		return digit.indexOf('.') > 0
			? digit.split('.').length <= 4
				? digit.split('.')[0] + '.' + digit.split('.')[1].substring(-1, 4)
				: digit
			: digit;
	};

	function handleUpdate() {
		let addressToUpdate: Api.UserAddress.Req.Update = editAddressForm.toModel();
		let firstName = nameForm.get(FormKeys.FIRST_NAME);
		let lastName = nameForm.get(FormKeys.LAST_NAME);
		if (firstName.value.toString().charAt(0) === ' ') {
			rsToastify.error('First name is required', 'Enter First name ');
			return;
		}
		if (lastName.value.toString().charAt(0) === ' ') {
			rsToastify.error('Last name is required', 'Enter Last name ');
			return;
		}
		addressToUpdate.name = `${firstName.value} ${lastName.value}`;
		addressToUpdate.isDefault = isDefault ? 1 : 0;
		addressToUpdate.zip = addressToUpdate.zip!.toString();
		props.editAddressCallback(addressToUpdate);
		popupController.closeById(fullProps.popupId);
	}

	return (
		<Popup {...props} className="rsEditUserAddressPopup">
			<Paper className="editAddressPopupPaper">
				<Box className="popupHeader">
					<Label variant={'h5'} className={'primaryTextColor'}>
						Edit Address
					</Label>
					<Icon
						iconImg={'icon-close'}
						onClick={() => {
							return popupController.closeById(fullProps.popupId);
						}}
						cursorPointer
					/>
				</Box>
				<Box className={'cardBodyContainer'}>
					<Box className={'inputFieldContainer'}>
						<LabelInput
							className={'addressInput'}
							labelVariant={'h6'}
							title={'First Name'}
							inputType={'text'}
							control={nameForm.get(FormKeys.FIRST_NAME)}
							updateControl={(control) => {
								if (control.key === 'firstName') {
									if (!isNaN(Number(control.value))) {
										// If the value is a number, clear it
										control.value = '';
									} else {
										// Remove special characters and ensure two decimal digits
										control.value = control.value.toString().replace(/[^A-Za-z]/g, '');
										control.value = ConTwoDecDigit(control.value);
									}
								}

								if (control.value.toString().charAt(0) !== ' ') {
									setNameForm(nameForm.clone().update(control));
								}
							}}
							isRequired
						/>
						<LabelInput
							className={'addressInput'}
							labelVariant={'h6'}
							title={'Last Name'}
							inputType={'text'}
							control={nameForm.get(FormKeys.LAST_NAME)}
							updateControl={(control) => {
								if (control.key === 'lastName') {
									if (!isNaN(Number(control.value))) {
										// If the value is a number, clear it
										control.value = '';
									} else {
										// Remove special characters and ensure two decimal digits
										control.value = control.value.toString().replace(/[^A-Za-z]/g, '');
										control.value = ConTwoDecDigit(control.value);
									}
								}

								if (control.value.toString().charAt(0) !== ' ') {
									setNameForm(nameForm.clone().update(control));
								}
							}}
							isRequired
						/>
					</Box>
					<LabelInput
						labelVariant={'h6'}
						className={'addressInput'}
						title={'Address Line 1'}
						inputType={'text'}
						control={editAddressForm.get(FormKeys.ADDRESS1)}
						updateControl={(control) => {
							if (control.value.toString().charAt(0) !== ' ') {
								updateNewAddressObj(control);
							}
						}}
						isRequired
					/>
					<LabelInput
						labelVariant={'h6'}
						className={'addressInput'}
						title={'Address Line 2'}
						inputType={'text'}
						control={editAddressForm.get(FormKeys.ADDRESS2)}
						updateControl={(control) => {
							if (control.value.toString().charAt(0) !== ' ') {
								updateNewAddressObj(control);
							}
						}}
					/>
					<Box className={'inputFieldContainer'}>
						<LabelInput
							className={'addressInput'}
							labelVariant={'h6'}
							title={'City'}
							inputType={'text'}
							control={editAddressForm.get(FormKeys.CITY)}
							updateControl={(control) => {
								if (control.value.toString().charAt(0) !== ' ') {
									updateNewAddressObj(control);
								}
							}}
							isRequired
						/>
						<LabelInput
							className={'addressInput'}
							labelVariant={'h6'}
							title={'Zip Code'}
							inputType={'text'}
							control={editAddressForm.get(FormKeys.ZIP)}
							updateControl={(control) => {
								if (control.value.toString().charAt(0) !== ' ') {
									updateNewAddressObj(control);
								}
							}}
							isRequired
						/>
					</Box>
					<Box className={'inputFieldContainer'}>
						<LabelSelect
							key={`select-${selectKey}`}
							className={'addressInput'}
							labelVariant={'h6'}
							title={'State'}
							options={stateList}
							control={editAddressForm.get(FormKeys.STATE)}
							updateControl={updateNewAddressObj}
							isRequired
						/>
						<LabelSelect
							className={'addressInput'}
							labelVariant={'h6'}
							title={'Country'}
							options={countries}
							control={editAddressForm.get(FormKeys.COUNTRY)}
							updateControl={updateNewAddressObj}
							isRequired
						/>
					</Box>
				</Box>
				<Box className={'popupFooter'}>
					<LabelCheckbox
						value={'isDefault'}
						labelVariant={'h6'}
						text={'Set as primary'}
						isChecked={!!isDefault}
						isDisabled={!!props.existingAddress.isDefault}
						onSelect={() => {
							setIsDefault(1);
						}}
						onDeselect={() => {
							setIsDefault(0);
						}}
					/>
					<LabelButton
						label={'UPDATE'}
						look={'containedPrimary'}
						variant={'ButtonMdLg'}
						onClick={handleUpdate}
						disabled={!isFormFilledOut()}
					/>
				</Box>
			</Paper>
		</Popup>
	);
};

export default EditUserAddressPopup;
