// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rs-popup-content{position:fixed;height:100%;width:100%;overflow:hidden;background-color:transparent;display:flex;align-items:center;justify-content:center}.rs-popup{position:fixed;height:100%;width:100%;top:0;bottom:0;left:0;right:0;background-color:transparent}.rs-popup:nth-last-of-type(6){z-index:95}.rs-popup:nth-last-of-type(5){z-index:96}.rs-popup:nth-last-of-type(4){z-index:97}.rs-popup:nth-last-of-type(3){z-index:98}.rs-popup:nth-last-of-type(2){z-index:99}.rs-popup:nth-last-of-type(1){z-index:100}
`, "",{"version":3,"sources":["webpack://./src/lib/@bit/redsky.framework.rs.996/dist/popup/Popup.scss"],"names":[],"mappings":"AAEA,kBACC,cAAe,CACf,WAAY,CACZ,UAAW,CACX,eAAgB,CAChB,4BAA6B,CAC7B,YAAa,CACb,kBAAmB,CACnB,sBAAuB,CACvB,UAGA,cAAe,CACf,WAAY,CACZ,UAAW,CACX,KAAM,CACN,QAAS,CACT,MAAO,CACP,OAAQ,CACR,4BAA6B,CAR9B,8BAWE,UAAW,CAXb,8BAeE,UAAW,CAfb,8BAmBE,UAAW,CAnBb,8BAuBE,UAAW,CAvBb,8BA2BE,UAAW,CA3Bb,8BA+BE,WAAY","sourcesContent":["@import '../variables.scss';\n\n.rs-popup-content {\n\tposition: fixed;\n\theight: 100%;\n\twidth: 100%;\n\toverflow: hidden;\n\tbackground-color: transparent;\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n}\n\n.rs-popup {\n\tposition: fixed;\n\theight: 100%;\n\twidth: 100%;\n\ttop: 0;\n\tbottom: 0;\n\tleft: 0;\n\tright: 0;\n\tbackground-color: transparent;\n\n\t&:nth-last-of-type(6) {\n\t\tz-index: 95;\n\t}\n\n\t&:nth-last-of-type(5) {\n\t\tz-index: 96;\n\t}\n\n\t&:nth-last-of-type(4) {\n\t\tz-index: 97;\n\t}\n\n\t&:nth-last-of-type(3) {\n\t\tz-index: 98;\n\t}\n\n\t&:nth-last-of-type(2) {\n\t\tz-index: 99;\n\t}\n\n\t&:nth-last-of-type(1) {\n\t\tz-index: 100;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
