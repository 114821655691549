import * as React from 'react';
import './LabelSelect.scss';
import Label from '../../lib/@bit/redsky.framework.rs.label';
import Select, { SelectProps } from '../../lib/@bit/redsky.framework.rs.select';

interface LabelSelectProps extends SelectProps {
	title: string;
	labelVariant?: string;
	isRequired?: boolean;
}

const LabelSelect: React.FC<LabelSelectProps> = (props) => {
	return (
		<div className={`rsLabelSelect ${props.className || ''}`}>
			<Label variant={`${props.labelVariant || 'caption'}`} mb={8}>
				{props.isRequired && <span className="requiredAsterisk">*</span>}
				{props.title}
			</Label>
			<Select {...props} className={''} />
		</div>
	);
};

export default LabelSelect;
