import * as React from 'react';
import './RateCodeCardResponsive.scss';
import { Box } from '../../../lib/@bit/redsky.framework.rs.996';
import Label from '../../../lib/@bit/redsky.framework.rs.label';
import PointsOrCentsBox from '../../pointsOrCentsBox/PointsOrCentsBox';
import themes from '../../../themes/themes.scss';

interface RateCodeCardResponsiveProps {
	pointPerDollar?: number;
	priceObj: Misc.Pricing;
	accommodationId: number;
	destinationId: number;
	packageLength?: number;
	loyaltyStatus: Model.LoyaltyStatus;
	upfrontCashRequired: boolean;
	isCustomResort?: boolean;
	phone?: number;
	destinationDetails: Api.Destination.Res.Details | undefined;
	onRateDetail: VoidFunction;
	campaignLength: number;
	accommodation?: Api.Destination.Res.Accommodation;
}

const RateCodeCardResponsive: React.FC<RateCodeCardResponsiveProps> = (props) => {
	return (
		<Box className={'rsRateCodeCardResponsive'}>
			<Box>
				<Label variant={'buttonMdLg'} paddingBottom={13}>
					{props.priceObj.rate.name || 'Promotional Rate'}
				</Label>
				<Label variant={'h6'}>{props.priceObj.rate.description || 'Promotional Rate'}</Label>
				<Label variant={'button'} color={themes.errorColor} onClick={props.onRateDetail}>
					Rate Details
				</Label>
			</Box>
			<PointsOrCentsBox
				pointPerDollar={props.pointPerDollar}
				destinationDetails={props.destinationDetails}
				priceObj={props.priceObj}
				accommodation={props.accommodation}
				accommodationId={props.accommodationId}
				resortPointsPrice={props.priceObj.pricePoints}
				packageLength={props.packageLength}
				destinationId={props.destinationId}
				loyaltyStatus={props.loyaltyStatus}
				upfrontCashRequired={props.upfrontCashRequired}
				isCustomResort={props.isCustomResort}
				phone={props.phone}
				campaignLength={props.campaignLength}
			/>
		</Box>
	);
};

export default RateCodeCardResponsive;
