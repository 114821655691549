import * as React from 'react';
import './DestinationDetailsMobilePopup.scss';
import { Box, Popup, popupController, PopupProps } from '../../lib/@bit/redsky.framework.rs.996';
import { useEffect, useLayoutEffect, useState } from 'react';
import { rsToastify } from '../../lib/@bit/redsky.framework.rs.toastify';
import useFlipTo from '../../customHooks/useFlipTo';
import { StringUtils, WebUtils } from '../../utils/utils';
import serviceFactory from '../../services/serviceFactory';
import PackageService from '../../services/package/package.service';
import DestinationService from '../../services/destination/destination.service';
import AccommodationService from '../../services/accommodation/accommodation.service';
import { useRecoilState } from 'recoil';
import globalState, { setRecoilExternalValue } from '../../state/globalState';
import MobileLightBox, { MobileLightBoxProps } from '../mobileLightBox/MobileLightBox';
import CarouselV2 from '../../components/carouselV2/CarouselV2';
import ComparisonService from '../../services/comparison/comparison.service';
import Label from '../../lib/@bit/redsky.framework.rs.label/dist/Label';
import Icon from '../../lib/@bit/redsky.framework.rs.icon';
import { ObjectUtils } from '../../lib/@bit/redsky.framework.rs.utils';
import SpinningLoader from '../../components/loader/Loader';
import DestinationExperienceImageGallery from '../../components/destinationExperienceImageGallery/DestinationExperienceImageGallery';
import ImageLabel from '../../components/imageLabel/ImageLabel';
import FilterBarLimited from '../../components/filterBar/FilterBarLimited';
import PaginationViewMore from '../../components/paginationViewMore/PaginationViewMore';
import AccommodationSearchCard from '../../components/accommodationSearchCardV2/AccommodationSearchCard';
import AccommodationOverviewPopup, {
	AccommodationOverviewPopupProps
} from '../../popups/accommodationOverviewPopup/AccommodationOverviewPopup';
import IconLabel from '../../components/iconLabel/IconLabel';
import Footer from '../../components/footer/Footer';
import { FooterLinks } from '../../components/footer/FooterLinks';
import Map from '../../components/googleMap/gMap';
import { Status, Wrapper } from '@googlemaps/react-wrapper';
import useGetCountryList from '../../customHooks/useGetCountryList';
import MetaPixelTag from '../../components/metaPixelTag/MetaPixelTag';
import router from '../../utils/router';
import { Rating } from 'react-simple-star-rating';
import ReviewPopup, { ReviewPopupProps } from '../../popups/reviewPopup/ReviewPopup';
import MapModal from '../../components/googleMap/components/mapModal/mapModal';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Accordion from '../../lib/@bit/redsky.framework.rs.accordion';
import classNames from 'classnames';
// import TopSearchBarMobile from '../../components/topSearchBar/topSearchBarMobile/TopSearchBarMobile';
import Button from '../../lib/@bit/redsky.framework.rs.button';
import FilterBarLimitedMobile from '../../components/filterBar/FilterBarLimitedMobile';
import moment from 'moment';
import { getPageFinder, undefinedHandler } from '../../utils/undefinedHandler';
import SimilarChoiceRecommendation from '../../components/similarChoiceRecommendation/similarChoiceRecommendation';
import FlexibleRates from '../../components/flexibleRates/FlexibleRates';
import { geoCoderApiKey } from '../../utils/constant';

export interface DestinationDetailsMobilePopupProps extends PopupProps {
	pointPerDollar?: number;
	destinationId: number;
	startDate: string;
	endDate: string;
	resortPoint?: number;
	resortPointsPrice?: number;
}

const DestinationDetailsMobilePopup: React.FC<DestinationDetailsMobilePopupProps> = (props) => {
	const countryList = useGetCountryList();
	const packageService = serviceFactory.get<PackageService>('PackageService');
	const destinationService = serviceFactory.get<DestinationService>('DestinationService');
	const accommodationService = serviceFactory.get<AccommodationService>('AccommodationService');
	const comparisonService = serviceFactory.get<ComparisonService>('ComparisonService');
	const [reservationFilters, setReservationFilters] = useRecoilState<Misc.ReservationFilters>(
		globalState.reservationFilters
	);
	const [isFetchingResults, setIsFetchingResults] = useState<boolean>(true);
	const [resortPackagesCount, setResortPackagesCount] = useState<number | any>(0);
	const [destinationDetails, setDestinationDetails] = useState<Api.Destination.Res.Details>();
	const [availabilityStayList, setAvailabilityStayList] = useState<Api.Accommodation.Res.Availability[]>([]);
	const [availabilityPointsData, setAvailabilityPointsData] = useState<number>();
	const [minPricePoints, setMinPricePoints] = useState<number>(0);
	const [minPriceCents, setMinPriceCents] = useState<number>(0);
	const [totalResults, setTotalResults] = useState<number>(0);
	const [currencyCode, setCurrencyCode] = useState<string>('USD');
	const [page, setPage] = useState<number>(1);
	const [searchParams, setSearchParams] = useSearchParams();
	const perPage = 10;
	const [loyaltyStatus, setLoyaltyStatus] = useState<Model.LoyaltyStatus>('PENDING');
	const [showMapModal, setShowMapModal] = useState<boolean>(false);
	const [pointPerDollar, setPointPerDollar] = useState<number>(0);
	const fullProps = popupController.convertProps(props);
	const GOOGLE_MAPS_API = 'AIzaSyCXwUAf8nshLghmDZkmkBcxgpqC0r18d5s';
	const [center, setCenter] = React.useState<google.maps.LatLngLiteral>();
	const GOOGLE_MAP_ZOOM = 16;
	const airport = '../../images/AirPort.svg';
	const location = '../../images/Location.svg';
	const poiToHide: google.maps.MapTypeStyle[] = [
		{
			featureType: 'poi',
			stylers: [{ visibility: 'off' }]
		}
	];
	// useEffect(() => {
	// 	window.history.pushState(null, 'DestinationDetailsMobilePopup Close', window.location.pathname);
	// 	window.addEventListener('popstate', (event: PopStateEvent) => {
	// 		event.preventDefault();
	// 		popupController.close(DestinationDetailsMobilePopup);
	// 	});
	// 	return () => {
	// 		window.removeEventListener('popstate', (event: PopStateEvent) => {
	// 			event.preventDefault();
	// 			popupController.close(DestinationDetailsMobilePopup);
	// 		});
	// 	};
	// }, []);

	useEffect(() => {
		document.body.style.overflow = 'hidden';
		document.body.style.position = 'fixed';
		document.body.style.top = '0';

		getPlatformVariables();

		return () => {
			document.body.style.overflow = 'unset';
			document.body.style.position = 'unset';
			document.body.style.top = 'unset';
		};
	}, []);

	async function getPlatformVariables() {
		try {
			const pointPerDollar = (await destinationService.getPlatformVariables()).rewardPointsPerDollar; //global reward point per dollar
			setPointPerDollar(pointPerDollar);
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	useEffect(() => {
		const id = router.subscribeToBeforeRouterNavigate((newPath) => {
			if (newPath.includes('book')) return;
			else {
				setReservationFilters((prev) => {
					// WebUtils.updateUrlParams({ ...prev, destinationId: undefined });
					return { ...prev, destinationId: undefined };
				});
			}
		});

		return () => {
			router.unsubscribeFromBeforeRouterNavigate(id);
		};
	}, []);

	const calculateHighestPointEarnable = (resultData: any) => {
		const points: number[] = [];
		resultData.map((datum: any) => {
			points.push(datum.pointsEarnable);
		});

		const MaxPointEarnable = Math.max(...points);
		setAvailabilityPointsData(MaxPointEarnable);
	};

	useLayoutEffect(() => {
		(async () => {
			try {
				const dest = await destinationService.getDestinationDetails({
					destinationId: props.destinationId
				});
				setDestinationDetails(dest);
				useFlipTo.setFlipInit(dest);
				// setRecoilExternalValue<Misc.ReservationFilters>(globalState.reservationFilters, {
				// 	...reservationFilters,
				// 	destinationId: dest.id,
				// 	flipToregionIds: dest.regions
				// });

				const request: Api.UpsellPackage.Req.Availability = {
					destinationId: props.destinationId,
					startDate: reservationFilters.startDate,
					endDate: reservationFilters.endDate
				};
				const responsePackages = await packageService.getAvailable(request);
				setResortPackagesCount(responsePackages.total);

				setCenter({
					lat: dest.latitude as number,
					lng: dest.longitude as number
				});
				setLoyaltyStatus(dest.loyaltyStatus);
			} catch (e) {
				// rsToastify.error(
				// 	WebUtils.getRsErrorMessage(e, 'Cannot get details for this destination.'),
				// 	'Server Error'
				// );
			}
		})();
	}, [props.destinationId]);

	useEffect(() => {
		(async () => {
			setPage(1);
			setAvailabilityStayList([]);
			await getAvailableStays(1);
		})();
	}, [reservationFilters, props.destinationId]);

	async function handleViewMore(): Promise<void> {
		if (!!searchParams.get('promo')) {
			const search = '';
			setSearchParams(search, { replace: true });
			page && destinationDetails && (await getAvailableStays(page, true));
		}
	}

	async function getAvailableStays(searchPage: number, viewAll: boolean = false) {
		// if (!reservationFilters.destinationId) return;
		if (!props.destinationId) return;

		try {
			let searchQueryObj: Misc.ReservationFilters = { ...reservationFilters };

			if (!!searchParams.get('promo') && !viewAll) {
				let promotion = searchParams.get('promo') || '';
				searchQueryObj = {
					...searchQueryObj,
					accessCode: promotion,
					accessType: !!promotion ? 'promotion' : ''
				};
			}

			let key: keyof Misc.ReservationFilters;
			for (key in searchQueryObj) {
				if (searchQueryObj[key] === undefined) delete searchQueryObj[key];
			}
			searchQueryObj.pagination = { page: searchPage, perPage };
			setIsFetchingResults(true);
			let result: Api.Accommodation.Res.MinMaxAvailability = await accommodationService.availability(
				// reservationFilters.destinationId,
				props.destinationId,
				searchQueryObj
			);

			calculateHighestPointEarnable(result.data);
			setTotalResults(result.total || 0);
			if (result.data.length > 0) setCurrencyCode(result.data[0].prices[0]?.rate?.currencyCode);
			setMinPricePoints(result.minPricePoints || 0);
			setMinPriceCents(result.minPriceCents || 0);
			if (searchPage === 1) setAvailabilityStayList(result.data);
			else
				setAvailabilityStayList((prev) => {
					return [...prev, ...result.data];
				});
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unable to get available accommodations.'), 'Server Error');
		}
		setIsFetchingResults(false);
	}

	function getImageUrls(destination: Api.Destination.Res.Details): string[] {
		try {
			if (destination.media) {
				let images = destination.media;
				images.sort((a, b) => {
					return b.isPrimary - a.isPrimary;
				});
				return images.map((urlObj) => {
					return urlObj.urls.large?.toString() || urlObj.urls.thumb;
				});
			}
			return [];
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
			return [];
		}
	}

	function renderLocations() {
		if (!destinationDetails) return;
		if (!destinationDetails.state) {
			let countryName: Api.Country.Res.Country | undefined = countryList.find(
				(item) => item.isoCode === destinationDetails.country
			);
			if (!countryName) return;
			return (
				<Box className="destinationLocation" paddingBottom={12}>
					<Icon iconImg={'icon-pin'} className="locationPin" size={17} />
					<Label variant="caption">
						{destinationDetails.city}, {countryName.name}
					</Label>
				</Box>
			);
		} else {
			return (
				<Box className="destinationLocation" paddingBottom={12}>
					<Icon iconImg={'icon-pin'} className="locationPin" size={17} />
					<Label variant="caption">
						{destinationDetails.city}, {destinationDetails.state}
					</Label>
				</Box>
			);
		}
	}

	function renderExperiencesSection() {
		if (!destinationDetails?.experiences) return null;
		if (!ObjectUtils.isArrayWithData(destinationDetails.experiences)) return <></>;
		return <DestinationExperienceImageGallery experiences={destinationDetails.experiences} />;
	}

	function renderMinMaxLabels(min: number, max: number) {
		if (min === max) return min;
		else if (min === 0) return `1-${max}`;
		else return `${min}-${max}`;
	}

	function renderExperiences() {
		if (!destinationDetails) return;
		return destinationDetails.experiences.map((experience) => {
			return (
				<IconLabel
					key={experience.id}
					labelName={experience.title}
					iconImg={experience.icon}
					iconPosition={'left'}
					iconSize={24}
					labelVariant={'caption'}
				/>
			);
		});
	}

	function renderAccommodations() {
		try {
			if (!ObjectUtils.isArrayWithData(availabilityStayList)) return;
			return availabilityStayList.map((accommodationAvailability) => {
				const promotedRate = accommodationAvailability?.prices.filter(
					(item) => item?.rate?.code === destinationDetails?.promotedRate
				);

				const nonPromotedRate = accommodationAvailability?.prices.filter(
					(item) => item?.rate?.code !== destinationDetails?.promotedRate
				);

				return (
					<AccommodationSearchCard
						isOpen={handleViewMore}
						accessCode={searchParams.get('promo') ?? ''}
						pointPerDollar={pointPerDollar}
						key={accommodationAvailability.id}
						promotedRate={promotedRate && promotedRate.length === 0 ? nonPromotedRate[0] : promotedRate[0]}
						nonPromotedRate={
							promotedRate && promotedRate.length === 0
								? nonPromotedRate.slice(1, nonPromotedRate.length)
								: nonPromotedRate
						}
						destinationDetails={destinationDetails}
						accommodation={accommodationAvailability}
						resortPoint={accommodationAvailability.pointsEarnable}
						resortPointsPrice={accommodationAvailability.minPricePoints}
						destinationId={reservationFilters.destinationId || props.destinationId}
						onClickInfoIcon={handleOnInfoClick}
						packageLength={resortPackagesCount}
						loyaltyStatus={destinationDetails?.loyaltyStatus || 'PENDING'}
						showInfoIcon
						upfrontCashRequired={!!destinationDetails?.upfrontCashRequired || false}
					/>
				);
			});
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	function renderPointsOrCash() {
		try {
			if (reservationFilters.redeemPoints && loyaltyStatus === 'ACTIVE') {
				return `${StringUtils.addCommasToNumber(minPricePoints)}pts`;
			} else return `${StringUtils.formatCurrency(currencyCode) + StringUtils.formatMoney(minPriceCents)}`;
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	async function handleOnInfoClick(accommodationId: number) {
		try {
			const availableAccommodation = availabilityStayList.find((stay) => stay.id === accommodationId);
			if (!availableAccommodation) return;
			popupController.open<AccommodationOverviewPopupProps>(AccommodationOverviewPopup, {
				pointPerDollar: pointPerDollar,
				destinationName: destinationDetails?.name || '',
				packageCount: resortPackagesCount,
				accommodationStay: availableAccommodation,
				destinationDetails: destinationDetails,
				loyaltyStatus: destinationDetails?.loyaltyStatus || 'PENDING',
				pointsEarnable: availableAccommodation.pointsEarnable,
				resortPointsPrice: availableAccommodation.minPricePoints,
				isCustomResort: destinationDetails?.isCustomResort
			});
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	function renderDestinationSize() {
		try {
			if (ObjectUtils.isArrayWithData(availabilityStayList)) {
				let sizeMin = destinationDetails?.minSquareFt;
				let sizeMax = destinationDetails?.maxSquareFt;
				if (!sizeMin && !sizeMax) return;
				return (
					<Label variant={'caption'} className={'border'} paddingLeft={8}>
						{sizeMin === sizeMax || !sizeMin || !sizeMax ? sizeMin || sizeMax : `${sizeMin} to ${sizeMax}`}{' '}
						ft&sup2;
					</Label>
				);
			}
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	function renderMapSource() {
		if (!destinationDetails) return;
		let address = `${destinationDetails.address1} ${destinationDetails.city} ${destinationDetails.state} ${
			destinationDetails.zip
		}, ${destinationDetails.country !== 'BM' ? destinationDetails.country : 'Bermuda'}`;
		address = address.replace(/ /g, '+');
		return encodeURI(`https://www.google.com/maps/dir/?api=1&destination=${address}`);
	}

	function renderGoogleMapsRenderProp(status: Status) {
		return <h1>{status}</h1>;
	}

	function reviewPopup(event: any) {
		event.stopPropagation();
		return popupController.open<ReviewPopupProps>(ReviewPopup, {
			destinationId: destinationDetails?.id,
			googleTotalReview: destinationDetails?.googleTotalReview,
			googleAllOverReview: destinationDetails?.googleAllOverReview
		});
	}
	let navigate = useNavigate();
	function navigator() {
		return navigate(-1);
		// if (!props.navigateTo) {
		// 	return navigate(-1);
		// }
		// const paths = { HOME: '/', RESERVATIONS: '/reservations' };
		// navigate(paths[props.navigateTo]);
	}
	const [hideTaxesAndFees, setHideTaxesAndFees] = useState<boolean>(true);
	const [isOtherFilterPopupOpen, setIsOtherFilterPopupOpen] = useState<boolean>(false);

	return (
		<Popup {...props}>
			{destinationDetails && (
				<>
					{showMapModal && (
						<MapModal
							setShowMapModal={setShowMapModal}
							destinationDetails={destinationDetails}
							GOOGLE_MAPS_API={geoCoderApiKey}
							renderGoogleMapsRenderProp={renderGoogleMapsRenderProp}
						/>
					)}

					<div className={'rsDestinationDetailsMobilePopup'}>
						<div className={'topNav'}>
							<Label variant={'h6'}>Resort Overview</Label>
							<Icon
								iconImg={'icon-close'}
								color={'#797979'}
								size={20}
								onClick={() => {
									setReservationFilters((prev) => {
										// WebUtils.updateUrlParams({ ...prev, destinationId: undefined });
										return { ...prev, destinationId: undefined };
									});
									popupController.closeById(fullProps.popupId);
								}}
							/>
						</div>

						{/* <TopSearchBarMobile  /> */}

						<Box className="mobileAccommodation">
							<hr className="mobileAccommodationHr" />
							{/* <Box display={'flex'} className={'mobileAccommodationSubNavMenu'}> */}
							<Accordion
								hideChevron
								hideHoverEffect
								// isOpen={props.isOpen}
								titleReact={
									<Box
										style={{ width: '100%', justifyContent: 'space-between' }}
										className="accordionContainer"
									>
										<Box className="accordionLabelContainer">
											<Box className="backButtonContainer">
												<Button className={'backButton'} look={'none'} onClick={navigator}>
													<Icon iconImg={'icon-chevron-left'} size={20} />
												</Button>
											</Box>

											<Box className="accommodationLabelName">
												<Label variant={'buttonMdLg'} className={'accordionLabel'}>
													Choose your accommodation
												</Label>
												<Label variant={'buttonMdLg'} className={'accordionLabel2'}>
													{`${moment(reservationFilters.startDate).format(
														'MMM DD'
													)} - ${moment(reservationFilters.endDate).format('MMM DD')}    ${
														reservationFilters.adultCount
													} Adults, ${reservationFilters.childCount} Children`}
												</Label>
											</Box>
										</Box>
										<Box className="iconContainer" display={'flex'}>
											<Icon
												iconImg={'icon-chevron-up'}
												className={classNames('taxIcon', { down: hideTaxesAndFees })}
												cursorPointer
											/>
										</Box>
									</Box>
								}
								onClick={() => setHideTaxesAndFees(!hideTaxesAndFees)}
							>
								{!hideTaxesAndFees && (
									<FilterBarLimitedMobile
										destinationId={destinationDetails?.id}
										loyaltyStatus={destinationDetails?.loyaltyStatus || 'visible'}
										externalId={destinationDetails?.externalId || ''}
										chainId={destinationDetails?.chainId || 0}
										// isMobile={isMobile}
										setIsOtherFilterPopupOpen={setIsOtherFilterPopupOpen}
										isCustomResort={destinationDetails?.isCustomResort}
									/>
								)}
							</Accordion>
							{hideTaxesAndFees && <hr className="mobileAccommodationHr" />}
							{/* </Box> */}
						</Box>
						<CarouselV2
							className={hideTaxesAndFees ? 'headerCarousel2' : 'headerCarousel'}
							destinationId={destinationDetails.id}
							resortPoint={availabilityPointsData}
							path={() => {
								popupController.open<MobileLightBoxProps>(MobileLightBox, {
									imageData: destinationDetails.media
								});
							}}
							imgPaths={getImageUrls(destinationDetails)}
							onAddCompareClick={() => {
								comparisonService.addToComparison(destinationDetails.id).catch(console.error);
							}}
							onRemoveCompareClick={() => {
								comparisonService.removeFromComparison(destinationDetails.id);
							}}
							onGalleryClick={() => {
								popupController.open<MobileLightBoxProps>(MobileLightBox, {
									imageData: destinationDetails.media
								});
							}}
							hideCompareButton={true}
							imageWidth={395}
						/>
						<ImageLabel
							labelName={destinationDetails.name}
							imgSrc={destinationDetails.logoUrl}
							imgWidth={96}
							destinationId={destinationDetails.id}
							imgHeight={32}
							iconPosition={'left'}
							labelVariant="body1B"
						/>
						{/* TEMPORARY CODE IS COMMENT FOR GOOGLE REVIEWS  */}
						{/* {!!destinationDetails.googleAllOverReview && !!destinationDetails.googleTotalReview && (
							<Box
								display={'flex'}
								alignItems={'center'}
								m={'15px 0'}
								onClick={(event) => reviewPopup(event)}
							>
								<Label variant={'caption'}>{destinationDetails.googleAllOverReview}</Label>
								<Rating
									initialValue={destinationDetails.googleAllOverReview}
									readonly={true}
									allowFraction={true}
									SVGstyle={{ width: 15, height: 15 }}
									style={{ margin: '0 5px 0 3px' }}
								/>
								<Label variant={'caption'} className={'googleReviewFormateMobile'}>
									{destinationDetails.googleTotalReview + ' Google reviews'}
								</Label>
							</Box>
						)} */}
						<Box display={'flex'} m={'15px 0'} className={'accommodationInfo'}>
							<Label variant="caption" paddingRight={8}>
								{renderMinMaxLabels(destinationDetails.minBedroom, destinationDetails.maxBedroom)} Bath
							</Label>
							<Label variant="caption" paddingX={8} className="border">
								{renderMinMaxLabels(destinationDetails.minBathroom, destinationDetails.maxBathroom)}{' '}
								Bath
							</Label>
							{renderDestinationSize()}
						</Box>
						<Box className="locationAndPricing" paddingBottom={24}>
							{renderLocations()}
							<Box className="destinationPrice">
								<Label variant={'body1B'} className={'secondaryTextColor'} display={'flex'}>
									{renderPointsOrCash()}
									<Label variant={'body1'} className={'primaryTextColor'} marginLeft={5}>
										/ night
									</Label>
								</Label>
								{!reservationFilters.redeemPoints && (
									<Label variant={'body1'} className={'primaryTextColor'}>
										+taxes and fees
									</Label>
								)}
							</Box>
						</Box>
						<Label variant={'body2'} mb={24}>
							{destinationDetails.description}
						</Label>
						<div
							title="Double click to open map in fullscreen"
							className="mapBox"
							onClick={(e: any) => {
								if (e.detail === 2) {
									document.body.classList.add('body-overall-container');
									setShowMapModal(true);
								}
							}}
						>
							<Wrapper apiKey={geoCoderApiKey} render={renderGoogleMapsRenderProp}>
								<Map
									style={{
										position: 'relative',
										overflow: 'hidden',
										width: '100%',
										height: '194px',
										borderRadius: '20px'
									}}
									isModalOpen={showMapModal}
									setShowMapModal={setShowMapModal}
									onClickPicker={() => null}
									destinationDetails={destinationDetails}
									currentLocation={{
										latitude:
											destinationDetails.latitude !== undefined ? destinationDetails.latitude : 0,
										longitude:
											destinationDetails.longitude !== undefined
												? destinationDetails.longitude
												: 0
									}}
								/>
							</Wrapper>
						</div>
						{destinationDetails?.pointsOfInterest && (
							<div className={'pointsOfInterestContainer'}>
								<Label variant={'h6'} mt={30} ml={24}>
									Points of Interest
								</Label>
								{destinationDetails?.pointsOfInterest.map((items: any, index: number) => {
									return (
										<Box
											style={{ borderBottomWidth: index === 4 ? 0 : 1 }}
											className={'interestcityStateContainer'}
										>
											<Box className={'interestContainerLocation'}>
												{index === 4 ? (
													<img className={'locationIcon'} src={airport} alt="" />
												) : (
													<img className={'locationIcon'} src={location} alt="" />
												)}
												<Label className={'locationName'} ml={10}>
													{/* {items.name} */}
													{items.name.length <= 16
														? `${items.name}`
														: `${items.name.slice(0, 16)}...`}
													<span class="tooltiptext">{items.name}</span>
												</Label>
											</Box>
											<Box>
												<Label className={'locationDistance'} mr={4}>
													{/* {items.distance} */}
													{items.distance.length <= 11
														? `${items.distance}`
														: `${items.distance.slice(0, 11)}...`}
													<span class="tooltiptext">{items.distance}</span>
												</Label>
											</Box>
										</Box>
									);
								})}
							</div>
						)}

						<hr />
						<Box className={'experienceIcons'}>{renderExperiences()}</Box>
						{!!destinationDetails && destinationDetails?.flexibleDateRate && (
							<>
								<hr />
								<FlexibleRates
									destinationId={destinationDetails.id}
									destinationDetails={destinationDetails}
									packageLength={resortPackagesCount}
									upfrontCashRequired={!!destinationDetails.upfrontCashRequired}
									isCustomResort={destinationDetails.isCustomResort}
									phone={destinationDetails?.phone}
									loyaltyStatus={destinationDetails.loyaltyStatus}
								/>
							</>
						)}
						<hr />

						<Label variant={'h6'} mb={15}>
							Choose Your Accommodation
						</Label>
						{!destinationDetails.isActive ? (
							<div id="availableStays">
								<Label variant={'h2'} color={'red'} className={'noDestinations'}>
									This destination is currently not accepting reservations from this site.
								</Label>
							</div>
						) : (
							<div className={'availableStays'}>
								{/* <FilterBarLimited
									destinationId={reservationFilters.destinationId}
									loyaltyStatus={loyaltyStatus}
									externalId={destinationDetails.externalId}
									chainId={destinationDetails.chainId}
									isMobile={true}
								/> */}
								<div className={'accommodationCardWrapper'}>
									{!isFetchingResults && availabilityStayList.length <= 0 && (
										<Label variant={'h2'}>No available options.</Label>
									)}
									{!isFetchingResults && renderAccommodations()}
									{isFetchingResults && <SpinningLoader />}
								</div>
								{!isFetchingResults && (
									<PaginationViewMore
										selectedRowsPerPage={perPage}
										total={totalResults}
										currentPageNumber={page}
										viewMore={(page) => setPage(page)}
									/>
								)}

								{
									<SimilarChoiceRecommendation
										regionId={destinationDetails?.regions[0]?.id}
										destinationId={destinationDetails?.id}
									/>
								}
							</div>
						)}
						<Footer links={FooterLinks} />
					</div>
				</>
			)}

			{/* Meta Pixel Integration. Don't delete the code */}
			<MetaPixelTag resortIdForMetaPixel={props.destinationId} />
		</Popup>
	);
};

export default DestinationDetailsMobilePopup;
