import React from 'react';
import './ItineraryCardMobile.scss';
import { Box, popupController } from '../../../lib/@bit/redsky.framework.rs.996';
import CarouselV2 from '../../carouselV2/CarouselV2';
import Label from '../../../lib/@bit/redsky.framework.rs.label/dist/Label';
import Icon from '../../../lib/@bit/redsky.framework.rs.icon';
import { useNavigate } from 'react-router-dom';
import MobileLightBox, { MobileLightBoxProps } from '../../../popups/mobileLightBox/MobileLightBox';
import Media = Api.Media;
import { ObjectUtils, StringUtils } from '../../../utils/utils';
import { get } from 'lodash';
import { getPageFinder, undefinedHandler } from '../../../utils/undefinedHandler';

interface ReservationCardMobileProps {
	logo: string;
	title: string;
	city: string;
	state: string;
	country: string;
	reservationDates: { startDate: string | Date; endDate: string | Date };
	propertyType: string;
	itineraryId: string;
	maxOccupancy: number;
	amenities: string[];
	totalPoints: number;
	linkPath: string;
	cancelPermitted: 0 | 1;
	itineraryTotal: number;
	paidWithPoints: boolean;
	media: Media[];
	currencyCode: string;
}

const ItineraryCardMobile: React.FC<ReservationCardMobileProps> = (props) => {
	let navigate = useNavigate();
	function getImagePaths(imageGallery: Media[]) {
		try {
			if (!ObjectUtils.isArrayWithData(imageGallery) || !imageGallery[0].id) return [];
			let images = imageGallery;
			images.sort((a, b) => {
				return b.isPrimary - a.isPrimary;
			});
			return images.map((urlObj) => {
				return urlObj.urls.large.toString();
			});
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
			return [];
		}
	}

	return (
		<Box className={'rsItineraryCardMobile'}>
			<CarouselV2
				path={props.linkPath}
				resortPoint={0}
				imgPaths={getImagePaths(props.media)}
				onGalleryClick={() => {
					popupController.open<MobileLightBoxProps>(MobileLightBox, {
						imageData: props.media.sort((a: any, b: any) => a.mediaIndex - b.mediaIndex)
					});
				}}
				imageWidth={327}
				hideCompareButton
			/>
			<Box className="titleAndButton">
				<Label variant={'h6'}>{props.title}</Label>
				<Icon
					iconImg="icon-info-outline"
					color={'#000'}
					size={20}
					className="locationIcon"
					onClick={() => {
						navigate(`${props.linkPath}`);
					}}
				/>
			</Box>
			<Box className={'bottomRow'}>
				{!props.paidWithPoints ? (
					<Box display="flex" alignItems={'center'}>
						<Label variant={'body1B'} marginRight={5}>
							{StringUtils.formatCurrency(props.currencyCode) +
								StringUtils.formatMoney(props.itineraryTotal)}
						</Label>
						<Label variant={'caption'}>Trip Total</Label>
					</Box>
				) : (
					<Box display="flex" alignItems={'center'}>
						<Label variant={'bold1B'} marginRight={5}>
							{StringUtils.addCommasToNumber(props.totalPoints)}
						</Label>
						<Label variant={'caption'}>Points Paid</Label>
					</Box>
				)}
				<Label variant={'caption'} className={'locationLabel'}>
					<Icon iconImg="icon-pin" color={'#D2555F'} size={17} className="locationIcon" />
					{get(props, 'city')}, {get(props, 'state') || get(props, 'country')}
				</Label>
			</Box>
		</Box>
	);
};

export default ItineraryCardMobile;
