Object.defineProperty(exports, '__esModule', { value: true });
exports.RsValidator = exports.RsValidatorEnum = void 0;
var RsValidatorEnum;
(function (RsValidatorEnum) {
	RsValidatorEnum['REQ'] = 'required';
	RsValidatorEnum['MIN'] = 'minLength';
	RsValidatorEnum['MAX'] = 'maxLength';
	RsValidatorEnum['EMAIL'] = 'email';
	RsValidatorEnum['NUM'] = 'numeric';
	RsValidatorEnum['REG'] = 'regexp';
	RsValidatorEnum['CUSTOM'] = 'custom';
})((RsValidatorEnum = exports.RsValidatorEnum || (exports.RsValidatorEnum = {})));
var RsValidator = (function () {
	function RsValidator(_validator, _errorMessage, _value) {
		this._validator = _validator;
		this._errorMessage = _errorMessage;
		this._value = _value;
	}
	Object.defineProperty(RsValidator.prototype, 'validator', {
		get: function () {
			return this._validator;
		},
		enumerable: false,
		configurable: true
	});
	Object.defineProperty(RsValidator.prototype, 'errorMessage', {
		get: function () {
			if (this._errorMessage.indexOf('$val') > -1) return this._errorMessage.replace(/\$val/g, this._value);
			else return this._errorMessage;
		},
		enumerable: false,
		configurable: true
	});
	Object.defineProperty(RsValidator.prototype, 'value', {
		get: function () {
			return this._value;
		},
		enumerable: false,
		configurable: true
	});
	return RsValidator;
})();
exports.RsValidator = RsValidator;
