var __createBinding =
	(this && this.__createBinding) ||
	(Object.create
		? function (o, m, k, k2) {
				if (k2 === undefined) k2 = k;
				Object.defineProperty(o, k2, {
					enumerable: true,
					get: function () {
						return m[k];
					}
				});
		  }
		: function (o, m, k, k2) {
				if (k2 === undefined) k2 = k;
				o[k2] = m[k];
		  });
var __setModuleDefault =
	(this && this.__setModuleDefault) ||
	(Object.create
		? function (o, v) {
				Object.defineProperty(o, 'default', { enumerable: true, value: v });
		  }
		: function (o, v) {
				o['default'] = v;
		  });
var __importStar =
	(this && this.__importStar) ||
	function (mod) {
		if (mod && mod.__esModule) return mod;
		var result = {};
		if (mod != null)
			for (var k in mod)
				if (k !== 'default' && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
		__setModuleDefault(result, mod);
		return result;
	};
var __rest =
	(this && this.__rest) ||
	function (s, e) {
		var t = {};
		for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
		if (s != null && typeof Object.getOwnPropertySymbols === 'function')
			for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
				if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
			}
		return t;
	};
Object.defineProperty(exports, '__esModule', { value: true });
require('./Label.scss');
var react_1 = __importStar(require('react'));
var spacing_1 = require('./spacing');
function transformProps(props) {
	var filtered = {};
	var i;
	for (i in props) {
		if (i === 'm') {
			filtered['margin'] = props[i];
		} else if (i === 'p') {
			filtered['padding'] = props[i];
		} else if (spacing_1.spacingKeys.includes(i)) {
			var stylePropNames = spacing_1.getSpacingProperties(i);
			for (var _i = 0, stylePropNames_1 = stylePropNames; _i < stylePropNames_1.length; _i++) {
				var propName = stylePropNames_1[_i];
				filtered[propName] = props[i];
			}
		} else if (i === 'bgcolor') {
			filtered['backgroundColor'] = props[i];
		} else {
			filtered[i] = props[i];
		}
	}
	return filtered;
}
var Label = function (props) {
	var labelRef = react_1.useRef(null);
	var className = props.className,
		onClick = props.onClick,
		variant = props.variant,
		id = props.id,
		other = __rest(props, ['className', 'onClick', 'variant', 'id']);
	var _a = react_1.useState(false),
		showSeeMoreButton = _a[0],
		setShowSeeMoreButton = _a[1];
	var _b = react_1.useState(false),
		isShowingFullText = _b[0],
		setIsShowingFullText = _b[1];
	react_1.useEffect(
		function () {
			var label = labelRef.current;
			if (!label || !props.lineClamp || !props.showMoreButton) {
				setShowSeeMoreButton(false);
				return;
			}
			setShowSeeMoreButton(true);
		},
		[props.lineClamp, props.showMoreButton]
	);
	var output = transformProps(other);
	var adjustedClassName = className ? 'rsLabel ' + className : 'rsLabel';
	adjustedClassName = variant ? adjustedClassName + ' ' + variant : adjustedClassName;
	if (output.hasOwnProperty('lineClamp') && !isShowingFullText) {
		output['WebkitLineClamp'] = output['lineClamp'];
		output['WebkitBoxOrient'] = output['boxOrient'] || 'vertical';
		output['textOverflow'] = 'ellipsis';
		output['display'] = '-webkit-box';
		output['overflow'] = 'hidden';
	}
	function renderText() {
		var showMore = props.showMoreText || 'Show More';
		var showLess = props.showLessText || 'Show Less';
		if (isShowingFullText) return showLess;
		else return showMore;
	}
	return showSeeMoreButton
		? react_1.default.createElement(
				react_1.default.Fragment,
				null,
				react_1.default.createElement(
					'div',
					{ id: id, onClick: props.onClick, style: output, className: adjustedClassName, ref: labelRef },
					props.children
				),
				react_1.default.createElement(
					'div',
					{
						className: 'rsLabelShowMoreButton',
						onClick: function () {
							setIsShowingFullText(!isShowingFullText);
							if (props.onShowMoreTextClick) props.onShowMoreTextClick();
						}
					},
					renderText()
				)
		  )
		: react_1.default.createElement(
				'div',
				{ id: id, onClick: props.onClick, style: output, className: adjustedClassName, ref: labelRef },
				props.children
		  );
};
exports.default = Label;
