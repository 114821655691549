import React from 'react';
import './Counter.scss';
import { Box } from '../../lib/@bit/redsky.framework.rs.996';
import Icon from '../../lib/@bit/redsky.framework.rs.icon';
import Label from '../../lib/@bit/redsky.framework.rs.label/dist/Label';
import { RsFormControl } from '../../lib/@bit/redsky.framework.rs.form';
import classNames from 'classnames';
import LabelInput from '../labelInput/LabelInput';

export interface GuestCounterProps {
	minCount?: number;
	maxCount?: number;
	title: string;
	control: RsFormControl;
	updateControl: (control: RsFormControl) => void;
	labelMarginRight: number;
	className?: string;
	labelVariant?: string;
}

const Counter: React.FC<GuestCounterProps> = (props) => {
	function isAtMinValue(): boolean {
		if (props.minCount === undefined) return false;
		return (props.control.value as number) <= props.minCount;
	}

	function isAtMaxValue(): boolean {
		if (props.maxCount === undefined) return false;
		return (props.control.value as number) >= props.maxCount;
	}

	const onDecrease = () => {
		let newValue: number = (props.control.value as number) - 1;
		if (props.minCount !== undefined && newValue < props.minCount) return;
		let tempControl = props.control;
		tempControl.value = newValue;
		props.updateControl(tempControl);
	};

	const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		let newValue: number = parseInt(e.target.value);
		if (!isNaN(newValue)) {
			if (props.minCount !== undefined && newValue < props.minCount) return;
			if (props.maxCount !== undefined && newValue > props.maxCount) return;
			let tempControl = props.control;
			tempControl.value = newValue;
			props.updateControl(tempControl);
		}
	};

	const onIncrease = () => {
		let newValue: number = (props.control.value as number) + 1;
		if (props.maxCount !== undefined && newValue > props.maxCount) return;
		let tempControl = props.control;
		tempControl.value = newValue;
		props.updateControl(tempControl);
	};
	return (
		<Box className={`rsCounter${props.className ? ` ${props.className}` : ''}`}>
			<Label variant={`${props.labelVariant || 'h6'}`} mr={props.labelMarginRight}>
				{props.title}
			</Label>
			<Box className={'valueChanger'}>
				<Icon iconImg={'icon-minus'} className={classNames({ disable: isAtMinValue() })} onClick={onDecrease} />
				{/* <Label variant={'body1'}>{props.control.value as number}</Label> */}
				<input
					type={'text'}
					onChange={onChange}
					value={props.control.value as number}
					className={'inputCounter'}
				/>
				<Icon iconImg={'icon-plus'} className={classNames({ disable: isAtMaxValue() })} onClick={onIncrease} />
			</Box>
		</Box>
	);
};

export default Counter;
