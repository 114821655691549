// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rsLabelShowMoreButton{margin-top:5px;width:fit-content;width:-moz-fit-content}.rsLabelShowMoreButton:hover{cursor:pointer}
`, "",{"version":3,"sources":["webpack://./src/lib/@bit/redsky.framework.rs.label/dist/Label.scss"],"names":[],"mappings":"AAGA,uBACC,cAAe,CACf,iBAAkB,CAClB,sBAAuB,CAHxB,6BAKE,cAAe","sourcesContent":[".rsLabel {\n}\n\n.rsLabelShowMoreButton {\n\tmargin-top: 5px;\n\twidth: fit-content;\n\twidth: -moz-fit-content;\n\t&:hover {\n\t\tcursor: pointer;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
