import * as React from 'react';
import './accommodationsPopup.scss';
import { Box, Popup, popupController, PopupProps } from '../../lib/@bit/redsky.framework.rs.996';
import Paper from '../../components/paper/Paper';
import Icon from '../../lib/@bit/redsky.framework.rs.icon';
import Label from '../../lib/@bit/redsky.framework.rs.label';
import AccommodationSearchCard from '../../components/accommodationSearchCardV2/AccommodationSearchCard';
import { useEffect, useState } from 'react';
import useIsAtBreakpoint from '../../customHooks/useIsAtBreakpoint';
import { ObjectUtils, WebUtils } from '../../utils/utils';
import serviceFactory from '../../services/serviceFactory';
import AccommodationService from '../../services/accommodation/accommodation.service';
import RsPagedResponseData = RedSky.RsPagedResponseData;
import PackageService from '../../services/package/package.service';
import { useRecoilValue } from 'recoil';
import globalState from '../../state/globalState';
import Loader from '../../components/loader/Loader';
import AccommodationOverviewPopup, {
	AccommodationOverviewPopupProps
} from '../accommodationOverviewPopup/AccommodationOverviewPopup';
import PaginationViewMore from '../../components/paginationViewMore/PaginationViewMore';
import SpinningLoaderPopup, { SpinningLoaderPopupProps } from '../spinningLoaderPopup/SpinningLoaderPopup';
import DestinationService from '../../services/destination/destination.service';
import { rsToastify } from '../../lib/@bit/redsky.framework.rs.toastify';

export interface AccommodationsPopupProps extends PopupProps {
	pointPerDollar?: number;
	propertyTypeName: string;
	destinationObj?: any;
	packageCount: number | undefined;
	destinationId: number;
	destinationName: string;
	loyaltyStatus: Model.LoyaltyStatus;
	propertyTypeId: number;
	upfrontCashRequired: boolean;
	isCustomResort?: boolean;
	phone?: number;
}

const AccommodationsPopup: React.FC<AccommodationsPopupProps> = (props) => {
	const packageService = serviceFactory.get<PackageService>('PackageService');
	const destinationService = serviceFactory.get<DestinationService>('DestinationService');
	const accommodationService = serviceFactory.get<AccommodationService>('AccommodationService');
	const reservationFilters = useRecoilValue<Misc.ReservationFilters>(globalState.reservationFilters);
	const [resortPackagesCount, setResortPackagesCount] = useState<number | any>(0);
	const [destinationDetails, setDestinationDetails] = React.useState<Api.Destination.Res.Details>();
	const fullProps = popupController.convertProps(props);
	const isMobile = useIsAtBreakpoint();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [accommodations, setAccommodations] = useState<Api.Accommodation.Res.Availability[]>([]);
	const [page, setPage] = useState<number>(1);
	const [totalResults, setTotalResults] = useState<number>(0);
	const perPage = 5;

	useEffect(() => {
		window.history.pushState(null, 'AccommodationsPopup Close', window.location.pathname);
		window.addEventListener('popstate', (e: PopStateEvent) => {
			e.preventDefault();
			popupController.close(AccommodationsPopup);
		});
		getDestinationDetails(props.destinationId);
		return () => {
			window.removeEventListener('popstate', (e: PopStateEvent) => {
				e.preventDefault();
				popupController.close(AccommodationsPopup);
			});
		};
	}, []);

	useEffect(() => {
		getAvailable(page).catch(console.error);
	}, [page]);

	async function getDestinationDetails(id: number) {
		try {
			/* uncooment this code if you wwant to get getDestinationDetails */
			// const dest = await destinationService.getDestinationDetails({
			// 	destinationId: id,
			// 	startDate: reservationFilters.startDate,
			// 	endDate: reservationFilters.endDate
			// });

			// setDestinationDetails(dest);
			const request: Api.UpsellPackage.Req.Availability = {
				destinationId: id,
				startDate: reservationFilters.startDate,
				endDate: reservationFilters.startDate
			};
			//api to get total amount if packages
			const responsePackages = await packageService.getAvailable(request);
			setResortPackagesCount(responsePackages.total);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Cannot get details for this destination.'), 'Server Error');
		}
	}

	async function getAvailable(searchPage: number) {
		setIsLoading(true);
		try {
			const results: RsPagedResponseData<Api.Accommodation.Res.MinMaxAvailability> =
				await accommodationService.availability(props.destinationId, {
					...reservationFilters,
					propertyTypeIds: [props.propertyTypeId],
					pagination: { page: searchPage, perPage }
				});

			if (searchPage === 1) setAccommodations(results.data);
			else
				setAccommodations((prev) => {
					return [...prev, ...results.data];
				});
			setTotalResults(results.total || 0);
		} catch (e) {
			console.log(e);
		}
		setIsLoading(false);
	}

	async function handleOnInfoClick(accommodationId: number) {
		popupController.open<SpinningLoaderPopupProps>(SpinningLoaderPopup, {});
		try {
			const accommodationStay = accommodations.find((stay) => stay.id === accommodationId);
			popupController.close(SpinningLoaderPopup);
			if (!accommodationStay) return;
			popupController.open<AccommodationOverviewPopupProps>(AccommodationOverviewPopup, {
				pointPerDollar: props.pointPerDollar,
				destinationName: props.destinationName,
				packageCount: resortPackagesCount,
				accommodationStay,
				destinationDetails: destinationDetails,
				loyaltyStatus: props.loyaltyStatus,
				pointsEarnable: accommodationStay.pointsEarnable,
				resortPointsPrice: accommodationStay.minPricePoints
			});
		} catch (e) {
			console.log(e);
			rsToastify.error('We were unable to load your accommodation.', 'Sorry!');
		}
	}

	return (
		<Popup {...props}>
			<Paper className={'rsAccommodationsPopup'}>
				<Box className={'titleContainer'}>
					<Label variant={isMobile ? 'h5' : 'h3'} className={'primaryTextColor'}>
						{isMobile ? props.propertyTypeName : `${props.destinationName} - ${props.propertyTypeName}`}
					</Label>
					<Icon
						className={'closeIcon'}
						iconImg={'icon-close'}
						size={isMobile ? 16 : 32}
						onClick={() => {
							popupController.closeById(fullProps.popupId);
						}}
					/>
				</Box>
				<Box className={'accommodationCards'}>
					{accommodations.map((accommodation) => {
						const promotedRate = accommodation?.prices?.filter(
							(item) => item?.rate?.code === props.destinationObj?.promotedRate
						);

						const nonPromotedRate = accommodation?.prices?.filter(
							(item) => item?.rate?.code !== props.destinationObj?.promotedRate
						);
						return (
							<AccommodationSearchCard
								pointPerDollar={props.pointPerDollar}
								key={accommodation.id}
								promotedRate={
									promotedRate && promotedRate.length === 0 ? nonPromotedRate[0] : promotedRate[0]
								}
								nonPromotedRate={
									promotedRate && promotedRate.length === 0
										? nonPromotedRate.slice(1, nonPromotedRate.length)
										: nonPromotedRate
								}
								destinationDetails={props.destinationObj}
								accommodation={accommodation}
								resortPoint={accommodation.pointsEarnable}
								resortPointsPrice={accommodation.minPricePoints}
								destinationId={props.destinationId}
								packageLength={resortPackagesCount}
								onClickInfoIcon={handleOnInfoClick}
								showInfoIcon
								loyaltyStatus={props.loyaltyStatus}
								upfrontCashRequired={props.upfrontCashRequired}
								isCustomResort={props.isCustomResort}
								phone={props.phone}
							/>
						);
					})}
					{isLoading && (
						<>
							<Box height={10} />
							<Loader />
							<Box height={10} />
						</>
					)}
					{page * perPage < totalResults && (
						<PaginationViewMore
							selectedRowsPerPage={perPage}
							total={totalResults}
							currentPageNumber={page}
							viewMore={(page) => setPage(page)}
							text={
								<Label variant="button" className="loadMoreButton">
									Load More
								</Label>
							}
						/>
					)}
				</Box>
			</Paper>
		</Popup>
	);
};

export default AccommodationsPopup;
