import * as React from 'react';
import './PaymentMethod.scss';
import Label from '../../lib/@bit/redsky.framework.rs.label';
import { Box } from '../../lib/@bit/redsky.framework.rs.996';
import Icon from '../../lib/@bit/redsky.framework.rs.icon';
import { StringUtils } from '../../utils/utils';
import { useRecoilState, useRecoilValue } from 'recoil';
import globalState from '../../state/globalState';
import { getPageFinder, undefinedHandler } from '../../utils/undefinedHandler';

interface PaymentMethodProps {
	userCheckout: any;
	userPrimaryPaymentMethod: Api.User.PaymentMethod | undefined;
	onEditClickCallback?: () => void;
	radioStepId?: number;
	isAffirmCheckout?: any;
}

const PaymentMethod: React.FC<PaymentMethodProps> = (props) => {
	const reservationFilters = useRecoilValue<Misc.ReservationFilters>(globalState.reservationFilters);
	const [checkoutUser, setCheckoutUser] = useRecoilState<Misc.Checkout | undefined>(globalState.checkoutUser);
	const affirm_base64 = '../../images/creditCardLogos/affirm_logo.svg';

	function getPaymentLogo(cardType: string) {
		try {
			let logo = '';
			switch (cardType.toLowerCase()) {
				case 'visa':
					logo = '../../images/creditCardLogos/Visa.svg';
					break;
				case 'master':
					logo = '../../images/creditCardLogos/MasterCard.svg';
					break;
				case 'discover':
					logo = '../../images/creditCardLogos/Discover.svg';
					break;
				case 'american_express':
					logo = '../../images/creditCardLogos/AmEx.svg';
					break;
			}
			if (!logo) return StringUtils.capitalizeFirst(cardType);
			else return <img src={logo} alt={'credit Card Logo'} width={104} height={64} />;
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	function renderPaymentType() {
		try {
			if (reservationFilters.redeemPoints) return 'Points';
			if (props.userCheckout.pmData) return getPaymentLogo(props.userCheckout.pmData.card_type);
			else if (props.userPrimaryPaymentMethod?.type)
				return StringUtils.capitalizeFirst(props.userPrimaryPaymentMethod.type);
			return 'None';
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}
	function renderCardType() {
		try {
			if (!props.userCheckout.pmData) return;
			if (!props.userCheckout.pmData.card_type.includes('_'))
				return StringUtils.capitalizeFirst(props.userCheckout.pmData.card_type);
			let cardType = props.userCheckout.pmData.card_type;
			let tempCardType = cardType.split('_');
			return tempCardType.map((word: string) => StringUtils.capitalizeFirst(word)).join(' ');
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	return (
		<div className={'rsPaymentMethod'}>
			<Label variant={'buttonMdLg'} className={'sectionTitle'} mb={40}>
				Payment Method
			</Label>
			{props?.isAffirmCheckout?.isAffirmCheckout && props.radioStepId == 2 ? (
				<Box display={'flex'} alignItems={'center'}>
					<div className={'creditCardImageaffirm'}>
						<img src={affirm_base64} alt={'credit Card Logo'} width={120} height={84} />
					</div>
				</Box>
			) : (
				<>
					<Box display={'flex'} alignItems={'center'}>
						<div className={'creditCardImage'}>{renderPaymentType()}</div>
						{!reservationFilters.redeemPoints && props.userCheckout.pmData && (
							<div className={'paymentInfoWrapper'}>
								{props.userCheckout.pmData.first_name && props.userCheckout.pmData.last_name && (
									<Label variant={'body2'} className={'fullName'}>
										{props.userCheckout.pmData.first_name} {props.userCheckout.pmData.last_name}
									</Label>
								)}
								<div className={'cardInfoWrapper'}>
									<Label variant={'body2'}>{renderCardType()} ending in</Label>
									<Label variant={'body2'} className={'lastFourDigits'}>
										{props.userCheckout.pmData.last_four_digits}
									</Label>
								</div>
							</div>
						)}
					</Box>
					{!!props.onEditClickCallback && !reservationFilters.redeemPoints && (
						<Icon
							className={'editIcon'}
							iconImg={'icon-edit'}
							size={32}
							color={'black'}
							onClick={props.onEditClickCallback}
						/>
					)}
				</>
			)}
		</div>
	);
};

export default PaymentMethod;
