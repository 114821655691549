import React from 'react';
import './CarouselV2.scss';
import { useEffect, useRef, useState } from 'react';
import Button from '../../lib/@bit/redsky.framework.rs.button';
import Icon from '../../lib/@bit/redsky.framework.rs.icon';
import { useNavigate } from 'react-router-dom';
import Label from '../../lib/@bit/redsky.framework.rs.label';
import { useRecoilState, useRecoilValue } from 'recoil';
import globalState from '../../state/globalState';
import { ObjectUtils } from '../../utils/utils';
import themes from '../../themes/themes.scss';
import { IKImage } from 'imagekitio-react';
import serviceFactory from '../../services/serviceFactory';
import UserService from '../../services/user/user.service';
import { rsToastify } from '../../lib/@bit/redsky.framework.rs.toastify';
import { getPageFinder, undefinedHandler } from '../../utils/undefinedHandler';

interface CarouselV2Props {
	path: string | (() => void);
	imgPaths: string[];
	onAddCompareClick?: () => void;
	onRemoveCompareClick?: () => void;
	onGalleryClick?: () => void;
	destinationId?: number;
	hideCompareButton?: boolean;
	imageWidth?: number;
	className?: string;
	resortPoint?: number;
	destinationObj?: Api.Destination.Res.AvailabilitySummary;
	FavoriteDestinationsIcon?: string;
	isAccommodations?: number;
}

const CarouselV2: React.FC<CarouselV2Props> = (props) => {
	let navigate = useNavigate();
	const comparisonState = useRecoilValue<Misc.ComparisonState>(globalState.destinationComparison);
	const parentRef = useRef<HTMLDivElement>(null);
	const totalChildren = props.imgPaths.length;
	const [imageViewIndex, setImageViewIndex] = useState<number>(1);
	const userService = serviceFactory.get<UserService>('UserService');
	const [user, setUser] = useRecoilState<Api.User.Res.Detail | undefined>(globalState.user);
	const [destinationSelection, setDestinationSelection] = useRecoilState<any>(globalState.destinationSelection);

	useEffect(() => {
		setTimeout(() => {
			if (!parentRef.current) return;
			parentRef.current!.scrollTo({ top: 0, left: 0 });
		}, 50);
	}, []);
	function renderImages() {
		try {
			return props.imgPaths.map((item, index) => {
				return (
					<IKImage
						key={index}
						src={item}
						alt={'img alt'}
						width={props.imageWidth ? props.imageWidth : 1920}
						height={'auto'}
						// loading={index !== 0 ? 'lazy' : 'eager'}
						loading="lazy"
						// rootmargin={'0px 10% 0px 0px'}
						// root={parentRef.current}
					/>
				);
			});
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	return (
		<div className={`rsCarouselV2 ${props.className || ''}`}>
			{props.resortPoint == 0 ? (
				''
			) : (
				<></>
				/*  PointLabeltextTag (don't delete)
					<PointsLabelBox pointSystem={props.resortPoint} />
				*/
			)}

			<div
				ref={parentRef}
				className={'imageCarouselContainer'}
				onClick={() => {
					if (typeof props.path === 'string') navigate(props.path);
					else props.path();
				}}
			>
				{renderImages()}
			</div>
			{user && !props.hideCompareButton && props.FavoriteDestinationsIcon && (
				<Button
					className={'addToFavoriteButton'}
					look={'none'}
					disableRipple
					onClick={(event) => {
						// event.stopPropagation();
						// if (props && props.destinationObj) {
						// 	travelPreferencesHandler('FavouriteDestinations', props.destinationObj?.name);
						// }
						// if (
						// 	props.destinationId !== undefined &&
						// 	ObjectUtils.isArrayWithData(comparisonState.destinationDetails) &&
						// 	comparisonState.destinationDetails
						// 		.map((details) => details.destinationId)
						// 		.includes(props.destinationId)
						// ) {
						// 	if (props.onRemoveCompareClick) props.onRemoveCompareClick();
						// } else {
						// 	if (props.onAddCompareClick) props.onAddCompareClick();
						// }
					}}
				>
					<img
						onClick={async () => {
							if (!destinationSelection.includes(props.destinationId)) {
								setDestinationSelection((DestinationSelectionList: any) => [
									...DestinationSelectionList,
									props.destinationId
								]);

								try {
									await userService.saveMyFavoriteDestinations(
										JSON.stringify([...destinationSelection, props.destinationId]),
										user?.id
									);
								} catch (e: any) {
									const message = `Getting error :${e.message} on ${getPageFinder(
										window.location.pathname
									)} page.`;
									undefinedHandler(message);
								}
							} else {
								setDestinationSelection(
									destinationSelection.filter((item: any) => item !== props.destinationId)
								);

								try {
									await userService.saveMyFavoriteDestinations(
										JSON.stringify(
											destinationSelection.filter((item: any) => item !== props.destinationId)
										),
										user?.id
									);
								} catch (e: any) {
									const message = `Getting error :${e.message} on ${getPageFinder(
										window.location.pathname
									)} page.`;
									undefinedHandler(message);
								}
							}
						}}
						src={props.FavoriteDestinationsIcon}
						alt="Add Card"
					/>
					<div className={'favoriteToolTip'}>
						<div className={'toolTipTriangle'} />
						<Label className={'caption'}>
							click to favorite, a list of favorites can be found in your Account
						</Label>
					</div>
				</Button>
			)}

			<Button
				className={'clickLeft'}
				look={'none'}
				onClick={(event) => {
					try {
						event.stopPropagation();
						let val = parentRef.current!.scrollLeft - parentRef.current!.offsetWidth;
						setImageViewIndex(imageViewIndex - 1);
						if (imageViewIndex <= 1) {
							val = parentRef.current!.offsetWidth * totalChildren;
							setImageViewIndex(totalChildren);
						}
						let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
						parentRef.current!.scrollTo({ top: 0, left: val, behavior: 'smooth' });
						if (isSafari) parentRef.current!.scrollLeft = val;
					} catch (error: any) {
						const message = `Getting error :${error.message} on ${getPageFinder(
							window.location.pathname
						)} page.`;
						undefinedHandler(message);
					}
				}}
			>
				<Icon iconImg={'icon-chevron-left'} color={'#001933'} size={8} />
			</Button>
			<Button
				className={'clickRight'}
				look={'none'}
				onClick={(event) => {
					try {
						event.stopPropagation();
						let val = parentRef.current!.offsetWidth + parentRef.current!.scrollLeft;
						setImageViewIndex(imageViewIndex + 1);
						if (imageViewIndex >= totalChildren) {
							val = 0;
							setImageViewIndex(1);
						}
						let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
						parentRef.current!.scrollTo({ top: 0, left: val, behavior: 'smooth' });
						if (isSafari) parentRef.current!.scrollLeft = val;
					} catch (error: any) {
						const message = `Getting error :${error.message} on ${getPageFinder(
							window.location.pathname
						)} page.`;
						undefinedHandler(message);
					}
				}}
			>
				<Icon iconImg={'icon-chevron-right'} color={'#001933'} size={8} />
			</Button>
			{!props.hideCompareButton && props.isAccommodations !== 0 && (
				<Button
					className={'addToCompareButton'}
					look={'none'}
					disableRipple
					onClick={(event) => {
						try {
							event.stopPropagation();
							if (
								props.destinationId !== undefined &&
								ObjectUtils.isArrayWithData(comparisonState.destinationDetails) &&
								comparisonState.destinationDetails
									.map((details) => details.destinationId)
									.includes(props.destinationId)
							) {
								if (props.onRemoveCompareClick) props.onRemoveCompareClick();
							} else {
								if (props.onAddCompareClick) props.onAddCompareClick();
							}
						} catch (error: any) {
							const message = `Getting error :${error.message} on ${getPageFinder(
								window.location.pathname
							)} page.`;
							undefinedHandler(message);
						}
					}}
				>
					<Icon
						iconImg={
							props.destinationId !== undefined &&
							ObjectUtils.isArrayWithData(comparisonState.destinationDetails) &&
							comparisonState.destinationDetails
								.map((details) => details.destinationId)
								.includes(props.destinationId)
								? 'icon-solid-check-circle'
								: 'icon-plus'
						}
						color={
							props.destinationId !== undefined &&
							ObjectUtils.isArrayWithData(comparisonState.destinationDetails) &&
							comparisonState.destinationDetails
								.map((details) => details.destinationId)
								.includes(props.destinationId)
								? themes.successGreen
								: themes.secondaryTextColor
						}
						size={
							props.destinationId !== undefined &&
							ObjectUtils.isArrayWithData(comparisonState.destinationDetails) &&
							comparisonState.destinationDetails
								.map((details) => details.destinationId)
								.includes(props.destinationId)
								? 20
								: 12
						}
					/>
					<span className="compareText">Compare</span>
				</Button>
			)}
			<Button
				look={'none'}
				className={'imageCountContainer'}
				onClick={(event) => {
					event.stopPropagation();
					if (props.onGalleryClick) props.onGalleryClick();
				}}
			>
				<Icon iconImg={'icon-gallery'} color={themes.white} size={18} />
				<Label variant={'subtitle1'}>{`${imageViewIndex} / ${totalChildren}`}</Label>
			</Button>
		</div>
	);
};

export default CarouselV2;
