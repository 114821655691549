import React from 'react';
import './IconLabel.scss';
import Label from '../../lib/@bit/redsky.framework.rs.label/dist/Label';
import { Box } from '../../lib/@bit/redsky.framework.rs.996';
import MultiColoredIcons from '../multiColoredIcons/MultiColoredIcons';
import { ObjectUtils } from '../../lib/@bit/redsky.framework.rs.utils';

interface IconLabelProps {
	labelName: string;
	iconImg: string;
	iconPosition: 'top' | 'right' | 'bottom' | 'left';
	iconSize: number;
	labelVariant?:
		| 'h1'
		| 'h2'
		| 'h3'
		| 'h4'
		| 'h5'
		| 'h6'
		| 'sectionHeader'
		| 'title'
		| 'subtitle1'
		| 'subtitle2'
		| 'body1'
		| 'body2'
		| 'caption'
		| 'button'
		| 'overline'
		| 'srOnly'
		| 'inherit'
		| 'error'
		| string;
	className?: string;
	color?: string;
	onClick?: () => void;
	disableClampSize?: boolean;
}

const IconLabel: React.FC<IconLabelProps> = (props) => {
	return (
		<Box className={`rsIconLabel ${props.className || ''} ${props.iconPosition}`} onClick={props.onClick}>
			<MultiColoredIcons iconName={props.iconImg} iconSize={props.iconSize} color={props.color} />
			<Label
				variant={props.labelVariant}
				className={'label'}
				color={props.color}
				lineClamp={props.disableClampSize ? undefined : 1}
			>
				{props.labelName}
			</Label>
		</Box>
	);
};

export default IconLabel;
