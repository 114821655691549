import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import './AppBar.scss';
import { Box, popupController } from '../../lib/@bit/redsky.framework.rs.996';
import Icon from '../../lib/@bit/redsky.framework.rs.icon';
import NavDrawer from '../../popups/navDrawer/NavDrawer';
import useWindowScrollChange from '../../customHooks/useWindowScrollChange';
import { useRecoilValue } from 'recoil';
import globalState from '../../state/globalState';
import router from '../../utils/router';
import useIsAtBreakpoint from '../../customHooks/useIsAtBreakpoint';
import SigninPopup from '../../popups/signin/SigninPopup';
import PointsLabelText from '../pointsLabelBox/pointsLabelText/PointsLabelText';
import classNames from 'classnames';
import Label from '../../lib/@bit/redsky.framework.rs.label';

const AppBar: React.FC = () => {
	let navigate = useNavigate();
	const isMobile = useIsAtBreakpoint();
	const location = useLocation();
	const appBarRef = useRef<HTMLElement>(null);
	const company = useRecoilValue<Api.Company.Res.GetCompanyAndClientVariables>(globalState.company);
	const user = useRecoilValue<Api.User.Res.Detail | undefined>(globalState.user);
	const [showSlideOutMenu, setShowSlideOutMenu] = useState<boolean>(false);
	const [addWhiteBackground, setAddWhiteBackground] = useState<boolean>(false);
	const [showWhiteBackgroundOnScroll, setShowWhiteBackgroundOnScroll] = useState<boolean>(false);
	let scrollDirection = useWindowScrollChange();
	const addWhiteBackgroundPagesUrl: string[] = [
		'/account',
		'/destination/',
		'/reservations',
		'/reservation/details',
		'/booking/checkout'
	];

	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const resortId: any = urlParams.get('data');

	useEffect(() => {
		let id = router.subscribeToAfterRouterNavigate(() => {
			let url = window.location.href;
			for (let i in addWhiteBackgroundPagesUrl) {
				if (url.includes(addWhiteBackgroundPagesUrl[i])) {
					setAddWhiteBackground(true);
					break;
				} else {
					setAddWhiteBackground(false);
				}
			}
		});

		return () => {
			router.unsubscribeFromAfterRouterNavigate(id);
		};
	}, []);

	useEffect(() => {
		window.addEventListener('scroll', whiteBackgroundControl);
		return () => {
			window.removeEventListener('scroll', whiteBackgroundControl);
		};
	});

	function whiteBackgroundControl() {
		if (window.scrollY > 20) {
			setShowWhiteBackgroundOnScroll(true);
		} else {
			setShowWhiteBackgroundOnScroll(false);
		}
	}

	const logoHandler = (brandType?: any) => {
		let companyid;
		if (brandType) {
			companyid = JSON.parse(brandType).themeId;
		} else {
			companyid = null;
		}

		let logo = '';
		switch (companyid) {
			case 3:
				logo = '../../images/companylogo/encorereunion.png';
				break;
			case 78:
				logo = '../../images/companylogo/cottagesbyrentyl.png';
				break;
			case 19:
				logo = '../../images/companylogo/cottagesbyrentyl.png';
				break;
			case 9:
				logo = '../../images/companylogo/bearsdenorlando.png';
				break;
			case 10:
				logo = '../../images/companylogo/resortsbyspectrum.png';
				break;
			default:
				logo = company.wideLogoUrl;
				break;
		}
		return <img src={logo} alt={company.name} width={'166px'} className={'logo'} />;
	};

	return (
		<div
			ref={appBarRef}
			className={classNames('rsAppBar', {
				hide: scrollDirection === 'DOWN',
				white: addWhiteBackground,
				whiteBackground: showWhiteBackgroundOnScroll
			})}
		>
			<Link to={'/'} className={'logoContainer'}>
				{logoHandler(resortId)}
				{/* <img src={} alt={company.name} width={'166px'} className={'logo'} /> */}
			</Link>
			{
				user && <PointsLabelText userPoinData={user.availablePoints} /> //display user point when logged in
			}

			{location.pathname == '/booking/checkout' && !isMobile && <img src={`../../images/Secure-Checkout.svg`} />}
			<Box display="flex" justifyContent="end" flexDirection="column" alignItems="end">
				{user && (
					<Label className={'welcomeLabel'} variant={'buttonSm'}>
						Welcome, {user.firstName}
					</Label>
				)}
				<Box display={'flex'} alignItems="center" flexDirection="column" className={'menuContainer'}>
					<Box display={'flex'}>
						<Icon
							iconImg={'icon-hamburger-menu'}
							size={16}
							color={'#767676'}
							onClick={() => {
								setShowSlideOutMenu(!showSlideOutMenu);
							}}
						/>
						<img
							src={`../../images/rentyl-rewards-small.png`}
							onClick={() => {
								if (!user) {
									popupController.open(SigninPopup);
								} else {
									navigate('/account');
								}
							}}
							className="rr-logo-small"
							alt="Rentyl Reward"
						/>
					</Box>
					{!user && (
						<Box
							pb={2}
							className="rs_signin"
							onClick={() => {
								if (!user) {
									popupController.open(SigninPopup);
								} else {
									navigate('/account');
								}
							}}
						>
							Sign In
						</Box>
					)}
				</Box>
			</Box>

			<NavDrawer
				isOpened={showSlideOutMenu}
				onClose={() => {
					setShowSlideOutMenu(false);
				}}
			/>
		</div>
	);
};

export default AppBar;
