var __awaiter =
	(this && this.__awaiter) ||
	function (thisArg, _arguments, P, generator) {
		function adopt(value) {
			return value instanceof P
				? value
				: new P(function (resolve) {
						resolve(value);
				  });
		}
		return new (P || (P = Promise))(function (resolve, reject) {
			function fulfilled(value) {
				try {
					step(generator.next(value));
				} catch (e) {
					reject(e);
				}
			}
			function rejected(value) {
				try {
					step(generator['throw'](value));
				} catch (e) {
					reject(e);
				}
			}
			function step(result) {
				result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
			}
			step((generator = generator.apply(thisArg, _arguments || [])).next());
		});
	};
var __generator =
	(this && this.__generator) ||
	function (thisArg, body) {
		var _ = {
				label: 0,
				sent: function () {
					if (t[0] & 1) throw t[1];
					return t[1];
				},
				trys: [],
				ops: []
			},
			f,
			y,
			t,
			g;
		return (
			(g = { next: verb(0), throw: verb(1), return: verb(2) }),
			typeof Symbol === 'function' &&
				(g[Symbol.iterator] = function () {
					return this;
				}),
			g
		);
		function verb(n) {
			return function (v) {
				return step([n, v]);
			};
		}
		function step(op) {
			if (f) throw new TypeError('Generator is already executing.');
			while (_)
				try {
					if (
						((f = 1),
						y &&
							(t =
								op[0] & 2
									? y['return']
									: op[0]
									? y['throw'] || ((t = y['return']) && t.call(y), 0)
									: y.next) &&
							!(t = t.call(y, op[1])).done)
					)
						return t;
					if (((y = 0), t)) op = [op[0] & 2, t.value];
					switch (op[0]) {
						case 0:
						case 1:
							t = op;
							break;
						case 4:
							_.label++;
							return { value: op[1], done: false };
						case 5:
							_.label++;
							y = op[1];
							op = [0];
							continue;
						case 7:
							op = _.ops.pop();
							_.trys.pop();
							continue;
						default:
							if (
								!((t = _.trys), (t = t.length > 0 && t[t.length - 1])) &&
								(op[0] === 6 || op[0] === 2)
							) {
								_ = 0;
								continue;
							}
							if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
								_.label = op[1];
								break;
							}
							if (op[0] === 6 && _.label < t[1]) {
								_.label = t[1];
								t = op;
								break;
							}
							if (t && _.label < t[2]) {
								_.label = t[2];
								_.ops.push(op);
								break;
							}
							if (t[2]) _.ops.pop();
							_.trys.pop();
							continue;
					}
					op = body.call(thisArg, _);
				} catch (e) {
					op = [6, e];
					y = 0;
				} finally {
					f = t = 0;
				}
			if (op[0] & 5) throw op[1];
			return { value: op[0] ? op[1] : void 0, done: true };
		}
	};
Object.defineProperty(exports, '__esModule', { value: true });
exports.RsFormControl = void 0;
var Validator_1 = require('./Validator');
var Utils_1 = require('../../utils/Utils');
var RsFormControl = (function () {
	function RsFormControl(_key, _value, _validators) {
		this._key = _key;
		this._value = _value;
		this._validators = _validators;
		this._errors = [];
		this._initialValue = '';
		this._initialValue = _value;
	}
	Object.defineProperty(RsFormControl.prototype, 'key', {
		get: function () {
			return this._key;
		},
		enumerable: false,
		configurable: true
	});
	Object.defineProperty(RsFormControl.prototype, 'value', {
		get: function () {
			return this._value;
		},
		set: function (value) {
			this._value = value;
		},
		enumerable: false,
		configurable: true
	});
	Object.defineProperty(RsFormControl.prototype, 'errors', {
		get: function () {
			return this._errors;
		},
		enumerable: false,
		configurable: true
	});
	RsFormControl.prototype.resetToInitial = function () {
		this._value = this._initialValue;
		this._errors = [];
	};
	RsFormControl.prototype.isAtInitialValue = function () {
		return this._value === this._initialValue;
	};
	RsFormControl.prototype.getErrorMessage = function (index) {
		if (!this._validators) return '';
		return this._validators[index].errorMessage;
	};
	RsFormControl.prototype.updateInitialValue = function () {
		this._initialValue = this._value;
	};
	RsFormControl.prototype.clearErrors = function () {
		this._errors = [];
	};
	RsFormControl.prototype.validate = function () {
		return __awaiter(this, void 0, void 0, function () {
			var index, validator, validatorRule, _a, min, max, isEmail, result;
			return __generator(this, function (_b) {
				switch (_b.label) {
					case 0:
						this._errors = [];
						if (!this._validators) return [3, 11];
						index = 0;
						_b.label = 1;
					case 1:
						if (!(index < this._validators.length)) return [3, 11];
						validator = this._validators[index];
						validatorRule = validator.validator;
						_a = validatorRule;
						switch (_a) {
							case Validator_1.RsValidatorEnum.REQ:
								return [3, 2];
							case Validator_1.RsValidatorEnum.MIN:
								return [3, 3];
							case Validator_1.RsValidatorEnum.MAX:
								return [3, 4];
							case Validator_1.RsValidatorEnum.NUM:
								return [3, 5];
							case Validator_1.RsValidatorEnum.EMAIL:
								return [3, 6];
							case Validator_1.RsValidatorEnum.REG:
								return [3, 7];
							case Validator_1.RsValidatorEnum.CUSTOM:
								return [3, 8];
						}
						return [3, 10];
					case 2:
						if (this._value === undefined || this._value === null) {
							this._errors.push(index);
							return [2, false];
						}
						if (typeof this._value === 'string' && this._value.trim() === '') {
							this._errors.push(index);
							return [2, false];
						}
						if (Array.isArray(this._value) && this._value.length === 0) {
							this._errors.push(index);
							return [2, false];
						}
						return [3, 10];
					case 3:
						min = parseInt(validator.value) || 0;
						if (this._value.length < min) {
							this._errors.push(index);
							return [2, false];
						}
						return [3, 10];
					case 4:
						max = parseInt(validator.value) || 0;
						if (this._value.length > max) {
							this._errors.push(index);
							return [2, false];
						}
						return [3, 10];
					case 5:
						if (isNaN(Number(this._value))) {
							this._errors.push(index);
							return [2, false];
						}
						return [3, 10];
					case 6:
						isEmail = Utils_1.StringUtils.validateEmail(this._value.toString());
						if (!isEmail) {
							this._errors.push(index);
							return [2, false];
						}
						return [3, 10];
					case 7:
						if (!validator.value.test(this._value)) {
							this._errors.push(index);
							return [2, false];
						}
						return [3, 10];
					case 8:
						return [4, validator.value(this)];
					case 9:
						result = _b.sent();
						if (!result) {
							this._errors.push(index);
							return [2, false];
						}
						return [3, 10];
					case 10:
						index++;
						return [3, 1];
					case 11:
						return [2, true];
				}
			});
		});
	};
	return RsFormControl;
})();
exports.RsFormControl = RsFormControl;
