import { RsFormControl, RsFormGroup } from '../../lib/@bit/redsky.framework.rs.form';
import './CardInfoCard.scss';
import { Box } from '../../lib/@bit/redsky.framework.rs.996';
import LabelInput from '../labelInput/LabelInput';
import * as React from 'react';
import Label from '../../lib/@bit/redsky.framework.rs.label';
import Icon from '../../lib/@bit/redsky.framework.rs.icon';
import { useEffect, useRef, useState } from 'react';
import serviceFactory from '../../services/serviceFactory';
import PaymentService from '../../services/payment/payment.service';
import { WebUtils } from '../../utils/utils';
import useIsAtBreakpoint from '../../customHooks/useIsAtBreakpoint';

export interface CardInfoCardProps {
	form: RsFormGroup;
	onUpdate: (control: RsFormControl) => void;
	onFormError: (isError: boolean) => void;
}

const CardInfoCard: React.FC<CardInfoCardProps> = (props) => {
	const paymentService = serviceFactory.get<PaymentService>('PaymentService');
	const isMobile = useIsAtBreakpoint();
	const [validNameOnCard, setValidNameOnCard] = useState<boolean>(true);
	const [validExpDate, setValidExpDate] = useState<boolean>(true);

	useEffect(() => {
		async function init() {
			const gatewayDetails: Api.Payment.Res.PublicData = await paymentService.getGateway();
			window.Spreedly.init(gatewayDetails.publicData.token, {
				numberEl: 'spreedly-number',
				cvvEl: 'spreedly-cvv'
			});
		}
		init().catch(console.error);
	}, []);

	useEffect(() => {
		props.onFormError(!(validExpDate && validNameOnCard));
	}, [validExpDate, validNameOnCard]);

	useEffect(() => {
		const input = document.querySelector('.nameOnCardInput');
		input!.addEventListener('change', () => {
			if (
				props.form.get('nameOnCard').value === '' ||
				!props.form.get('nameOnCard').value.toString().trim().includes(' ')
			) {
				setValidNameOnCard(false);
			} else {
				setValidNameOnCard(true);
			}
		});
	}, []);

	useEffect(() => {
		const input = document.querySelector('.creditCardExpInput');
		let expirationMonthString = props.form.get('expiration').value.toString().substring(0, 2);
		let expirationYearString = props.form.get('expiration').value.toString().substring(3, 7);
		let expirationYear = Number(expirationYearString);
		let expirationMonth = Number(expirationMonthString);
		let currentYear = new Date().getFullYear();
		let currentMonth = new Date().getMonth() + 1;
		input!.addEventListener('change', () => {
			if (
				props.form.get('expiration').value.toString().length !== 7 ||
				expirationYear > currentYear + 20 ||
				expirationYear < currentYear ||
				(expirationMonth <= currentMonth && expirationYear <= currentYear)
			) {
				setValidExpDate(false);
			} else {
				setValidExpDate(true);
			}
		});
	});

	async function updateExpiration(control: RsFormControl) {
		const controlValue = control.value.toString();
		if (
			control.key === 'expiration' &&
			!control.value.toString().includes('/') &&
			control.value.toString().length === 4
		) {
			control.value = controlValue.slice(0, 2) + '/' + controlValue.slice(2, 4);
			control.clearErrors();
		}

		//This causes the cursor to be placed at the end of the input.
		await WebUtils.sleep(50);
		props.onUpdate(control);
	}

	function renderCreditCardImages() {
		return ['AmEx.svg', 'Discover.svg', 'MasterCard.svg', 'Visa.svg'].map((item) => {
			return <img key={item} src={`../../images/creditCardLogos/${item}`} alt={'credit card logos'} />;
		});
	}

	const numberRef = useRef<HTMLElement>(null);
	const cvvRef = useRef<HTMLElement>(null);

	return (
		<Box className={'rsCardInfoCard'}>
			<Box className={'title'} justifyContent={'space-between'}>
				<Label variant={'h5'}>Credit / Debit Card</Label>
				{!isMobile && (
					<Box display={'flex'} gap={'5px'} alignItems={'center'}>
						{renderCreditCardImages()}
						<Label variant={'body1'}>and more...</Label>
					</Box>
				)}
			</Box>
			<Box className={'cardBody'}>
				<Box className={'cardInfoGroup stretchedInput'}>
					<div ref={numberRef} id={'spreedly-number'}>
						<Label id={'Number'} variant={'h6'} mb={10}>
							<span className="asterisks">*</span>Card number
						</Label>
					</div>
				</Box>
				<Box className={'cardInfoGroup stretchedInput'}>
					<LabelInput
						labelClassName={!validNameOnCard ? 'error' : ''}
						labelVariant={'h6'}
						className={'nameOnCardInput'}
						title={'Name on card'}
						inputType={'text'}
						control={props.form.get('nameOnCard')}
						updateControl={props.onUpdate}
						isRequired
					/>
				</Box>
				<Box className={'cardInfoGroup'}>
					<LabelInput
						labelClassName={!validExpDate ? 'error' : ''}
						labelVariant={'h6'}
						maxLength={7}
						className={'creditCardExpInput'}
						title={'Expiration'}
						inputType={'text'}
						control={props.form.get('expiration')}
						updateControl={updateExpiration}
						placeholder={'MM/YYYY'}
						isRequired
					/>
					<div ref={cvvRef} id={'spreedly-cvv'}>
						<Box display={'flex'} alignItems={'center'} mb={8}>
							<Label id={'Cvv'} variant={'h6'} mr={8}>
								<span className="asterisks">*</span>CVC
							</Label>
							<Box className={'questionMarkWrapper'}>
								<Icon iconImg={'icon-solid-question-circle'} cursorPointer size={16} />
								<div className={'compareToolTip'}>
									<div className={'toolTipTriangle'} />
									<Label className={'caption'}>3-digit security code on back of card</Label>
								</div>
							</Box>
						</Box>
					</div>
				</Box>
			</Box>
		</Box>
	);
};

export default CardInfoCard;
