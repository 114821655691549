import React, { useLayoutEffect } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import './CheckoutPage.scss';
import { Box, Page, popupController } from '../../lib/@bit/redsky.framework.rs.996';
import ConfirmationImageSummary from '../../components/confirmationImageSummary/ConfirmationImageSummary';
import ThankYouCard from '../../components/thankYouCard/ThankYouCard';
import PersonalInformation from '../../components/personalInformation/PersonalInformation';
import PaymentMethod from '../../components/paymentMethod/PaymentMethod';
import RsPagedResponseData = RedSky.RsPagedResponseData;
import Policies from '../../components/policies/Policies';
import CheckoutReservationSummary from '../../components/checkoutReservationSummary/CheckoutReservationSummary';
import CheckoutBreadcrumbs from '../../components/checkoutBreadcrumbs/CheckoutBreadcrumbs';
import PrintableQrCode from '../../components/printableQrCode/PrintableQrCode';
import BookingSummaryCard from '../../components/bookingSummaryCard/BookingSummaryCard';
import Button from '../../lib/@bit/redsky.framework.rs.button';
import router from '../../utils/router';
import CheckOutPaymentCard from '../../components/checkoutPaymentCard/CheckOutPaymentCard';
import { useRecoilState, useRecoilValue } from 'recoil';
import globalState, { setRecoilExternalValue } from '../../state/globalState';
import { DateUtils, NumberUtils, ObjectUtils, StringUtils, WebUtils } from '../../utils/utils';
import { rsToastify } from '../../lib/@bit/redsky.framework.rs.toastify';
import serviceFactory from '../../services/serviceFactory';
import SpinningLoaderPopup from '../../popups/spinningLoaderPopup/SpinningLoaderPopup';
import PaymentService from '../../services/payment/payment.service';
import ReservationsService from '../../services/reservations/reservations.service';
import debounce from 'lodash.debounce';
import SigninPopup from '../../popups/signin/SigninPopup';
import UserService from '../../services/user/user.service';
import Label from '../../lib/@bit/redsky.framework.rs.label/dist/Label';
import UpdatePaymentMethodPopup from '../../popups/updatePaymentMethodPopup/UpdatePaymentMethodPopup';
import UpdateAddressPopup, { UpdateAddressPopupProps } from '../../popups/updateAddressPopup/UpdateAddressPopup';
import useIsAtBreakpoint from '../../customHooks/useIsAtBreakpoint';
import QuestionnairePopup, { QuestionnairePopupProps } from '../../popups/questionnairePopup/QuestionnairePopup';
import Loader from '../../components/loader/Loader';
import TagManager from 'react-gtm-module';
import DestinationService from '../../services/destination/destination.service';
import metaCapi from '../../customHooks/useMetaCapi';
import ConvertToCashPopup, { ConvertToCashPopupProps } from '../../popups/convertToCashPopup/ConvertToCashPopup';
import NotEnoughPointsPopup, {
	NotEnoughPointsPopupProps
} from '../../popups/notEnoughPointsPopup/NotEnoughPointsPopup';
// import PageViewBookingScript from './PageViewBookingScript';
import { get } from 'lodash';
import RulerAnalytics from '../../components/rulerAnalytics/RulerAnalytics';
import { getPageFinder, undefinedHandler } from '../../utils/undefinedHandler';
import AccommodationService from '../../services/accommodation/accommodation.service';
import ConfirmBoxPopup, { ConfirmBoxPopupProps } from '../../popups/confirmBoxPopup/ConfirmBoxPopup';
import SystemService from '../../services/system/system.service';
import moment from 'moment';
import CheckOutUserInfoCard from '../../components/checkoutUserInfoCard/CheckoutUserInfoCard';
import LabelInput from '../../components/labelInput/LabelInput';
import { RsFormControl, RsFormGroup, RsValidator, RsValidatorEnum } from '../../lib/@bit/redsky.framework.rs.form';
import LabelSelect from '../../components/labelSelect/LabelSelect';
import classNames from 'classnames';
import { OptionType } from '../../lib/@bit/redsky.framework.rs.select';
import useGetCountryList from '../../customHooks/useGetCountryList';
import CountryService from '../../services/country/country.service';
import affirm_base64 from '../../images/affirm_logo';
import CardInfoCard from '../../components/cardInfoCard/CardInfoCard';
import FilterBarDropDown from '../../components/filterBarDropDown/FilterBarDropDown';
import RedeemRewardCard from '../../components/redeemRewardCard/RedeemRewardCard';
import LabelRadioButton from '../../components/labelRadioButton/LabelRadioButton';
import LabelButton from '../../components/labelButton/LabelButton';
import CheckOutUserInfoFrom from '../../components/CheckOutUserInfoFrom/CheckOutUserInfoFrom';
import Banner from '../../components/banner/Banner';

enum FormKeys {
	FIRST_NAME = 'firstName',
	LAST_NAME = 'lastName',
	PHONE = 'phone',
	EMAIL = 'email',
	ADDRESS1 = 'address1',
	ADDRESS2 = 'address2',
	CITY = 'city',
	STATE = 'state',
	ZIP = 'zip',
	COUNTRY = 'country',
	NAME_ON_CARD = 'nameOnCard',
	EXPIRATION_DATE = 'expiration'
}

enum CheckoutStages {
	INFO = 0,
	PAYMENT = 1,
	BOOK = 2,
	CONFIRMATION = 3,
	AFTER_PRINT = 4
}

var isCvvLength = 0;

const CheckoutPage: React.FC = () => {
	let navigate = useNavigate();
	const { pathname } = useLocation();
	const [searchParams] = useSearchParams();
	const monitorUrl = pathname + searchParams;
	const isMobile = useIsAtBreakpoint();

	const paymentService = serviceFactory.get<PaymentService>('PaymentService');
	const reservationService = serviceFactory.get<ReservationsService>('ReservationsService');
	const userService = serviceFactory.get<UserService>('UserService');
	const destinationService = serviceFactory.get<DestinationService>('DestinationService');
	const accommodationService = serviceFactory.get<AccommodationService>('AccommodationService');
	const questionnaireService = serviceFactory.get<SystemService>('SystemService');
	const countryService = serviceFactory.get<CountryService>('CountryService');
	const reservationFilters = useRecoilValue<Misc.ReservationFilters>(globalState.reservationFilters);
	const [isSavePaymentMethodGlobal, setIsSavePaymentMethodGlobal] = useRecoilState<boolean>(
		globalState.isSavePaymentMethodGlobal
	);
	const user = useRecoilValue<Api.User.Res.Detail | undefined>(globalState.user);

	const params = router.getPageUrlParams<{
		stage: number;
		data: any;
		reservationId: number;
		anonymousUserID: number;
	}>([
		{ key: 's', default: 0, type: 'integer', alias: 'stage' },
		{ key: 'data', default: 0, type: 'string', alias: 'data' },
		{ key: 'ri', default: '', type: 'string', alias: 'reservationId' },
		{ key: 'aui', default: '', type: 'string', alias: 'anonymousUserID' }
	]);
	const queryString = window.location.search;

	const urlParams = new URLSearchParams(queryString);
	const resortId: any = urlParams.get('data');
	const anonymousUserID: any = urlParams.get('anonymousUserID');
	const paramsData = ObjectUtils.smartParse(params.data as unknown as string) as Misc.BookingParams;
	const destinationId: number = paramsData.destinationId;
	const stayParams: Misc.StayParams = get(paramsData, 'stays.0', []);
	const [verifiedAccommodation, setVerifiedAccommodation] = useRecoilState<
		Api.Reservation.Res.Verification | undefined
	>(globalState.verifiedAccommodation);
	const [anonymousUserData, setAnonymousUserData] = useState<boolean>(false);
	const [checkoutUser, setCheckoutUser] = useRecoilState<Misc.Checkout | undefined>(globalState.checkoutUser);
	const [showBillingAddress, setShowBillingAddress] = useState<boolean>(false);
	const [checkoutButtonRef, setCheckoutButtonRef] = useState<React.RefObject<any> | null>(null);
	const [checkoutPaymentButtonRef, setCheckoutPaymentButtonRef] = useState<React.RefObject<any> | null>(null);
	const [userPrimaryAddress, setUserPrimaryAddress] = useState<Api.User.Address>();
	const [completeTransaction, setCompleteTransaction] = useState<boolean>(false);
	const [transactionData, setTransactionData] = useState<Api.Reservation.Res.Itinerary.Get>();
	const [userPrimaryPaymentMethod, setUserPrimaryPaymentMethod] = useState<Api.User.PaymentMethod>(
		JSON.parse(localStorage.getItem('primaryPaymentMethod')!)
	);
	const [userTempPoints, setUserTempPoints] = useState<number>(0);
	const [itineraryId, setItineraryId] = useState<any>(null);
	const [tempAnonymousUserId, setTempAnonymousUserId] = useState<any>(null);
	const mountedRef = useRef<any>(null);
	const mountedRefvalue = useRef(null);
	const [signUp, setSignUp] = useState<boolean>(checkoutUser?.shouldCreateUser || false);
	const [infoForm, setInfoForm] = useState<RsFormGroup>(getInfoForm);
	const [stateKey, setStateKey] = useState<number>(0);
	const [countries, setCountries] = useState<OptionType[]>([]);
	const countryList = useGetCountryList();
	const [stateList, setStateList] = useState<Misc.OptionType[]>([]);
	const [phoneError, setPhoneError] = useState<boolean>(false);
	const firstLastNamePattern = new RegExp(/[a-zA-Z]+\s[a-zA-Z]+/);
	const [checkoutData, setCheckoutData] = useState<any>();
	const [radioStep, setRadioStep] = useState(0);
	const [isSavePaymentMethod, setIsSavePaymentMethod] = useState<boolean>(
		checkoutUser?.isExistingCard == undefined ? true : checkoutUser?.isExistingCard
	);
	const [isErrorOnForm, setErrorOnForm] = useState<boolean>(false);
	const [existingCardList, setExistingCardList] = useState<Api.User.PaymentMethod[]>([]);
	const [selectedPaymentMethord, setPaymentMethod] = useState<Api.User.PaymentMethod | null>(null);
	const [isAffirmPay, setIsAffirmPay] = useRecoilState<number>(globalState.isAffirmPay);
	const [isAffirmCheckout, setIsAffirmCheckout] = useState<any>();
	const [radioStepNew, setRadioStepNew] = useState(false);
	const [isFilledOut, setIsFilledOut] = useState<boolean>(true);
	const [isFilledOutAnonymousUser, setIsFilledOutAnonymousUser] = useState<boolean>(false);
	const [isFilledOutPaymnet, setIsFilledOutPaymnet] = useState<boolean>(true);
	const [isTimeToSubmit, setIsTimeToSubmit] = useState<boolean>(false);
	function getInfoForm() {
		return new RsFormGroup([
			new RsFormControl(FormKeys.FIRST_NAME, checkoutUser?.personal?.firstName || '', [
				new RsValidator(RsValidatorEnum.REQ, 'First name is required')
			]),
			new RsFormControl(FormKeys.LAST_NAME, checkoutUser?.personal?.lastName || '', [
				new RsValidator(RsValidatorEnum.REQ, 'Last name is required')
			]),
			new RsFormControl(FormKeys.EMAIL, checkoutUser?.personal?.email || '', [
				new RsValidator(RsValidatorEnum.EMAIL, 'Enter a valid Email'),
				new RsValidator(RsValidatorEnum.CUSTOM, 'Enter a valid Email', (control) => {
					return StringUtils.customBillingEmailPattern.test(control.value.toString());
				})
			]),
			new RsFormControl(FormKeys.PHONE, checkoutUser?.personal?.phone || '', [
				new RsValidator(RsValidatorEnum.REQ, 'Enter a valid phone number'),
				new RsValidator(RsValidatorEnum.MIN, 'Enter a valid phone number', 7),
				new RsValidator(RsValidatorEnum.CUSTOM, 'Enter a valid phone number', (control) => {
					const firstDigit = control.value.toString().slice(0, 1);
					const secondThreeDigit = control.value.toString().slice(1, 4);
					return !(Number(firstDigit) === 1 && Number(secondThreeDigit) === 0);
				})
			]),
			new RsFormControl(FormKeys.ADDRESS1, checkoutUser?.personal?.address1 || '', [
				new RsValidator(RsValidatorEnum.REQ, 'Address is required')
			]),
			new RsFormControl(FormKeys.ADDRESS2, checkoutUser?.personal?.address2 || '', []),
			new RsFormControl(FormKeys.CITY, checkoutUser?.personal?.city || '', [
				new RsValidator(RsValidatorEnum.REQ, 'City is required')
			]),
			new RsFormControl(FormKeys.ZIP, checkoutUser?.personal?.zip || '', [
				new RsValidator(RsValidatorEnum.REQ, 'Zip is required'),
				new RsValidator(RsValidatorEnum.CUSTOM, 'Invalid Zip Code', (control) => {
					return StringUtils.zipPattern.test(control.value.toString());
				})
			]),
			new RsFormControl(FormKeys.STATE, checkoutUser?.personal?.state || '', [
				new RsValidator(RsValidatorEnum.REQ, 'State is required')
			]),
			new RsFormControl(FormKeys.COUNTRY, checkoutUser?.personal?.country || 'US', [
				new RsValidator(RsValidatorEnum.REQ, 'Country is required')
			])
		]);
	}

	const [paymentForm, setPaymentForm] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl(FormKeys.NAME_ON_CARD, checkoutUser?.paymentInfo?.nameOnCard || '', [
				new RsValidator(RsValidatorEnum.CUSTOM, 'Card Name is required', customPaymentRequired),
				new RsValidator(RsValidatorEnum.CUSTOM, '', (control: RsFormControl) => {
					return firstLastNamePattern.test(control.value.toString());
				})
			]),
			new RsFormControl(FormKeys.EXPIRATION_DATE, checkoutUser?.paymentInfo?.expiration || '', [
				new RsValidator(RsValidatorEnum.CUSTOM, 'Expiration required', customPaymentRequired),
				new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
					if (isPayingWithPoints() || isUsingExistingPaymentMethod()) return true;

					let month = parseInt(control.value.toString().slice(0, 2));
					let year = parseInt(control.value.toString().slice(3, 7));
					let currentYear = new Date().getFullYear();
					let currentMonth = new Date().getMonth() + 1;
					if (month > 12) return false;
					if (year === currentYear) return month >= currentMonth;
					else return year > currentYear;
				})
			])
		])
	);

	const [destinationDetails, setDestinationDetails] = useState<any>();
	const [destinationName, setDestinationName] = useState<string>('');
	const [destinationLogo, setDestinationLogo] = useState<string>('');
	const [accommodationStay, setAccommodationStay] = useState<any>();
	const [prices, setPrices] = useState<any>();
	const [isInvalidSpreedlyIframe, setIsInvalidSpreedlyIframe] = useState<boolean>(true);
	const [isFormFilledOutPayment, setIsFormFilledOutPayment] = useState<boolean>(false);
	const [showExpectedPoint, setShowExpectedPoint] = useState<boolean>(true);
	const [redemptionHandler, setRedemptionHandler] = useState<boolean>(false);
	const [bookedStays, setBookedStays] = useState<
		{
			images: Api.Media[];
			title: string;
			price: number;
			dateBooked: Date;
			paymentMethod: any;
			confirmationNumber: string;
			currencyCode: string;
		}[]
	>(getStoredBookedStays);
	const checkoutFormHasError = useRecoilValue<Misc.CheckoutFormError>(globalState.checkoutFormHasError);
	const printRef = useRef(null);
	const [checkoutPolicy, setCheckoutPolicy] = useState(null);
	const [second, setSecond] = useState(600);
	let myInterval: any;

	mountedRefvalue.current = tempAnonymousUserId;

	// checkPointEligibility(user?.availablePoints, props.bookingData?.prices.grandTotalPoints);

	function isPayingWithPoints() {
		// This is used because for some reason I can't access stateful variables from within form controls
		return (document.querySelector('.isPayingWithPoints [id^="RsSwitch_"]') as HTMLInputElement)?.checked || false;
	}

	function isUsingExistingPaymentMethod() {
		// This is used because for some reason I can't access stateful variables from within form controls
		return (
			(document.querySelector('.isUsingExistingPaymentMethod [id^="RsSwitch_"]') as HTMLInputElement)?.checked ||
			false
		);
	}

	function customPaymentRequired(control: RsFormControl) {
		return isPayingWithPoints() || isUsingExistingPaymentMethod() || !!control.value.toString();
	}

	function getStoredBookedStays() {
		let stays = sessionStorage.getItem('bookedStays');
		if (!!stays) return JSON.parse(stays);
		else return [];
	}

	useEffect(() => {
		tagHandlar();
	}, [verifiedAccommodation]);

	const tagHandlar = async () => {
		const destinationId = JSON.parse(params.data);

		const searchQueryObj: Misc.ReservationFilters = { ...reservationFilters };
		let key: keyof Misc.ReservationFilters;
		for (key in searchQueryObj) {
			if (searchQueryObj[key] === undefined) delete searchQueryObj[key];
		}
		const results: RsPagedResponseData<Api.Accommodation.Res.MinMaxAvailability> =
			await accommodationService.availability(destinationId.destinationId, searchQueryObj);

		const accommodationStay = results?.data.find((stay: any) => stay.id === verifiedAccommodation?.accommodationId);

		setAccommodationStay(accommodationStay);
		setPrices(verifiedAccommodation?.prices);
	};

	useEffect(() => {
		myInterval = setInterval(async () => {
			if (second == 0) {
				popupController.open<ConfirmBoxPopupProps>(ConfirmBoxPopup, {});
				clearInterval(myInterval);
			} else {
				setSecond(second - 1);
			}
		}, 1000);
		return () => {
			clearInterval(myInterval);
		};
	}, []);

	async function getAccommodationPolicy() {
		const searchQueryObj: Misc.ReservationFilters = { ...reservationFilters, destinationId: destinationId };
		let key: keyof Misc.ReservationFilters;
		for (key in searchQueryObj) {
			if (searchQueryObj[key] === undefined) delete searchQueryObj[key];
		}

		let accommodationPolicy: any = await accommodationService.getBookingPolicy(
			destinationId,
			searchQueryObj,
			destinationId,
			verifiedAccommodation?.accommodationId
		);

		accommodationPolicy.find((accommodation: any) => {
			let policy = accommodation.contentLists.PolicyList.CancelPolicy[0].Description;
			setCheckoutPolicy(policy);
		});
	}

	useEffect(() => {
		if (verifiedAccommodation?.accommodationName && destinationId && reservationFilters) {
			getAccommodationPolicy().catch(console.error);
		}
	}, [destinationId, verifiedAccommodation, reservationFilters]);

	useEffect(() => {
		try {
			if (!reservationFilters.redeemPoints || !verifiedAccommodation) return;
			if (userTempPoints < 0) {
				popupController.open<NotEnoughPointsPopupProps>(NotEnoughPointsPopup, {
					verifiedAccommodation,
					userTempPoints,
					addedPackages: verifiedAccommodation.upsellPackages,
					destinationId: destinationId,
					removePackage: (id: number, points: number) => {
						setVerifiedAccommodation((prev) => {
							if (!prev) return;
							return { ...prev, upsellPackages: prev.upsellPackages.filter((item) => item.id !== id) };
						});
						setUserTempPoints((prev) => prev + points);
					}
				});
			}
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}, [userTempPoints, reservationFilters.redeemPoints]);

	useEffect(() => {
		// async function init() {
		// 	const gatewayDetails: Api.Payment.Res.PublicData = await paymentService.getGateway();
		// 	window.Spreedly.init(gatewayDetails.publicData.token, {
		// 		numberEl: 'spreedly-number',
		// 		cvvEl: 'spreedly-cvv'
		// 	});
		// }
		// init().catch(console.error);
	}, []);

	useEffect(() => {
		let cancelRequest = false;
		async function verifyAvailability() {
			// if (verifiedAccommodation) return;
			try {
				let verifyData: Api.Reservation.Req.Verification = {
					accommodationId: stayParams.accommodationId,
					destinationId: destinationId,
					adultCount: stayParams.adults,
					childCount: stayParams.children,
					arrivalDate: stayParams.arrivalDate,
					departureDate: stayParams.departureDate,
					numberOfAccommodations: 1,
					upsellPackages: stayParams.packages.map((packageId) => {
						return { id: packageId };
					})
				};
				if (stayParams.rateCode) verifyData.rateCode = stayParams.rateCode;

				setShowExpectedPoint(true);
				let response = await reservationService.verifyAvailability(verifyData);
				if (cancelRequest) return;
				// response.arrivalDate = DateUtils.displayUserDate(response.arrivalDate, 'MM-DD-YYYY');
				// response.departureDate = DateUtils.displayUserDate(response.departureDate, 'MM-DD-YYYY');
				setVerifiedAccommodation(response);
				setUserTempPoints((user ? user.availablePoints : 0) - response.prices.grandTotalPoints);
			} catch (e: any) {
				const results: Api.Destination.Res.PublicSlim[] = await destinationService.getPublicSlim();
				const destinationIdtem: any = urlParams.get('data');
				const destinationId = results.find(
					(result) => parseInt(result?.id.toString()) === JSON.parse(destinationIdtem).destinationId
				)?.id;

				const destinationName = results
					.find((result) => parseInt(result?.id.toString()) === JSON.parse(destinationIdtem).destinationId)
					?.name.split(' ')
					.join('-')
					.toLocaleLowerCase();

				navigate(`/destination/${destinationName}`, {
					state: {
						...reservationFilters,
						destinationId: destinationId
					}
				});
				// await navigate(`/destination/details${WebUtils.createUrlParams(reservationFilters)}`);
				rsToastify.error(
					'Your selected accommodation is no longer available for these dates. Removed unavailable accommodation(s).',
					'No Longer Available'
				);
				const message = `Getting error :${e.message} on ${getPageFinder(window.location.pathname)} page.`;
				undefinedHandler(message);
			}
		}
		verifyAvailability().catch(console.error);
		return () => {
			cancelRequest = true;
		};
	}, []);

	const redeemMyPointHandler = (mode: string) => {
		if (mode === 'REDEEM') {
			setRedemptionHandler(true);
		} else if (mode === 'UNREDEEM') {
			setRedemptionHandler(false);
		} else {
			setRedemptionHandler(false);
		}
	};

	const ConTwoDecDigit = (digit: any) => {
		return digit.indexOf('.') > 0
			? digit.split('.').length <= 4
				? digit.split('.')[0] + '.' + digit.split('.')[1].substring(-1, 4)
				: digit
			: digit;
	};

	function updateForm(control: RsFormControl) {
		if (control.key === 'firstName') {
			if (!isNaN(Number(control.value))) {
				// If the value is a number, clear it
				control.value = '';
			} else {
				// Remove special characters and ensure two decimal digits
				control.value = control.value.toString().replace(/[^A-Za-z]/g, '');
				control.value = ConTwoDecDigit(control.value);
			}
		}

		if (control.key === 'lastName') {
			if (!isNaN(Number(control.value))) {
				// If the value is a number, clear it
				control.value = '';
			} else {
				// Remove special characters and ensure two decimal digits
				control.value = control.value.toString().replace(/[^A-Za-z]/g, '');
				control.value = ConTwoDecDigit(control.value);
			}
		}

		if (control.key === FormKeys.COUNTRY) {
			const stateControl = infoForm.get(FormKeys.STATE);
			stateControl.value = '';
			infoForm.update(stateControl);
			setStateKey((prevState) => ++prevState);
		}
		setInfoForm(infoForm.clone().update(control));
	}

	function updatePaymentForm(control: RsFormControl) {
		if (control.key === FormKeys.COUNTRY) {
			const stateControl = paymentForm.get(FormKeys.STATE);
			stateControl.value = '';
			paymentForm.update(stateControl);
		}
		if (control.key == FormKeys.EXPIRATION_DATE) {
			const digitsOnly = /[^\d\/]/g;
			control.value = control.value.toString().replace(digitsOnly, '');
		}
		setPaymentForm(paymentForm.clone().update(control));
	}

	useEffect(() => {
		setInfoForm(getInfoForm());
		setStateKey((prevState) => ++prevState);
	}, [checkoutUser]);

	useEffect(() => {
		setCountries(formatStateOrCountryListForSelect(countryList));
	}, [countryList]);

	useEffect(() => {
		if (!user) setIsSavePaymentMethod(false);
		if (!user) return;
		let cardList: any = [];
		if (user.paymentMethods.length !== 0) {
			user.paymentMethods.map((detail) => {
				if (!detail.metaData?.isAffirmCheckout) {
					cardList.push(detail);
				}
			});
		}
		// setUseYourSavedPaymentShow(cardList);

		setExistingCardList(user.paymentMethods);
		if (user.paymentMethods.length == 0) {
			setIsSavePaymentMethod(false);
		}
		if (selectedPaymentMethord == null) {
			let card = user.paymentMethods.find((card) => card.isPrimary);
			card && setIsSavePaymentMethod(true);
			card && setPaymentMethod(card);
		}
		const primaryUserAddress: any = user.address.find((address) => address.isDefault === 1);
		const primaryPaymentMethod = user.paymentMethods.find((paymentMethod) => paymentMethod.isPrimary === 1);
		setCheckoutUser({
			personal: {
				firstName: checkoutUser?.personal?.firstName || user?.firstName || '',
				lastName: checkoutUser?.personal?.lastName || user?.lastName || '',
				address1: primaryUserAddress?.address1 || checkoutUser?.personal?.address1 || '',
				address2: primaryUserAddress?.address2 || checkoutUser?.personal?.address2 || '',
				zip: (primaryUserAddress?.zip || checkoutUser?.personal.zip || '').toString(),
				city: primaryUserAddress?.city || checkoutUser?.personal.city || '',
				state: primaryUserAddress?.state || checkoutUser?.personal.state || '',
				country: primaryUserAddress?.country || checkoutUser?.personal?.country || 'US',
				email: checkoutUser?.personal?.email || user?.primaryEmail || '',
				phone: user?.phone || checkoutUser?.personal?.phone || ''
			},
			billing: {
				firstName:
					checkoutUser?.billing?.firstName || checkoutUser?.personal?.firstName || user?.firstName || '',
				lastName: checkoutUser?.billing?.lastName || checkoutUser?.personal?.lastName || user?.lastName || '',
				address1: checkoutUser?.billing?.address1 || checkoutUser?.personal?.address1 || '',
				address2: checkoutUser?.billing?.address2 || checkoutUser?.personal?.address2 || '',
				city: checkoutUser?.billing?.city || checkoutUser?.personal?.city || '',
				state: checkoutUser?.billing?.state || checkoutUser?.personal?.state || '',
				zip: checkoutUser?.billing?.zip || checkoutUser?.personal?.zip || '',
				country: checkoutUser?.billing?.country || checkoutUser?.personal?.country || 'US',
				email: checkoutUser?.personal?.email || user?.primaryEmail || '',
				phone: user?.phone || checkoutUser?.personal?.phone || ''
			},
			usePoints: !!reservationFilters?.redeemPoints,
			shouldCreateUser: checkoutUser?.shouldCreateUser || false,
			pmData: checkoutUser?.pmData,
			isAffirmCheckout: checkoutUser?.isAffirmCheckout,
			isExistingCard: checkoutUser?.isAffirmCheckout ? false : user.paymentMethods.length > 0,
			existingCardId: checkoutUser?.isAffirmCheckout
				? undefined
				: user.paymentMethods.find((card) => card.isPrimary)?.id
		});

		setUserPrimaryAddress(primaryUserAddress);
		if (primaryPaymentMethod) {
			setUserPrimaryPaymentMethod(primaryPaymentMethod);
		}
	}, [user]);

	function formatStateOrCountryListForSelect(statesOrCountries: any[]) {
		return statesOrCountries.map((item) => {
			return { value: item.isoCode, label: item.name };
		});
	}

	useEffect(() => {
		let cancelFetch: boolean = false;
		async function getStates() {
			try {
				const states = await countryService.getStates(infoForm.get(FormKeys.COUNTRY).value.toString());
				if (cancelFetch) return;
				if (states.states.length === 0) {
					setStateList([{ value: 'N/A', label: 'N/A' }]);
				} else {
					setStateList(formatStateOrCountryListForSelect(states.states));
				}
			} catch (e: any) {
				rsToastify.error(
					WebUtils.getRsErrorMessage(e, 'Unable to get states for the selected country.'),
					'Server Error'
				);

				const message = `Getting error :${e.message} on ${getPageFinder(window.location.pathname)} page.`;
				undefinedHandler(message);
			}
		}
		getStates().catch(console.error);
		return () => {
			cancelFetch = true;
		};
	}, [infoForm.get(FormKeys.COUNTRY)]);

	useEffect(() => {
		(async function checkIfFilledOut() {
			let iscompleted = 0;
			let completed = await infoForm.isValid();
			if (completed) {
				if (iscompleted < 1) {
					iscompleted = iscompleted + 1;
					googleCheckoutProcess1();
				}
			}
			setIsFilledOut(completed);

			if (completed) {
				setIsFilledOutAnonymousUser(true);
			}

			setPhoneError(ObjectUtils.isArrayWithData(infoForm.get('phone').errors));
		})();
	}, [infoForm]);

	useEffect(() => {
		(async function checkIfFilledOut() {
			let completed = await paymentForm.isValid();

			setIsFilledOutPaymnet(completed);
		})();
	}, [paymentForm]);

	const googleTagEcommerce = (res: any) => {
		try {
			TagManager.dataLayer({
				// Clear the previous ecommerce object.
				dataLayer: {
					event: 'transaction',
					ecommerce: null
				}
			});

			TagManager.dataLayer({
				dataLayer: {
					event: 'transaction',
					ecommerce: {
						purchase: {
							actionField: {
								id: res.stays[0].externalConfirmationId,
								affiliation: res.destination.name,
								revenue: NumberUtils.centsToDollars(res.stays[0].priceDetail?.grandTotalCents),

								tax: NumberUtils.centsToDollars(res.stays[0].priceDetail.taxTotalsInCents[0]?.amount)
							},
							products: [
								{
									id: res.stays[0].externalConfirmationId,
									name: res.destination.name,
									price: NumberUtils.centsToDollars(
										res.stays[0].priceDetail.accommodationTotalInCents
									),
									// category: res.stays.map((stay: any) => stay.accommodation.name).toString(),
									quantity: 1
								}
							]
						}
					}
				}
			});
		} catch (e: any) {
			console.error(e);
			console.error('Error in googleTagEcommerce function.');
			const message = `Getting error :${e.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	};

	const googleCheckoutProcess4 = () => {
		try {
			TagManager.dataLayer({
				// Clear the previous ecommerce object.
				dataLayer: {
					event: 'checkout-step-4',
					ecommerce: null
				}
			});

			TagManager.dataLayer({
				dataLayer: {
					event: 'checkout-step-4',
					ecommerce: {
						checkout: {
							actionField: {
								step: 4,
								option: 'Confirmation'
							},
							products: [{}]
						}
					}
				}
			});
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	};

	const googleCheckoutProcess3 = () => {
		try {
			TagManager.dataLayer({
				// Clear the previous ecommerce object.
				dataLayer: {
					event: 'checkout-step-3',
					ecommerce: null
				}
			});

			TagManager.dataLayer({
				dataLayer: {
					event: 'checkout-step-3',
					ecommerce: {
						checkout: {
							actionField: {
								step: 3,
								option: 'Review and Book'
							},
							products: [{}]
						}
					}
				}
			});
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	};

	const googleCheckoutProcess2 = async () => {
		const searchQueryObj: Misc.ReservationFilters = { ...reservationFilters };

		const checkout_step_2 = {
			item_name: verifiedAccommodation?.accommodationName,
			item_id: get(accommodationStay, 'externalSystemId', verifiedAccommodation?.accommodationId),
			price: NumberUtils.centsToDollars(prices?.accommodationTotalInCents),
			item_brand: verifiedAccommodation?.destinationName,
			item_category: get(accommodationStay, 'contentLists.RoomCategoryList.0.Name', null),
			item_category2: get(accommodationStay, 'contentLists.RoomCategoryList.0.Name', null),
			quantity: 1, // we'll consider 1 room as a item
			item_list_name: 'Rooms',
			arrival_date: searchQueryObj.startDate,
			depart_date: searchQueryObj.endDate,
			total_cost: NumberUtils.centsToDollars(prices?.grandTotalCents),
			payment_type: radioStep === 2 ? 'Monthly Payment' : 'Card Payment',
			currency: prices?.currencyCode
		};

		try {
			TagManager.dataLayer({
				// Clear the previous ecommerce object.
				dataLayer: {
					event: 'checkout-step-2',
					ecommerce: null
				}
			});

			TagManager.dataLayer({
				dataLayer: {
					event: 'checkout-step-2',
					ecommerce: {
						checkout: {
							actionField: {
								step: 2,
								option: 'Payment'
							},
							products: [checkout_step_2]
						}
					}
				}
			});
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	};

	const googleCheckoutProcess1 = async () => {
		const checkout_step_1 = {
			item_name: verifiedAccommodation?.accommodationName,
			item_id: get(accommodationStay, 'externalSystemId', verifiedAccommodation?.accommodationId),
			price: NumberUtils.centsToDollars(prices?.accommodationTotalInCents),
			item_brand: verifiedAccommodation?.destinationName,
			item_category: get(accommodationStay, 'contentLists.RoomCategoryList.0.Name', null),
			item_category2: get(accommodationStay, 'contentLists.RoomCategoryList.0.Name', null),
			quantity: 1, // we'll consider 1 room as a item
			item_list_name: 'Rooms'
		};

		try {
			TagManager.dataLayer({
				// Clear the previous ecommerce object.
				dataLayer: {
					event: 'checkout-step-1',
					ecommerce: null
				}
			});

			TagManager.dataLayer({
				dataLayer: {
					event: 'checkout-step-1',
					ecommerce: {
						checkout: {
							actionField: {
								step: 1,
								option: 'Info'
							},
							products: [checkout_step_1]
						}
					}
				}
			});
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	};

	function debounceAffirmPromotionalMessage() {
		setTimeout(() => {
			window.affirm.ui.ready(function () {
				window.affirm.ui.refresh();
			});
		}, 400);
	}

	useEffect(() => {
		debounceAffirmPromotionalMessage();
		if (checkoutUser && verifiedAccommodation) {
			setCheckoutData({
				merchant: {
					use_vcn: true,
					name: StringUtils.getCorporateName()
				},
				shipping: {
					name: {
						full: `${checkoutUser?.billing?.firstName} ${checkoutUser?.billing?.lastName}`
					},
					address: {
						line1: `${checkoutUser?.billing?.address1}`,
						line2: `${checkoutUser?.billing?.address2}`,
						city: `${checkoutUser?.billing?.city}`,
						state: `${checkoutUser?.billing?.state}`,
						zipcode: `${checkoutUser?.billing?.zip}`,
						country: `${checkoutUser?.billing?.country}`
					},
					email: `${checkoutUser?.billing?.email}`
				},
				billing: {
					name: {
						full: `${checkoutUser?.billing?.firstName} ${checkoutUser?.billing?.lastName}`
					},
					address: {
						line1: `${checkoutUser?.billing?.address1}`,
						line2: `${checkoutUser?.billing?.address2}`,
						city: `${checkoutUser?.billing?.city}`,
						state: `${checkoutUser?.billing?.state}`,
						zipcode: `${checkoutUser?.billing?.zip}`,
						country: `${checkoutUser?.billing?.country}`
					},
					email: `${checkoutUser?.billing?.email}`
				},
				items: [
					{
						display_name: `${verifiedAccommodation?.destinationName}`,
						sku: '',
						unit_price: `${verifiedAccommodation?.prices?.accommodationTotalInCents}`,
						qty: verifiedAccommodation?.adultCount,
						item_image_url: '',
						item_url: ''
					}
				],
				metadata: {
					mode: 'modal'
				},
				currency: 'USD',
				tax_amount: verifiedAccommodation?.prices?.taxAndFeeTotalInCents,
				total: verifiedAccommodation?.prices?.grandTotalCents
			});
		}
	}, [verifiedAccommodation]);

	const affirmCheckout = () => {
		const newCheckoutData = {
			merchant: {
				use_vcn: true,
				name: StringUtils.getCorporateName()
			},
			shipping: {
				name: {
					full: `${infoForm.get(FormKeys.FIRST_NAME).value.toString()} ${infoForm
						.get(FormKeys.LAST_NAME)
						.value.toString()}`
				},
				address: {
					line1: `${infoForm.get(FormKeys.ADDRESS1).value.toString()}`,
					line2: `${infoForm.get(FormKeys.ADDRESS2).value.toString()}`,
					city: `${infoForm.get(FormKeys.CITY).value.toString()}`,
					state: `${infoForm.get(FormKeys.STATE).value.toString()}`,
					zipcode: `${infoForm.get(FormKeys.ZIP).value.toString()}`,
					country: `${infoForm.get(FormKeys.COUNTRY).value.toString()}`
				},
				email: `${infoForm.get(FormKeys.EMAIL).value.toString()}`
			},
			billing: {
				name: {
					full: `${infoForm.get(FormKeys.FIRST_NAME).value.toString()} ${infoForm
						.get(FormKeys.LAST_NAME)
						.value.toString()}`
				},
				address: {
					line1: `${infoForm.get(FormKeys.ADDRESS1).value.toString()}`,
					line2: `${infoForm.get(FormKeys.ADDRESS2).value.toString()}`,
					city: `${infoForm.get(FormKeys.CITY).value.toString()}`,
					state: `${infoForm.get(FormKeys.STATE).value.toString()}`,
					zipcode: `${infoForm.get(FormKeys.ZIP).value.toString()}`,
					country: `${infoForm.get(FormKeys.COUNTRY).value.toString()}`
				},
				email: `${infoForm.get(FormKeys.EMAIL).value.toString()}`
			},
			items: [
				{
					display_name: `${verifiedAccommodation?.destinationName}`,
					sku: '',
					unit_price: `${verifiedAccommodation?.prices?.accommodationTotalInCents}`,
					qty: verifiedAccommodation?.adultCount,
					item_image_url: '',
					item_url: ''
				}
			],
			metadata: {
				mode: 'modal'
			},
			currency: 'USD',
			tax_amount: verifiedAccommodation?.prices?.taxAndFeeTotalInCents,
			total: verifiedAccommodation?.prices?.grandTotalCents
		};

		if (newCheckoutData && verifiedAccommodation) {
			popupController.open(SpinningLoaderPopup);
			window.affirm.checkout.open_vcn({
				checkout_data: newCheckoutData,
				success: async function (card_response: any) {
					try {
						const cardholder_name = card_response.cardholder_name.split(' ');
						const year = card_response.expiration.slice(2);
						const month = card_response.expiration.slice(0, 2);

						const isProduction =
							window.location.href.split('?')[0].indexOf('https://book.rentylresorts.com') > -1;
						const isUAT =
							window.location.href.split('?')[0].indexOf('https://uat.book.rentylresorts.com/') > -1;

						const affirmData = {
							payment_method: {
								credit_card: {
									first_name: cardholder_name[1],
									last_name: cardholder_name[2],
									number: card_response.number,
									verification_value: card_response.cvv,
									month: month,
									year: isProduction || isUAT ? `20${year}` : `2029`
								}
							}
						};

						const spreedlyRes = await paymentService.checkoutInSpreedly(affirmData);

						if (spreedlyRes && spreedlyRes?.status && spreedlyRes?.status !== 200) {
							popupController.close(SpinningLoaderPopup);
							rsToastify.error(`${JSON.parse(spreedlyRes?.msg).errors[0]?.message}`, 'Server Error');
							return;
						}

						let newCheckoutUser = {
							...checkoutUser,
							personal: {
								firstName: infoForm.get(FormKeys.FIRST_NAME).value.toString(),
								lastName: infoForm.get(FormKeys.LAST_NAME).value.toString(),
								address1: infoForm.get(FormKeys.ADDRESS1).value.toString(),
								address2: infoForm.get(FormKeys.ADDRESS2).value.toString(),
								zip: infoForm.get(FormKeys.ZIP).value.toString(),
								city: infoForm.get(FormKeys.CITY).value.toString(),
								state: infoForm.get(FormKeys.STATE).value.toString(),
								country: infoForm.get(FormKeys.COUNTRY).value.toString(),
								email: infoForm.get(FormKeys.EMAIL).value.toString(),
								phone: infoForm.get(FormKeys.PHONE).value.toString()
							},
							billing: {
								firstName: infoForm.get(FormKeys.FIRST_NAME).value.toString(),
								lastName: infoForm.get(FormKeys.LAST_NAME).value.toString(),
								address1: infoForm.get(FormKeys.ADDRESS1).value.toString(),
								address2: infoForm.get(FormKeys.ADDRESS2).value.toString(),
								zip: infoForm.get(FormKeys.ZIP).value.toString(),
								city: infoForm.get(FormKeys.CITY).value.toString(),
								state: infoForm.get(FormKeys.STATE).value.toString(),
								country: infoForm.get(FormKeys.COUNTRY).value.toString(),
								email: infoForm.get(FormKeys.EMAIL).value.toString(),
								phone: infoForm.get(FormKeys.PHONE).value.toString()
							},
							existingCardId: undefined,
							isExistingCard: false,
							pmData: spreedlyRes.transaction.payment_method,
							paymentInfo: {
								nameOnCard: card_response.cardholder_name,
								expiration: `${month}/20${year}`
							},
							isAffirmCheckout: true
						};

						setIsAffirmCheckout(newCheckoutUser);

						popupController.close(SpinningLoaderPopup);
						// navigate(`/booking/checkout?s=${params.stage + 1}&data=${params.data}${isRedeemPoints}`);
						// }
					} catch (e: any) {
						popupController.close(SpinningLoaderPopup);
					}
				},
				error: function (error_response: any) {
					popupController.close(SpinningLoaderPopup);
					//add logic on handling a declination or abandoned checkout here. We recommend redirecting users to the payment selection screen to choose an alternative payment method.
				},
				onValidationError: function (checkout_validation_error: any) {
					popupController.close(SpinningLoaderPopup);
				}
			});
		}
	};

	function canShowCardForm() {
		if (!user) return true;
		return !reservationFilters.redeemPoints && !isSavePaymentMethod && radioStepNew;
	}

	function checkPointEligibility(userPoint: any, bookingPriceInPoint: any) {
		/* To activate RedeemRewardCard
			if (userPoint >= bookingPriceInPoint) {
				return true;
			} else {
				return false;
			}
		*/

		return false;
	}

	// useEffect(() => {
	// 	if (!user) setIsSavePaymentMethod(false);
	// 	if (!user) return;
	// 	let cardList: any = [];
	// 	if (user.paymentMethods.length !== 0) {
	// 		user.paymentMethods.map((detail) => {
	// 			if (!detail.metaData?.isAffirmCheckout) {
	// 				cardList.push(detail);
	// 			}
	// 		});
	// 	}
	// 	// setUseYourSavedPaymentShow(cardList);

	// 	setExistingCardList(user.paymentMethods);
	// 	if (user.paymentMethods.length == 0) {
	// 		setIsSavePaymentMethod(false);
	// 	}
	// 	if (selectedPaymentMethord == null) {
	// 		let card = user.paymentMethods.find((card) => card.isPrimary);
	// 		card && setPaymentMethod(card);
	// 	}
	// }, [user]);

	useEffect(() => {
		if (checkoutUser?.isAffirmCheckout) {
			setIsSavePaymentMethod(false);
			setRadioStep(2);
			setCheckoutUser({
				...checkoutUser,
				isAffirmCheckout: false
			});
		}
	}, []);

	useEffect(() => {
		if (!isTimeToSubmit || !checkoutUser) return;
		if (checkoutUser.paymentInfo && !isSavePaymentMethod) {
			let paymentObj = {
				full_name: checkoutUser.paymentInfo.nameOnCard,
				month: Number(checkoutUser.paymentInfo.expiration.split('/')[0]),
				year: Number(checkoutUser.paymentInfo.expiration.split('/')[1])
			};
			// window.Spreedly.tokenizeCreditCard(paymentObj);
			setIsTimeToSubmit(false);
		}
	}, [isTimeToSubmit, checkoutUser]);

	useLayoutEffect(() => {
		const paramsdestinationId = JSON.parse(String(params.data));
		async function getDestinationDetails(id: number) {
			// if (isMobile) return;
			try {
				const dest = await destinationService.getDestinationDetails({
					destinationId: id,
					startDate: reservationFilters.startDate,
					endDate: reservationFilters.endDate
				});
				const affirmData = dest?.installmentPaymentRate;
				let hasAffirmOption: number = 0;
				affirmData?.forEach((e: any) => {
					if (dest.isAffirmPay && e.value === paramsdestinationId?.stays[0]?.rateCode) {
						hasAffirmOption = 1;
					}
				});
				setIsAffirmPay(hasAffirmOption);
			} catch (e: any) {
				const message = `Getting error :${e.message} on ${getPageFinder(window.location.pathname)} page.`;
				undefinedHandler(message);
				rsToastify.error(
					WebUtils.getRsErrorMessage(e, 'Cannot get details for this destination.'),
					'Server Error ',
					{
						onClose: () => {
							navigate(-1);
						}
					}
				);
			}
		}

		getDestinationDetails(paramsdestinationId.destinationId).catch(console.error);
		return () => {
			setIsAffirmPay(0);
		};
	}, []);

	useEffect(() => {
		sessionStorage.setItem('bookedStays', JSON.stringify(bookedStays));
		return () => {
			sessionStorage.removeItem('bookedStays');
		};
	}, [bookedStays]);

	let debounceCvvCardError = debounce(async (element: 'Number' | 'Cvv') => {
		let htmlBlock: HTMLElement | null = document.querySelector(`#${element}`);
		setIsInvalidSpreedlyIframe(true);
		if (!!htmlBlock) htmlBlock.style.color = 'red';
	}, 100);
	let debounceCvvCardSuccess = debounce(async (element: 'Number' | 'Cvv') => {
		let htmlBlock: HTMLElement | null = document.querySelector(`#${element}`);
		setIsInvalidSpreedlyIframe(false);
		if (!!htmlBlock) htmlBlock.style.color = '#001933';
	}, 100);

	// useEffect(() => {
	// 	let readySubscriptionId = paymentService.subscribeToSpreedlyReady(() => {
	// 		window.Spreedly.setStyle(
	// 			'number',
	// 			'width:100%;font-size: 16px;height: 38px;padding: 0 10px;box-sizing: border-box;border-radius: 5px;border: ' +
	// 				'1px solid #dedede; color: #001933; background-color: #ffffff; transition: border-color 300ms; '
	// 		);
	// 		window.Spreedly.setStyle(
	// 			'cvv',
	// 			'width:100%;font-size: 16px;height: 38px;padding: 0 10px;box-sizing: border-box;border-radius: 5px;' +
	// 				'border: 1px solid #dedede; color: #001933; background-color: #ffffff; transition: border-color 300ms; '
	// 		);
	// 		window.Spreedly.setFieldType('number', 'text');
	// 		window.Spreedly.setNumberFormat('prettyFormat');
	// 	});

	// 	let fieldEventSubscriptionId = paymentService.subscribeToSpreedlyFieldEvent(
	// 		(
	// 			name: 'number' | 'cvv',
	// 			type: 'focus' | 'blur' | 'mouseover' | 'mouseout' | 'input' | 'enter' | 'escape' | 'tab' | 'shiftTab',
	// 			activeEl: 'number' | 'cvv',
	// 			inputProperties: {
	// 				cardType?: string;
	// 				validNumber?: boolean;
	// 				validCvv?: boolean;
	// 				numberLength?: number;
	// 				cvvLength?: number;
	// 			}
	// 		) => {
	// 			if (name === 'number') {
	// 				if (type === 'focus') {
	// 					window.Spreedly.setStyle('number', 'border: 1px solid #004b98;');
	// 				}
	// 				if (type === 'blur') {
	// 					window.Spreedly.setStyle('number', 'border: 1px solid #dedede;');
	// 				}
	// 				if (type === 'mouseover') {
	// 					window.Spreedly.setStyle('number', 'border: 1px solid #004b98;');
	// 				}
	// 				if (type === 'mouseout') {
	// 					window.Spreedly.setStyle('number', 'border: 1px solid #dedede;');
	// 				}

	// 				if (type === 'input' && !inputProperties.validNumber) {
	// 					debounceCvvCardError('Number');
	// 					setRecoilExternalValue<Misc.CheckoutFormError>(globalState.checkoutFormHasError, {
	// 						cvv: !inputProperties.validCvv,
	// 						card: !inputProperties.validNumber
	// 					});
	// 				} else if (type === 'input' && inputProperties.validNumber) {
	// 					debounceCvvCardSuccess('Number');
	// 					setRecoilExternalValue<Misc.CheckoutFormError>(globalState.checkoutFormHasError, {
	// 						cvv: !inputProperties.validCvv,
	// 						card: !inputProperties.validNumber
	// 					});
	// 				}
	// 			}
	// 			if (name === 'cvv') {
	// 				if (inputProperties.cvvLength) isCvvLength = inputProperties.cvvLength;
	// 				if (type === 'focus') {
	// 					window.Spreedly.setStyle('cvv', 'border: 1px solid #004b98;');
	// 				}
	// 				if (type === 'blur') {
	// 					window.Spreedly.setStyle('cvv', 'border: 1px solid #dedede;');
	// 				}
	// 				if (type === 'mouseover') {
	// 					window.Spreedly.setStyle('cvv', 'border: 1px solid #004b98;');
	// 				}
	// 				if (type === 'mouseout') {
	// 					window.Spreedly.setStyle('cvv', 'border: 1px solid #dedede;');
	// 				}
	// 				if (type === 'input' && isCvvLength !== 3 && isCvvLength !== 4) {
	// 					debounceCvvCardError('Cvv');
	// 					setRecoilExternalValue<Misc.CheckoutFormError>(globalState.checkoutFormHasError, {
	// 						cvv: true,
	// 						card: !inputProperties.validNumber
	// 					});
	// 				} else if (type === 'input' && (isCvvLength === 3 || isCvvLength === 4)) {
	// 					debounceCvvCardSuccess('Cvv');
	// 					setRecoilExternalValue<Misc.CheckoutFormError>(globalState.checkoutFormHasError, {
	// 						cvv: false,
	// 						card: !inputProperties.validNumber
	// 					});
	// 				}
	// 			}
	// 		}
	// 	);

	// 	// Error response codes
	// 	// https://docs.spreedly.com/reference/api/v1/#response-codes
	// 	let errorSubscriptionId = paymentService.subscribeToSpreedlyError((errorMsg) => {
	// 		setIsFormFilledOutPayment(false);
	// 		let errorMessage = 'Please verify the information you have entered.';
	// 		if (ObjectUtils.isArrayWithData(errorMsg)) {
	// 			errorMessage = errorMsg[0].message;
	// 		}
	// 		rsToastify.error(errorMessage, 'Payment method invalid');
	// 	});
	// 	let paymentMethodSubscriptionId = paymentService.subscribeToSpreedlyPaymentMethod(
	// 		async (token: string, pmData: Api.Payment.PmData) => {
	// 			if (!checkoutUser || isInvalidSpreedlyIframe) return;

	// 			if (!pmData.verification_value) {
	// 				debounceCvvCardError('Cvv');
	// 				rsToastify.error('Please verify the information you have entered.', 'Payment method invalid');
	// 				setIsFormFilledOutPayment(false);
	// 				return;
	// 			}

	// 			const newdata = buildCheckoutUser();
	// 			const newCheckoutUser = { ...newdata, pmData: pmData };

	// 			const booking = {
	// 				item_name: verifiedAccommodation?.accommodationName,
	// 				item_id: get(accommodationStay, 'externalSystemId', verifiedAccommodation?.accommodationId),
	// 				price: NumberUtils.centsToDollars(prices?.accommodationTotalInCents),
	// 				item_brand: verifiedAccommodation?.destinationName,
	// 				item_category: get(accommodationStay, 'contentLists.RoomCategoryList.0.Name', null),
	// 				item_category2: get(accommodationStay, 'contentLists.RoomCategoryList.0.Name', null),
	// 				quantity: 1,
	// 				item_list_name: 'Rooms',
	// 				arrival_date: verifiedAccommodation?.arrivalDate,
	// 				depart_date: verifiedAccommodation?.departureDate,
	// 				total_cost: NumberUtils.centsToDollars(prices?.grandTotalCents),
	// 				currency: prices?.currencyCode
	// 			};
	// 			googleCheckoutProcess2();
	// 			googleCheckoutProcess3();
	// 			completeBooking(newCheckoutUser, booking);

	// 			// const  newdata =buildCheckoutUser(newCheckoutUser)
	// 			// setPmData(pmData);
	// 			// setCheckoutUser(newdata);
	// 			// userService.setCheckoutUserInLocalStorage(checkoutUser);
	// 			// if (params.stage !== CheckoutStages.BOOK) {
	// 			// 	await handleForwardButtonClick();
	// 			// }
	// 			popupController.close(UpdatePaymentMethodPopup);
	// 			// rsToastify.success('Payment method added successfully', 'Success');
	// 		}
	// 	);

	// 	return () => {
	// 		paymentService.unsubscribeToSpreedlyFieldEvent(fieldEventSubscriptionId);
	// 		paymentService.unsubscribeToSpreedlyError(errorSubscriptionId);
	// 		paymentService.unsubscribeToSpreedlyPaymentMethod(paymentMethodSubscriptionId);
	// 		paymentService.unsubscribeToSpreedlyReady(readySubscriptionId);
	// 	};
	// }, [checkoutUser]);

	const buttonHandler = (brandType: any) => {
		let companyid;
		if (brandType) {
			companyid = JSON.parse(brandType).themeId;
		} else {
			companyid = null;
		}

		let bgColor = '';
		switch (companyid) {
			case 3:
				bgColor = 'encorereunion';
				// bgColor = '#e51c14';
				break;
			case 78:
				bgColor = 'cottagesbyrentyl';
				break;

				bgColor = 'cottagesbyrentyl';
				break;

			case 9:
				bgColor = 'bearsdenorlando';
				break;
			case 10:
				bgColor = 'resortsbyspectrum';
				break;
			default:
				bgColor = 'yellow';
				break;
		}

		return (
			<LabelButton
				className={`${bgColor} primaryButtonPayment`}
				look={'none'}
				variant={'buttonMdLg'}
				label={'Review & Book'}
				buttonType={'submit'}
				// buttonRef={checkoutPaymentButtonRef}
				disabled={
					isSavePaymentMethod
						? !selectedPaymentMethord
						: !checkoutUser?.usePoints
						? isErrorOnForm || checkoutFormHasError.card || checkoutFormHasError.cvv
						: false
				}
			/>
		);
	};

	function handleButtonColor(brandType: any) {
		let companyid;
		if (brandType) {
			companyid = JSON.parse(brandType).themeId;
		} else {
			companyid = null;
		}
		let bgColor = '';
		switch (companyid) {
			case 3:
				bgColor = 'encorereunion';
				break;
			case 78:
				bgColor = 'cottagesbyrentyl';
				break;
			case 19:
				bgColor = 'cottagesbyrentyl';
				break;
			case 9:
				bgColor = 'bearsdenorlando';
				break;
			case 10:
				bgColor = 'resortsbyspectrum';
				break;
			default:
				bgColor = 'yellow';
				break;
		}

		return bgColor;
	}

	async function isMissingSubmissionData() {
		if (!(await infoForm.isValid())) {
			// If the form is not valid
			rsToastify.error('Please ensure you have filled out all fields', 'Missing or Incorrect Information');
			return true; // Button should be disabled
		} else {
			return false; // Button should not be disabled
		}
	}

	function isButtonDisabled() {
		switch (radioStep) {
			case 0:
				if (!user) {
					//if no user display the sign in for faster checkout button so do not disable
					return (
						!(!!stayParams || !reservationFilters.redeemPoints || !verifiedAccommodation) ||
						!isFilledOut ||
						!isFilledOutPaymnet ||
						checkoutFormHasError.card ||
						checkoutFormHasError.cvv
					);
				} else {
					if (!verifiedAccommodation) {
						return true;
					} else {
						return false;
					}
				}
			case 2:
				return (
					!(!!stayParams || !reservationFilters.redeemPoints || !verifiedAccommodation) ||
					!isFilledOut ||
					!isAffirmCheckout
				);

			default:
				return false;
		}
	}

	function buildCheckoutUser(): Misc.Checkout | undefined {
		let newCheckoutUser: Misc.Checkout = {
			...checkoutUser,
			personal: {
				firstName: infoForm.get(FormKeys.FIRST_NAME).value.toString(),
				lastName: infoForm.get(FormKeys.LAST_NAME).value.toString(),
				address1: infoForm.get(FormKeys.ADDRESS1).value.toString(),
				address2: infoForm.get(FormKeys.ADDRESS2).value.toString(),
				zip: infoForm.get(FormKeys.ZIP).value.toString(),
				city: infoForm.get(FormKeys.CITY).value.toString(),
				state: infoForm.get(FormKeys.STATE).value.toString(),
				country: infoForm.get(FormKeys.COUNTRY).value.toString(),
				email: infoForm.get(FormKeys.EMAIL).value.toString(),
				phone: infoForm.get(FormKeys.PHONE).value.toString()
			},
			billing: {
				firstName: infoForm.get(FormKeys.FIRST_NAME).value.toString(),
				lastName: infoForm.get(FormKeys.LAST_NAME).value.toString(),
				address1: infoForm.get(FormKeys.ADDRESS1).value.toString(),
				address2: infoForm.get(FormKeys.ADDRESS2).value.toString(),
				zip: infoForm.get(FormKeys.ZIP).value.toString(),
				city: infoForm.get(FormKeys.CITY).value.toString(),
				state: infoForm.get(FormKeys.STATE).value.toString(),
				country: infoForm.get(FormKeys.COUNTRY).value.toString(),
				email: infoForm.get(FormKeys.EMAIL).value.toString(),
				phone: infoForm.get(FormKeys.PHONE).value.toString()
			},
			shouldCreateUser: signUp,
			isExistingCard: isSavePaymentMethod,
			existingCardId: selectedPaymentMethord?.id
		};

		if (!reservationFilters.redeemPoints) {
			newCheckoutUser.paymentInfo = {
				expiration: paymentForm.get(FormKeys.EXPIRATION_DATE).value.toString(),
				nameOnCard: paymentForm.get(FormKeys.NAME_ON_CARD).value.toString()
			};
			setCheckoutUser(newCheckoutUser);
			// userService.setCheckoutUserInLocalStorage(newCheckoutUser);
		} else {
			delete newCheckoutUser.paymentInfo;
		}

		setCheckoutUser(newCheckoutUser);
		return newCheckoutUser;
	}

	function shouldTokenizePaymentInformation() {
		return !reservationFilters.redeemPoints && !!checkoutUser?.pmData;
	}

	useEffect(() => {
		if (anonymousUserID) {
			getAnonymousUserInfo(anonymousUserID);
		}
	}, [anonymousUserID]);

	const getAnonymousUserInfo = async (anonymousUserID: any) => {
		const res: any = await questionnaireService.getAnonymousUserInfo(anonymousUserID);

		setCheckoutUser({
			personal: {
				firstName: res.data.firstName || '',
				lastName: res.data.lastName || '',
				address1: res.data.address.split(',')[0] || '',
				address2: res.data.address.split(',')[1] || '',
				zip: res.data.zipCode || '',
				city: res.data.city || '',
				state: res.data.state || '',
				country: res.data.country || 'US',
				email: res.data.email || '',
				phone: res.data.phone || ''
			},
			billing: {
				firstName: res.data.firstName || '',
				lastName: res.data.lastName || '',
				address1: res.data.address.split(',')[0] || '',
				address2: res.data.address.split(',')[1] || '',
				zip: res.data.zipCode || '',
				city: res.data.city || '',
				state: res.data.state || '',
				country: res.data.country || 'US',
				email: res.data.email || '',
				phone: res.data.phone || ''
			},
			usePoints: !!reservationFilters?.redeemPoints,
			shouldCreateUser: checkoutUser?.shouldCreateUser || false,
			pmData: checkoutUser?.pmData,
			isAffirmCheckout: checkoutUser?.isAffirmCheckout,
			isExistingCard: false,
			existingCardId: undefined
		});

		// const newCheckoutUser = buildCheckoutUser(res.data);
		// setCheckoutUser(newCheckoutUser);
		// await userService.setCheckoutUserInLocalStorage(newCheckoutUser);
	};

	useEffect(() => {
		const data: any = {
			arrivalDate: stayParams.arrivalDate,
			departureDate: stayParams.departureDate,
			adultCount: stayParams.adults,
			childCount: stayParams.children,
			name: verifiedAccommodation?.destinationName,
			price: JSON.stringify({
				totalBaseRate: verifiedAccommodation?.prices?.accommodationTotalInCents,
				taxAndFeeTotalInCents: verifiedAccommodation?.prices?.taxAndFeeTotalInCents,
				itineraryId: itineraryId?.tempitineraryId,
				totalIncludingTaxesAndFees: verifiedAccommodation?.prices.grandTotalCents
			}),

			reservationId: itineraryId?.reservationId
		};

		setAnonymousUserData(data);
	}, [verifiedAccommodation, itineraryId, mountedRefvalue, mountedRef]);

	mountedRef.current = anonymousUserData;

	useEffect(() => {
		return () => {
			onVisibilityChange();
		};
	}, []);

	useEffect(() => {
		window.addEventListener('beforeunload', alertUser);
		// window.addEventListener('unload', handleTabClosing);
		return () => {
			window.removeEventListener('beforeunload', alertUser);
			// window.removeEventListener('unload', handleTabClosing);
		};
	});

	const handleTabClosing = () => {
		onVisibilityChange();
	};

	const alertUser = (event: any) => {
		event.preventDefault();
		// event.returnValue = '';
		onVisibilityChange();
	};

	async function onVisibilityChange() {
		if (itineraryId?.reservationId && anonymousUserID) {
			questionnaireService.upDateAnonymousReservation(itineraryId?.reservationId, anonymousUserID);
		} else if (mountedRefvalue.current && !user) {
			let url = new URL(window.location.href);

			let params = new URLSearchParams(url.search);

			// Add or update the 'anonymousUserID' parameter
			params.set('anonymousUserID', mountedRefvalue.current || anonymousUserID); // Replace 'yourValue' with the actual value you want to set

			url.search = params.toString();

			// Get the modified URL
			let modifiedURL = url.toString();

			const temp = {
				...mountedRef.current,
				anonymousUserId: mountedRefvalue.current,
				anonymousUserURL: modifiedURL,
				abandonmentPlace: JSON.stringify({
					abandonmentDateStamp: moment().toDate().toISOString(),
					abandonmentPlace: {
						page: 'booking/checkout',
						section: 'Info'
					}
				})
			};

			questionnaireService.sendAnonymousReservation(temp);
		}
	}

	useEffect(() => {
		let id = router.subscribeToBeforeRouterNavigate(async (newPath) => {
			let disableNavGate = newPath === '/booking/checkout' && params.stage === CheckoutStages.CONFIRMATION;
			if (disableNavGate) rsToastify.info('You cannot go back after a successful checkout', 'Sorry!');

			return disableNavGate;
		});

		async function sendAnonymousUserInfo(data?: any) {
			try {
				if (!user && isFilledOutAnonymousUser) {
					const tempdata = {
						firstName: infoForm.get(FormKeys.FIRST_NAME).value.toString(),
						lastName: infoForm.get(FormKeys.LAST_NAME).value.toString(),
						address: `${infoForm.get(FormKeys.ADDRESS1).value.toString()},${infoForm
							.get(FormKeys.ADDRESS2)
							.value.toString()}`,
						zipCode: infoForm.get(FormKeys.ZIP).value.toString(),
						city: infoForm.get(FormKeys.CITY).value.toString(),
						state: infoForm.get(FormKeys.STATE).value.toString(),
						country: infoForm.get(FormKeys.COUNTRY).value.toString(),
						email: infoForm.get(FormKeys.EMAIL).value.toString(),
						phone: Number(infoForm.get(FormKeys.PHONE).value.toString())
					};
					let res: any;

					if (data) {
						res = await questionnaireService.upDateAnonymousUserInfo(tempdata, data);
					} else {
						res = await questionnaireService.sendAnonymousUserInfo(tempdata);
					}

					setTempAnonymousUserId(res.data.id);
					localStorage.setItem('TempAnonymousUser', JSON.stringify(res.data));

					// tempAnonymousUserId = res.data.id;
				}
			} catch (error: any) {
				const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
				undefinedHandler(message);
			}
		}

		const TempAnonymousUser: any = JSON.parse(localStorage.getItem('TempAnonymousUser') ?? 'null');

		if (!TempAnonymousUser) {
			if (anonymousUserID) {
				sendAnonymousUserInfo(anonymousUserID).catch(console.error);
			} else {
				sendAnonymousUserInfo().catch(console.error);
			}
		} else {
			if (
				TempAnonymousUser &&
				TempAnonymousUser.email &&
				TempAnonymousUser.email !== checkoutUser?.personal.email
			) {
				if (anonymousUserID) {
					sendAnonymousUserInfo(anonymousUserID).catch(console.error);
				} else {
					sendAnonymousUserInfo().catch(console.error);
				}
			}
		}

		return () => {
			router.unsubscribeFromBeforeRouterNavigate(id);
		};
	}, [isFilledOutAnonymousUser]);

	async function completeBooking(checkoutUser?: any, bookingGa4?: any) {
		if (!verifiedAccommodation || !checkoutUser) return;
		const stays: Api.Reservation.Req.Itinerary.Stay[] = [
			{
				accommodationId: verifiedAccommodation.accommodationId,
				numberOfAccommodations: 1,
				arrivalDate: verifiedAccommodation.arrivalDate,
				departureDate: verifiedAccommodation.departureDate,
				adultCount: verifiedAccommodation.adultCount,
				childCount: verifiedAccommodation.childCount,
				rateCode: verifiedAccommodation.rateCode,
				upsellPackages: verifiedAccommodation.upsellPackages,
				guest: {
					firstName: checkoutUser?.personal?.firstName,
					lastName: checkoutUser?.personal?.lastName,
					phone: checkoutUser?.personal?.phone.toString(),
					email: checkoutUser?.personal?.email,
					Comments: checkoutUser.isAffirmCheckout ? '***Affirm Payment***' : ''
				},
				additionalDetails: ''
			}
		];
		let data: Api.Reservation.Req.Itinerary.Create;

		if (!reservationFilters.redeemPoints && !!checkoutUser.pmData && !checkoutUser.isExistingCard) {
			data = {
				destinationId: destinationId,
				stays,
				isAffirmCheckout: checkoutUser?.isAffirmCheckout ? 1 : 0,
				payment: {
					pmData: {
						...checkoutUser.pmData,
						first_six_digits: Number(checkoutUser.pmData.first_six_digits),
						last_four_digits: Number(checkoutUser.pmData.last_four_digits)
					},
					isPrimary: checkoutUser?.isAffirmCheckout ? 0 : 1,
					cardToken: checkoutUser.pmData.token,
					offsiteLoyaltyEnrollment: 0
				}
			};
		} else {
			data = {
				destinationId: destinationId,
				stays
			};
		}
		if (checkoutUser.isExistingCard && !checkoutUser.usePoints) {
			data.paymentMethodId = checkoutUser.existingCardId;
		} else {
			delete data.paymentMethodId;
		}
		try {
			if (user) data.userId = user.id;
			if (!user) data.signUp = checkoutUser.shouldCreateUser ? 1 : 0;
			if (userPrimaryAddress) {
				data.existingAddressId = userPrimaryAddress.id;
			} else {
				data.newAddress = {
					type: showBillingAddress ? (checkoutUser.billing ? 'BILLING' : 'BOTH') : 'BOTH',
					address1: showBillingAddress
						? checkoutUser.billing
							? checkoutUser.billing?.address1
							: checkoutUser?.personal?.address1
						: checkoutUser?.personal?.address1,
					address2: showBillingAddress
						? checkoutUser.billing
							? checkoutUser.billing?.address2
							: checkoutUser?.personal?.address2
						: checkoutUser?.personal?.address2,
					city: showBillingAddress
						? checkoutUser.billing
							? checkoutUser.billing?.city
							: checkoutUser?.personal?.city
						: checkoutUser?.personal?.city,
					state: showBillingAddress
						? checkoutUser.billing
							? checkoutUser.billing?.state
							: checkoutUser?.personal?.state
						: checkoutUser?.personal?.state,
					zip: showBillingAddress
						? checkoutUser.billing
							? checkoutUser.billing?.zip
							: checkoutUser?.personal?.zip
						: checkoutUser?.personal?.zip,
					country: showBillingAddress
						? checkoutUser.billing
							? checkoutUser.billing?.country
							: checkoutUser?.personal?.country
						: checkoutUser?.personal?.country,
					isDefault: 1
				};
			}

			const res = await reservationService.createItinerary(data);

			setItineraryId({ tempitineraryId: res.itineraryId, reservationId: res.parentReservationId });
			setPhoneError(false);

			setShowExpectedPoint(false);

			googleCheckoutProcess4();
			googleTagEcommerce(res);
			metaCapi.purchase(res);
			setTransactionData(res);
			setCompleteTransaction(true);
			setBookedStays(
				res.stays.map((stay) => {
					return {
						images: stay.accommodation.media,
						title: stay.accommodation.name,
						price: checkoutUser?.usePoints
							? stay.priceDetail.grandTotalPoints
							: stay.priceDetail?.grandTotalCents,
						currencyCode: stay.priceDetail.currencyCode,
						paymentMethod: res.paymentMethod,
						dateBooked: new Date(),
						confirmationNumber: stay.externalConfirmationId
					};
				})
			);
			if (res.itineraryId) {
				setCheckoutUser(undefined);
			}
			const booking = {
				...bookingGa4,
				transaction_id: res?.stays[0]?.externalConfirmationId
			};

			const transactionTotal = res.stays.map((stay) => {
				return checkoutUser?.usePoints
					? stay.priceDetail.grandTotalPoints
					: NumberUtils.centsToDollars(stay.priceDetail?.grandTotalCents); // Ensure this returns a number
			});

			TagManager.dataLayer({
				dataLayer: {
					event: 'booking',
					transactionId: res.stays[0].externalConfirmationId,
					transactionTotal: [transactionTotal.reduce((acc, curr) => acc + curr, 0)],
					ecommerce: {
						checkout: {
							products: [booking]
						}
					}
				}
			});
			popupController.close(SpinningLoaderPopup);
			popupController.open<QuestionnairePopupProps>(QuestionnairePopup, {
				confirmationNumber: res.itineraryId,
				guestName: res.stays[0].guest.firstName,
				firstName: res.stays[0].guest.firstName,
				lastName: res.stays[0].guest.lastName,
				email: res.stays[0].guest.email,
				arrivalDate: res.stays[0].arrivalDate,
				departureDate: res.stays[0].departureDate,
				adultCount: res.stays[0].adultCount,
				childCount: res.stays[0].childCount,
				destinationName: res.destination.name,
				price: {
					baseRate: res.stays[0].priceDetail.accommodationTotalInCents,
					total: res.stays[0].priceDetail.grandTotalCents
				},
				userId: res.userId
			});
			// await handleForwardButtonClick();
		} catch (e: any) {
			rsToastify.error(
				WebUtils.getRsErrorMessage(e, 'An error occurred, unable to book your reservation.'),
				'Server Error!',
				{ autoClose: false, position: 'top-center' }
			);
			popupController.close(SpinningLoaderPopup);
			const message = `Getting error :${e.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	async function handleButtonClick() {
		if (!(await infoForm.isValid())) {
			setInfoForm(infoForm.clone());
			rsToastify.error('Please ensure you have filled out all fields', 'Missing or Incorrect Information');
			return;
		}

		if (
			!isAffirmCheckout &&
			!isSavePaymentMethod &&
			!reservationFilters.redeemPoints &&
			!(await paymentForm.isValid())
		) {
			setPaymentForm(paymentForm.clone());
			// props.isFormFilledOutCallback(false);
			rsToastify.error('Please ensure you have filled out all fields', 'Missing or Incorrect Information');
			return;
		}

		popupController.open(SpinningLoaderPopup);

		const booking = {
			item_name: verifiedAccommodation?.accommodationName,
			item_id: get(accommodationStay, 'externalSystemId', verifiedAccommodation?.accommodationId),
			price: NumberUtils.centsToDollars(prices?.accommodationTotalInCents),
			item_brand: verifiedAccommodation?.destinationName,
			item_category: get(accommodationStay, 'contentLists.RoomCategoryList.0.Name', null),
			item_category2: get(accommodationStay, 'contentLists.RoomCategoryList.0.Name', null),
			quantity: 1,
			item_list_name: 'Rooms',
			arrival_date: verifiedAccommodation?.arrivalDate,
			depart_date: verifiedAccommodation?.departureDate,
			total_cost: NumberUtils.centsToDollars(prices?.grandTotalCents),
			currency: prices?.currencyCode
		};

		if (isAffirmCheckout && isAffirmCheckout?.isAffirmCheckout && radioStep == 2) {
			googleCheckoutProcess2();
			googleCheckoutProcess3();
			completeBooking(isAffirmCheckout, booking);
		} else {
			if (!isSavePaymentMethod && !reservationFilters.redeemPoints && !(await paymentForm.isValid())) {
				setPaymentForm(paymentForm.clone());
				rsToastify.error('Please ensure you have filled out all fields', 'Missing or Incorrect Information');
				return;
			} else {
				if (isSavePaymentMethod && selectedPaymentMethord?.id && !(await paymentForm.isValid())) {
					const data = buildCheckoutUser();
					googleCheckoutProcess2();
					googleCheckoutProcess3();
					completeBooking(data, booking);
				} else {
					let newCreditCardObj: any = {};
					newCreditCardObj.full_name = paymentForm.get(FormKeys.NAME_ON_CARD).value.toString();
					newCreditCardObj.isPrimary = true;
					newCreditCardObj.month = parseInt(
						paymentForm.get(FormKeys.EXPIRATION_DATE).value.toString().split('/')[0]
					);
					newCreditCardObj.year = parseInt(
						paymentForm.get(FormKeys.EXPIRATION_DATE).value.toString().split('/')[1]
					);
					buildCheckoutUser();
					// window.Spreedly.tokenizeCreditCard(newCreditCardObj);
				}
			}
		}
	}

	function renderRemainingViews() {
		let userCheckout: any = checkoutUser;
		if (checkoutUser?.isExistingCard) {
			let selectedPaymentMethord = user?.paymentMethods.find((card) => card.id == checkoutUser.existingCardId);

			userCheckout = {
				pmData: {
					first_name: selectedPaymentMethord?.nameOnCard || '',
					last_name: ' ',
					cardType: selectedPaymentMethord?.type || '',
					card_type: selectedPaymentMethord?.type || '',
					last_four_digits:
						selectedPaymentMethord?.cardNumber.slice(
							selectedPaymentMethord.cardNumber.length - 4,
							selectedPaymentMethord.cardNumber.length
						) || ''
				}
			};
		}

		return (
			<>
				{itineraryId && (
					<>
						{bookedStays[0]?.images?.length >= 3 && (
							<ConfirmationImageSummary
								images={bookedStays[0]?.images.slice(0, 3).map((image) => image?.urls?.imageKit)}
							/>
						)}
						<ThankYouCard confirmationNumber={bookedStays[0]?.confirmationNumber} />
					</>
				)}
				{checkoutUser && (
					<React.Fragment>
						<PersonalInformation
							personalInfo={checkoutUser.personal}
							isBillingInfo={showBillingAddress}
							billingInfo={checkoutUser.billing || checkoutUser.personal}
							onChangeCheckbox={(value) => {
								setShowBillingAddress(value);
								if (!value) {
									setCheckoutUser((prev) => {
										if (!prev) return;
										let newData = { ...prev };
										newData.billing = newData.personal;
										return newData;
									});
								}
							}}
							onEditPersonalClickCallback={
								!itineraryId
									? async () => {
											if (!checkoutUser) return;
											popupController.open<UpdateAddressPopupProps>(UpdateAddressPopup, {
												// addressFormData: checkoutUser.billing || checkoutUser.personal,
												info: checkoutUser.personal,
												isPersonalInfo: true,
												onUpdate: (value) => {
													setCheckoutUser((prev) => {
														if (!prev) return;
														let newData = { ...prev };
														// if (isPersonal) {
														newData.personal = value;
														return newData;
														// }
														// newData.billing = value;
														// return newData;
													});
													rsToastify.success('Updated');
													popupController.close(UpdateAddressPopup);
												}
											});
									  }
									: undefined
							}
							onEditBillingClickCallback={
								!itineraryId
									? async () => {
											if (!checkoutUser) return;
											popupController.open<UpdateAddressPopupProps>(UpdateAddressPopup, {
												// addressFormData: checkoutUser.billing || checkoutUser.personal,
												// personalInfo: checkoutUser.personal,
												info: checkoutUser.billing || checkoutUser.personal,
												isPersonalInfo: false,
												onUpdate: (value) => {
													setCheckoutUser((prev) => {
														if (!prev) return;
														let newData = { ...prev };
														// if (isPersonal) {
														// 	newData.personal = value;
														// 	return newData;
														// }
														newData.billing = value;
														return newData;
													});
													rsToastify.success('Updated');
													popupController.close(UpdateAddressPopup);
												}
											});
									  }
									: undefined
							}
						/>
						<PaymentMethod
							userCheckout={
								bookedStays.length == 0
									? userCheckout
									: {
											pmData: {
												...bookedStays[0].paymentMethod,
												card_type: bookedStays[0].paymentMethod?.type,
												first_name: bookedStays[0].paymentMethod?.nameOnCard,
												last_name: ' ',
												last_four_digits: bookedStays[0].paymentMethod?.last4
											}
									  }
							}
							isAffirmCheckout={isAffirmCheckout}
							radioStepId={radioStep}
							userPrimaryPaymentMethod={userPrimaryPaymentMethod}
							onEditClickCallback={
								!itineraryId && !checkoutUser.isExistingCard
									? async () => popupController.open(UpdatePaymentMethodPopup)
									: undefined
							}
						/>
					</React.Fragment>
				)}

				{verifiedAccommodation && (
					<Policies
						checkInTime={StringUtils.convertTwentyFourHourTime(
							verifiedAccommodation.policies.find((policy) => policy.type === 'CheckIn')?.value || '1600'
						)}
						checkOutTime={StringUtils.convertTwentyFourHourTime(
							verifiedAccommodation.policies.find((policy) => policy.type === 'CheckOut')?.value || '1000'
						)}
						bookingDescription={verifiedAccommodation.accommodationName}
						guaranteePolicy={
							verifiedAccommodation.policies.find((policy) => policy.type === 'Guarantee')?.value ||
							'10% of the total price is required at the time of booking to guarantee the reservation.'
						}
						cancellationPolicy={checkoutPolicy || ''}
					/>
				)}
				{itineraryId && (
					<CheckoutReservationSummary
						orders={bookedStays.map((stay) => {
							return {
								image:
									stay.images.find((image) => image.isPrimary)?.urls.imageKit ||
									stay.images[0].urls.imageKit,
								dateBooked: DateUtils.formatDate(new Date(stay.dateBooked), 'MM-DD-YY'),
								price: stay.price,
								title: stay.title,
								currencyCode: stay.currencyCode,
								resortName: destinationName,
								resortLogo: destinationLogo,
								usePoints: !!reservationFilters.redeemPoints
							};
						})}
					/>
				)}
			</>
		);
	}

	return (
		<Page className={'rsNewCheckoutFlowPage'}>
			<div className={'printableContentWrapper'} ref={printRef}>
				{itineraryId ? (
					<div className={'leftColumn'}>{renderRemainingViews()}</div>
				) : (
					<div className={'leftColumn'}>
						{/* <CheckOutUserInfoCard
							onContinue={handleForwardButtonClick}
							isFormFilledOutCallback={(value,data) => {
								
								
								setIsFormFilledOut(value);
							}}
							onCheckoutInfoButtonClick={(buttonRef) => {
								setCheckoutButtonRef(buttonRef);
							}}
						/> */}
						{!user && !!verifiedAccommodation && (
							<Banner
								signUp={signUp}
								onSelect={() => setSignUp(true)}
								onDeselect={() => setSignUp(false)}
								bookingData={verifiedAccommodation}
							/>
						)}
						<CheckOutUserInfoFrom
							form={infoForm}
							onUpdate={updateForm}
							stateKey={stateKey}
							phoneError={phoneError}
							onFormError={(isError) => {
								// setErrorOnForm(isError);
							}}
						/>

						{/* <hr className="cardDivider" /> */}
						{/* <CheckOutPaymentCard
								onRedemption={redeemMyPointHandler}
								onContinue={handleForwardButtonClick}
								bookingData={verifiedAccommodation}
								userPrimaryPaymentMethod={userPrimaryPaymentMethod}
								onCheckoutPaymentButtonClick={(buttonRef) => {
									setCheckoutPaymentButtonRef(buttonRef);
								}}
								isFormFilledOutCallback={(value) => {
									setIsFormFilledOutPayment(value);
								}}
							/> */}

						{/* <Box className={`rsCheckOutPaymentCard`}>
							{!reservationFilters.redeemPoints && (
								<Box className="payment-tabs">
									{!checkoutUser?.usePoints && user && existingCardList.length >= 0 ? (
										<>
											<div
												className={`${
													radioStep === 0
														? 'payment-option-button'
														: 'payment-option-button-active'
												}`}
												onClick={() => {
													setIsSavePaymentMethod(true);
													setRadioStep(0);
													setRadioStepNew(false);
													setRecoilExternalValue<Misc.CheckoutFormError>(
														globalState.checkoutFormHasError,
														{
															cvv: true,
															card: true
														}
													);
												}}
											>
												Credit/Debit Card
											</div>
											{isAffirmPay === 1 && (
												<div
													className={` ${
														radioStep === 2
															? 'payment-option-button'
															: 'payment-option-button-active'
													}`}
													onClick={() => {
														setIsSavePaymentMethod(false);
														debounceAffirmPromotionalMessage();
														setRadioStep(2);
														setRadioStepNew(false);
														setRecoilExternalValue<Misc.CheckoutFormError>(
															globalState.checkoutFormHasError,
															{
																cvv: true,
																card: true
															}
														);
													}}
												>
													Monthly Payment
												</div>
											)}
										</>
									) : (
										<>
											<div
												className={` ${
													radioStep === 0
														? 'payment-option-button'
														: 'payment-option-button-active'
												}`}
												onClick={() => {
													setIsSavePaymentMethod(false);
													setRadioStep(0);
													setRadioStepNew(false);
													setRecoilExternalValue<Misc.CheckoutFormError>(
														globalState.checkoutFormHasError,
														{
															cvv: true,
															card: true
														}
													);
												}}
											>
												Credit/Debit Card
											</div>
											{isAffirmPay === 1 && (
												<div
													className={` ${
														radioStep === 2
															? 'payment-option-button'
															: 'payment-option-button-active'
													}`}
													onClick={() => {
														setIsSavePaymentMethod(false);
														debounceAffirmPromotionalMessage();
														setRadioStep(2);
														setRadioStepNew(false);
														setRecoilExternalValue<Misc.CheckoutFormError>(
															globalState.checkoutFormHasError,
															{
																cvv: true,
																card: true
															}
														);
													}}
												>
													Monthly Payments
												</div>
											)}
										</>
									)}
								</Box>
							)}
							<form>
								{!reservationFilters.redeemPoints && (
									<>
										{radioStep === 0 && (
											<Box className="cardSelectWarper">
												<>
													{user && (
														<Box className="cardSelectWarper-iner">
															<Box className="FilterBarDropDown">
																<FilterBarDropDown
																	onChangeCallBack={() => {
																		setRadioStepNew(false);
																		setIsSavePaymentMethod(true);
																	}}
																	fieldName={'Selected Card'}
																	onClearCallback={() => {
																		setPaymentMethod(null);
																	}}
																	title={
																		selectedPaymentMethord
																			? `${selectedPaymentMethord.nameOnCard} - ${
																					selectedPaymentMethord.type
																			  } ending in ${selectedPaymentMethord.cardNumber.slice(
																					selectedPaymentMethord.cardNumber
																						.length - 4,
																					selectedPaymentMethord.cardNumber
																						.length
																			  )}`
																			: 'Select payment method'
																	}
																	dropdownContentClassName="cardDropdown"
																	hasIconSpinAnimation
																>
																	<Box padding={24}>
																		{existingCardList.map((card, index) => {
																			if (!card?.metaData?.isAffirmCheckout) {
																				return (
																					<LabelRadioButton
																						key={index}
																						radioName="cardSelector"
																						value="sortHigh"
																						checked={
																							card.id ==
																							selectedPaymentMethord?.id
																						}
																						text={`${card.nameOnCard} - ${
																							card.type
																						} ending in ${card.cardNumber.slice(
																							card.cardNumber.length - 4,
																							card.cardNumber.length
																						)}`}
																						onSelect={() => {
																							setIsSavePaymentMethod(
																								true
																							);
																							setPaymentMethod(card);
																						}}
																						labelVariant="h6"
																						className="priceHighRadio"
																					/>
																				);
																			}
																		})}
																	</Box>
																</FilterBarDropDown>
																<Box
																	style={{
																		display: 'flex',
																		justifyContent: 'center',
																		alignItems: 'center'
																	}}
																	onClick={() => {
																		setIsSavePaymentMethod(false);
																		// setRadioStep(1);
																		setRadioStep(0);
																		setRadioStepNew(true);
																	}}
																>
																	<p style={{ margin: '20px', cursor: 'pointer' }}>
																		Add New Card
																	</p>
																</Box>
															</Box>
															{selectedPaymentMethord && radioStep == 0 && !radioStepNew && (
																<Box className="selectedCardView">
																	<PaymentMethod
																		userCheckout={{
																			pmData: {
																				first_name:
																					selectedPaymentMethord.nameOnCard,
																				last_name: ' ',
																				cardType: selectedPaymentMethord.type,
																				card_type: selectedPaymentMethord.type,
																				last_four_digits:
																					selectedPaymentMethord.cardNumber.slice(
																						selectedPaymentMethord
																							.cardNumber.length - 4,
																						selectedPaymentMethord
																							.cardNumber.length
																					)
																			}
																		}}
																		isAffirmCheckout={isAffirmCheckout}
																		radioStepId={radioStep}
																		userPrimaryPaymentMethod={undefined}
																		onEditClickCallback={undefined}
																	/>

																	{!checkoutUser?.usePoints &&
																		user &&
																		existingCardList.length > 0 &&
																		isMobile &&
																		checkPointEligibility(
																			user?.availablePoints,
																			verifiedAccommodation?.prices
																				.grandTotalPoints
																		) && (
																			<RedeemRewardCard
																				bookingData={verifiedAccommodation}
																				onRedemption={redeemMyPointHandler}
																			/>
																		)}
																</Box>
															)}
														</Box>
													)}
													{canShowCardForm() && (
														<CardInfoCard
															form={paymentForm}
															onUpdate={updatePaymentForm}
															onFormError={(isError) => {
																// setErrorOnForm(isError);
															}}
														/>
													)}
												</>
											</Box>
										)}
										{radioStep == 2 && !isAffirmCheckout && (
											<div className={'rsPersonalInformation-affim'}>
												<div className={'affirm-as-low-as textInfo'}>
													<p className={'payLaterTextInfo'}>
														Book now, pay later with
														<img className={'affirmlogo'} src={affirm_base64} />
													</p>
												</div>
												<p
													className="affirm-as-low-as textInfo"
													data-page-type="category"
													data-amount={verifiedAccommodation?.prices?.grandTotalCents}
												></p>
												<div className={'affirm-as-low-as textInfo'}>
													<p className="textInfo">
														Affirm makes it easy to book your getaway today, then pay over
														time - even past the dates you book. Find out if you're eligible
														by filling out a few pieces of basic information. Your credit
														score won't be affected by checking.
													</p>
												</div>
												<div className={'affirm-as-low-as textInfo'}>
													<p style={{ color: '#4A4AF4' }} className={'payLaterTextInfo'}>
														Import information
													</p>
												</div>
												<div className={'affirm-as-low-as textInfo'}>
													<ul>
														<li>
															This rate is non-refundable. If you change or cancel your
															booking you will not get a refund or credit to use for a
															future stay. This policy will apply regardless of COVID-19,
															subject to any local consumer laws.
														</li>
														<li>
															No refunds will be issued for late check-in or early
															check-out.
														</li>
														<li>Stay extensions require a new reservation.</li>
														<li>Front desk staff will greet guests on arrival.</li>
													</ul>
												</div>
												<div className={'affirm-as-low-as textInfo'}>
													<p className="textInfo">
														By clicking on the button below, I acknowledge that I have
														reviewed the Privacy Statement and Government Travel Advice and
														have reviewed and accept the Rules & Restrictions and Terms of
														Use .
													</p>
												</div>
												<div
													style={{ backgroundColor: '#4A4AF4', color: 'white' }}
													className="continueButton"
													onClick={() => {
														if (isAffirmCheckout) {
															rsToastify.error(
																'Continue to book ',
																'you are already pay with installment payment'
															);
														} else {
															affirmCheckout();
														}
													}}
												>
													Continue to Affirm
												</div>
												<div className={'affirm-as-low-as textInfo'}>
													<p className="textInfo">
														We use secure transmission and encrypted storage to protect your
														personal information.
													</p>
												</div>
												<div className={'affirm-as-low-as textInfo'}>
													<p className="textInfo">
														This payment will be processed in the U.S. This does not apply
														when the travel provider (airline/hotel/rail, etc.) processes
														your payment.
													</p>
												</div>
											</div>
										)}
									</>
								)}
							</form>
						</Box> */}

						<hr />
						{verifiedAccommodation && (
							<Policies
								checkInTime={StringUtils.convertTwentyFourHourTime(
									verifiedAccommodation.policies.find((policy) => policy.type === 'CheckIn')?.value ||
										'1600'
								)}
								checkOutTime={StringUtils.convertTwentyFourHourTime(
									verifiedAccommodation.policies.find((policy) => policy.type === 'CheckOut')
										?.value || '1000'
								)}
								bookingDescription={verifiedAccommodation.accommodationName}
								guaranteePolicy={
									verifiedAccommodation.policies.find((policy) => policy.type === 'Guarantee')
										?.value ||
									'10% of the total price is required at the time of booking to guarantee the reservation.'
								}
								cancellationPolicy={checkoutPolicy || ''}
							/>
						)}

						<hr />

						<Button
							className={`printConfirmButton ${handleButtonColor(resortId)}`}
							look={'containedPrimary'}
							onClick={handleButtonClick}
							disabled={isButtonDisabled()} // Await the result of isButtonDisabled()
						>
							<Label variant={'buttonMdLg'}>Review & Book</Label>
						</Button>
					</div>
				)}

				<div className={'bookingSummaryColumn'}>
					<PrintableQrCode qrCodeValue={'https://spireloyalty.com/'} />

					{!itineraryId && (
						<Box className="reviewAndBook">
							<Button
								className={`printConfirmButton  ${handleButtonColor(resortId)}`}
								look={'containedPrimary'}
								onClick={handleButtonClick}
								disabled={isButtonDisabled()} // Await the result of isButtonDisabled()
							>
								<Label variant={'buttonMdLg'}>Review & Book</Label>
							</Button>
						</Box>
					)}

					{!!verifiedAccommodation ? (
						<BookingSummaryCard
							onRedeem={redemptionHandler}
							bookingData={verifiedAccommodation}
							canHide={false}
							usePoints={!!reservationFilters.redeemPoints}
							isOpen={false}
							showExpectedPoint={showExpectedPoint}
						/>
					) : (
						<Loader />
					)}
				</div>
			</div>
		</Page>
	);
};

export default CheckoutPage;
