import * as React from 'react';
import './PasswordValidator.scss';
import { useEffect, useState } from 'react';
import IconLabel from '../../components/iconLabel/IconLabel';
import {
	lowerCasePattern,
	uppercasePattern,
	numberPattern,
	specialCharPattern,
	whiteSpacePattern
} from '../../utils/utils';
import { rsToastify } from '../../lib/@bit/redsky.framework.rs.toastify';

interface PasswordValidatorProps {
	password: string;
	onValidationStatusUpdate?: (isValid: boolean) => void;
}

const PasswordValidator: React.FC<PasswordValidatorProps> = (props) => {
	const [lengthCheck, setLengthCheck] = useState<boolean>(false);
	const [uppercaseCheck, setUppercaseCheck] = useState<boolean>(false);
	const [lowerCaseCheck, setLowercaseCheck] = useState<boolean>(false);
	const [numberCaseCheck, setNumberCaseCheck] = useState<boolean>(false);
	const [specialCharCheck, setSpecialCharCheck] = useState<boolean>(false);

	useEffect(() => {
		let password = props.password?.toString() || '';
		const isLengthProper = password.length >= 8;
		const isUppercase = !!password.match(uppercasePattern);
		const isNumberCase = !!password.match(numberPattern);
		const isLowercase = !!password.match(lowerCasePattern);
		const isSpecialChar = !!password.replace(whiteSpacePattern, '').match(specialCharPattern);
		const isWhiteSpace = !!password.match(whiteSpacePattern);
		if (isWhiteSpace) {
			rsToastify.error('Password Cannot Contain Spaces', 'Invalid Password');
		}
		setLengthCheck(isLengthProper);
		setUppercaseCheck(isUppercase);
		setNumberCaseCheck(isNumberCase);
		setLowercaseCheck(isLowercase);
		setSpecialCharCheck(isSpecialChar);
		props.onValidationStatusUpdate &&
			props.onValidationStatusUpdate(
				isLengthProper && isUppercase && isNumberCase && isLowercase && isSpecialChar && !isWhiteSpace
			);
	}, [props.password]);

	return (
		<div className={`rsPasswordValidator`}>
			<IconLabel
				iconImg={lengthCheck ? 'icon-solid-check' : 'icon-close'}
				labelName={'At least 8 characters'}
				iconPosition={'left'}
				labelVariant={'body1'}
				className={lengthCheck ? 'success' : 'failure'}
				iconSize={16}
			/>
			<IconLabel
				iconImg={uppercaseCheck ? 'icon-solid-check' : 'icon-close'}
				labelName={'At least 1 uppercase letter'}
				iconPosition={'left'}
				labelVariant={'body1'}
				className={uppercaseCheck ? 'success' : 'failure'}
				iconSize={16}
			/>
			<IconLabel
				iconImg={lowerCaseCheck ? 'icon-solid-check' : 'icon-close'}
				labelName={'At least 1 lowercase letter'}
				iconPosition={'left'}
				labelVariant={'body1'}
				className={lowerCaseCheck ? 'success' : 'failure'}
				iconSize={16}
			/>
			<IconLabel
				iconImg={numberCaseCheck ? 'icon-solid-check' : 'icon-close'}
				labelName={'At least 1 number'}
				iconPosition={'left'}
				labelVariant={'body1'}
				className={numberCaseCheck ? 'success' : 'failure'}
				iconSize={16}
			/>
			<IconLabel
				iconImg={specialCharCheck ? 'icon-solid-check' : 'icon-close'}
				labelName={'At least 1 special character'}
				iconPosition={'left'}
				labelVariant={'body1'}
				className={specialCharCheck ? 'success' : 'failure'}
				iconSize={16}
			/>
		</div>
	);
};

export default PasswordValidator;
